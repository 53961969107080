import React from "react";
import HowItWork1 from "../img/how-it-work1.svg";
import HowItWork2 from "../img/how-it-work2.svg";

const FounderFriendly = () => {
  return (
    <>
      <section className="founder-friendly-main">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="founder-friendly-header">
                <h1>HOW IT WORKS</h1>
                <p>Founder friendly flexible funding</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="founder-content founder-content-left">
                <div className="d-flex justify-content-center">
                  <img src={HowItWork1} alt="equity" />
                </div>
                <p>
                  Run through our simple, speedy, sign up to find out how large
                  an advance you're eligible for (£100k to £1.5m). We agree a
                  fixed fee and target term, with no personal guarantees or loss
                  of equity
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="founder-content founder-content-right">
              <div className="d-flex justify-content-center">
                <img src={HowItWork2} alt="growth" />
                </div>
                <p>
                  You focus on using our funds to grow your business.Our revenue
                  share model means repayments are made as and when available,
                  with funds recouped through a fixed share of monthly cash
                  receipts until repaid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FounderFriendly;
