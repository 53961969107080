import React from "react";
import FlatFee from "../img/flat-fee.svg";
import RepayementDate from "../img/repayment-date.svg";
import FastApproval from "../img/approval.svg";
import NoLegalFees from "../img/no-legal-fee.svg";

const FinanceFeature = () => {
  return (
    <>
      <section className="finance-feature">
        <div className="container finance-feature-container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
              <div className="finance-content">
                <img src={FlatFee} alt="Flat Fee" />
                <p>Flat Fee</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
              <div className="finance-content">
                <img src={RepayementDate} alt="Repayment Date" />
                <p>No Fixed Repayment Date</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
              <div className="finance-content">
                <img src={FastApproval} alt="Fast Approval" />
                <p>Fast Approval</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6">
              <div className="finance-content">
                <img src={NoLegalFees} alt="No Legal Fee" />
                <p>No Legal Fees</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinanceFeature;
