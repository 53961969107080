import './App.css';
import React, { useEffect } from "react";
import "./components/css/Index.css";
import "./components/css/Footer.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginForm from './authentication/signIn/LogIn';
import SignUpForm from './authentication/signup/SignUp'
import Index from './getFunded';
import ClientDashboard from './clientdashboard/Dashboard';
import ViewOffer from './clientdashboard/ViewOffer';
import Ledger from './clientdashboard/ledger';
import Dashboard from './admin/dashboard';
import ViewApplication from './admin/view-application';
import AdminViewOffer from './admin/view-offer';
import AdminLedgers from './admin/ledger';
import { wrapper } from "./store/store";
import ForgotPassword from './authentication/forgot-password/ForgotPassword';
import HomePageMain from './homepage';
import { useSelector } from "react-redux";
import CommonLoader from '../src/components/Common-Loader';
import CodatLinkVerify from './codat-account/CodatLinkVerify';
import { Authenticate } from './components/Auth/Authenticate';
import PageNotFound from './page-not-found/PageNotFound';
import TermsConditions from './homepage/TermsConditions';
import PrivacyPolicy from './homepage/PrivacyPolicy';
import ResetPassword from './authentication/reset-password/ResetPassword';
import MyProfile from './clientdashboard/profile/MyProfile';
import CompleteProfile from './clientdashboard/profile/CompleteProfile';
import YapilyPage from './clientdashboard/yapily/YapilyPage';
import YapilyVerifyPage from './clientdashboard/yapily/YapilyVerifyPage';

function App() {
  const {Loader} = useSelector(state => state);

  // logout from all tabs
  window.addEventListener('storage', e => {
    if(e.key === 'token' && e.oldValue && !e.newValue) {
      window.open('/login',"_self");
    }
  });
  // append third-party script to head tag
  useEffect(() => {
    var envData = process.env.REACT_APP_CLARITY_TP_API;
    if(envData && envData !== '') {
      var script = document.createElement("script");
      script.type = 'text/javascript';
      script.text = envData;
      document.head.appendChild(script);
    }
  },[]);

  return (
    <div className="App">
      {Loader ? <CommonLoader /> : null}
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Authenticate role='UnAuthenticated'><HomePageMain title='Barlow Marshall' /></Authenticate>} />
          <Route path={"/login"} element={<Authenticate role='UnAuthenticated'><LoginForm title='Barlow Marshall - SignIn' /></Authenticate>} />
          <Route path={"/get-funded"} element={<Authenticate role='UnAuthenticated'><SignUpForm title='Barlow Marshall - SignUp' /></Authenticate>} />
          <Route path={"/forgot-password"} element={<Authenticate role='UnAuthenticated'><ForgotPassword  title='Barlow Marshall - Forgot Password' /></Authenticate>} />
          <Route path={"/reset-password"} element={<Authenticate role='UnAuthenticated'><ResetPassword  title='Barlow Marshall - Reset Password' /></Authenticate>} />
          <Route path={"/complete-profile"} element={<Authenticate role='UnAuthenticated'><CompleteProfile  title='Barlow Marshall - Complete Profile' /></Authenticate>} />
          <Route path={"/getFunded"} element={<Authenticate role='funded'><Index title='Barlow Marshall - Get Funded' /></Authenticate>} />
          <Route path={"/client-dashboard"} element={ <Authenticate role='client'><ClientDashboard title='Barlow Marshall - Dashboard' /> </Authenticate>} />
          <Route path={"/client-viewoffer"} element={<Authenticate role='client'><ViewOffer  title='Barlow Marshall - Offer' /></Authenticate>} />
          <Route path={"/client-ledger"} element={<Authenticate role='client'><Ledger  title='Barlow Marshall - Ledger' /></Authenticate>} />
          <Route path={"/profile"} element={<Authenticate role='client'><MyProfile  title='Barlow Marshall - My Profile' /></Authenticate>} />
          <Route path={"/client-yapily/:page"} element={<Authenticate role='client'><YapilyPage  title='Barlow Marshall - Yapily Integration' /></Authenticate>} />
          <Route path={"/verify-yapily"} element={<YapilyVerifyPage  title='Barlow Marshall - Verify Yapily Integration' />} />
          <Route path={"/codat_link_verify"} element={<CodatLinkVerify  title='Barlow Marshall - Codat Verify Link' />} />
          <Route path={"/admin-dashboard"} element={<Authenticate role='admin'><Dashboard title='Barlow Marshall - Admin Dashboard'/></Authenticate>} />
          <Route path={"/admin-view-application"} element={<Authenticate role='admin'><ViewApplication  title='Barlow Marshall - Application' /></Authenticate>} />
          <Route path={"/admin-view-offer"} element={<Authenticate role='admin'><AdminViewOffer  title='Barlow Marshall - View Offer' /></Authenticate>} />
          <Route path={"/admin-ledger"} element={<Authenticate role='admin'><AdminLedgers  title='Barlow Marshall - Admin Ledget' /></Authenticate>} />
          <Route path={"/terms-conditions"} element={<TermsConditions  title='Barlow Marshall - Terms and Condition' />} />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy  title='Barlow Marshall - Privacy and Policy' />} />
          <Route path="*" element={ <PageNotFound title='Barlow Marshall - Page not found' />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default wrapper.withRedux(App)
