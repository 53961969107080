import * as typeS from "../service/types";
import Swal from 'sweetalert2'
import { ProfileData } from "../service/ApiConfig";

export const profileInfoRequest = () => {
    return {
      type: typeS.PROFILE_INFO_REQUEST,
    };
  };
  
  export const profileInfoSuccess = (list) => {
    return {
      type: typeS.PROFILE_INFO_SUCCESS,
      payload: list,
    };
  };
  
  export const profileInfoError = (error) => {
    return {
      type: typeS.PROFILE_INFO_ERROR,
      payload: error,
    };
  };
  
  export const profileInfoData = () => {
    return (dispatch) => {
      try {
        ProfileData().then((res) => {
          if (res&&res.status === 200) {
            dispatch(profileInfoSuccess(res && res.data && res.data.data));
          } else {
            dispatch(profileInfoError("err.message"));
          }
        }).catch((err) => {
          dispatch(profileInfoError(err.message));
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err,
          })
        });
      } catch (err) {
        // console.log(err);
      }
    };
  }
  