import axios from 'axios';
import { ErrorHandler } from '../../components/Auth/errorHandler';
import { handleError } from '../../services/UseHttps';
// import { BASE_URL, BASE_URL_GETFUNDED } from '../../config';
import { BASE_URL, clientListAPI, codeatDataAPI, codatLinkVerifyAPI, getFundedAPI, listApplicationAPI, signInTokenAPI, signUpAPI, transactionListAPI, transactionPendingAPI, transactionStatusAPI, trueLayerCallAPI, userProfileAPI, validateNumberAPI, viewOfferAPI } from '../../config';
import { LOADER_START, LOADER_STOP } from './types';
import CryptoJS from 'crypto-js';

export const CodatAccount = () => dispatch => {
    dispatch({ type: LOADER_START });
    return axios.get(BASE_URL + codeatDataAPI, {
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((response) => {
            dispatch({ type: LOADER_STOP });
            return response;
        })
        .catch((err) => {
            dispatch({ type: LOADER_STOP });
        });
};

export const CodatLink = (data) => {
    const token = localStorage.getItem('token')
    return axios.get(BASE_URL + `${codatLinkVerifyAPI}?companyId=${data.companyId}&flow=${data.flow}&statuscode=${data.statusCode}&errormessage=${data.errorMessage}`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
};

export const TrueLayer = (data) => dispatch => {
    dispatch({ type: LOADER_START });
    return axios.get(BASE_URL + `${trueLayerCallAPI}?code=${data.code}&scope=${data.scope}`, {
        headers: {
            "Content-Type": "application/json"
        },
    })
        .then((response) => {
            dispatch({ type: LOADER_STOP });
            return response;
        })
        .catch((err) => {
            dispatch({ type: LOADER_STOP });
            ErrorHandler(err)
        });
};

// Login/Sign-in api (LogInApi)
export const GetTokenApi = (data) => {
    const headers = {
        "Content-Type": "application/json"
    };
    const body = data;
    return axios.post(BASE_URL + signInTokenAPI, body, headers)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
            // ErrorHandler(err)
        });
};

export const GetFundedApi = (data) => {
    const token = localStorage.getItem('token')
    const headers = {
        "Content-Type": "application/json",
        Authorization: token
    };
    return axios.post(BASE_URL + signUpAPI, data, headers)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            ErrorHandler(err)
        });
};

export const GetFundedDetailsApi = (data) => {
    var formData = new FormData();
    // company details
    formData.append('company_name', data.company_name);
    formData.append('company_number', data.company_number);
    formData.append('company_type', data.company_type);
    formData.append('country_of_incorporation', data.country_of_incorporation);
    formData.append('type_of_business', data.type_of_business);
    formData.append('sic_code', data.sic_code);
    formData.append('annual_recurring_revenue', data.annual_recurring_revenue);
    formData.append('trading_history', data.trading_history);
    formData.append('comments', data.comments);
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('phone_number', data.phone_number);
    // application files
    if (data && data.company_sales_deck) {
        formData.append('company_sales_deck', data.company_sales_deck);
    }
    if (data && data.company_investment_deck) {
        formData.append('company_investment_deck', data.company_investment_deck);
    }
    if (data && data.company_forecasts_model) {
        formData.append('company_forecasts_model', data.company_forecasts_model);
    }
    if (data && data.any_other_documents) {
        formData.append('any_other_documents', data.any_other_documents);
    }
    if (data && data.status) { formData.append('status', data.status); }
    const token = localStorage.getItem('token')
    const headers = {
        "Content-Type": "application/json",
        Authorization: token,
    };
    return axios.post(BASE_URL + getFundedAPI, formData, headers)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            ErrorHandler(err)
        });
};

export const ListApplication = async () => {
    try {
        const response = await axios.get(BASE_URL + listApplicationAPI, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return response;
    } catch (err) {
        ErrorHandler(err)
    }
};

export const ListAdminOffer = async () => {
    try {
        const response = await axios.get(BASE_URL + viewOfferAPI, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return response;
    } catch (err) {
        ErrorHandler(err)
    }
}
export const ProfileData = async () => {
    try {
        const response = await axios.get(BASE_URL + userProfileAPI, {
            headers: {
                "Content-Type": "application/json"
            },
        });
       
        return response;
    } catch (err) {
        ErrorHandler(err)
    }
}

export const PhoneNumberApi = (data) => {
    var key = process.env.REACT_APP_AES_ENCRYPTION_KEY;
    var randomKey = process.env.REACT_APP_AES_ENCRYPTION_IV;
    var messageKey = process.env.REACT_APP_AUTH_TOKEN_OTP;
    key = CryptoJS.enc.Utf8.parse(key);
    var iv = CryptoJS.enc.Utf8.parse(randomKey)
    var encrypted = CryptoJS.AES.encrypt(messageKey, key, {iv:iv,mode: CryptoJS.mode.CBC});
    encrypted = encrypted.toString();
    const headers = {
        "Content-Type": "application/json",
        "x-access-health": encrypted
    };
    data.phone_number = "+" + data.phone_number;
    return axios.post(BASE_URL + validateNumberAPI, data, {headers: headers})
        .then((response) => {
            return response;
        })
        .catch((err) => {
            ErrorHandler(err)
        });
};
export const listTransactions = async (clientid,source,days,status,account_number,currentPage,recordsPerPage) => {
    try {
        const response = await axios.get(BASE_URL + `${transactionListAPI}/` + clientid + `?page=${currentPage}&size=${recordsPerPage}&days=${days}&status=${status}&account_number=${account_number}&source=${source}`, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return response;
    } catch (err) {
        ErrorHandler(err)
        return err;
    }
}
export const ListClient = async () => {
    try {
        const response = await axios.get(BASE_URL + clientListAPI, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return response;
    } catch (err) {
        ErrorHandler(err)
    }
};

export const ledgerApi = async (status, applicationId) => {
    // const status = ['settled','challenged','pending']
    try {
        const response = await axios.get(BASE_URL + `${transactionPendingAPI}/` + applicationId +`?status=${status}`, {
            header: {
                "Content-Type": "application/json"
            }
        });
        return response;

    } catch (error) {
        ErrorHandler(error)
    }
}

export const settledData = async (data) => {
    try {
        const response = await axios.patch(BASE_URL + transactionStatusAPI ,data);
        return response;

    } catch (error) {
        handleError(error)
    }
}

export const listChallengedTransactions = async (clientid) => {
    try {
        const response = await axios.get(BASE_URL + `${transactionPendingAPI}/` + clientid + `?status=challenged`, {
            headers: { "Content-Type": "application/json" },
        });
        return response;
    } catch (err) {
        ErrorHandler(err)
        return err;
    }
}