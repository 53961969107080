import React, { useState } from 'react';
import { ErrorHandler } from '../../components/Auth/errorHandler';
import { createYapilyUserApi } from '../../config';
import { HttpCall } from '../../services/UseHttps';
import Swal from "sweetalert2";

const YapilyConnect = ({email, isParent, isYapActive, yapilyError, setCurrState, setLoading}) => {
    // const [loading, setLoading] = useState(false);

    // connect with yapily button click
    const onConnectYapilyClick = () => {
      try {
          if(!isYapActive) { return; }
          if(email) {
              setLoading(true);
              HttpCall(`${createYapilyUserApi}`, "POST", {email}).then((res) => {
                  if(res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
                      // Swal.fire({icon: 'success',title: 'Success', text: res.data.data});
                      setCurrState({yapilyBtn: false, institution:true, auth:false, consentData:{}, accounts: false, dashboard: false});
                  } else {
                      setCurrState({yapilyBtn: true, institution:false, auth:false, consentData:{}, accounts: false, dashboard: false});
                      Swal.fire({icon: 'error',title: 'Error', text: res.data&&res.data.error?res.data.error:'Fails to create yapily user.'});
                  }
                  setLoading(false);
              }).catch((error) => {
                  setLoading(false);
                  ErrorHandler(error);
              });
          } else {
              setCurrState({yapilyBtn: true, institution:false, auth:false, consentData:{}, accounts: false, dashboard: false});
              Swal.fire({icon: 'error',title: 'Error', text: 'User email id not found.'});
          }
      } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.response && error.response.data && error.response.data.error ?  error.response.data.error : 'Fails to create yapily user.'
          });
          setLoading(false);
      }
    }

    return (
      <div className="mb-4">
        <div className="card">
          <div className="card-body">
            <div className="col-12">
              {isYapActive===false ?
                <div className="alert alert-danger noti-alert alert-dismissible">{yapilyError?yapilyError:''}</div>
              : ''}
              <div className="tab-content" id="myTabContent">
                <div style={{display:'flex',justifyContent: 'space-between', margin:5, padding:15}}>
                  <label style={{marginTop:10}}><b>Integrate Open Banking</b></label>
                  <button className="mb-4 btn btn-custom btn-custom-2 btn-custom-no-white-shadow" 
                    onClick={()=> onConnectYapilyClick() } disabled={!isParent || !isYapActive}>Connect with Open Banking</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default YapilyConnect;
