import React, { useEffect, useState } from 'react';
import {Container, Row, Card} from 'react-bootstrap';
import { ErrorHandler } from '../../components/Auth/errorHandler';
import { yapilyUserInstitutionApi, yapilyUserAccountAuthorisationApi } from '../../config';
import { HttpCall } from '../../services/UseHttps';
import Swal from "sweetalert2";
import YapilyFooter from './YapilyFooter';

const SearchSelectYapily = ({isParent, isYapActive, bankList, setCurrState, userId, setLoading}) => {
    const [banks, setBanks] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    useEffect(() => {
      setBanks(bankList);
    }, [bankList]);

    const onSearch = (e) => {
      if(e.target.value) {
        setSearchStr(e.target.value);
        const filtArr = bankList.filter((ele) => ele.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
        setBanks(filtArr);
      } else {
        setSearchStr('');
        setBanks(bankList);
      }
    }
    // onSelectIntitution
    const onSelectIntitution = (selectedInsti) => {
      if(!isYapActive) { return; }
      if(!isParent) {
        Swal.fire({icon: 'error',title: 'Error', text: 'Parent users only allowed to integrate yapily.'});
        return;
      }
      if(selectedInsti.id && selectedInsti.full_name && selectedInsti.media && selectedInsti.media[0].source) {
        onSelectYapilyUserInstitution(selectedInsti.id,selectedInsti.full_name,selectedInsti.media[0].source);
      } else {
        Swal.fire({icon: 'error',title: 'Error', text: 'User institution id not found.'});
      }
    }
    // Save Selected Institution
    const onSelectYapilyUserInstitution = (institution_id, full_name, icon_url) => {
      try {
          setLoading(true);
          HttpCall(`${yapilyUserInstitutionApi}`, "POST", {institution_id, full_name, icon_url}).then((res) => {
              if(res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
                  // Swal.fire({icon: 'success',title: 'Success', text: res.data.data});
                  // call save api
                  onSelectedYapilyUserInstitutionConsent(institution_id,userId,full_name);
              } else {
                  Swal.fire({icon: 'error',title: 'Error', text: res.data&&res.data.error?res.data.error:'Fails to save yapily user institution.'});
                  setLoading(false);
              }
          }).catch((error) => {
              setLoading(false);
              ErrorHandler(error);
              if(error && error?.response&& error?.response?.status === 409 && error?.response?.data?.status===false) {
                onAddAccountInConsent(institution_id,userId,full_name); // add account api call...
              }else if(error && error.response&& error.response.status === 400 || error.response.status === 404) {
                console.log('yep')
                Swal.fire({icon: 'error',title: 'Error', text: error.response.data&&error.response.data.error?error.response.data.error:'Support user adding is failed.'});
            }
          });
      } catch (error) {
          Swal.fire({icon: 'error',title: 'Error',
                  text: error.response && error.response.data && error.response.data.error ?  error.response.data.error : 'Fails to save yapily user institution.'
              });
          setLoading(false);
      }
    }
    // Save Selected Institution Consent
    const onSelectedYapilyUserInstitutionConsent = (institution_id, user_id, full_name) => {
      try {
          setLoading(true);
          HttpCall(`${yapilyUserAccountAuthorisationApi}`, "POST", {institution_id, user_id}).then((res) => {
              if(res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
                  // Swal.fire({icon: 'success',title: 'Success', text: 'Yapily user institution created successfully, Please authorize your banking credentials.'});
                  localStorage.setItem('intId',institution_id);
                  localStorage.setItem('bank',full_name);
                  setCurrState({yapilyBtn: false, institution:false, auth:true, consentData: res.data.data, accounts: false, dashboard: false});
                  // call save api
              } else {
                  setCurrState({yapilyBtn: false, institution:true, auth:false, consentData:{}, accounts: false, dashboard: false});
                  Swal.fire({icon: 'error',title: 'Error', text: res.data&&res.data.error?res.data.error:'Fails to save yapily user institution.'});
              }
              setLoading(false);
          }).catch((error) => {
              setCurrState({yapilyBtn: false, institution:true, auth:false, consentData:{}, accounts: false, dashboard: false});
              setLoading(false);
              ErrorHandler(error);
          });
      } catch (error) {
          Swal.fire({icon: 'error',title: 'Error',
                  text: error.response && error.response.data && error.response.data.error ?  error.response.data.error : 'Fails to save yapily user institution.'
              });
          setLoading(false);
      }
    }

    // add more accounts in institution
  const onAddAccountInConsent = (institution_id, user_id, full_name) => {
    try {
      setLoading(true);
      HttpCall(`${yapilyUserAccountAuthorisationApi}`, "POST", { institution_id, user_id }).then((res) => {
        if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
          // Swal.fire({ icon: 'success', title: 'Success', text: 'Please authorize your banking credentials.' });
          localStorage.setItem('intId', institution_id);
          localStorage.setItem('fromYDS', 'true');
          localStorage.setItem('bank',full_name);
          setCurrState({ yapilyBtn: false, institution: false, auth: true, consentData: res.data.data, accounts: false, dashboard: false });
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.error ? res.data.error : 'Fails to add yapily user institution account.' });
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        ErrorHandler(error);
      });
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails to save yapily user institution.'
      });
      setLoading(false);
    }
  }

    const onClickBackToDashboard = () => {
      setCurrState({yapilyBtn: false, institution:false, auth:false, consentData:{}, accounts: false, dashboard: true});
    }

    return (
      <>
      <div className="mb-4">
        <div className="card">
          <div className="card-body">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div className="mb-4">
                  <div className="text-left">
                    <h4>Select Your Bank</h4>
                    <p>Select from the list of banks you would like to connect your yapily account with </p>
                    <div>
                      <button className="btn btn-outline-primary" onClick={onClickBackToDashboard} style={{position:'absolute',float: 'right',right: 10,top: 25}}>My Institutions</button>
                    </div>
                    <div className="mb-2 filter-transaction-container">
                      <input type='text' value={searchStr} className='search-banks' placeholder='Search bank name' onChange={(e)=> onSearch(e)}/>
                    </div>
                    <div className="mb-2">
                      <Container className='p-4'>  
                        <Row>
                          {banks.map((itm, ind) => 
                            <Card key={ind} style={{ padding:25, margin:10, display: 'flex',alignItems: 'center',justifyContent: 'center',flexDirection: 'column',width: '23%', textAlign:'center', cursor: 'pointer' }} onClick={()=>onSelectIntitution(itm)}>
                              <Card.Img variant="top" src={itm?.media[0]?.source} style={{width:'100%', height:50, objectFit: 'contain'}}/>
                              <Card.Body>
                                <Card.Title>{itm.name}</Card.Title>
                                {/* <Card.Text></Card.Text> */}
                              </Card.Body>
                            </Card>
                          )}
                          </Row>
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <YapilyFooter />
      </>
    )
}

export default SearchSelectYapily;
