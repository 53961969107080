import { MDBDataTableV5 } from 'mdbreact';
import { useEffect, useState } from 'react'
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ClipLoader from "react-spinners/ClipLoader";
import { listAdminOfferData } from '../../redux/action/offerList';
import { useDispatch, useSelector } from 'react-redux';
import ViewOffers from './ViewOffers';
import AmendOffer from './AmendOffer';
import { confirmWithdraw } from './Withdraw';
import LinkNonModulrAccount from './LinkNonModulrAccount';
import { formatAmount } from '../../services/CommonFunction';
import queryIcon from "../../img/query.svg";
import ViewOfferQuery from './ViewOfferQuery';
import EffectiveDateUpdate from './EffectiveDateUpdate';

const AdminViewOffer = (props) => {
  const [customerId, setCustomerId] = useState(null);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [tabsState, setTabsState] = useState("pending");
  const [dataFlag, setDataFlag] = useState(true);
  const [modalShowAmend, setModalShowAmend] = useState(false);
  const [modalShowLinkAccount, setModalShowLinkAccount] = useState(false);
  const [modalShowEffectiveDate, setModalShowEffectiveDate] = useState(false);
  const [modalShowQuery, setModalShowQuery] = useState(false);
  const [offerQueryText, setOfferQueryText] = useState("");
  const dispatch = useDispatch();
  const select = useSelector(
    (state) =>
      state &&
      state.OfferList
  );
  const [filterTableData, setFilterTableData] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [mdbColHeaders, setMdbColHeaders] = useState([
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    },
    {
      label: "Company Name",
      field: "company_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Effective Date",
      field: "effective_from",
      sort: "asc",
      width: 150,
    },
    {
      label: "Advance Amount",
      field: "advance_amount",
      sort: "asc",
      width: 150,
    },
    {
      label: "Fixed Fee",
      field: "fixed_fee",
      sort: "asc",
      width: 150,
    },
    {
      label: "Full Repayment",
      field: "full_repayment",
      sort: "asc",
      width: 150,
    },
    {
      label: "Minimum Split (First 12 Months) %",
      field: "minimum_split_first_12_months",
      sort: "asc",
      width: 150,
    },
    {
      label: "Minimum Split (From 12 Months) %",
      field: "minimum_split_from_12_months",
      sort: "asc",
      width: 150,
    },
    {
      label: "Maximum split %",
      field: "maximum_split",
      sort: "asc",
      width: 150,
    },
    {
      label: "Minimum Performance",
      field: "minimum_performance",
      sort: "asc",
      width: 150,
    },
    {
      label: "More Details",
      field: "moreDetails",
      sort: "asc",
      width: 150,
    },
  ]);
  const [datatable, setDatatable] = useState({
    columns: mdbColHeaders,
    rows: [],
  });

  useEffect(() => {
    dispatch(listAdminOfferData());
    document.title = props.title;
  }, []);

  useEffect(() => {
    const newArray =
      select && select.listAdminOfferData && select.listAdminOfferData.length && select.listAdminOfferData.map((el) => {
        const { id, ...rest } = el.id;
        el.advance_amount = el.advance_amount ? '£'+formatAmount(el.advance_amount):'';
        el.fixed_fee = el.fixed_fee ? '£'+formatAmount(el.fixed_fee):'';
        el.full_repayment = el.full_repayment ? '£'+formatAmount(el.full_repayment):'';
        el.minimum_performance = el.minimum_performance ? '£'+formatAmount(el.minimum_performance):'';
        return { ...el, ...rest };
      })
        .sort(SortArray);
    const newArrayNONempty = (newArray || []).filter((em) => em.name !== "");
    const newArrayempty = (newArray || []).filter((em) => em.name === "");
    const finalArray = (newArrayNONempty || []).concat(newArrayempty);
    setTableData(select && select.listAdminOfferData || []);
  }, [select]);
  function SortArray(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    if (dataFlag) {
      tabsHandler(tabsState);
      return;
    }
    setDataFlag(false);
  }, [TableData]);

  const tabsHandler = (status) => {
    setFilterTableData(
      TableData && TableData.filter((el) => el.status === status)
    );
    setTabsState(status);
    // eslint-disable-next-line
    const filtData = TableData.filter((el) => {
      let act = "";
      if (el.status === "pending") {
        act = (<>
          <div className='d-flex justify-content-around'>
          <i
            onClick={() => {setSelectedOfferId(el.application_id);confirmWithdraw(el.id,dispatch)}}
            className="fa-solid fa-ban text-danger cursor-pointer"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModalWithdraw"
            title="Withdraw"
          ></i>
            <i
              onClick={() => {setOfferData(el);setModalShowAmend(true)}}
              className="fa-solid fa-edit text-primary cursor-pointer"
              // data-bs-toggle="modal" data-bs-target="#exampleModalAmend"
              title="Amend Offer"
            ></i>
          </div>
        </>
        );
      } else if (el.status === "accepted" ) {
        act = (<>
          <div className='d-flex justify-content-around'>
            <i
              onClick={() => {setOfferData(el);setModalShowAmend(true)}}
              className="fa-solid fa-edit text-primary cursor-pointer"
              // data-bs-toggle="modal" data-bs-target="#exampleModalAmend"
              title="Amend Offer"
            ></i>
            {el && el.account_number && el.account_number !== '' ?
              <i title="Linked Client Settlement Account" onClick={() => { setOfferData(el); setModalShowLinkAccount(true); } }
                className="fa-solid fa-link text-success cursor-pointer"
              ></i>
            :
              <i title="Link Client Settlement Account" onClick={() => { setOfferData(el); setModalShowLinkAccount(true); } }
                className="fa-solid fa-link text-danger cursor-pointer"
              ></i>
            }
            <i onClick={() => {setOfferData(el);setModalShowEffectiveDate(true)}}
              className="fa-solid fa-calendar-check text-default cursor-pointer"
              title="Update Offer Effective Date"></i>
        </div>
        </>)
      } else if (el.status === "query" ) {
        act = (<>
          <div className='d-flex justify-content-around'>
              <div className={`glyphicon glyphicon-remove-circle cursor-pointer`} title="Query Offer" onClick={()=>{showQuery(el)}} 
                data-bs-toggle="modal" data-bs-target="#exampleModalQuery" data-keyboard="false" data-backdrop="static">
                 <img width={'25px'} src={queryIcon} alt=""/>
              </div>
        </div>
        </>)
      }
      // } else if (el.status === "accepted") {
    //     act = (
    //       <i
    //         onClick={() => setCustomerId(el.id)}
    //         className="fa-solid fa-circle text-success mx-1 text-center w-100"
    //         title="Offer Accepted"
    //       ></i>
    //     );
    //   } else if (el.status === "rejected") {
    //     act = (
    //       <i
    //         onClick={() => setCustomerId(el.id)}
    //         className="fa-solid fa-circle text-danger mx-1 text-center w-100"
    //         title="Offer Rejected"
    //       ></i>
    //     );
    //   } else if (el.status === "withdraw_offer") {
    //     act = (
    //       <i
    //         onClick={() => setCustomerId(el.id)}
    //         className="fa-solid fa-circle text-danger mx-1 text-center w-100"
    //         title="Offer Withdraw"
    //       ></i>
    //     );
    //   } else if (el.status === "expired") {
    //     act = (
    //       <i
    //         onClick={() => setCustomerId(el.id)}
    //         className="fa-solid fa-circle text-danger mx-1 text-center w-100"
    //         title="Offer Decline"
    //       ></i>
    //     );
    //   } else if (el.status === "others") {
    //     act = (
    //       <i
    //         onClick={() => setCustomerId(el.id)}
    //         className="fa-solid fa-circle text-danger mx-1 text-center w-100"
    //         title="Offer Other"
    //       ></i>
    //     );
      // }
      el.moreDetails = status==='pending' ? (
       <div className='d-flex justify-content-between'>
         {/* <i
           onClick={() => {setSelectedOfferId(el.application_id);confirmWithdraw(el.id,dispatch)}}
           className="fa-solid fa-ban text-danger cursor-pointer"
           data-bs-toggle="modal"
           data-bs-target="#exampleModalWithdraw"
           title="Withdraw"
         ></i>
          <i
            onClick={() => {setOfferData(el);setModalShowAmend(true)}}
            className="fa-solid fa-edit text-primary cursor-pointer"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalAmend"
            title="Amend Offer"
          ></i> */}
        <i
          onClick={() => setOfferData(el)} style={{color:'#979797'}}
          className="fa-solid fa-up-right-from-square text-secondary cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal3"
          title="More Details"
        ></i>
       </div>
      ):
      (<>
        <i
          onClick={() => setOfferData(el)}
          className="fa-solid fa-up-right-from-square text-primary cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal3"
          title="More Details"
        ></i>
       </> 
      );
      if (el.status === status) {
        el.action = act;
        return el;
      }
    });
    setDatatable({ ...datatable, rows: filtData?filtData:[] });
  };

  const getRecordsCountByStatus = (status) => {
    return TableData?.length && TableData.filter((el) => el.status == status).length;
  }

  const showQuery = (element) => {
    setModalShowQuery(true);
    setOfferQueryText(element?.reason);
  }
  const closeQueryModel = () => {
    setOfferQueryText('');
    setModalShowQuery(false);
  }

  return (
    <>
      <NavBar admin="true" />
      <main>
        <section className="sectionone bg-light section-height">
          <div className="container ">
            <div className="row">
              <div className="col-12 mt-4">
                <ul
                  className="nav nav-tabs flex-nowrap"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation" onClick={() => tabsHandler("pending")}>
                    <button
                      className="nav-link w-100 active"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab2"
                      type="button"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      Awaiting{" "}
                      <span className="count rounded">
                        {getRecordsCountByStatus('pending')}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => tabsHandler("accepted")}>
                    <button
                      className="nav-link w-100"
                      id="tab3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab3"
                      type="button"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      Accepted{" "}
                      <span className="count rounded">
                        {getRecordsCountByStatus('accepted')}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => tabsHandler("rejected")}>
                    <button
                      className="nav-link w-100"
                      id="tab3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab3"
                      type="button"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      Rejected{" "}
                      <span className="count rounded">
                        {getRecordsCountByStatus('rejected')}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => tabsHandler("withdraw_offer")}>
                    <button
                      className="nav-link w-100"
                      id="tab5-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab5"
                      type="button"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      Withdraw{" "}
                      <span className="count rounded">
                        {getRecordsCountByStatus('withdraw_offer')}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => tabsHandler("expired")}>
                    <button
                      className="nav-link w-100"
                      id="tab4-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab4"
                      type="button"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      Expired{" "}
                      <span className="count rounded">
                        {getRecordsCountByStatus('expired')}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" onClick={() => tabsHandler("query")}>
                    <button
                      className="nav-link w-100"
                      id="tab6-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab6"
                      type="button"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="false"
                    >
                      Queries{" "}
                      <span className="count rounded">
                        {getRecordsCountByStatus('query')}
                      </span>
                    </button>
                  </li>
                </ul>
              </div>

              <div className="col-12">
                <div className="tab-content" id="myTabContent">
                  <div className="mb-4">
                    {filterTableData&&filterTableData.length === 0 && (
                      <div
                        className="dierector-content mt-3 "
                        style={{
                          borderRight: "15px solid #003056",
                          borderLeft: "15px solid #003056",
                        }}
                      >
                        <p className="table-font-color m-0">
                          <b>View offer records not found.</b>
                        </p>
                      </div>
                    )}
                    {filterTableData&&filterTableData.length > 0 &&
                      (!select.loading ? (
                        <MDBDataTableV5
                          striped
                          bordered
                          hover
                          noRecordsFoundLabel="true"
                          data={datatable}
                          autoWidth
                          searching={false}
                          fullPagination
                          searchTop
                          searchBottom={false}
                          entriesOptions={[2, 5, 10, 20, 50, 100]}
                          paging={!(filterTableData.length <= "4")}
                          className={`${
                            filterTableData.length <= "4"
                            ? "hide-pagination"
                            : ""
                            }`}
                        // pagingTop searchTop
                        // theadColor="blue lighten-5" entries={100} pagesAmount={4} order={['uptime', 'asc']} className="mx-auto"
                        />
                      ) : (
                        <>
                          <ClipLoader
                            size={150}
                            css={{
                              display: "block",
                              margin: "0 auto",
                              borderColor: "green",
                            }}
                            color="green"
                          />
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      {/* modal */}

      <ViewOffers id={selectedOfferId} offerData={offerData}/>
      <ViewOfferQuery offerQueryText={offerQueryText} closeQueryModel={closeQueryModel} />
      <AmendOffer offerData={offerData} showModal={modalShowAmend} setModalShow={setModalShowAmend}/>
      <EffectiveDateUpdate offerData={offerData} showModal={modalShowEffectiveDate} setModalShow={setModalShowEffectiveDate}/>
      <LinkNonModulrAccount offerData={offerData} showModal={modalShowLinkAccount} setModalShow={setModalShowLinkAccount}/>
    </>
  )
}

export default AdminViewOffer