export const BASE_URL = `${window.location.protocol}//${window.location.hostname}/api/`;

// file upload Configs
export const FILE_SIZE_LIMIT = 1 * 1024 * 1024; // 1 MB
export const ALLOWED_FILE_FORMAT = ["image/png", "image/jpg", "image/jpeg", "image/gif", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.slideshow", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
export const MAX_FILE_LENGTH = 5;
export const MAIL_TO = 'ellis.hudson@blenheimchalcot.com';
export const TRANSACTION_UPLOAD_EXCEL_LINK = '/files/Sample_Transaction_Template.xlsx';
export const YAPILY_TERM_CON_LINK = 'https://www.yapily.com/legal/yapilyconnect-terms-and-conditions';
export const YAPILY_PRI_POL_LINK = 'https://www.yapily.com/legal/privacy-policy';
export const MODULR_MONEY_SAFE_LINK = '/files/modulr_money_safe.pdf';

// API listing
//admin - client Both
export const userProfileAPI = 'user-profile'; // GET '/user-profile'
export const makeOfferAPI = 'offer-api'; // post '/offer-api' - {advance_amount,fixed_fee,full_repayment, minimum_split_first_12_months,minimum_split_from_12_months,maximum_split,minimum_performance,effective_from}
export const viewOfferAPI = 'offer-api' // get '/offer-api' - '/offer-api/{application_id}'
export const offerStatusAPI = 'offer-api' // patch '/offer-api/{offer-id}' - {status,reason, ...} - (AmendOffer, WithdrawOffer, AcceptOffer, RejectOffer, QueryOffer)
export const effectiveDateUpdateAPI = 'offer-api/effective-from/' // patch '/offer-api/effective-from/{offer-id}' - {"effective_from":"2022-09-30"}
export const setInEligibleAPI = 'application-api'; // Patch '/application-api/{aaplication-id}' - {reason, status}
export const listApplicationAPI = 'application-api'; // get '/application-api'
export const addBankAccountAPI = 'non-modulr/add-bank-account';
export const adminModulrTransAPI = 'admin-modulr-transactions'; // get '/admin-modulr-transactions/user-id?page=0&size=10&days=90'
export const adminDashboardModulrTransAPI = 'admin-dashboard-modulr-transactions'; // get 'admin-dashboard-modulr-transactions?page=0&size=10'
export const adminDashboardTransDataAPI = 'admin-dashboard-transactions-data'; // get 'api/admin-dashboard-transactions-data'
export const adminDashboardRecentTransAPI = 'clients-recent-transactions'; // get 'api/clients-recent-transactions'
export const adminDashboardRepaymentGraphAPI = 'graph/dashboard/repayments/'; // get '/api/graph/dashboard/repayments/<duration>' // duration = yearly, monthly('YYYY'), weekly('YYYY-MM'), daily('YYYY-MM-DD_YYYY-MM-DD')
export const adminDashboardRepaymentProgressGraphAPI = 'graph/dashboard/repayments/progress'; // get '/api/graph/dashboard/repayments/progress'
// both
export const transactionStatusAPI = 'transaction-status' // patch - paylod {status,reason,transaction_id,account_type}
// Monthly - /sales_and_repayment/<application-id>/all , Daily -/sales_and_repayment/<application-id>/all/<yyyy-mm>
export const salseRepaymentGraphAPI = 'graph/sales_and_repayment/' // get - /api/graph/sales_and_repayment/<application-id>/{'all'|'modulr'|'non_modulr'}
export const overviewSectionGraphAPI = 'graph/overview_section/' // get - /api/graph/overview_section/<application-id>
export const transactionListAPI = 'transaction'; // GET - 'transaction/{client-id}?page=0&size=10&days=30&status=null&account_number=null'
export const clientListAPI = 'client-list'; // GET - '/client-list'
export const transactionPendingAPI = 'transaction-pending'; // GET - '/transaction-pending/{application-id}?status='

//client
export const excelUploadTransactionAPI = 'transaction'; // post {file, application_id} - excel transaction upload API
export const modulrDashboardApi = 'modulr' //GET - /modulr/80a1601f-2af6-4ed2-a71c-f41d20f326f7?page=0&size=2&days=30
export const supportUsersApi = 'support-user' //GET - /support-user
export const addSupportUserApi = 'add-support-user' //POST - /add-support-user {first_name, last_name,email}
export const supportUserStatusApi = 'support-user-status' //POST - /support-user-status {email, is_active=1:0}
export const verifyEmailTokenApi = 'verify-email-token' //POST - /verify-email-token {token}
export const completeProfileApi = 'complete-profile' //POST - /add-support-user {email,password,phone_number,token}
export const clientCompanyApi = 'client-company' //POST - /client-company  {"parent_user_id": ""}
// yapily
export const createYapilyUserApi = 'yapily-user' //POST - /yapily-user  {"email": ""}
export const getYapilyInstitutionApi = 'yapily-institutions' //GET - /yapily-institutions
export const yapilyUserInstitutionApi = 'yapily-user-institution' //POST - /yapily-user-institution {institution_id, full_name, icon_url}
export const yapilyUserAccountAuthorisationApi = 'yapily-user-account-authorisation' //POST - /yapily-user-account-authorisation {user_id, institution_id}
export const yapilyConsentAccountsApi = 'yapily-consent-accounts' //POST - /yapily-consent-accounts {consent_created_at,consent_expires_at,consent_id,consent_tokens,institution_id}
export const yapilyUserAccountsApi = 'yapily-user-accounts' //PATCH - /yapily-user-accounts {account_update_parameters:[ {yapily_user_account_id,update_column_values:{is_sync:1}}, ]}
export const getYapilyUserInstitutionApi = 'yapily-user-institution' //GET - /yapily-user-institution
export const getYapilyUserAccountsApi = 'yapily-user-accounts' //GET - /yapily-user-accounts?institution_id=&consent_id=
export const getYapilyUserAccountSyncApi = 'yapily-user-account-sync' //PUT - /yapily-user-account-sync - {"account_ids" : ['']}
export const getYapilyUserConsentExtendApi = 'yapily-user-consent-extend' //PATCH - /yapily-user-consent-extend {consent_id}
export const getYapilyUserConsentRevokeApi = 'yapily-user-consent-revoke' //PATCH - /yapily-user-consent-revoke {consent_id}

// Auth api
export const signInTokenAPI = 'signin'; // POST - {"email": "","password": "","is_signup" : false}
export const checkUserAPI = 'check-user'; // POST /check-user - {"email": "","password": ""}
export const signUpAPI = 'signup'; // POST - {"first_name","last_name","website","email","phone_number","password","annual_revenue"}
export const getFundedAPI = 'get-funded'; // POST - FormData - {company_name,company_number,company_type,country_of_incorporation,type_of_business,sic_code,annual_recurring_revenue,trading_history,comments,first_name,last_name,phone_number,company_sales_deck,company_investment_deck,company_forecasts_model,any_other_documents,status}
export const signOutAPI = 'signout'; // GET - /api/signout
export const forgetPasswordAPI = 'password-reset'; // GET - /password-reset?email=''
export const verifyFPOtpAPI = 'password-reset'; // POST - /password-reset - {email, phone_number, otp}
export const resetPasswordAPI = 'password-reset'; // PATCH - FormData - {password, confirm_password}

// company name search api
export const companyNameSearchAPI = 'company-api' // POST - {"q": ""}
export const companyDetailsAPI = 'company-api/' // GET - id - company-number
export const generateOtpAPI = 'generate-otp' // POST -  {"phone_number" : "+91"}
export const verifyOtpAPI = 'verify-otp' // POST -  {"phone_number" : "+91",  "otp" :""} , RES : {"otp_status": "approved/pending"}
export const validateNumberAPI = 'validate-number' // POST -  { 'phone_number': '', 'country_code': '' }
export const generateEmailOtpAPI = 'generate-email-otp'; // POST - /generate-email-otp - {email:'', name:''}
export const verifyEmailOtpAPI = 'verify-email-otp'; // POST - /verify-email-otp - {email:'',otp:''}
// Codat api
export const codeatDataAPI = 'codat-data' // GET - '/codat-data'
export const codatLinkVerifyAPI = 'codat-link-verify' // GET - '/codat-link-verify?companyId=''&flow=''&statuscode=''&errormessage='
// TrueLayer api
export const trueLayerCallAPI = 'callback?code' // GET - '/callback?code=''&scope='