import logo from "../../img/logo-light.png";
import Loginpic from "../../img/img-19.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { HttpCall } from "../../services/UseHttps";
import { resetPasswordAPI } from "../../config";
import Swal from "sweetalert2";
const ResetPassword = (props) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    useEffect(() => {
        document.title = props.title;
        if(!token || token === '' && token === undefined) {
            navigate('/forgot-password');
        }
    }, []);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownCon, setPasswordShownCon] = useState(false);
    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/;

    const resetPassSchema = Yup.object().shape({
        password: Yup.string().required('Password is required')
        .matches(passPattern, 'Password length should be minimum 8 characters and must contain at least one lowercase letter, uppercase letter, number and special character(!,@,#,$,%,^,&,*)'),
        // .oneOf([Yup.ref('password'), null], 'Confirm password & Password must be same')
        confirm_password: Yup.string()//.required('Confirm password is required'),
    });
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: green;
    `;
    const handleSubmit = (formValues) => {
        try {
            if(token && formValues.password === formValues.confirm_password) {
                setErrorMsg('');
                setLoading(true);   
                const resetPassValue = { 'password': formValues.password, 'confirm_password': formValues.confirm_password };
                HttpCall(`${resetPasswordAPI}`, "PATCH", resetPassValue).then((res) => {
                    if (res && res.status && (res.status === 200 || res.status === 201)) {
                        Swal.fire({icon: 'success',title: 'Success', text: 'Your password reset successfully.'});
                        localStorage.removeItem('token');
                        navigate('/login');
                    } else {
                        Swal.fire({icon: 'error',title: 'Error', text: 'Fail to reset password.'});
                    }
                    setLoading(false);
                }).catch((error) => {
                    Swal.fire({icon: 'error',title: 'Error',
                        text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to send verification code.'
                    });
                    setLoading(false);
                });
            } else {
                if(formValues.password !== formValues.confirm_password) {
                    // Swal.fire({icon: 'error',title: 'Error', text: 'Both password are not same.'});
                    return;
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: 'Token not found.'});
                }
            }
        } catch(err) {
            setLoading(false);
        }
    }

    return (
        <>
            <section className="overflow-hidden div-height">
                <div className="row div-height">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="background h-100">
                            <div className="text-right px-4 pt-3 pb-5">
                                <Link to="/">
                                    <img src={logo} alt="logo" width="200" height="20" className="" />
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">

                                    <img className="text-center" src={Loginpic} alt="welcome" height="350" width="400" />
                                    <h1 className="text-white mb-4">Barlow Marshall</h1>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-4">
                                <div>
                                    <Link className="text-white PX-2" to={'/terms-conditions'}>Terms and Conditions</Link>
                                    <span className="text-white mx-2"> | </span>
                                    <Link className="text-white px-2" to={'/privacy-policy'}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="signup-box p-5 mt-2">
                            <h1 className="text-center mb-4"><b>Reset Password</b></h1>
                            {errorMsg && <div className="alert alert-danger" role="alert">{errorMsg}</div> }
                            <Formik
                                initialValues={{ password: "", confirm_password: "" }}
                                validationSchema={resetPassSchema}
                                onSubmit={(values) => { handleSubmit(values) }}
                            >
                                {({ touched, errors, values}) =>
                                    !loading ? (
                                        <div>
                                            <Form className="custom-form">
                                                <div className="input-group mb-3">
                                                    <Field
                                                        type={passwordShown ? "text" : "password"}
                                                        name="password"
                                                        placeholder="Password"
                                                        id="password"
                                                        autoComplete="off"
                                                        className={`mt-2 form-control
                        ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                    />
                                                    <div className="mt-2">
                                                        <div className="input-group-text" tabIndex="0" onKeyDown={(e) => e.keyCode === 32 ? setPasswordShown(!passwordShown) : () => {} }>
                                                            {passwordShown ? <i className="far fa-eye" id="togglePassword" onClick={()=> setPasswordShown(!passwordShown) } ></i> : <i className="far fa-eye-slash" id="togglePassword" onClick={()=>setPasswordShown(!passwordShown)} ></i>}

                                                        </div>
                                                    </div>
                                                    <ErrorMessage
                                                        component="div"
                                                        name="password"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                                <div className="input-group mb-3">
                                                    <Field
                                                        type={passwordShownCon ? "text" : "password"}
                                                        name="confirm_password"
                                                        placeholder="Confirm Password"
                                                        id="confirm_password"
                                                        autoComplete="off"
                                                        className={`mt-2 form-control
                        ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                    />
                                                    <div className="mt-2">
                                                        <div className="input-group-text" tabIndex="0" onKeyDown={(e) => e.keyCode === 32 ? setPasswordShownCon(!passwordShownCon) : () => {} }>
                                                            {passwordShownCon ? <i className="far fa-eye" id="togglePassword" onClick={()=> setPasswordShownCon(!passwordShownCon) } ></i> : <i className="far fa-eye-slash" id="togglePassword" onClick={()=>setPasswordShownCon(!passwordShownCon)} ></i>}
                                                        </div>
                                                    </div>
                                                </div>
                                                    {touched.confirm_password && values.confirm_password === '' &&  <div style={{width:'100%',marginTop:'-10px',marginBottom:10,fontSize:'.875em',color:'#dc3545'}}>Confirm password is required.</div>
                                                    }
                                                    {touched.password && touched.confirm_password && values.password && values.confirm_password && 
                                                        values.password !== values.confirm_password &&  <div style={{width:'100%',marginTop:'-10px',marginBottom:10,fontSize:'.875em',color:'#dc3545'}}>Both password are not same.</div>
                                                    }
                                                <button type="submit" className="mb-4 btn btn-custom btn-custom-2 btn-custom-no-white-shadow">
                                                    {loading ? <><ClipLoader css={override} size={150} /></> : <>Submit</> }
                                                </button>
                                                <Link to="/login" className="text-decoration-none" style={{float:'right'}}>Back to Login</Link>
                                                <br />
                                            </Form>
                                            <br />
                                        </div>
                                    ) : (
                                        <> <ClipLoader css={override} size={150} /> </>
                                    )
                                }
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ResetPassword;