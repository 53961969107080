import React from "react";
import whyBarlowMarshall1 from "../img/keep-control.svg";
import whyBarlowMarshall2 from "../img/flexible.svg";
import whyBarlowMarshall3 from "../img/patient.svg";
import whyBarlowMarshall4 from "../img/simple.svg";

const BarlowMarshall = () => {
  return (
    <>
      <section className="why-Barlow-Marshall">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 align-self-center">
              <div className="section-title">WHY BARLOW MARSHALL?</div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="section-first common-background">
                <div className="section-first-image common-image">
                  <img src={whyBarlowMarshall1} alt="Keep Control" />
                </div>
                <div className="section-first-title common-title">
                  KEEP CONTROL
                </div>
                <div className="section-first-text common-text">
                  We don't take a single share, option, warrant or otherwise. As
                  founders ourselves, we appreciate the value of growing your
                  company for you.
                </div>
              </div>

              <div className="section-third common-background">
                <div className="section-third-image common-image">
                  <img src={whyBarlowMarshall3} alt="Patient" />
                </div>
                <div className="section-third-title common-title">Patient</div>
                <div className="section-third-text common-text">
                  Whilst our advances don't have late fees or any term limit, we
                  target repayment in up to 3 years. This longer term means you
                  can generate a return on investment, prioritising long term
                  growth first.
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="section-second common-background">
                <div className="section-second-image common-image">
                  <img src={whyBarlowMarshall2} alt="Flexible" />
                </div>
                <div className="section-second-title common-title">
                  Flexible
                </div>
                <div className="section-second-text common-text">
                  Repayment is via revenue share, meaning that if your revenues
                  slow or stop, so do our repayments.
                </div>
              </div>

              <div className="section-four common-background">
                <div className="section-four-image common-image">
                  <img src={whyBarlowMarshall4} alt="Simple" />
                </div>
                <div className="section-four-title common-title">Simple</div>
                <div className="section-four-text common-text">
                  No personal guarantees, overly restrictive clauses or
                  covenants. Our fixed fee mode means you know the full cost
                  upfront with no surprises.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BarlowMarshall;
