import React from "react";
import B2bSaasImage from "../img/b2b-saas.svg";

const B2bSaas = () => {
  return (
    <>
      <section className="b2b-saas-main">
        <div className="container b2b-container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <img
                src={B2bSaasImage}
                alt="Finance Growth"
                className="b2b-image"
              />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center">
              <div className="b2b-title">WE UNDERSTAND B2B SAAS</div>
              <div className="b2b-text">
                <p>
                  We understand the challenges of long sales cycles, upfront
                  customer acquisition costs and negative initial cashflows.
                </p>
                <p>
                  That's why we don't rush the payback period. Although we don't
                  define a term length, our longer time horizon (targeting up to
                  three years) gives you time to generate a return on our
                  investment...for you.
                </p>
                <p>
                  We're the only digital lending provider offering flexible
                  repayments (via a receipt share) AND over 12 month terms
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default B2bSaas;
