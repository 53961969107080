
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, FieldArray, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import { FILE_SIZE_LIMIT, ALLOWED_FILE_FORMAT, MAX_FILE_LENGTH, companyDetailsAPI, offerStatusAPI } from '../config';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PhoneNumberApi } from '../redux/service/ApiConfig';
import { countryList, getCountryCode } from '../services/CommonFunction';
import moment from 'moment';
import SignatureCanvas from "react-signature-canvas";
import { handleError, HttpCall } from '../services/UseHttps';
import { profileInfoData, profileInfoSuccess } from '../redux/action/profileInfo';
const AcceptOffer = ({...props}) => {
    const [directorsList, setDirectorsList] = useState([]);
    const [selectedDirector, setSelectedDirector] = useState([]);
    const dispatch = useDispatch();
    const [pageLoading, setPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [fileError, setFileError] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]); // file selected
    const [isFileSelect, setIsFileSelect] = useState(false);
    const [loadingMobile, setLoadingMobile] = useState([false,false,false]);
    const [fileErorrMessage, setFileErorrMessage] = useState('');
    const [phonenumberError, setphonenumberError] = useState([false,false,false]);
    const companyNumberProfile = useSelector(state =>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.company_obj && state.profileInfo.profileData.company_obj.company_number);
    const profileData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);

    const sigCanvas = useRef({});
    let numberRequest = {
        'phone_number': "",
        'country_code': ""
    }
    const [initialValues, setInitialValues] = useState({
        directors: [{title: '',name:'',email:'',date_of_birth: '',mobile_number: '',address_line_1: '',address_line_2: '',pincode: '',town: '',country: 'GB', applicant: '1'}],
        beneficiaries: [{first_name:'', last_name:'',date_of_birth: '',ownership_perc: '',address_1: '',address_2: '',town: '',postcode: '',country: 'GB'}],
        acceptTerms: false,
        otherdocument:''
    });
    const validationSchema = Yup.object().shape({
        directors: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().trim().required('Director Name required'),
                email: Yup.string().required('Email is required').email('Email is invalid'),
                date_of_birth: Yup.date().test("DOB", "Age must be between 18 to 100", (value) => {
                    return (moment().diff(moment(value),'years') >= 18 && moment().diff(moment(value),'years') < 100);
                    }).required("Required"),
                mobile_number: Yup.string().required('Mobile number is required')
                    // .min(8, "Minimum number should be 8 digits")
                    .max(15, "Maximum mobile number should be of 15 digits"),
                address_line_1: Yup.string().trim().required('Address line 1 required').max(50, 'Address line 1 maximum length of 50 characters'),
                address_line_2: Yup.string().max(50, 'Address line 2 maximum length of 50 characters'),
                pincode: Yup.string().required('Pincode is required').max(10, 'postcode maximum length of 10 characters'),
                town: Yup.string().trim('Town cannot include leading and trailing spaces').strict(true).required('Town is required').max(30, 'Town maximum length of 30 characters'),
                country: Yup.string().required('Country is required'),
                applicant: Yup.string() //Yup.bool().oneOf([true], 'Applicant is required')
            })
        ),
        beneficiaries: Yup.array().of(
            Yup.object().shape({
                first_name: Yup.string().trim().required('First name required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for first name"),
                last_name: Yup.string().trim().required('Last name required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for last name"),
                date_of_birth: Yup.date().test("DOB", "Age must be between 18 to 100", (value) => {
                        return (moment().diff(moment(value),'years') >= 18 && moment().diff(moment(value),'years') < 100);
                    }).required("Required"),
                ownership_perc: Yup.number().required('Ownership percentage is required')
                    .min(1, 'Minimum percentage value 1%')
                    .max(100, 'Maximum percentage value 100%'),
                address_1: Yup.string().trim().required('Address line 1 required').max(50, 'Address line 1 maximum length of 50 characters'),
                address_2: Yup.string().max(50, 'Address line 2 maximum length of 50 characters'),
                town: Yup.string().trim('Town cannot include leading and trailing spaces').strict(true).required('Town is required').max(30, 'Town maximum length of 30 characters'),
                postcode: Yup.string().required('Postcode is required').max(10, 'Postcode maximum length of 10 characters'), //.trim().matches(pinPatt, 'Pincode only allows 6 digit'),
                country: Yup.string().required('Country is required')
            })
        ),
        acceptTerms: Yup.bool().oneOf([true], 'Accept terms & conditions is required')
    });
    const [indexSet, setIndexSet] = useState([0,1,2]);
    // keyName = 'directors'|'beneficiaries'
    function onChangeDirectorBeneOwner(isAdd, index, keyName, values, setValues) {
        // update dynamic form
        const keyValues = [...values[keyName]];
        if (isAdd && keyName === 'directors') {
            // const dirc = directorsList[0];
            // keyValues.push({
            //     title: dirc.occupation ? dirc.occupation : '',
            //     name: dirc.name ? dirc.name : '',
            //     email:'',
            //     date_of_birth: dirc.date_of_birth && dirc.date_of_birth.month && dirc.date_of_birth.year ? (dirc.date_of_birth.year + '-' + (dirc.date_of_birth.month < 10 ? '0' + dirc.date_of_birth.month : dirc.date_of_birth.month) + '-01') : '',
            //     address_line_1: dirc.address&&dirc.address.address_line_1?dirc.address.address_line_1:'',
            //     address_line_2: dirc.address&&dirc.address.address_line_2?dirc.address.address_line_2:'',
            //     pincode: dirc.address&&dirc.address.postal_code?dirc.address.postal_code:'',
            //     town: dirc.address&&dirc.address.locality?dirc.address.locality:'',
            //     country: dirc.address&&dirc.address.country ? getCountryCode(dirc.address.country) : 'GB',
            //     mobile_number: '', applicant: '0'
            // });
            keyValues.push({ title:'', name:'', email:'', date_of_birth:'', address_line_1:'', address_line_2:'',
                pincode:'', town:'', country:'GB', mobile_number: '', applicant: '0' });
            const newErrArr = [...phonenumberError];
            newErrArr[index]=false;
            setphonenumberError(newErrArr);
        } else if (isAdd && keyName === 'beneficiaries') {
            keyValues.push({ first_name: '',last_name: '',date_of_birth: '',ownership_perc: '',address_1: '',address_2: '',town: '',postcode: '',country: 'GB' });
        } else {
            if (keyName === 'directors') {
                const oldIndex = selectedDirector.indexOf(keyValues[index].name);
                if (oldIndex > -1) { selectedDirector.splice(oldIndex, 1); }
                setSelectedDirector(selectedDirector);
                keyValues.splice(index, 1);
            } else {
                keyValues.splice(index, 1);
            }
        }
        if (keyName === 'directors') {
            if(keyValues.length === 1) {
                keyValues[0].applicant = '1';
            }
            setValues({ ...values, directors:keyValues });
        } else {
            setValues({ ...values, beneficiaries:keyValues });
        }
    }

    // director selection/change
    const handleDirectorChange = (e, values, setValues) => {
        const dirs = directorsList.filter(dir => dir.name === e.target.value);
        const dirc = dirs[0];
        const inputValue = document.getElementById(e.target.name);
        if(inputValue && inputValue.value) {
            const index = parseInt(inputValue.value);
            const currDire = values.directors;
            const oldIndex = selectedDirector.indexOf(currDire[index].name);
            if (oldIndex > -1) { selectedDirector.splice(oldIndex, 1); }
            const isApplicant = (currDire[index].applicant==='1' ? currDire[index].applicant : (currDire.length === 1 ? '1' : '0'));
            currDire[index] = {
                title: dirc.occupation ? dirc.occupation : '',
                name: dirc.name ? dirc.name : '',
                date_of_birth: '',//dirc.date_of_birth && dirc.date_of_birth.month && dirc.date_of_birth.year ? (dirc.date_of_birth.year + '-' + (dirc.date_of_birth.month < 10 ? '0' + dirc.date_of_birth.month : dirc.date_of_birth.month) + '-01') : '',
                email: currDire[index]&&currDire[index].email?currDire[index].email:'',
                address_line_1: dirc.address&&dirc.address.address_line_1?dirc.address.address_line_1:'',
                address_line_2: dirc.address&&dirc.address.address_line_2?dirc.address.address_line_2:'',
                pincode: dirc.address&&dirc.address.postal_code?dirc.address.postal_code:'',
                town: dirc.address&&dirc.address.locality?dirc.address.locality:'',
                country: dirc.address&&dirc.address.country ? getCountryCode(dirc.address.country) : 'GB',
                mobile_number: '', applicant: isApplicant
            };
            setSelectedDirector([...selectedDirector, dirc.name]);
            // setInitialValues({ ...initialValues, directors: currDire });
            setValues({ ...values, directors: currDire });
        }
    }
    // applicant change event
    const handleApplicantChange = (values, index, setValues) => {
        // if(e.target && e.target.checked) {
            const directorArr = [...values['directors']];
            directorArr.forEach((d,i) => {
                if(i=== index) { d.applicant='1'; } else { d.applicant='0'; }
            });
            setValues({ ...values, directors: directorArr });
        // }
    }

    // get company details from company number.
    function getCompanyDetails(companyNumber) {
        if (companyNumber && companyNumber.trim() !== '') {
            setPageLoading(true);
            HttpCall(`${companyDetailsAPI}${companyNumber}`, "GET").then((res) => {
                if (res && res.data && res.data.data) {
                    const scd = res.data.data;
                    const directorActive = [];
                    if(scd?.directors && scd?.directors?.length > 0) {
                        // eslint-disable-next-line array-callback-return
                        scd.directors.map((dire) => {
                            if(!dire?.resigned_on || !dire?.resigned_on===undefined) { directorActive.push(dire); }
                        });
                    }
                    const directorObj = directorActive&&directorActive[0] ? directorActive[0] :{};
                    setDirectorsList(directorActive);
                    setSelectedDirector([directorObj.name]);
                    setInitialValues({
                        ...initialValues,
                        directors: [{
                            title: directorObj.occupation ? directorObj.occupation : '',
                            name: directorObj.name ? directorObj.name : '',
                            date_of_birth: '',//directorObj.date_of_birth && directorObj.date_of_birth.month && directorObj.date_of_birth.year ? (directorObj.date_of_birth.year + '-' + (directorObj.date_of_birth.month < 10 ? '0' + directorObj.date_of_birth.month : directorObj.date_of_birth.month) + '-01') : '',
                            email: directorObj.email ? directorObj.email : '',
                            mobile_number: directorObj.mobile_number ? directorObj.mobile_number : '',
                            address_line_1: directorObj.address&&directorObj.address.address_line_1 ? directorObj.address.address_line_1 : '',
                            address_line_2: directorObj.address&&directorObj.address.address_line_2 ? directorObj.address.address_line_2 : '',
                            pincode: directorObj.address&&directorObj.address.postal_code ? directorObj.address.postal_code : '',
                            town: directorObj.address&&directorObj.address.locality ? directorObj.address.locality : '',
                            country: directorObj.address&&directorObj.address.country ? getCountryCode(directorObj.address.country) : 'GB',
                            applicant: '1'
                        }],
                        otherdocument: directorObj.documents ? directorObj.documents : '',
                        acceptTerms: false
                    });
                    setPageLoading(false);
                } else {setPageLoading(false);} 
            }).catch((error) => {
                setPageLoading(false);
                handleError(error);
                setInitialValues({ ...initialValues });
            });
        }
    }
    useEffect(() => {
        if(companyNumberProfile) { getCompanyDetails(companyNumberProfile); }
    }, [companyNumberProfile]);
    // phone validation
    const phoneValue = (value, country) => {
        numberRequest = {
            'phone_number': value,
            'country_code': country.iso2
        }
    };
    const handleBlurMobile = (e,values,ind, setValues) => {
        const loadArr = [...loadingMobile];
        try {
            const directorArr = [...values['directors']];
            const newErrorArr = [...phonenumberError];
            if(numberRequest&&numberRequest['phone_number']&&numberRequest['phone_number'].length <= 4) {
                newErrorArr[ind] = true;
                setphonenumberError(newErrorArr);
                directorArr[ind].mobile_number = '';
                setValues({ ...values, directors: directorArr });
                return;
            }
            loadArr[ind] = true;
            setLoadingMobile(loadArr);
            PhoneNumberApi(numberRequest).then((res) => {
                if (res&&res.data&&res.data.is_valid === true) {
                    newErrorArr[ind] = false;
                    setphonenumberError(newErrorArr);
                    const mobileValues = e.target.value.split(' ');
                    const mobileCode = mobileValues.splice(0,1);
                    const turnCode = (mobileCode.toString()).replace('+', '+(');
                    const phoneNum = turnCode+')' + (mobileValues.toString()).replace(/\W+/g, "");
                    directorArr[ind].mobile_number = phoneNum;
                    setValues({ ...values, directors: directorArr });
                } else {
                    newErrorArr[ind] = true;
                    setphonenumberError(newErrorArr);
                    directorArr[ind].mobile_number = '';
                    setValues({ ...values, directors: directorArr });
                }
                loadArr[ind] = false;
                setLoadingMobile(loadArr);
            })
        } catch (err) {
            loadArr[ind] = false;
            setLoadingMobile(loadArr);
        }
    }

    const dtatUrlBlob = function(dataURI, dataTYPE) {
        if(typeof dataURI !== 'string'){
            throw new Error('Invalid argument: dataURI must be a string');
        }
        dataURI = dataURI.split(',');
        var type = dataURI[0].split(':')[1].split(';')[0],
        byteString = atob(dataURI[1]),
        byteStringLength = byteString.length,
        arrayBuffer = new ArrayBuffer(byteStringLength),
        intArray = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteStringLength; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }
        return new Blob([intArray], {
            type: type
        });
    }


    useEffect(() => {
        if (selectedFiles.length > 0) {
            setFileErorrMessage('');
        }
    }, [selectedFiles])
  
    const checkSubmitValidation = () => {
        if (selectedFiles && !selectedFiles.length > 0) {
            setFileErorrMessage('Any other document is required to upload.');
        } else { setFileErorrMessage(''); }
        if (sigCanvas.current.isEmpty()) {
            setErorrMessage('E-signature is required to accept offer.');
        } else { setErorrMessage(''); }
    }
  
    function onSubmit(values) {
        // console.log('SUCCESS!! onSubmit values : ', values);
        try {
            if (selectedFiles && !selectedFiles.length > 0) {
                setFileErorrMessage('Any other document is required to upload.')
                return;
            }
            setFileErorrMessage('');
            if ( sigCanvas.current.isEmpty()) {
                setErorrMessage('E-signature is required to accept offer.')
                return;
            }
            if (values && values.directors.length < 0) { setErorrMessage('Please add valid directors details.');return;}
            if (values && values.directors.length > 0) {
                let count = 0;
                values.directors.map(dir => {
                    if(dir.applicant==='1') { count = count+1;}
                });
                if(count===0) {
                    setErorrMessage('Please select valid directors applicant.');
                    return;
                }
            }
            if (values && values.beneficiaries.length < 0) { setErorrMessage('Please add valid beneficiaries details.');return;}
            setErorrMessage('');
            setIsLoading(true);
            const formData = new FormData();
            // Director details
            formData.append('director', JSON.stringify(values.directors));
            // Bere Owner
            formData.append('beneficiary', JSON.stringify(values.beneficiaries));
            formData.append('company_number', companyNumberProfile);
            if (selectedFiles && selectedFiles.length > 0) {
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('kyc_documents', selectedFiles[i]);
                }
            }
            formData.append("status",'accepted');
            formData.append("file",dtatUrlBlob( sigCanvas?.current?.getTrimmedCanvas()?.toDataURL("image/png") ||''));
            // save
            HttpCall(`${offerStatusAPI}/${profileData.offer.id}`, "patch", formData).then((res) => {
                if(res && (res.status === 200 || res.status === 201)) {
                    dispatch(profileInfoSuccess(''));
                    props.setShowAcceptOffer(false);
                    Swal.fire({
                        title: "Done!",
                        text: `Offer Accepted`,
                        icon: "success",
                        // timer: 2000,
                        // button: false,
                    });
                    // formik.resetForm();
                    clears();
                    dispatch(profileInfoData());
                    setErorrMessage('');
                    setIsLoading(false);
                    window.scrollTo(0, 0);
                } else {
                    setErorrMessage('Failed accept offer.');
                    setIsLoading(false);
                }
            }).catch(e => {
                setIsLoading(false);
                handleError(e);
            });
        } catch(err) {
            setIsLoading(false);
        }
    }

    // kyc dodument upload...
    const documentChange = (e) => {
        const files = e.target.files;
        const filesList = [];
        setFileError([]);
        const errmsg = `Maximum ${MAX_FILE_LENGTH} files allowed to upload.`;
        if (files && files.length > 0) {
            let total = selectedFiles.length;
            for (let i = 0; i < files.length; i++) {
                if (total < MAX_FILE_LENGTH) {
                    total++;
                    const type = files[i].type.toLowerCase();
                    if (ALLOWED_FILE_FORMAT.includes(type)) {
                        if (files[i].size < FILE_SIZE_LIMIT) {
                            filesList.push(files[i]);
                        } else { setFileError([...fileError, files[i].name + ' file size is more than 1 MB.']); }
                    } else { setFileError([...fileError, files[i].name + ' file type is not allowed.']); }
                } else { if (!fileError.includes(errmsg)) setFileError([...fileError, errmsg]) }
            }
            setSelectedFiles([...selectedFiles, ...filesList]);
        }
        e.target.value = null;
    }
    const removeFile = (i) => {
        const filesArr = [...selectedFiles];
        filesArr.splice(i, 1);
        setSelectedFiles([...filesArr]);
    }
    // show file selection 
    const updateFileClick = () => {
        setSelectedFiles([]);
        setIsFileSelect(true);
    }
    // show file selection 
    const cancelUpdateFileClick = () => {
        setSelectedFiles([]);
        setIsFileSelect(false);
    }

    // signature code started
    const [errorMessage, setErorrMessage] = useState('');
    const clears = () => {
        if(Object.keys(sigCanvas.current || {}).length!==0) { 
            return sigCanvas?.current?.clear()
        }
    };
  
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-8 m-auto">
                                    <h5 className="fw-normal">Director Details</h5>
                                    {pageLoading ? <ClipLoader size={150} css={{display:'block',margin:'0 auto',borderColor:'green'}} />
                                    :
                                    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                        {({ errors, values, touched, setValues, setFieldValue, handleChange, handleBlur }) => (
                                            <Form>
                                                <ScrollToFieldError />
                                                <div className="accordion" id="accordionKyc">
                                                    <div className="accordion-item mt-3">
                                                        <h2 className="accordion-header" id="headingDirector">
                                                            <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#collapseDirector" aria-expanded="false" aria-controls="collapseDirector">
                                                                <b> Director Details
                                                                </b>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseDirector" className="accordion-collapse collapse show" aria-labelledby="headingDirector">
                                                            <div className="accordion-body">
                                                                <FieldArray name="directors">
                                                                    {() => (values.directors.map((dir, i) => {
                                                                        const ticketErrors = errors.directors?.length && errors.directors[i] || {};
                                                                        const ticketTouched = touched.directors?.length && touched.directors[i] || {};
                                                                        return (
                                                                            <div key={i} className="list-group list-group-flush">
                                                                                <div className="list-group-item">
                                                                                    <h5 className="card-title">
                                                                                        <span>{i + 1}. Director</span>
                                                                                        {i+1 === values.directors.length && i < 2 ?
                                                                                            <>
                                                                                                <span title='Add More Director' style={{float:'right',cursor:'pointer'}} onClick={() => onChangeDirectorBeneOwner(true, i, 'directors', values, setValues)}>
                                                                                                    <i className="fa fa-plus-circle" style={{fontSize:'24px'}}></i>
                                                                                                </span>
                                                                                                {   i > 0 &&
                                                                                                    <span title='Remove Director' style={{float:'right', marginRight:10,cursor:'pointer'}} onClick={() => onChangeDirectorBeneOwner(false, i, 'directors', values, setValues)}>
                                                                                                        <i className="fa fa-minus-circle" style={{fontSize:'24px',color:'red'}}></i>
                                                                                                    </span>
                                                                                                }
                                                                                            </>
                                                                                        :
                                                                                            <span title='Remove Director'  style={{float:'right',cursor:'pointer'}} onClick={() => onChangeDirectorBeneOwner(false, i, 'directors', values, setValues)}>
                                                                                                <i className="fa fa-minus-circle" style={{fontSize:'24px',color:'red'}}></i>
                                                                                            </span>
                                                                                        }
                                                                                        { values&&values['directors']&&values['directors'][i]&&values['directors'][i].applicant === '1' ?
                                                                                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { handleApplicantChange(values,i, setValues) }}
                                                                                                style={{marginRight:10, marginTop:'-5px',color:'#fff',backgroundColor:'#1f77fb',borderColor:'#0d6efd',float:'right'}}
                                                                                            >Applicant</button>
                                                                                        :
                                                                                            <button type="button" className="btn btn-light btn-sm" onClick={() => { handleApplicantChange(values,i, setValues) }}
                                                                                                style={{marginRight:10, marginTop:'-5px',color:'#8f8f8f',backgroundColor:'#ebebeb',borderColor:'#bfbfc0',float:'right'}}>Applicant</button>
                                                                                        }
                                                                                    </h5>
                                                                                    <div className="form-row">
                                                                                        {/* <div className="mb-3">
                                                                                            <input type="radio" name="applicant" id={`directors.${i}.applicant`}
                                                                                                value={values['directors'][i].applicant} onChange={(e) => { handleApplicantChange(e,values,i, setValues) }}
                                                                                                className="form-check-input" checked={ values['directors'][i].applicant === '1'}
                                                                                            />
                                                                                            <label htmlFor={`directors.${i}.applicant`}><b> : Applicant </b></label>
                                                                                            <ErrorMessage name={`directors.${i}.applicant`} component="div" className="invalid-feedback" />
                                                                                        </div> */}
                                                                                        <div className="mb-3">
                                                                                            <select className="form-select" name={`directors.${i}.name`} value={values['directors']&&values['directors'][i]&&values['directors'][i].name?values['directors'][i].name:''}
                                                                                                onChange={ e => handleDirectorChange(e, values, setValues) } placeholder='Select Director'>
                                                                                                    <option disabled value={''}>Select Director</option>
                                                                                                    {
                                                                                                        directorsList && directorsList.length > 0 && directorsList.map((dir,i) => 
                                                                                                        <option key={i} value={dir.name}
                                                                                                        disabled={selectedDirector.includes(dir.name)}
                                                                                                        >{dir&&dir.name?dir.name:''}</option>)
                                                                                                    }
                                                                                            </select>
                                                                                            <input type="hidden" id={`directors.${i}.name`} value={indexSet[i] || indexSet[0]} onChange={handleChange} />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`directors.${i}.name`} value={values['directors'][i].name}
                                                                                                onChange={handleChange} onBlur={handleBlur} placeholder="Director Name"
                                                                                                type="text" className={'form-control' + (ticketErrors.name && ticketTouched.name ? ' is-invalid' : '' )} />
                                                                                            <ErrorMessage name={`directors.${i}.name`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`directors.${i}.email`} value={values['directors'][i].email} onChange={handleChange} onBlur={handleBlur} placeholder="Email"
                                                                                                type="text" className={'form-control' + (ticketErrors.email && ticketTouched.email ? ' is-invalid' : '' )} />
                                                                                            <ErrorMessage name={`directors.${i}.email`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`directors.${i}.date_of_birth`} value={values['directors'][i].date_of_birth} onChange={handleChange} onBlur={handleBlur} max={new Date().toLocaleDateString('en-ca')}
                                                                                                type="date" className={'form-control text-uppercase' + (ticketErrors.date_of_birth && ticketTouched.date_of_birth ? ' is-invalid' : '' )} placeholder="Date of Birth" />
                                                                                            <ErrorMessage name={`directors.${i}.date_of_birth`} component="div" className="invalid-feedback" />
                                                                                        </div>       
                                                                                        <div className="shadow">
                                                                                            <PhoneInput inputProps={{name: `directors.${i}.mobile_number`}}
                                                                                                country={'gb'}
                                                                                                name={`directors.${i}.mobile_number`}
                                                                                                value={values['directors'][i].mobile_number}
                                                                                                className=""
                                                                                                placeholder="Phone Number"
                                                                                                isValid={(value, country) => phoneValue(value, country)}
                                                                                                onChange={handleChange}
                                                                                                onBlur={(e) => handleBlurMobile(e,values,i, setValues)}
                                                                                            />
                                                                                            { phonenumberError[i] === true ? <div className='text-danger'> Please enter valid number</div> : ''}
                                                                                            {/* <ErrorMessage name={`directors.${i}.mobile_number`} component="div" className="invalid-feedback" /> */}
                                                                                            {errors&&errors['directors']&&errors['directors'][i]&&errors['directors'][i].mobile_number 
                                                                                            && touched&&touched['directors']&&touched['directors'][i]&&touched['directors'][i].mobile_number && (
                                                                                                <div className="text-danger">{errors&&errors['directors']&&errors['directors'][i]&&errors['directors'][i].mobile_number}</div>
                                                                                            )}
                                                                                            <div style={{marginTop:'-50px',position:'absolute',marginLeft:'100px'}}>
                                                                                                {loadingMobile[i] === true ? <ClipLoader size={50} css={{ display: 'block', float: 'right', borderColor: 'green' }} color="green" /> : null}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div style={{marginTop:20}}>
                                                                                        <h6 className="card-title">
                                                                                            <span>Director residential address</span>
                                                                                        </h6>
                                                                                            <small><b>Note: Address should be same as per KYC documents provided.</b></small>
                                                                                        </div>
                                                                                        <div className="mb-3" style={{marginTop:5}}>
                                                                                            <input type="textarea" 
                                                                                                className={'form-control ' + (ticketErrors.address_line_1 && ticketTouched.address_line_1 ? ' is-invalid' : '' )}
                                                                                                placeholder="Address Line 1"
                                                                                                name={`directors.${i}.address_line_1`}
                                                                                                value={values['directors'][i]['address_line_1']}
                                                                                                onChange={handleChange} onBlur={handleBlur} />
                                                                                            <ErrorMessage name={`directors.${i}.address_line_1`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3" style={{marginTop:20}}>
                                                                                            <input type="textarea" 
                                                                                                className={'form-control ' + (ticketErrors.address_line_2 && ticketTouched.address_line_2 ? ' is-invalid' : '' )}
                                                                                                placeholder="Address Line 2"
                                                                                                name={`directors.${i}.address_line_2`}
                                                                                                value={values['directors'][i]['address_line_2']}
                                                                                                onChange={handleChange} onBlur={handleBlur} />
                                                                                            <ErrorMessage name={`directors.${i}.address_line_2`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`directors.${i}.town`} value={values['directors'][i].town} placeholder="Town" 
                                                                                                onChange={handleChange} onBlur={handleBlur} 
                                                                                                type="text" className={'form-control' + (ticketErrors.town && ticketTouched.town ? ' is-invalid' : '' )} />
                                                                                            <ErrorMessage name={`directors.${i}.town`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`directors.${i}.pincode`} value={values['directors'][i].pincode} placeholder="Postcode" 
                                                                                                onChange={handleChange} onBlur={handleBlur} 
                                                                                                type="text" className={'form-control' + (ticketErrors.pincode && ticketTouched.pincode ? ' is-invalid' : '' )} />
                                                                                            <ErrorMessage name={`directors.${i}.pincode`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <select className="form-select" name={`directors.${i}.country`} value={values['directors'][i].country}
                                                                                                onChange={handleChange} onBlur={handleBlur} aria-label="Default select example">
                                                                                                <option disabled>Country</option>
                                                                                                {countryList && countryList.length > 0 &&
                                                                                                    countryList.map((cntr, i) => <option key={i} value={cntr.code}>{cntr.name+' ('+cntr.code+')'}</option>)
                                                                                                }
                                                                                            </select>
                                                                                            <ErrorMessage name={`directors.${i}.country`} component="div" className="invalid-feedback" />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }))}
                                                                </FieldArray>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item mt-3">
                                                        <h2 className="accordion-header" id="headingBeneOwner">
                                                            <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#collapseBeneOwner" aria-expanded="false" aria-controls="collapseBeneOwner">
                                                                <b> Beneficial Owner Details </b>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseBeneOwner" className="accordion-collapse collapse show" aria-labelledby="headingBeneOwner">
                                                            <div className="accordion-body">
                                                            <FieldArray name="beneficiaries">
                                                                    {() => (values.beneficiaries.map((ticket, i) => {
                                                                        const ticketErrors = errors.beneficiaries?.length && errors.beneficiaries[i] || {};
                                                                        const ticketTouched = touched.beneficiaries?.length && touched.beneficiaries[i] || {};
                                                                        return (
                                                                            <div key={i} className="list-group list-group-flush">
                                                                                <div className="list-group-item">
                                                                                    <h5 className="card-title">
                                                                                        <span>{i + 1}. Beneficiary Owner</span>
                                                                                        {i+1 === values.beneficiaries.length && i < 2 ?
                                                                                            <>
                                                                                                <span title='Add More Beneficiary Owner' style={{float:'right',cursor:'pointer'}} onClick={() => onChangeDirectorBeneOwner(true, i, 'beneficiaries', values, setValues)}><i className="fa fa-plus-circle" style={{fontSize:'24px'}}></i></span>
                                                                                                {i > 0 && <span title='Remove Beneficiary Owner' style={{float:'right',cursor:'pointer'}} onClick={() => onChangeDirectorBeneOwner(false, i, 'beneficiaries', values, setValues)}><i className="fa fa-minus-circle" style={{fontSize:'24px',color:'red'}}></i></span> }
                                                                                            </>
                                                                                        :
                                                                                            <span title='Remove Beneficiary Owner' style={{float:'right',cursor:'pointer'}} onClick={() => onChangeDirectorBeneOwner(false, i, 'beneficiaries', values, setValues)}><i className="fa fa-minus-circle" style={{fontSize:'24px',color:'red'}}></i></span>
                                                                                        }
                                                                                    </h5>
                                                                                    <div className="form-row">
                                                                                        <div className="mb-3">
                                                                                            <Field name={`beneficiaries.${i}.first_name`} value={values['beneficiaries'][i].first_name} onChange={handleChange} onBlur={handleBlur}
                                                                                                type="text" className={'form-control' + (ticketErrors.first_name && ticketTouched.first_name ? ' is-invalid' : '' )} placeholder="First Name" />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.first_name`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`beneficiaries.${i}.last_name`}  value={values['beneficiaries'][i].last_name} onChange={handleChange} onBlur={handleBlur}
                                                                                                type="text" className={'form-control' + (ticketErrors.last_name && ticketTouched.last_name ? ' is-invalid' : '' )} placeholder="Last Name" />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.last_name`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`beneficiaries.${i}.date_of_birth`} value={values['beneficiaries'][i].date_of_birth} onChange={handleChange} onBlur={handleBlur} max={new Date().toLocaleDateString('en-ca')}
                                                                                                type="date" className={'form-control text-uppercase' + (ticketErrors.date_of_birth && ticketTouched.date_of_birth ? ' is-invalid' : '' )} placeholder="Date of Birth" />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.date_of_birth`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`beneficiaries.${i}.ownership_perc`}  value={values['beneficiaries'][i].ownership_perc} 
                                                                                                onChange={(e) => { let per = Number(e.target.value).toFixed(0); setFieldValue(`beneficiaries.${i}.ownership_perc`, per?per:e.target.value); }} onBlur={handleBlur} onWheel={(e) => e.target.blur()}
                                                                                                type="text" className={'form-control' + (ticketErrors.ownership_perc && ticketTouched.ownership_perc ? ' is-invalid' : '' )} placeholder="Owenership Percentage" />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.ownership_perc`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <small><b>Note: Address should be same as per KYC documents provided.</b></small>
                                                                                        <div className="mb-3" style={{marginTop:5}}>
                                                                                            <input type="textarea" 
                                                                                                className={'form-control ' + (ticketErrors.address_1 && ticketTouched.address_1 ? ' is-invalid' : '' )}
                                                                                                placeholder="Address Line 1"
                                                                                                name={`beneficiaries.${i}.address_1`}
                                                                                                value={values['beneficiaries'][i]['address_1']}
                                                                                                onChange={handleChange} onBlur={handleBlur} />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.address_1`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3" style={{marginTop:20}}>
                                                                                            <input type="textarea" 
                                                                                                className={'form-control ' + (ticketErrors.address_2 && ticketTouched.address_2 ? ' is-invalid' : '' )}
                                                                                                placeholder="Address Line 2"
                                                                                                name={`beneficiaries.${i}.address_2`}
                                                                                                value={values['beneficiaries'][i]['address_2']}
                                                                                                onChange={handleChange} onBlur={handleBlur} />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.address_2`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`beneficiaries.${i}.town`} value={values['beneficiaries'][i].town} placeholder="Town" 
                                                                                                onChange={handleChange} onBlur={handleBlur} 
                                                                                                type="text" className={'form-control' + (ticketErrors.town && ticketTouched.town ? ' is-invalid' : '' )} />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.town`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <Field name={`beneficiaries.${i}.postcode`} value={values['beneficiaries'][i].postcode} placeholder="Postcode" 
                                                                                                onChange={handleChange} onBlur={handleBlur} 
                                                                                                type="text" className={'form-control' + (ticketErrors.postcode && ticketTouched.postcode ? ' is-invalid' : '' )} />
                                                                                            <ErrorMessage name={`beneficiaries.${i}.postcode`} component="div" className="invalid-feedback" />
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <select className="form-select" name={`beneficiaries.${i}.country`} value={values['beneficiaries'][i].country}
                                                                                                onChange={handleChange} onBlur={handleBlur} aria-label="Default select example">
                                                                                                <option disabled>Country</option>
                                                                                                {countryList && countryList.length > 0 &&
                                                                                                    countryList.map((cntr, i) => <option key={i} value={cntr.code}>{cntr.name+' ('+cntr.code+')'}</option>)
                                                                                                }
                                                                                            </select>
                                                                                            <ErrorMessage name={`beneficiaries.${i}.country`} component="div" className="invalid-feedback" />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }))}
                                                                </FieldArray>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Other */}
                                                <div className="custom-form" style={{marginTop:20}}>
                                                    <div className="mb-3 form-check">
                                                        <div style={{padding:10, fontWeight: 'bold', marginLeft: -25, marginBottom: 5, border: '1px solid black'}}>
                                                            Barlow Marshall will introduce you as a client to Modulr Finance Limited (“Modulr”), where an electronic money account will be set up as per the agreement between you and Barlow Marshall.
                                                        </div>
                                                        <input type="checkbox"
                                                            name="acceptTerms"
                                                            value={values.acceptTerms}
                                                            checked={values && values.acceptTerms ? true : false}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="form-check-input" id="exampleCheck1" />
                                                        <label className="form-check-label" htmlFor="exampleCheck1"><b>By checking this box, you are agreeing to Modulr's Introduced Clients 
                                                            <a href={`${window.location.origin}/terms-conditions`} target="_blank"> Terms and conditions</a></b>
                                                        </label>
                                                        {errors.acceptTerms && touched.acceptTerms && (
                                                            <div className="text-danger">{errors.acceptTerms}</div>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mt-3">Any Other Document(s) : </label>
                                                            <>
                                                                <input type="file" id="myfile"
                                                                    name="otherdocument"
                                                                    placeholder='Files'
                                                                    onChange={documentChange}
                                                                    className="mt-2 mb-3 form-control" multiple
                                                                    accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf,
                                                                    application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                                    .csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                                                                    .ppt, pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation" />

                                                                <div>
                                                                <div className="text-danger">{fileErorrMessage}</div>

                                                                    <small><b>Maximum 5 files & Images, PDF, Document, Excel, Powerpoint files allowed, per file size limit 1 MB</b></small>
                                                                    {
                                                                        selectedFiles && selectedFiles.length > 0 &&
                                                                        <>
                                                                            <button type='button' onClick={cancelUpdateFileClick} title='Close' style={{ float: 'right', color: '#fff', backgroundColor: 'red' }}><i className="fa fa-close" aria-hidden="true"></i></button>
                                                                            <ul>
                                                                                {selectedFiles.map((file, i) =>
                                                                                    <li key={i}>{file && file.name ? file.name : ''}
                                                                                        <button type="button" className="close btn-danger" aria-label="Close" onClick={() => removeFile(i)} style={{ margin: 5, textAlign: 'center' }}><i className="fa fa-trash" aria-hidden="true"></i></button></li>
                                                                                )}
                                                                            </ul>
                                                                        </>
                                                                    }
                                                                    {
                                                                        fileError && fileError.length > 0 ?
                                                                            fileError.map((err, i) => <div key={i} className="text-danger">{err ? err : ''}</div>)
                                                                            : ''
                                                                    }
                                                                </div>
                                                            </>
                                                    </div>
                                                </div>
                                                <div className="row d-flex justify-content-md-between">
                                                    <div className="signature-container">
                                                        <label className="w-100 m-3 " >
                                                        <h6> E-Signature</h6>
                                                        </label>
                                                        <div className="wrapper d-flex align-items-end mt-2 mb-4">
                                                            <div className="d-flex flex-column">

                                                            <SignatureCanvas
                                                            id="canvas2"
                                                            penColor="green"
                                                            ref={sigCanvas}
                                                            //   onClick={OnchangeSignature}
                                                            canvasProps={{
                                                                width: 340,
                                                                height: 150,
                                                                className: "sigCanvas ml-auto",
                                                                }}
                                                                style={{ cursor: "crosshair",margin:'auto' }}
                                                                />
                                                            <div className="text-danger">{errorMessage}</div>
                                                                </div>
                                                            <br />
                                                            <div className="d-flex justify-content-end ">
                                                            
                                                            <br />
                                                            <div className="">
                                                                <div
                                                                onClick={() => {
                                                                    clears();
                                                                }}
                                                                className="btn btn-custom btn-custom-4 btn-custom-no-white-shadow w-100"
                                                                id="clear"
                                                                >
                                                                {" "}
                                                                Clear{" "}
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                                                    onClick={checkSubmitValidation} disabled={isLoading}>
                                                    Submit
                                                </button>
                                                <br />
                                            </Form>
                                        )}
                                    </Formik>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

    // Returns an array of error field names using object dot notation for array fields
    export const getFieldErrorNames = (formikErrors) => {
        const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key]
            if (!value) return;
            const nextKey = prefix ? `${prefix}.${key}` : key
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result)
            } else {
                result.push(nextKey)
            }
        });
            return result;
        }
        return transformObjectToDotNotation(formikErrors);
    }
    // Component fetch error and auto scroll to localted input-error
    export const ScrollToFieldError = ({ scrollBehavior = { behavior: 'smooth', block: 'center' } }) => {
        const { submitCount, isValid, errors } = useFormikContext()
        useEffect(() => {
            if (isValid) return
            const fieldErrorNames = getFieldErrorNames(errors)
            if (fieldErrorNames.length <= 0) return
            const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`)
            if (!element) return;
            element.scrollIntoView(scrollBehavior); // Scroll to first known error into view
        }, [submitCount]) // eslint-disable-line react-hooks/exhaustive-deps
        return null
    }
export default AcceptOffer