import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { YAPILY_PRI_POL_LINK, YAPILY_TERM_CON_LINK } from '../../config';
import YapilyDisclaimer from './YapilyDisclaimer';

const YapilyFooter = () => {
  const [showRevoke, setShowDisclaimer] = useState(false);

  const onClickOpenModel = (e) => {
    e.preventDefault();
    setShowDisclaimer(true);
  };
  const handleCloseModel = () => {
    setShowDisclaimer(false);
  };
  return (
    <>
    <div style={{ textAlign: 'center', fontSize: 'small', fontWeight: 600 }}>
      <p>By using the service, you agree to yapily's <a href={YAPILY_TERM_CON_LINK} target='_blank'>Terms & Conditions</a> and <a href={YAPILY_PRI_POL_LINK} target='_blank'>Privacy Policy</a>. yapily will retrieve your account information. <a href='#' onClick={(e)=>onClickOpenModel(e)}>Read More</a>.</p>
    </div>

    <Modal size="lg" show={showRevoke} onHide={handleCloseModel} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Disclaimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <YapilyDisclaimer isAuthScreen={false} onClickBack={false} onClickAuthButton={false} />
                  {/* <Container>
                    <Row>
                      <p>By using this service 
                        you will now be securely redirected to your bank to confirm your consent for
                        Yapily Connect to read and receive the following information:</p>
                        <div className=''>
                          <ul>
                            <li className='mb-3'>Identification details</li>
                            <li className='mb-3'>Account(s) details</li>
                            <li className='mb-3'>Balances</li>
                            <li className='mb-3'>Interest rates</li>
                            <li className='mb-3'>Other transactional and account information</li>
                          </ul>
                        </div>
                        <p>For more information please refer to the Yapily Connect's <a href={YAPILY_TERM_CON_LINK} target='_blank'>Terms & Conditions</a>.</p>
                    </Row>
                  </Container> */}
                </Modal.Body>
                <Modal.Footer style={{display:'flex',justifyContent: 'center'}}>
                  <div>
                    <button variant="secondary" type="button" name="reasonname"
                        className="btn btn-outline-primary" type="button" onClick={handleCloseModel}>
                        Close
                    </button>
                  </div>
                </Modal.Footer>
            </Modal>
    </>
  )
}

export default YapilyFooter;
