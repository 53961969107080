import * as typeS from "../service/types";
import { CodatAccount, CodatLink } from "../service/ApiConfig";
import Swal from 'sweetalert2';
import { ErrorHandler } from "../../components/Auth/errorHandler";

export const codatRequest = () => {
  return {
    type: typeS.CODAT_REQUEST,
  };
};

export const codatSuccess = (codat) => {
  return {
    type: typeS.CODAT_SUCCESS,
    payload: codat,
  };
};

export const codatError = (error) => {
  return {
    type: typeS.CODAT_ERROR,
    payload: error,
  };
};

export const loadCodatData = () => {
  return (dispatch) => {
    try {
      CodatAccount().then((res) => {
        if (res&&res.data&&res.data.success === true) {
          const codatData = res;
          dispatch(codatSuccess(codatData));
        } else {
          dispatch(codatError());
        }
      }).catch((err) => {
        dispatch(codatError(err.message));
        ErrorHandler(err);
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Oops...',
        //   text: err,
        // })
      });
    } catch (err) {
      // console.log(err);
    }
  };
}


export const codatLinkRequest = () => {
  return {
    type: typeS.CODAT_LINK_REQUEST,
  };
};

export const codatLinkSuccess = (codatlink) => {
  return {
    type: typeS.CODAT_LINK_SUCCESS,
    payload: codatlink,
  };
};

export const codatLinkError = (error) => {
  return {
    type: typeS.CODAT_LINK_ERROR,
    payload: error,
  };
};

export const CodatLinkData = (data) => {
  return (dispatch) => {
    try {
      CodatLink(data).then((res) => {
        if (res && res.status === 200) {
          const codatData = res;
          dispatch(codatLinkSuccess(codatData));
        } else {
          const error = res
          dispatch(codatLinkError(error));
        }
      }).catch((err) => {
        dispatch(codatLinkError(err.message));
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err,
        })
      });
    } catch (err) {
      // console.log(err);
    }
  };
}