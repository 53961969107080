import * as types from "../service/types";

const InitState = {
  loading: false,
  getfundedData: "",
  error: false,
  getfundedDetails: "",
  token: null,
  is_client: false,
  is_admin: false,
  status: ''
};
const GetFunded = (state = InitState, action) => {
  switch (action.type) {
    case types.GET_FUNDED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_FUNDED_SUCCESS:
      return {
        ...state,
        loading: true,
        error: false,
        getfundedData: action.payload.data,
      };
    case types.GET_FUNDED_ERROR:
      return {
        loading: false,
        getfundedData: "",
        error: true,
      };
    case types.GET_TOKEN:
      return {
        ...state,
        loading: true,
      };
    case types.GET_TOKEN_SUCCESS:
      return {
        ...state,
        loading: true,
        error: false,
        token: action.payload.data.token,
        is_client: action.payload.data.is_client,
        is_admin: action.payload.data.is_admin,
        getfundedData: null,
        getfundedDetails: null,
        status: null
      };
    case types.GET_TOKEN_ERROR:
      return {
        loading: false,
        token: "",
        error: true,
        is_client: false,
        is_admin: false
      };
      case types.GET_FUNDED_DETAILS:
      return {
        ...state,
        loading: true,
        tokens: action.token
      };
    case types.GET_FUNDED_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getfundedDetails: action.payload.getfundeddetails,
        token: null,// action.token,
        error: false,
        status: action.payload.status,
        getfundedData: null,
        is_client: false,
        is_admin: false
      };
    case types.GET_FUNDED_DETAILS_ERROR:
      return {
        loading: false,
        getfundedDetails: "",
        error: true,
        status: ''
      };
    default:
      return state;
  }
};

export default GetFunded;