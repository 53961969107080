import React from "react";
import caseStudy from "../img/case-study.svg";

const CaseStudy = () => {
  return (
    <>
      <section className="case-study-main">
        <div className="container case-study-container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center">
              <div className="case-study-title">Case Study - Instrumental</div>
              <div className="case-study-text">
                <p>
                  Instrumental, music investors and record label with a
                  technology focus, took a £1,000,000 advance to help grow their
                  sales team and diversify their revenue streams.
                </p>
                <p>
                  When Covid hit, they worried their revenue might dip, but
                  Barlow Marshall's flexible financing meant they didn't need to
                  worry.​
                </p>
                <p>
                  [After paying back the advance over a 35 month period (through
                  a 10% revenue share), Instrumental saw MRR grow over 300%, and
                  saved £1.5m in dilution when valued at their next funding
                  round.]
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center">
              <img
                src={caseStudy}
                alt="Finance Growth"
                className="case-study-image"
              />
            </div>

            <div className="get-funded-main">
                <form className="get-funded-form">
                  <div className="d-flex  get-funded-div">
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your name"
                        aria-label="Enter your name"
                      />
                    </div>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        aria-label="Enter your email"
                      />
                    </div>
                    <div className="">
                      <button className="get-funded-btn">
                        Get Funded
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default CaseStudy;
