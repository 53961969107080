import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ledgerPendingData, ledgerSettledInitiateData, settledApi } from "../../redux/action/ledgerTransaction";
import ClipLoader from "react-spinners/ClipLoader";
import { HttpCall } from "../../services/UseHttps";
import { excelUploadTransactionAPI } from "../../config";
import Swal from 'sweetalert2';
import TransactionList from "./upload/TransactionList";
import TransactionUpload from "./upload/TransactionUpload";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
// import { customStyles } from '../../utils/customSelect';
import { MDBDataTableV5 } from "mdbreact";
import { ErrorHandler } from "../../components/Auth/errorHandler";
import { formatAmount } from "../../services/CommonFunction";
import YapilyDashboard from "../yapily/YapilyDashboard";

// let onLoad = false;

const Transactions = (props) => {
    const historyTransaction = props.transactionTabs;
    const [loading, setLoading] = useState(false)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheckAny, setIsCheckAny] = useState(false);
    const [isRender, setIsRender] = useState(false);
    const [transactionPending, setTransactionPending] = useState([])
    const [transactionSettleInit, setTransactionSettleInit] = useState([])
    const [show, setShow] = useState(false);
    const [reason, setReason] = useState('');
    const [successType, setSuccessType] = useState('');

    const [tranId, setTranId] = useState([]);

    const [selectedChallenge, setSelectedChallenge] = useState({ value: null, label: 'Select the reason for challenge' });
    const [selectedReasonErr, setSelectedReasonErr] = useState('');

    const [mdbColHeaders, setMdbColHeaders] = useState([
        {
          label: "Source",
          field: "source",
          sort: "asc",
          width: 100,
        },
        {
          label: "Sort Code",
          field: "sort_code",
          sort: "asc",
          width: 150,
        },
        {
          label: "Account Number",
          field: "account_number",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date",
          field: "date",
          sort: "asc",
          width: 150,
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
          width: 150,
        },
        {
          label: "Reference",
          field: "reference",
          sort: "asc",
          width: 150,
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: 150,
        },
        {
          label: "Currency Debit",
          field: "currency_debit",
          sort: "asc",
          width: 150,
        },
        {
          label: "Currency Credit",
          field: "currency_credit",
          sort: "asc",
          width: 150,
        },
        {
          label: "Currency to GBP Exchange",
          field: "gbp_exchange_rate",
          sort: "asc",
          width: 150,
        },
        {
            label: "GBP Debit (Received)",
            field: "debit_received",
            sort: "asc",
            width: 150,
        },
        {
            label: "GBP Credit (Spent)",
            field: "credit_spent",
            sort: "asc",
            width: 150,
        },
        {
          label: "Split %",
          field: "split_percentage",
          sort: "asc",
          width: 150,
        },
        {
          label: "Split Amount",
          field: "repayment",
          sort: "asc",
          width: 150,
        },
        {
          label: "Client Amount",
          field: "client_amount",
          sort: "asc",
          width: 150,
        },
        {
          label: "Reason",
          field: "reason",
          sort: "asc",
          width: '270px',
        },
      ]);
      const [datatable, setDatatable] = useState({
        columns: mdbColHeaders,
        rows: []
      });

    const handleClose = () => {
        setShow(false);
        setReason('');
        setSelectedChallenge({ value: null, label: 'Select the reason for challenge' });
    }

    const handleShow = (txId) => {
        let transId = [];
        transId.push(txId)
        setTranId(transId);
        setShow(true);
    }

    const optionsChallenge = [
        { value: null, label: 'Select the reason for challenge' },
        { value: 'intercompany', label: 'Intercompany Transfer' },
        { value: 'loanreceipts', label: 'Loan Receipts' },
        { value: 'securitysale', label: 'Security Sale' },
        { value: 'insurancereceipt', label: 'Insurance Receipt' },
        { value: 'refund', label: 'Refund' },
        { value: 'vatrefund', label: 'VAT Refund' },
        { value: 'rdtax', label: 'R&D Tax Refund' },
        { value: 'bankinterest', label: 'Bank Interest' },
        { value: 'others', label: 'Other' },
    ]

    const userData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj);
    const yapilyStatus = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.yapily_obj);
    const applicationId = useSelector(state => state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj && state.profileInfo.profileData.application_obj && state.profileInfo.profileData.application_obj.application_id);

    const ledgerPendingDatas = useSelector(state => state.ledgerTransaction && state.ledgerTransaction.ledgerPendingData && state.ledgerTransaction.ledgerPendingData.data && state.ledgerTransaction.ledgerPendingData.data && state.ledgerTransaction.ledgerPendingData.data.data);
    const ledgerSettleInitiatedDatas = useSelector(state => state.ledgerTransaction && state.ledgerTransaction.ledgerSettleInitiatedData && state.ledgerTransaction.ledgerSettleInitiatedData.data && state.ledgerTransaction.ledgerSettleInitiatedData.data && state.ledgerTransaction.ledgerSettleInitiatedData.data.data);

    const settleChallengeSuccess = useSelector(state => state.ledgerTransaction && state.ledgerTransaction.settledData && state.ledgerTransaction.settledData);

    const isLoading = useSelector(state => state.ledgerTransaction && state.ledgerTransaction.loading);

    const dispatch = useDispatch();

    // Pending Api //
    const onLoadData = () => {
        dispatch(ledgerPendingData('pending', applicationId));
    };
    
    useEffect(() => {
        if (applicationId && historyTransaction === 'transaction') {
            onLoadData();
            dispatch(ledgerSettledInitiateData('settle_initiated', applicationId));
            fileUploadCancel();
        }
        // eslint-disable-next-line
    }, [applicationId, historyTransaction]);

    useEffect(() => {
        if (ledgerPendingDatas) {
            setTransactionPending(ledgerPendingDatas)
        } else {
            if(ledgerPendingDatas === null ) { setTransactionPending([]); }
        }
    }, [ledgerPendingDatas])
    useEffect(() => {
        if (ledgerSettleInitiatedDatas) {
            const records = ledgerSettleInitiatedDatas.map((itm) => {
                // return {...itm, repayment:Number(itm['repayment']).toFixed(2) };
                return {
                    ...itm, 
                    currency: itm['currency']?`${(itm['currency']).toUpperCase()}`:'',
                    currency_debit: itm['currency_debit']?`${(itm['currency']).toUpperCase()=='USD'?'$':(itm['currency']).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(itm['currency_debit']):'0',
                    currency_credit: itm['currency_credit']?`${itm['currency']=='USD'?'$':'£'}`+formatAmount(itm['currency_credit']):'0',
                    debit_received: '£'+formatAmount(itm['debit_received']),
                    credit_spent: '£'+formatAmount(itm['credit_spent']),
                    client_amount: '£'+formatAmount(itm['client_amount']),
                    repayment:'£'+formatAmount(itm['repayment']),
                    source: itm['source'] === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}> <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i> </div> :
                            itm['source'] === "yapily" ? <div style={{ width: "100%", textAlign: "center" }}> <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i> </div> :'-'
                    };
            });
            setTransactionSettleInit(records);
            setDatatable({ ...datatable, rows: records?records:[] });
        } else {
            if(ledgerSettleInitiatedDatas === null ) { setDatatable({ ...datatable, rows: [] }); }
        }
    }, [ledgerSettleInitiatedDatas])

    // useEffect(() => {
    //     if (historyTransaction === 'transaction') {
    //         onLoadData();
    //     }
    // }, [historyTransaction])

    useEffect(() => {
        if (settleChallengeSuccess || settleChallengeSuccess === null) {
            onLoadData();
            dispatch(ledgerSettledInitiateData('settle_initiated', applicationId));
            if (successType === 'settle' || successType === 'challenged') {
                Swal.fire({
                    title: "Success!",
                    text: 'Request successfully ' + (successType === 'settle' ? 'initiated for settlement!' : 'challenged!'),
                    icon: "success", focusConfirm:true, returnFocus:false,
                    // timer: 2000,
                    // button: false,
                });
                setSuccessType('');
                setReason('');
                if(isCheckAny) { setIsCheckAny(false); }
                if(isCheckAll) { setIsCheckAll(false); }
                setSelectedChallenge({ value: null, label: 'Select the reason for challenge' });
            }
        }
    }, [settleChallengeSuccess])

    // Settle Api //

    const handleSattled = (values) => {
        Swal.fire({
            title: "Are you sure want to settle?",
            type: "warning",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            showLoaderOnConfirm: true,
            onOpen: () => Swal.getConfirmButton().focus(),
            inputPlaceholder: "Please enter your reason",
            preConfirm: (inputValue) => {
                return inputValue;
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const settledData = {
                    status: 'settle_initiated', // 'settled',
                    reason: 'Settlement Initiated: Client', // result.value,
                    transaction_id: values,
                    account_type: 'non_modulr'
                }
                dispatch(settledApi(settledData))
                setSuccessType('settle');
                setLoading(true)
                return;
            }
        });
    }

    const handleSelectAll = e => {
        setIsCheckAll(e.target.checked);
        if(e.target.checked) { setIsCheckAny(true);} else { setIsCheckAny(false); }
        let allCheck = transactionPending;
        for (let item of allCheck) {
            item.checked = e.target.checked;
        }
        setTransactionPending(allCheck);
    };

    const singleSelect = (e) => {
        let allData = transactionPending;
        allData.forEach(element => {
            if (element.id === e.target.value) {
                element.checked = e.target.checked
            }
        });
        if(allData.some((val) => val.checked === true)) { setIsCheckAny(true);} else { setIsCheckAny(false); }
        if (allData.every((val, i, arr) => val.checked === true)) {
            setIsCheckAll(true);
        } else {
            setIsCheckAll(false);
        }
        setTransactionPending(allData);
        setIsRender(!isRender)
    }

    const handlemultiSattled = () => {
            let idArr = []
            transactionPending.map((items) => {
                if (items.checked) {
                    idArr.push(items.id)
                }
            })
            if (idArr && idArr.length > 0) {
                Swal.fire({
                    title: "Are you sure want to settle?",
                    type: "warning",
                    inputAttributes: {
                        autocapitalize: "off",
                    },
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    showLoaderOnConfirm: true,
                    onOpen: () => Swal.getConfirmButton().focus(),
                    inputPlaceholder: "Please enter your reason",
                    preConfirm: (inputValue) => {
                        return inputValue;
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        const settledData = {
                            status: 'settle_initiated', // 'settled',
                            reason: 'Settlement Initiated: Client', // result.value,
                            transaction_id: idArr,
                            account_type: 'non_modulr'
                        }
                        dispatch(settledApi(settledData))
                        setSuccessType('settle');
                        return;
                    }
                });
            }
    }

    // Challenge Api //

    const selectChallenge = (e) => {
        setSelectedChallenge({ label: e.label, value: e.value });
        setSelectedReasonErr('');
    };
    const handleChanges = (e) => {
        setReason(e.target.value)
    }

    const handleChallenge = (values, reason, selectedChallenge) => {
        if(selectedChallenge && selectedChallenge.value) {
            const settledData = {
                status: 'challenged',
                reason_type: selectedChallenge.value,
                reason: reason?('Challenged: Client - '+reason):'',
                transaction_id: values,
                account_type: 'non_modulr'
            }
            dispatch(settledApi(settledData))
            setSuccessType('challenged');
            setShow(false);
        } else {
            setSelectedReasonErr('Please select reason to challenge transaction.');
        }
    }

    /* Upload TransactionSTART */
    const [recordList, setRecordList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const profileData = useSelector(state => state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);
    const fileUploadHandle = () => {
        const appId = profileData && profileData.application_id ? profileData.application_id : '';
        if (selectedFile && appId) {
            let data = new FormData();
            data.append("file", selectedFile);
            data.append("application_id", appId);
            HttpCall(`${excelUploadTransactionAPI}`, "post", data)
                .then((res) => {
                    if (res && res.data && res.data.status) {
                        Swal.fire({ title: "Success!", text: 'Transaction uploaded successfully!', icon: "success"});
                        setSelectedFile(null);
                        setRecordList([]);
                        dispatch(ledgerPendingData('pending', applicationId));
                    } else {
                        Swal.fire({
                            title: "Fail!",
                            text: res && res.data && res.data.error ? res.data.error : 'File upload fails!',
                            icon: "error"
                        });
                    }
                }).catch((error) => {
                    const ermsg = error && error.response && error.response.data && error.response.data.error && error.response.data.error ? error.response.data.error : '';
                    Swal.fire({
                        title: "Error",
                        text: ermsg ? ermsg : 'Failed to upload file.',
                        icon: "error",
                        // timer: 2000,
                        // button: false,
                    });
                    ErrorHandler(error);
                });
        } else {
            Swal.fire({
                title: "Fail",
                text: 'File not found.',
                icon: "error",
                // timer: 2000,
                // button: false,
            });
        }
    };
    const fileUploadCancel = () => {
        setSelectedFile(null);
        setRecordList([]);
    }

    return (
        <>
            {/* yapily institutions display */}
            {historyTransaction&&historyTransaction === 'transaction'?
                <div className="">
                    <YapilyDashboard fromYapilyTab={false} isParent={userData?.parent} isYapActive={yapilyStatus?.yapily_integration_is_active} yapilyError={yapilyStatus?.yapily_integration_status_reason}/>
                </div>
            :''}

            <div className="mb-4">
                <div className="card">
                    <div className="card-body">
                        <TransactionUpload setRecordList={setRecordList} setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
                        {recordList && recordList.length > 0 && recordList.map((item, i) =>
                            <div key={i} style={{ borderRadius: 4, border: '1px solid #000000', padding: 10 }}>
                                <TransactionList number={i + 1} sheetName={item.sheetName} transItem={item.record} />
                            </div>
                        )}

                        <div style={{ textAlign: 'center', margin: 20 }}>
                            {selectedFile &&
                                <>
                                    <h4>File name : <u>{selectedFile && selectedFile.name ? selectedFile.name : ''}</u></h4>
                                    <button type="button" className="btn btn-success" onClick={fileUploadHandle} title="Upload Transaction">
                                        <i className="fa fa-upload" aria-hidden="true"></i> Upload File
                                    </button>
                                    <button type="button" className="btn btn-danger" onClick={fileUploadCancel} title="Cancel Upload Transaction" style={{marginLeft:10}}>
                                        <i className="fa fa-close" aria-hidden="true"></i> Cancel
                                    </button>
                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="card">
                    <div className="card-body">
                        <h4 className="my-3"><b>Non Modulr Account </b></h4>
                        {/* SETTLEMENT INITIATED TRANSACTIONS */}
                        { transactionSettleInit && transactionSettleInit.length > 0 && <>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5><b>Settlement Initiated Transactions</b></h5>
                                </div>
                                <MDBDataTableV5 responsive
                                    striped bordered hover
                                    data={datatable} searchTop searchBottom={false}
                                    fullPagination entriesOptions={[2, 5, 10, 20, 50, 100]}
                                    paging={!(datatable&&datatable.rows&&datatable.rows.length <= "4")}
                                />
                            </>
                        }

                        {/* PENDING TRANSACTIONS */}
                        <div className="d-flex justify-content-between align-items-center">
                            <h5><b>Pending Transactions</b></h5>
                            <div>

                                <button type="button" disabled={!isCheckAny}
                                    onClick={(e) => handlemultiSattled(e)}
                                    className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue">Initiate Settlement</button>
                                {/* <button
                                    type="button"
                                    onClick={handleShow}
                                    className="btn btn-custom1 ml-2 btn-custom-no-white-shadow  btn-custom-orange">Challenge</button> */}
                            </div>
                        </div>

                        <div className='table-responsive' style={{height:'70vh'}}>
                        <table className="table table-bordered table-striped mb-4">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th style={{ width: "5%" }}><input
                                        name="checkbox"
                                        id="checkbox"
                                        autoComplete="off"
                                        type="checkbox"
                                        checked={isCheckAll}
                                        onChange={(e) => handleSelectAll(e)}
                                        className="form-check-input mx-3"
                                    /></th>
                                    <th>Source</th>
                                    <th>Sort Code</th>
                                    <th>Account Number</th>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Reference</th>
                                    <th>Currency</th>
                                    <th>Currency Debit</th>
                                    {/* <th>Currency Credit</th> */}
                                    <th>Currency to GBP Exchange</th>
                                    <th>GBP Debit (Received)</th>
                                    <th style={{minWidth:'270px'}}>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (transactionPending && transactionPending.length > 0) &&
                                    transactionPending.map((item, id) => {
                                        return item.status === "pending" || item.status === "challenge_rejected" ?
                                            <tr key={id}>
                                                <td>
                                                    <div style={{ width: "100%", textAlign: "center" }}>
                                                        <i onClick={() => handleSattled([item.id])}
                                                            className="fa-solid fa-circle-check text-warning cursor-pointer mx-1"
                                                            data-bs-placement="top" title="Initiate settlement of transaction" ></i>
                                                        {item.status === "pending" &&
                                                            // <i onClick={()=>handleShow(item.id)}
                                                            <i
                                                                onClick={() => handleShow(item.id)}
                                                                // onClick={() => handleChallenge([item.id], '')}
                                                                className="fa-solid fa-bullhorn text-warning cursor-pointer mx-1"
                                                                data-bs-placement="top" title="Challenge Transaction" ></i>
                                                        }
                                                        {item.status === "challenge_rejected" &&
                                                            <i className="fa-solid fa-circle-info text-danger cursor-pointer mx-1"
                                                                data-bs-placement="top" title={'Rejected Reason : ' + item.challenged_rejected_reason} ></i>
                                                        }
                                                    </div>
                                                </td>
                                                <td><input
                                                    name="checkbox"
                                                    autoComplete="off"
                                                    type="checkbox"
                                                    value={item.id}
                                                    checked={item.checked !== undefined ? item.checked : false}
                                                    onChange={(e) => singleSelect(e)}
                                                    className="form-check-input mx-3"
                                                /></td>
                                                <td>{item&&item.source&&
                                                        item.source === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}>
                                                          <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i>
                                                        </div>
                                                      : item.source === "yapily"? <div style={{ width: "100%", textAlign: "center" }}>
                                                          <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i>
                                                        </div> :'-'}
                                                </td>
                                                <td>{item.sort_code ? item.sort_code : ''}</td>
                                                <td>{item.account_number}</td>
                                                <td>{item.date}</td>
                                                <td>{item.description}</td>
                                                <td>{item.reference}</td>
                                                <td>{item&&item.currency?(item.currency).toUpperCase():''}</td>
                                                <td>{item&&item.currency_debit?`${(item.currency).toUpperCase()=='USD'?'$':(item.currency).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(item.currency_debit):'0'}</td>
                                                <td>{item&&item.gbp_exchange_rate?item.gbp_exchange_rate:'0'}</td>
                                                <td>{item.debit_received?'£'+formatAmount(item.debit_received):'0'}</td>
                                                <td>{item.challenged_reason}</td>
                                            </tr>
                                            : ''
                                    })
                                }
                            </tbody>
                        </table>
                        {transactionPending.length === 0 && (
                            <p className="table-font-color text-center m-0">
                                {
                                    isLoading ?
                                        <><ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" /></>
                                        :
                                        <b>Pending transactions records not found.</b>
                                }
                            </p>
                        )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Challenge Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Select
                            className="react-select-container border"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => ""}
                            onChange={selectChallenge}
                            value={selectedChallenge || ''}
                            options={optionsChallenge}
                            placeholder="Select the reason for challenge"
                        />
                        {selectedReasonErr?<p className="text-danger">{selectedReasonErr}</p>:''}
                        <label className="h5">Reason</label>
                        <textarea name="reasonname" id="comment" rows="4" cols="40"
                            required="required" className="form-control"
                            maxLength="100"
                            value={reason}
                            onChange={(reason) => handleChanges(reason)}
                            placeholder="Please enter your reason" >
                        </textarea>
                        {
                            reason.length > 0 ? <p className={reason.length === 100 ? "text-danger": "font-weight-bold"}>Reason must be no longer than 100 characters ({reason.length} / 100)</p> : ''
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <div className="text-center">
                        <button variant="primary"
                            type="button"
                            className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue"
                            onClick={() => handleChallenge(tranId, reason, selectedChallenge)}>
                            Submit
                        </button>
                        <button variant="secondary"
                            type="button"
                            name="reasonname"
                            className="btn btn-custom1 btn-custom-no-white-shadow btn-danger bg-danger btn-custom-blue"
                            onClick={handleClose}>
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default Transactions;