import * as types from "../service/types";

const InitState = {
  loading: false,
  phonenumber: "",
  error: false,
};
const PhoneNumber = (state = InitState, action) => {
  switch (action.type) {
    case types.PHONE_NUMBER:
      return {
        ...state,
        loading: true,
      };
    case types.PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        phonenumber: action.payload,
      };
    case types.PHONE_NUMBER_FAILED:
      return {
        loading: false,
        phonenumber: "",
        error: true,
      };
    default:
      return state;
  }
};

export default PhoneNumber;