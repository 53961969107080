
import Swal from "sweetalert2";
import { offerStatusAPI } from "../../config";
import { listAdminOfferData } from "../../redux/action/offerList";
import { handleError, HttpCall } from "../../services/UseHttps";

export const confirmWithdraw = (value,dispatch,setLoading) => {
    
    Swal.fire({
      title: "Are you sure you want to withdraw this offer?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I am sure!",
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          {
            title: "Reason",
            input: "textarea",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            showLoaderOnConfirm: true,
            onOpen: () => Swal.getConfirmButton().focus(),
            inputPlaceholder: "Withdraw Reason",
            preConfirm: (inputValue) => {
              if (inputValue === "") {
                Swal.showValidationMessage("Please enter Withdraw reason") ;
                    return false;
                  }
                  return inputValue;
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((res) => {
            if (res && res.isConfirmed) {
            let data = new FormData();
            data.append("status", 'withdraw_offer');
            data.append("note", res && res.value);
            HttpCall(`${offerStatusAPI}/${value}`, "patch", data)
            .then((res) => {
                // formik.resetForm();
                Swal.fire({
                    title: "Done!",
                    text: "Offer Withdrawn !!",
                    icon: "success",
                    // timer: 2000,
                    // button: false,
                });
                dispatch(listAdminOfferData());
                // setModalShow(false);
            })
            .catch((error) => {
                // formik.resetForm();
                handleError(error);
            });
            return;
            }
            return;
          })
      }
    });
  };