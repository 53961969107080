import {LOADER_START, LOADER_STOP} from "../service/types";

const initState = false;
const Loader = (state = initState, action) => {
    switch (action.types) {
        case LOADER_START:
            return {
                ...state,
                loading: true,
            };
        case LOADER_STOP:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
export default Loader;