import MainHeader from "../components/Header";
import Footer from "../components/Footer";
import './HomePageResponsive.css'
import { useEffect } from "react";

const PrivacyPolicy = (props) => {
  useEffect(() => {
    document.title = props.title;
    window.scroll(0,0);
  }, []);
  return (
    <div>
      <MainHeader boxShadow={true}/>
      <section className="why-Barlow-Marshall" style={{ marginTop: 80, backgroundColor: '#fff', height:'100%',overflow:'auto' }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{marginTop:30}}>
              <h1 className="">Barlow Marshall Pivacy And Policy</h1>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <article style={{ marginTop: 35 }}>
                <div>
                  <div style={{ fontSize: 20, fontWeight: 700 }}>Corporate - Privacy Policy</div>
                </div>
                <div className="entry-content">
                  <p>This Privacy Policy describes the information that we gather on, or through, our Services and how we use and process such information. For each processing purpose we will articulate the reason for requiring the data, what data we will process, the legal basis for processing the data and how long we will keep the data.</p>
                  <p>Where the legal basis of consent is to be used, this will be gathered freely, and we will use clear, plain language that is easy to understand, and you will be able to remove your consent at any point.</p>
                  <p>References in this Privacy Policy and on our website to “we”, “our” or “us” are references to Barmar Holdings Ltd, Accelerate Digital Ltd, Accelerate Talent Ltd and our trading name Barlow Marshall. References to “you” and “your” means each natural or legal person who uses our services.</p>
                  <ol>
                    <li><strong>What Information do we collect about you and how do we use it?</strong>
                      <p>We collect/process information so that we can provide the best possible experience when you utilise our services.  This section of the policy will describe the purpose for processing your personal data, the legal basis to do so and how long we will keep your data.</p>
                      <ol>
                        <li><strong>As an employee</strong>
                          <p>We collect, store, and use the following categories of personal information about you:</p>
                          <ul>
                              <li>Personal contact details such as name, title, addresses, telephone numbers, and personal email addresses.</li>
                              <li>Dates of birth, marriage and divorce.</li>
                              <li>Gender.</li>
                              <li>Marital status and dependants.</li>
                              <li>Next of kin, emergency contact and death benefit nominee(s) information.</li>
                              <li>National Insurance number.</li>
                              <li>Bank account details, payroll records and tax status information.</li>
                              <li>Salary, annual leave, pension and benefits information.</li>
                              <li>Start date, leaving date.</li>
                              <li>Location of employment or workplace.</li>
                              <li>Copy of driving licence, passport, birth and marriage certificates, decree absolute.</li>
                              <li>Recruitment information (including copies of right to work documentation, references and other information included in a CV or cover letter or as part of the application process).</li>
                              <li>Full employment records for Civil Service employment (including contract, terms and conditions, job titles, work history, working hours, promotion, absences, attendances, training records and professional memberships).</li>
                              <li>Compensation history.</li>
                              <li>Performance and appraisal information.</li>
                              <li>Disciplinary and grievance information.</li>
                              <li>Secondary employment and volunteering information.</li>
                              <li>CCTV footage and other information obtained through electronic means such as swipe card records.</li>
                              <li>Information about your use of our information and communications systems.</li>
                              <li>Photographs, videos.</li>
                              <li>Accident book, first aid records, injury at work and third party accident information.</li>
                              <li>Evidence of how you meet the Civil Service nationality rules and confirmation of your security clearance. This can include passport details, nationality details and information about convictions/allegations of criminal behaviour.</li>
                              <li>Evidence of your right to work in the UK/immigration status</li>
                          </ul>
                          <p>We will also collect, store and use the following "special categories" of more sensitive personal information:</p>
                          <ul>
                            <li>Information about your race or ethnicity, religious beliefs, sexual orientation and political opinions.</li>
                            <li>Trade union membership.</li>
                            <li>Information about your health, including any medical condition, health and sickness records.</li>
                            <li>Genetic information and biometric data.</li>
                            <li>Information about criminal convictions/allegations and offences</li>
                          </ul>
                          <p>We typically collect personal information about employees, workers and contractors through the application and recruitment process, either directly from candidates or sometimes from an employment agency or background check provider. We will sometimes collect additional information from third parties including former employers, credit reference agencies or other background check agencies, including:</p>
                          <ul>
                            <li>Her Majesty's Revenue and Customs HMRC</li>
                            <li>Disclosure Barring Service (DBS)</li>
                          </ul>
                          <p>We will collect additional personal information in the course of job-related activities throughout the period of you working for us.</p>
                          <p>We will only use your personal information when the law allows us to. Most commonly, we will use your personal information in the following circumstances:</p>
                          <ul>
                            <li>Where it is necessary for performing the contract we have entered into with you.</li>
                            <li>Where we need to comply with a legal obligation.</li>
                          </ul>
                          <p>There can be rare occasions where it becomes necessary to use your personal information to protect your interests (or someone else's interests).</p>
                          <p>Situations in which we will use your personal information</p>
                          <p>We need all the categories of information in the list above (see: The kind of information we hold about you) to enable us to perform our role as employer; to enable us to comply with legal obligations, to carry  out our functions as an employer; or where it is necessary to do.</p>
                          <p>The situations in which we will process your personal information are listed below.</p>
                          <ul>
                            <li>Making a decision about your recruitment or appointment.</li>
                            <li>Determining the terms on which you work for us.</li>
                            <li>Checking you are legally entitled to work in the UK and to provide you with the security clearance appropriate for your role.</li>
                            <li>Paying you and, if you are an employee, deducting tax and National Insurance contributions.</li>
                            <li>Liaising with your pension provider, providing information about changes to your employment such as promotions, changing in working hours.</li>
                            <li>General administration of the contract we have entered into with you.</li>
                            <li>Business management and planning, including accounting and auditing.</li>
                            <li>Conducting performance reviews, managing performance and determining performance requirements.</li>
                            <li>Making decisions about salary reviews and compensation.</li>
                            <li>Assessing qualifications for a particular job or task, including decisions about promotions.</li>
                            <li>Gathering evidence and any other steps relating to possible grievance or disciplinary matters and associated hearings.</li>
                            <li>Making decisions about your continued employment or engagement.</li>
                            <li>Making arrangements for the termination of our working relationship.</li>
                            <li>Education, training and development requirements.</li>
                            <li>Dealing with legal disputes involving you, or other employees, workers and contractors, including accidents at work.</li>
                            <li>Ascertaining your fitness to work, managing sickness absence.</li>
                            <li>Complying with health and safety obligations.</li>
                            <li>To prevent fraud.</li>
                            <li>To monitor your business and personal use of our information and communication systems to ensure compliance with our IT policies.</li>
                            <li>To ensure network and information security, including preventing unauthorised access to our computer and electronic communications systems and preventing malicious software distribution.</li>
                            <li>To conduct data analytics studies to review and better understand employee retention and attrition rates.</li>
                            <li>Equal opportunities monitoring.</li>
                            <li>Dealing with Freedom of Information Act/Environmental Information Regulations requests</li>
                          </ul>
                          <p>Some of the purposes will overlap and there can be several grounds which justify our use of your personal information.</p>
                          <p>If you fail to provide personal information</p>
                          <p>If you fail to provide certain information when requested, we will not be able to fully perform the contract we have entered with you (such as paying you or providing a benefit), or we could be prevented from complying with our legal obligations (such as to ensure the health and safety of our workers).</p>

                        </li>
                      </ol>
                    </li>
                    <li> <strong>Updating These Terms</strong>
                      <p>We may change our Service and Policies, and we may need to make changes to these Terms so that they accurately reflect our Service and Policies. Unless otherwise required by law, we will notify you at least 30 days before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can request an account deletion.</p>
                    </li>
                    <li> <strong>Your Rights</strong>
                      <p>We may change our Service and Policies, and we may need to make changes to these Terms so that they accurately reflect our Service and Policies. Unless otherwise required by law, we will notify you at least 30 days before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can request an account deletion.</p>
                      <ol>
                        <li><strong>Accessing or Rectifying your personal data</strong>
                          <p>We want to make sure that your personal information is accurate and up to date and you have the right to request a copy and update the personal data that we hold about you. You may ask us to correct or remove information you think is inaccurate. If you would like to invoke this right, please write to us at DP, Barlow Marshall, Scale Space, 1st Floor, 58 Wood Lane, London, W12 7RZ.</p>
                        </li>
                        <li><strong>Deletion</strong>
                          <p>Based upon the retention periods described above we will remove your personal data from our platforms.</p>
                        </li>
                        <li><strong>Object, Restrict or Withdraw Consent</strong>
                          <p>You may wish to object to or restrict our ability to process your personal data, this can be done either via email or in writing, using the contact details below. Further context may need to be requested to ensure we can carry out the relevant tasks on our platforms to perform the request.</p>
                        </li>
                        <li><strong>Portability</strong>
                          <p>You may wish to port your personal data to another platform. If you would like to invoke this right, please write to us at Barlow Marshall, Scale Space, 1st Floor, 58 Wood Lane, London, W12 7RZ.</p>
                        </li>
                      </ol>
                    </li>
                    <li><strong>Who We Are And How To Contact Us</strong>
                      <p>If you are an individual and not associated with a contracted client we are the Data Controller and are responsible for defining and managing how your personal data is processed. If you are associated with a contracted client we are will be a data processor and the contract client will be responsible for defining and managing how your personal data is processed.</p>
                      <p>Our company name is Accelerate Talent Ltd trading as Barlow Marshall</p>
                      <p>Our company address is Barlow Marshall, Scale Space, 1st Floor, 58 Wood Lane, London, W12 7RZ.</p>
                    </li>
                    <li><strong>To Whom We Disclose Information</strong>
                      <p>Except as described in this Policy, we will not intentionally disclose the Personal Data that we collect or store on the Service to third parties without the consent of the data subject. We may disclose information to third parties if you consent to us doing so, as well as in the following circumstances:</p>
                      <p>Unrestricted Information</p>
                      <p>Any information that you voluntarily choose to include in a Public Area of the Service, such as a public profile page, will be available to any Visitor or User who has access to that content.</p>
                    </li>
                    <li><strong>Service Providers</strong>
                      <p>We work with third party service providers who provide email hosting, core corporate applications, web hosting, maintenance, and other services for us. These third parties may have access to, or process Personal Data as part of providing those services for us. We limit the information provided to these service providers to that which is reasonably necessary for them to perform their functions, and our contracts with them require them to maintain the confidentiality of such information.</p>
                      <p>Service providers include:</p>
                      <ul>
                        <li>Agilisys Limited, 1 Hammersmith Broadway, London W6 9DL; for the purpose of Service Desk services</li>
                        <li>Cobens Limited, 4th Floor, Peek House, 20 Eastcheap, London, EC3M 1NP, United Kingdom; for the purpose of Payroll</li>
                        <li>BC LFT Limited, 1 Hammersmith Broadway, London W6 9DL; for the purpose of HR and Finance Services</li>
                        <li>Microsoft, Microsoft Campus, Thames Valley Park, Reading, RG6 1WG; for the purpose of Corporate Applications</li>
                      </ul>
                      <ol>
                        <li><strong>Overseas transfers</strong>
                          <p>We do not transfer your personal information outside the European Economic Area (EEA).</p>
                        </li>
                        <li><strong>Non-Personally Identifiable Information</strong>
                          <p>We may make non-personally-identifiable information available to third parties for various purposes. This data maybe automatically-collected and would be analysed to create an aggregated view of the data, ensure the reported information was anonymous.</p>
                        </li>
                        <li><strong>Law Enforcement, Legal Process and Compliance</strong>
                          <p>We may disclose Personal Data or other information if required to do so by law or in the good-faith belief that such action is necessary to comply with applicable laws, in response to a valid court order, judicial or other government subpoena or warrant, or to otherwise cooperate with law enforcement or other governmental agencies.</p>
                        </li>
                        <li><strong>Change of Ownership</strong>
                          <p>Information about data subject, may be disclosed and otherwise transferred to an acquirer, successor or assignee as part of any merger, acquisition, debt financing, sale of assets, or similar transaction, as well as in the event of an insolvency, bankruptcy, or receivership in which information is transferred to one or more third parties as one of our business assets and only if the recipient of the personal data commits to a Privacy Policy that has terms substantially consistent with this Privacy Policy.</p>
                        </li>
                      </ol>
                    </li>
                    <li><strong>Our Data Security</strong>
                      <p>We have appropriate security measures in place to prevent personal information from being accidentally lost, used or accessed in an unauthorised way.</p>
                      <p>The following security procedures, and technical and organisational measures to safeguard your personal information have been put in place:</p>
                      <ul>
                        <li>In cases where personal data is being processed in third countries or third parties, a rigorous data protection impact assessment is being performed to ensure that your data is always secured.</li>
                        <li>Our application platform is hosted in ISO 27001 certified secure data centres in the UK.</li>
                        <li>Firewalls, intrusion detection and prevention, anti-virus and anti-malware and backup and disaster recovery is in place to prevent data loss or deletion.</li>
                        <li>24/7 security guard, closed circuit television and a door access control system to authorized personnel secures our offices and data centres.</li>
                        <li>Our applications are engineered by following industry standards to minimise security vulnerabilities and updates on a regular basis.</li>
                        <li>Intrusion detection and prevention secures the network traffic to the servers and applications.</li>
                        <li>Anti-malware and anti-virus software is deployed to all of our servers and regularly scan and update with the latest anti-malware and virus signatures.</li>
                        <li>We regularly apply critical, security patches and firmware updates to operating systems and physical hardware to minimise the risk of vulnerabilities.</li>
                        <li>Our employees undergo background screening and selection processes, with a restricted list of employees having access to secure areas of the applications, databases and physical infrastructure. The access to the secure areas are logged and auditable.</li>
                        <li>We will use all reasonable efforts to safeguard your personal information. However, you should be aware that the use of the Internet is not entirely secure and for this reason we cannot guarantee the security or integrity of any personal information which is transferred from you or to you via the Internet.</li>
                        <li>We limit access to your personal information to those who have a genuine business need to know it. Those processing your information will do so only in an authorised manner and are subject to a duty of confidentiality.</li>
                        <li>We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
