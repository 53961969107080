import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import OutStanding from "./ledger/OutStanding";
// import Overview from "./ledger/Overview";
// import Sales from "./ledger/Sales";
import Transactions from "./ledger/Transactions";
import ClientTransations from "./ledger/ClientTransations";
import { Tab, Tabs, } from "react-bootstrap";
import { useSelector } from "react-redux";

const Ledger = (props) => {
  const profileData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);
  useEffect(() => { document.title = props.title },
    // eslint-disable-next-line
    [])

  const [transactionTabs, settransactionTabs] = useState('Transactionhistory');

  // const [values, setValues] = useState("transaction");

  // const handleTabChanges = () => {
  //   if (value === 'transaction') {
  //     setValues('Outstanding');
  //   }
  // }
  const handleTransaction = (value) => {
    settransactionTabs(value)
  }

  return (
    <>
      <NavBar />
      <div style={{minHeight: "75vh"}}>
        {profileData && profileData.offer && profileData.offer.status === "accepted" ? 
          <div className="container">
            <div className="row my-3">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Tabs
                  id="controlled-tab-example"
                  onSelect={(e) => handleTransaction(e)}
                  className="mb-3 ledger-tabs"
                >
                  {/* <Tab eventKey="Overview" title="Overview">
                    <Overview transactionTabs={transactionTabs} />
                  </Tab>
                  <Tab eventKey="Sales" title="Sales & Repayments">
                    <Sales transactionTabs={transactionTabs} />
                  </Tab> */}
                  <Tab eventKey="Transactionhistory" title="Transaction History">
                    <ClientTransations transactionTabs={transactionTabs} />
                  </Tab>
                  <Tab eventKey="transaction" title="Transactions">
                    <Transactions transactionTabs={transactionTabs} />
                  </Tab>
                  <Tab eventKey="Outstanding" title="Outstanding Challenges">
                    <OutStanding transactionTabs={transactionTabs} />
                  </Tab>
                </Tabs>

                {/* <ul className="nav nav-tabs flex-row" id="myTab" role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link w-100 text-center active"
                      id="tab1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1"
                      type="button"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                    >
                      Overview
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link w-100 text-center"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab2"
                      type="button"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      Sales & Repayments
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link w-100 text-center"
                      id="tab4-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab4"
                      type="button"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      Transaction History
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link w-100 text-center"
                      id="tab5-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab5"
                      type="button"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                      eventkey="transaction"
                    >
                      Transactions
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link w-100 text-center"
                      id="tab6-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab6"
                      type="button"
                      role="tab"
                      aria-controls="tab6"
                      aria-selected="false"
                      eventkey="challenge"
                    >
                      Outstanding Challenges
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent" onSelect={(k) => handleTabChange} activekey={value}>
                  <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                    <Overview />
                  </div>
                  <div className="tab-pane fade show" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                    <Sales />
                  </div>
                  <div className="tab-pane fade  " id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                    <Repayment />
                  </div>
                  <div className="tab-pane fade " id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                    <ClientTransations />
                  </div>
                  <div className="tab-pane fade " id="tab5" role="tabpanel" eventkey="transaction" aria-labelledby="tab5-tab">
                    <Transactions setValue={setValue} />
                  </div>
                  <div className="tab-pane fade " id="tab6" role="tabpanel" aria-labelledby="tab6-tab" eventkey="challenge">
                    <OutStanding />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        :
          profileData &&
          <div className="ineligible-info">
            <p>The offer is not accepted, you are not allowed to access ledger page.</p>
          </div>
        }
      </div>
      <Footer />
    </>
  )
};

export default Ledger;