import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Badge } from "react-bootstrap";

const ConnectionDetails = ({ ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const codaInfo = props.codatDetailsRes;

    const submitHandle = () => {
        props.moveTab('third');
        props.setStepCompletion('second');
    }

    // redirect to codAt integration link
    const connectCodat = () => {
        if (codaInfo) {
            if (codaInfo && codaInfo && codaInfo.redirect_link) {
                const link = codaInfo.redirect_link;
                window.open(link,"_self");
            }
        }
    }

    return (
        <>
            <div>
                <div className="row">
                    {isLoading ? <ClipLoader size={150} /> :
                        <>
                            <div className="col-lg-12">
                                <div className="custom-form">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="text-center fw-normal">Connection Details</h5>
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item mt-3">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <b> Provide your company accounts
                                                                <a href={void(0)} data-bs-toggle="tooltip" data-bs-placement="top" className="text-white text-left" title="Please connect your accounting software. Should your accounting software not be listed, please upload your most recent management accounts and set of full submitted accounts.">
                                                                    <i aria-hidden className="fa fa-info-circle mx-2" ></i>
                                                                </a>
                                                            </b>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree">
                                                        <div className="accordion-body">
                                                            <div className="">
                                                                {codaInfo && codaInfo.link_authorization && codaInfo.link_authorization === 'LINKED' ?
                                                                    <Badge pill bg="success"> Codat Account Linked</Badge>
                                                                    :
                                                                    <button type="button" onClick={connectCodat} className="btn btn-secondary text-white w-50 mb-3 mt-2">Connect your Accounting Platform</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mt-3">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            <b> Business bank accounts
                                                                <a href={void(0)} data-bs-toggle="tooltip" data-bs-placement="top" className="text-white" title="Please connect all the bank accounts that revenue for your business is paid into.">
                                                                    <i aria-hidden className="fa fa-info-circle mx-2"></i>
                                                                </a>
                                                            </b>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo">
                                                        <div className="accordion-body">
                                                            <div className="">
                                                                <a href={void(0)} className="btn btn-secondary w-50 mb-3 text-white mt-2">Connect bank account</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mt-3">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <b>Payment processor and / or subscription management
                                                                <a href={void(0)} data-bs-toggle="tooltip" data-bs-placement="top" className="text-white " title="If applicable, please connect to Stripe, otherwise please use our template file to upload
                                                            details of client contracts, past and present.">
                                                                    <i aria-hidden className="fa fa-info-circle mx-2"></i>
                                                                </a>
                                                            </b>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne">
                                                        <div className="accordion-body">
                                                            <div className="">
                                                                <div className="dropdown">
                                                                    <a className="btn btn-secondary dropdown-toggle text-white w-50 mb-3 mt-2" href={void(0)} role="button" id="dropdownMenuLink"
                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                        Add connection
                                                                    </a>

                                                                    <ul className="dropdown-menu connection" aria-labelledby="dropdownMenuLink">
                                                                        <li>
                                                                            <div className="dropdown-item">Shopify</div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item">

                                                                                <a href="https://link-api.codat.io/companies/ce970b96-7b74-4a01-b86f-49f928b13d3a/connections/5fb91d86-9684-4677-861f-da1681370276/start" className="text-decoration-none">Stripe</a>

                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item">PayPal</div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item">Amazon</div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="dropdown-item">Prestashop</div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-custom btn-custom-2 mt-3" type="button"
                                                onClick={submitHandle}>
                                                Submit
                                            </button>
                                            <br />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
};

export default ConnectionDetails;