import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { MDBDataTableV5 } from "mdbreact";
import Swal from 'sweetalert2';
import { listChallengedTransactionData } from "../../redux/action/listChallengedTransaction";
import { Modal } from "react-bootstrap";
import { handleError, HttpCall } from "../../services/UseHttps";
import { transactionStatusAPI } from "../../config";
import { formatAmount } from "../../services/CommonFunction";

const OutStanding = ({ applicationId }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [reason, setReason] = useState('');
    const [recordId, setRecordId] = useState('');
    const [actionType, setActionType] = useState('');
    const [errmsg, setErrorMsg] = useState('');
    const [mdbColHeaders, setMdbColHeaders] = useState([
        {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 150,
        },
        {
            label: "Source",
            field: "source",
            sort: "asc",
            width: 100,
        },
        {
            label: "Sort Code",
            field: "sort_code",
            sort: "asc",
            width: 150,
        },
        {
            label: "Account Number",
            field: "account_number",
            sort: "asc",
            width: 150,
        },
        {
            label: "Date",
            field: "date",
            sort: "asc",
            width: 150,
        },
        {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 150,
        },
        {
            label: "Reference",
            field: "reference",
            sort: "asc",
            width: 150,
        },
        {
          label: "Currency",
          field: "currency",
          sort: "asc",
          width: 150,
        },
        {
          label: "Currency Debit",
          field: "currency_debit",
          sort: "asc",
          width: 150,
        },
        // {
        //   label: "Currency Credit",
        //   field: "currency_credit",
        //   sort: "asc",
        //   width: 150,
        // },
        {
          label: "Currency to GBP Exchange",
          field: "gbp_exchange_rate",
          sort: "asc",
          width: 150,
        },
        {
            label: "GBP Debit (Received)",
            field: "debit_received",
            sort: "asc",
            width: 150,
        },
        // {
        //   label: "Credit (Spent)",
        //   field: "credit_spent",
        //   sort: "asc",
        //   width: 150,
        // },
        {
            label: "Reason Type",
            field: "challenged_reason_type",
            sort: "asc",
            width: 150,
        },
        {
            label: "Reason",
            field: "challenged_reason",
            sort: "asc",
            width: 270,
        }
    ]);

    const optionsChallenge = [
        { value: null, label: 'Select the reason for challenge' },
        { value: 'intercompany', label: 'Intercompany Transfer' },
        { value: 'loanreceipts', label: 'Loan Receipts' },
        { value: 'securitysale', label: 'Security Sale' },
        { value: 'insurancereceipt', label: 'Insurance Receipt' },
        { value: 'refund', label: 'Refund' },
        { value: 'vatrefund', label: 'VAT Refund' },
        { value: 'rdtax', label: 'R&D Tax Refund' },
        { value: 'bankinterest', label: 'Bank Interest' },
        { value: 'others', label: 'Other' },
    ];

    const [datatable, setDatatable] = useState({
        columns: mdbColHeaders,
        rows: []
    });

    useEffect(() => {
        if (applicationId) {
            dispatch(listChallengedTransactionData(setLoading, applicationId));
        }
    }, [applicationId]);

    const listOfChallange = useSelector((state) =>
        state && state.challengedTransaction && state.challengedTransaction.listChallengedTransactions
    );
    useEffect(() => {
        if (listOfChallange) {
            listOfChallange.filter((item) => {
                item.action = <>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <i onClick={() => handleShow(item.id, 'Accept')}
                            className="fa-solid fa-circle-check text-success cursor-pointer mx-1"
                            data-bs-placement="top" title="Accept Challenge" ></i>
                        <i onClick={() => handleShow(item.id, 'Reject')}
                            className="fa-solid fa-circle-xmark text-danger mx-1 cursor-pointer"
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Reject Challenge" ></i>
                    </div>
                    {/* <button type="button" onClick={()=>handleShow(item.id, 'Accept')} className="btn btn-custom btn-custom-no-white-shadow btn-custom-3">Accept</button>
                    <button type="button" onClick={()=>handleShow(item.id, 'Reject')} className="btn btn-custom btn-custom-no-white-shadow btn-custom-2">Reject</button> */}
                </>;
            });

            let tmpLists = [];
            for (let item of listOfChallange) {
                let challengeType = optionsChallenge.filter((oc) => {
                    return oc.value === item.challenged_reason_type;
                });
                if (challengeType.length > 0) {
                    item.challenged_reason_type = challengeType[0].label;
                }
                item.currency = item['currency']?`${(item['currency']).toUpperCase()}`:'';
                item.currency_debit = item['currency_debit']?`${(item['currency']).toUpperCase()=='USD'?'$':(item['currency']).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(item['currency_debit']):'';
                item.debit_received = item.debit_received? '£'+formatAmount(item.debit_received):'';
                item.source = item['source'] === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}> <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i> </div> :
                      item['source'] === "yapily" ? <div style={{ width: "100%", textAlign: "center" }}> <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i> </div> :'-';
                tmpLists.push(item);
            }

            setDatatable({ ...datatable, rows: tmpLists?tmpLists:[] });
        } else {
            if (listOfChallange === null) { setDatatable({ ...datatable, rows:[]}); }
        }
    }, [listOfChallange]);

    // model open & close
    const handleShow = (id, value) => {
        setShow(true);
        setRecordId(id);
        setActionType(value);
        // if(value === 'Accept') { setReason(''); }
    }
    const handleClose = () => {
        setShow(false);
        setReason('');
        setRecordId('');
        setActionType('');
        setErrorMsg('');
    };

    const handleChallenge = () => {
        setErrorMsg('');
        if (actionType === 'Reject' && (!reason || reason === '')) { setErrorMsg('Please enter valid reason'); return; }
        if (reason && reason.length > 100) { setErrorMsg('Maximum length for reason is 100 character.'); return; }
        if (!recordId) { setErrorMsg('Record id not found'); return; }
        if (actionType && recordId) {
            const param = {
                "status": actionType === 'Accept' ? "challenge_accepted" : "challenge_rejected",
                "reason": reason ? (actionType === 'Accept' ?`Challenge Accepted: Admin - ${reason}`:`Challenge Rejected: Admin - ${reason}`) : '',
                "transaction_id": [recordId],
                "account_type": "non_modulr"
            };
            HttpCall(`${transactionStatusAPI}`, "PATCH", param).then((res) => {
                if (res && res.data && res.data.status) {
                    Swal.fire({
                        title: "Success!",
                        text: actionType === 'Accept'?'Challenge Accepted Successfully!':'Challenge Rejected Successfully!',
                        icon: "success",
                        // timer: 2000,
                        // button: false,
                    });
                    handleClose();
                    dispatch(listChallengedTransactionData(setLoading, applicationId));
                } else {
                    Swal.fire({
                        title: "Success!",
                        text: 'Request failed',
                        icon: "success",
                        // timer: 2000,
                        // button: false,
                    });
                }
            }).catch((error) => {
                handleError(error);
            });
        }
    };


    return (
        <>
            <div className="mb-4">
                <div className="card">
                    <div className="card-body">
                        <h5><b>Outstanding Challenges</b></h5>
                        {datatable&&datatable.rows&&datatable.rows.length === 0 && (
                            <div className="dierector-content mt-3 "
                                style={{
                                    borderRight: "15px solid #003056",
                                    borderLeft: "15px solid #003056",
                                }}
                            >
                                <p className="table-font-color m-0">
                                    <b>{applicationId ? 'Outstanding challenge records not found.' : 'Please Select Client'}</b>
                                </p>
                            </div>
                        )}
                        {datatable&&datatable.rows&&datatable.rows.length > 0 &&
                            (!loading ? (
                                <MDBDataTableV5 // autoWidth
                                    striped bordered hover responsive
                                    data={datatable} searchTop searchBottom={false} // searching={false}
                                    fullPagination entriesOptions={[2, 5, 10, 20, 50, 100]}
                                    paging={!(datatable.rows.length <= "4")}
                                // className={`${
                                //   datatable.rows.length <= "4" ? "hide-pagination" : ""
                                // } ${showReason?'':'hide-reason'}`}
                                />
                            ) : (
                                <>
                                    <ClipLoader size={150} color="green"
                                        css={{
                                            display: "block", margin: "0 auto", borderColor: "green",
                                        }}
                                    />
                                </>
                            ))}
                        {/* <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice Number</th>
                                    <th>Account Number</th>
                                    <th>Supplier</th>
                                    <th>Description</th>
                                    <th>Credit</th>
                                    <th>Balance</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>12 May 2022</td>
                                    <td>234232</td>
                                    <td>5678923145</td>
                                    <td>Acme Co</td>
                                    <td></td>
                                    <td>£20,500.34</td>
                                    <td>£541,678.05</td>
                                    <td>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-3">Settle</button>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-2">Resolve</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11 May 2022</td>
                                    <td>234231</td>
                                    <td>5678923145</td>
                                    <td>Westfield</td>
                                    <td></td>
                                    <td>£20,500.34</td>
                                    <td>£541,678.05</td>
                                    <td>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-3">Settle</button>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-2">Resolve</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>

                </div>


            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Challenge {actionType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="h5">Reason</label>
                        <textarea type="text" className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Please enter reason"
                            maxLength={100}></textarea>
                        {
                            reason.length > 0 ? <p className={reason.length === 100 ? "text-danger": "font-weight-bold"}>Reason must be no longer than 100 characters ({reason.length} / 100)</p> : ''
                        }
                    </div>
                    {errmsg && <div className="alert alert-danger">{errmsg}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" type="button" name="reasonname"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-danger"
                        type="button" onClick={handleClose}>
                        Close
                    </button>
                    <button variant="primary" type="button"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue"
                        onClick={() => handleChallenge()}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default OutStanding;