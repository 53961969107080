import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import profileimage from "../img/download.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTokenSuccess } from "../redux/action/getfunded";
import { localStorageClear, toTitleCase } from "../services/CommonFunction";
import { HttpCall } from "../services/UseHttps";
import { signOutAPI } from "../config";

const NavBarGetFunded = ({userName}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    
    useEffect(()=>{
        if(userName && (userName.fname || userName.lname)) {
            setUsername(toTitleCase(userName.fname?userName.fname:'') +' '+ toTitleCase(userName.lname?userName.lname:''));
        }
    },[userName]);
    
    // logout function
    const logoutClick = () => {
        // localStorageClear(); // later clear specific LS.
        // dispatch(getTokenSuccess({data:{token:null, is_client: null, is_admin: null}}));
        // navigate('/login');
        HttpCall(`${signOutAPI}`, "GET").then((res) => {
            // if (res && res.status && (res.status === 200 || res.status === 201)) {}
            localStorageClear();
            dispatch(getTokenSuccess({data:{token:null, is_client: null, is_admin: null}}));
            navigate('/login');
        }).catch((error) => {
            localStorageClear();
            dispatch(getTokenSuccess({data:{token:null, is_client: null, is_admin: null}}));
            navigate('/login');
        });
    } 
    return (
        <>
            <header className="header-shadow">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <Link to="/" className="header-logo d-inline-block">
                            <img src='/img/Barlow.jpg' alt="Barlow Marshall" />
                        </Link>
                        <nav className="header-nav d-flex align-items-center">
                            <ul className="m-0 p-0 d-flex align-items-center list-unstyled button-links">
                                <div>
                                    <Link className="dropdown-toggle align-items-center d-flex text-decoration-none" to="/#" role="button" id="dropdownMenuLink"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={profileimage} className="rounded-circle" height="30" width="40"
                                            alt="Portrait of a Man" loading="lazy" />
                                        <span className="ml-1"><b>{username?username:''}</b></span>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                                        {/* <li className="m-0">
                                            <Link className="dropdown-item" to="/#">My profile</Link>
                                        </li>
                                        <li className="m-0">
                                            <Link className="dropdown-item" to="/#">Settings</Link>
                                        </li> */}
                                        <li className="m-0">
                                            <button type="button" className="dropdown-item btn btn-link" onClick={logoutClick}>Logout</button>
                                        </li>
                                    </ul>
                                </div>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
};

export default memo(NavBarGetFunded);