import logo from "../img/logo-light.png";
import Loginpic from "../img/img-19.png";
import { Link } from "react-router-dom";
import { memo, useEffect } from "react";
const PageNotFound = (props) => {
    useEffect(() => { document.title = props.title; }, []);

    return (
        <>
            <section className="overflow-hidden div-height">
                <div className="row div-height">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="background h-100">
                            <div className="text-right px-4 pt-3 pb-5">
                                <Link to="/">
                                    <img src={logo} alt="logo" width="200" height="20" className="" />
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <img className="text-center" src={Loginpic} alt="welcome" height="350" width="400" />
                                    <h1 className="text-white mb-4">Barlow Marshall</h1>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-4">
                                <div>
                                    <Link className="text-white PX-2" to={'/terms-conditions'}>Terms and Conditions</Link>
                                    <span className="text-white mx-2"> | </span>
                                    <Link className="text-white px-2" to={'/privacy-policy'}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="signup-box p-5 " style={{marginTop:'10rem', textAlign: 'center'}}>
                            <h1 className="text-center mb-4 text-danger"><b>Page Not Found</b></h1>
                            <p>Sorry, but we can't find the page that you're looking for (404 Error).</p>
                            <p><Link className="PX-2" to="/">Click here </Link> to go back to home page.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default memo(PageNotFound);