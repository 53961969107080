import Swal from 'sweetalert2';
import { localStorageClear } from '../../services/CommonFunction';
export const ErrorHandler = (error) => {
    if (error?.response?.status === 401) {
        localStorageClear();
        window.location.assign("/login");
    }
    if(error.toJSON().message === 'Network Error') {
        if (window.navigator.onLine) {
            Swal.fire({
                title: "Oops! something went wrong, we are working on it. Please try after sometime",
                icon: 'error',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            })
        } else {
            Swal.fire({
                title: "Please check your internet connection",
                icon: 'error',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            })
        }
    } else {
        if (error && error.response && error.response.status === 500) {
            Swal.fire({
                title: "Internal Server Error",
                icon: 'error',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                timer: 3000,
                heightAuto: false
            })
        }
    }
}