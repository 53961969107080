import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getFundedDetailsData, getFundedDetailsSuccess } from '../redux/action/getfunded';
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { ALLOWED_FILE_FORMAT, FILE_SIZE_LIMIT, MAIL_TO } from '../config';
import { downloadFile, localStorageClear } from "../services/CommonFunction";

const ApplicationDetails = ({ ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getStatusNEW = useSelector((state) => state.GetFunded && state.GetFunded.status);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (getStatusNEW && getStatusNEW === 'NEW') {
            Swal.fire({
                icon: 'success',
                title: 'Your application has been submitted!',
                showConfirmButton: true,
                text: 'Thank you for submitting your application.'
            });
            setIsFileSelect({ companyinvest: false, companyforcast: false, companysales: false, otherdocument: false });
            setSelectedFile({ companyinvest: null, companyforcast: null, companysales: null, otherdocument: null });
            localStorageClear();
            navigate('/login');
            dispatch(getFundedDetailsSuccess(null, null)); // set null
        }
        // eslint-disable-next-line
    }, [getStatusNEW]);
    const companydetails = props.companyValue;
    const codaInfo = props.codatDetailsRes;
    const appAdditionalInfo = props.applicationDetailsRes && props.applicationDetailsRes.additional_information;
    const [fileName, setFileName] = useState({ companyinvest: '', companyforcast: '', companysales: '', otherdocument: '' });
    const [isFileSelect, setIsFileSelect] = useState({
        companyinvest: false,
        companyforcast: false,
        companysales: false,
        otherdocument: false
    });
    const [selectedFile, setSelectedFile] = useState({
        companyinvest: null,
        companyforcast: null,
        companysales: null,
        otherdocument: null
    });
    const [errorMsg, setErrorMsg] = useState('');
    // on file section change
    const onFileSelection = (key, e) => {
        setErrorMsg('');
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const type = file.type.toLowerCase();
            if (!ALLOWED_FILE_FORMAT.includes(type)) { setErrorMsg(file.name + ' file type is not allowed.'); e.target.value = null; return; }
            if (file.size > FILE_SIZE_LIMIT) { setErrorMsg(file.name + ' file size is more than 1 MB.'); e.target.value = null; return; }
            if (key === 'companyinvest') { setSelectedFile({ ...selectedFile, companyinvest: file }); }
            if (key === 'companyforcast') { setSelectedFile({ ...selectedFile, companyforcast: file }); }
            if (key === 'companysales') { setSelectedFile({ ...selectedFile, companysales: file }); }
            if (key === 'otherdocument') { setSelectedFile({ ...selectedFile, otherdocument: file }); }
            // e.target.value = null;
        }
    }
    //
    const updateFileClick = (key) => {
        setIsFileSelect({ ...isFileSelect, [key]: true });
        setSelectedFile({ ...selectedFile, [key]: null });
    }
    const cancelUpdateFileClick = (key) => {
        setIsFileSelect({ ...isFileSelect, [key]: false });
        setSelectedFile({ ...selectedFile, [key]: null });
        document.getElementById(key).value=null;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyinvest: appAdditionalInfo?.company_investment_deck ? appAdditionalInfo.company_investment_deck : '',
            companyforcast: appAdditionalInfo?.company_forecasts_model ? appAdditionalInfo.company_forecasts_model : '',
            companysales: appAdditionalInfo?.company_sales_deck ? appAdditionalInfo.company_sales_deck : '',
            otherdocument: appAdditionalInfo?.any_other_documents ? appAdditionalInfo.any_other_documents : ''
        },

        onSubmit: (values) => {
            setErrorMsg('');
            // props.moveTab('third')
            if ((selectedFile.companysales || values.companysales) && (selectedFile.companyforcast || values.companyforcast)) {
                const fileObj = {};
                if (selectedFile.companysales) { fileObj['company_sales_deck'] = selectedFile.companysales; }
                if (selectedFile.companyinvest) { fileObj['company_investment_deck'] = selectedFile.companyinvest; }
                if (selectedFile.companyforcast) { fileObj['company_forecasts_model'] = selectedFile.companyforcast; }
                if (selectedFile.otherdocument) { fileObj['any_other_documents'] = selectedFile.otherdocument; }
                const isCodatLink = codaInfo && codaInfo.link_authorization && codaInfo.link_authorization === 'LINKED' ? true : false;
                const allValues = {
                    'company_name': companydetails.companyname,
                    'company_number': companydetails.company_number,
                    'company_type': companydetails.company_type,
                    'country_of_incorporation': companydetails.country,
                    'type_of_business': companydetails.typeofbusiness,
                    'sic_code': companydetails.sic_code,
                    'annual_recurring_revenue': companydetails.annualrecurring,
                    'trading_history': companydetails.tradinghistory,
                    'comments': companydetails.anycomment,
                    'first_name': companydetails.firstname,
                    'last_name': companydetails.lastname,
                    'phone_number': companydetails.phonenumber,
                    ...fileObj,
                    'status': isCodatLink ? 'NEW' : 'DRAFT'
                }
                setIsLoading(true);
                dispatch(getFundedDetailsData(allValues, 'NEW'))
                setIsLoading(false);
            } else {
                setErrorMsg('Please select required files.');
            }
        },
        // validationSchema: yup.object({
        //     companysales: yup.string().trim().required('Company Sales Documents required'),
        //     // companyinvest: yup.string().trim().required('Company Investment required'),
        //     companyforcast: yup.string().trim().required('Company Forecasts Documents required'),

        // }),
    });

    // set filenames for documents
    useEffect(() => {
        const url1 = formik.values.companyinvest ? formik.values.companyinvest : '';
        const url2 = formik.values.companyforcast ? formik.values.companyforcast : '';
        const url3 = formik.values.companysales ? formik.values.companysales : '';
        const url4 = formik.values.otherdocument ? formik.values.otherdocument : '';
        setFileName({
            companyinvest: url1.split('=')[1],
            companyforcast: url2.split('=')[1],
            companysales: url3.split('=')[1],
            otherdocument: url4.split('=')[1]
        });
        // eslint-disable-next-line
    }, []);
    // download file
    const onClickDownloadFile = (key) => {
        const url = formik.values[key];
        if (url && fileName && fileName[key]) {
            downloadFile(url, fileName[key]);
            Swal.fire({
                icon: 'info',
                title: 'Downloading',
                text: 'File downloading is started.'
            });
        }
    }

    return (
        <>
            <div>
                <div className="row">
                    {isLoading ? <ClipLoader size={150} /> :
                        <>
                            <div className="col-lg-12">
                                {/* <div hidden={!submitteds} className="alert alert-primary" role="alert">
                            {messages}
                        </div> */}
                                <form className="custom-form" onSubmit={formik.handleSubmit}>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="text-center fw-normal">Any Other Documents</h5>
                                            <p className="text-center">Welcome {companydetails && companydetails.firstname ? companydetails.firstname : ''}</p>
                                            <p>Thanks for applying for funding with Barlow Marshall. To help us understand your business quickly and
                                                easily, please connect us to your financial information. These connections are secure, read only
                                                connections, whereby your data is encrypted and never shared with third parties.</p>
                                            <p><strong>Alternatively</strong>, if you would like to speak to a member of the team instead, please get
                                                in touch <a href={`mailto:${MAIL_TO}`}>here</a></p>
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item mt-3">
                                                    <h2 className="accordion-header" id="headingSix">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                            data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                            <b> Any other documents details
                                                                <a href={void(0)} data-bs-toggle="tooltip" data-bs-placement="top" className="text-white" title="If applicable, please upload any other relevant documents to your application as required.">
                                                                    <i aria-hidden className="fa fa-info-circle mx-2"></i>
                                                                </a>
                                                            </b>
                                                        </button>
                                                    </h2>
                                                    <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingSix">
                                                        <div className="accordion-body">
                                                            <div className="">
                                                                <label className="">Company Sales Deck <span className="text-danger">*</span></label><br />
                                                                {formik.values.companysales && isFileSelect.companysales !== true ?
                                                                    // <input type="text" id="myfile" name="companysales"
                                                                    //     value={formik.values.companysales}
                                                                    // onChange={formik.handleChange}
                                                                    // onBlur={formik.handleBlur}
                                                                    // className="mt-2 form-control" disabled readOnly/>
                                                                    <div>Document Name :
                                                                        <button type='button' className="btn btn-link" onClick={() => onClickDownloadFile('companysales')}>{fileName&&fileName.companysales?fileName.companysales:''}</button>
                                                                        <button type='button' onClick={() => updateFileClick('companysales')} title='Update file' style={{ float: 'right', color: '#fff', backgroundColor: '#029f02' }}><i className="fa fa-upload" aria-hidden="true"></i></button>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {selectedFile.companysales && <button type='button' onClick={() => cancelUpdateFileClick('companysales')} title='Close' style={{ float: 'right', color: '#fff', backgroundColor: 'red' }}><i className="fa fa-close" aria-hidden="true"></i></button>}
                                                                        <input type="file" id="companysales" name="companysales"
                                                                            onChange={(e) => onFileSelection('companysales', e)} className="mt-2 form-control"
                                                                            accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, 
                                                                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                                            .csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                                                                            .ppt, pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                                    </>
                                                                }
                                                                {formik.errors.companysales && formik.touched.companysales && (
                                                                    <div className="text-danger">{formik.errors.companysales}</div>
                                                                )}
                                                                <hr />

                                                                <label className="mt-1">Company Investment Deck (If Applicable)</label><br />
                                                                {formik.values.companyinvest && isFileSelect.companyinvest !== true ?
                                                                    // <input type="text" id="myfile1"
                                                                    //     name="companyinvest"
                                                                    //     value={formik.values.companyinvest}
                                                                    //     // onChange={formik.handleChange}
                                                                    //     // onBlur={formik.handleBlur}
                                                                    //     className="mt-2 form-control" disabled readOnly/>
                                                                    <div>Document Name :
                                                                        <button type='button' className="btn btn-link" onClick={() => onClickDownloadFile('companyinvest')}>{fileName&&fileName.companyinvest?fileName.companyinvest:''}</button>
                                                                        <button type='button' onClick={() => updateFileClick('companyinvest')} title='Update file' style={{ float: 'right', color: '#fff', backgroundColor: '#029f02' }}><i className="fa fa-upload" aria-hidden="true"></i></button>
                                                                    </div>
                                                                    : <>
                                                                        {selectedFile.companyinvest && <button type='button' onClick={() => cancelUpdateFileClick('companyinvest')} title='Close' style={{ float: 'right', color: '#fff', backgroundColor: 'red' }}><i className="fa fa-close" aria-hidden="true"></i></button>}
                                                                        <input type="file" id="companyinvest" name="companyinvest"
                                                                            onChange={(e) => onFileSelection('companyinvest', e)} className="mt-2 form-control"
                                                                            accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, 
                                                                            application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                                            .csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                                                                            .ppt, pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                                    </>
                                                                }
                                                                {formik.errors.companyinvest && formik.touched.companyinvest && (
                                                                    <div className="text-danger">{formik.errors.companyinvest}</div>
                                                                )}
                                                            </div>
                                                            <label className="mt-4">Company Forecasts / Model <span className="text-danger">*</span></label><br />
                                                            {formik.values.companyforcast && isFileSelect.companyforcast !== true ?
                                                                // <input type="text" id="myfile2" name="companyforcast"
                                                                //     value={formik.values.companyforcast}
                                                                //     // onChange={formik.handleChange}
                                                                //     // onBlur={formik.handleBlur}
                                                                //     className="mt-2 form-control" disabled readOnly/>
                                                                <div>Document Name :
                                                                    <button type='button' className="btn btn-link" onClick={() => onClickDownloadFile('companyforcast')}>{fileName&&fileName.companyforcast?fileName.companyforcast:''}</button>
                                                                    <button type='button' onClick={() => updateFileClick('companyforcast')} title='Update file' style={{ float: 'right', color: '#fff', backgroundColor: '#029f02' }}><i className="fa fa-upload" aria-hidden="true"></i></button>
                                                                </div>
                                                                : <>
                                                                    {selectedFile.companyforcast && <button type='button' onClick={() => cancelUpdateFileClick('companyforcast')} title='Close' style={{ float: 'right', color: '#fff', backgroundColor: 'red' }}><i className="fa fa-close" aria-hidden="true"></i></button>}
                                                                    <input type="file" id="companyforcast" name="companyforcast"
                                                                        onChange={(e) => onFileSelection('companyforcast', e)} className="mt-2 form-control"
                                                                        accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, 
                                                                        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                                        .csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                                                                        .ppt, pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                                </>
                                                            }
                                                            {formik.errors.companyforcast && formik.touched.companyforcast && (
                                                                <div className="text-danger">{formik.errors.companyforcast}</div>
                                                            )}
                                                            <hr />

                                                            <label className="mt-1">Any Other Document(s)</label><br />
                                                            {formik.values.otherdocument && isFileSelect.otherdocument !== true ?
                                                                // <input type="text" id="myfile3" name="otherdocument"
                                                                //     value={formik.values.otherdocument}
                                                                //     // onChange={formik.handleChange}
                                                                //     // onBlur={formik.handleBlur}
                                                                //     className="mt-2 form-control" disabled readOnly />
                                                                <div>Document Link :
                                                                    <button type='button' className="btn btn-link" onClick={() => onClickDownloadFile('otherdocument')}>{fileName&&fileName.otherdocument?fileName.otherdocument:''}</button>
                                                                    <button type='button' onClick={() => updateFileClick('otherdocument')} title='Update file' style={{ float: 'right', color: '#fff', backgroundColor: '#029f02' }}><i className="fa fa-upload" aria-hidden="true"></i></button>
                                                                </div>
                                                                :
                                                                <>
                                                                    {selectedFile.otherdocument && <button type='button' onClick={() => cancelUpdateFileClick('otherdocument')} title='Close' style={{ float: 'right', color: '#fff', backgroundColor: 'red' }}><i className="fa fa-close" aria-hidden="true"></i></button>}
                                                                    <input type="file" id="otherdocument" name="otherdocument" onChange={(e) => onFileSelection('otherdocument', e)} className="mt-2 form-control"
                                                                        accept="image/png, image/jpg, image/jpeg, image/gif, application/pdf, 
                                                                        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                                                        .csv, .xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
                                                                        .ppt, pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                                </>
                                                            }
                                                            {/* <div className="text-danger">{errorMsg ? errorMsg : ''}</div> */}
                                                            <small><b>Only Images, PDF, Document, Excel, Powerpoint files allowed, per file size limit 1 MB</b></small>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errorMsg && 
                                                    <div className="alert alert-danger" style={{marginTop:5}}>{errorMsg ? errorMsg : ''}</div>
                                                }
                                            </div>
                                            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" >
                                                <div className="modal-dialog modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <h5>
                                                                Thank you for submitting your application. A member of the team will be in touch shortly. Should you wish to talk to someone before then, please speak to a member of the team
                                                                <a href={void(0)} className="text-decoration-underline">here</a>
                                                            </h5>
                                                        </div>
                                                        <div className="modal-footer justify-content-start p-0">
                                                            <button type="submit" className="btn btn-custom btn-custom-2" data-bs-dismiss="modal">Ok</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button className="btn btn-custom btn-custom-2 mt-3" type="submit">
                                                Submit
                                            </button>
                                            <br />
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
};

export default ApplicationDetails;