import React from "react";
import finance from "../img/finance.svg";

const FinanceBarlow = () => {
  return (
    <section className="finance-barlow">
      <div className="container finance-container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <img src={finance} alt="Finance Growth" className="finace-image" />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 align-self-center">
            <div className="section-title-growth">
              A NEW WAY TO FINANCE GROWTH
            </div>
            <div className="section-text-growth">
              <p>
                Founders shouldn't have to give up equity to grow. Dilution is
                expensive, loses you control and future returns forever.
                </p>
                <p>
                Alternatively, choosing debt means strict terms, minimum monthly
                payments and personal guarantees.
                </p> 
                <p>Both are time consuming
                distractions, taking you away from what you do best - growing
                yourcompany. 
                </p>
                <p>Barlow Marshall is changing this with our flexible
                revenue finance.
                </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinanceBarlow;
