import React, { memo, useEffect, useState } from "react";
import profileimage from "../img/download.png";
import {  Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileInfoData, profileInfoSuccess } from "../redux/action/profileInfo";
import { decryptString, encryptString, localStorageClear, toTitleCase, yapilyLocalStorageClear } from "../services/CommonFunction";
import { HttpCall } from "../services/UseHttps";
import { clientCompanyApi, signOutAPI } from "../config";
import { ErrorHandler } from "./Auth/errorHandler";

const NavBar = ({admin}) => {
  const dispatch = useDispatch();
  const profileData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);
  const userInfo = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj);
  const yapilyStatus = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.yapily_obj);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const pid = decryptString(localStorage.getItem('pid')||'');

  const navigate = useNavigate();
  const logoutHandler = () =>{
    // localStorageClear();
    // navigate('/login');
    HttpCall(`${signOutAPI}`, "GET").then((res) => {
      // if (res && res.status && (res.status === 200 || res.status === 201)) {}
      localStorageClear();
      dispatch(profileInfoSuccess(''));
      navigate('/login');
    }).catch((error) => {
      localStorageClear();
      navigate('/login');
    });
  }

  useEffect(() => {
    const role = decryptString(localStorage.getItem('role'));
    const companyList = role && role === 'client' ? JSON.parse(decryptString(localStorage.getItem('cmplt'))) || [] : [];
    setCompanies(companyList);
    dispatch(profileInfoData());
  }, [])
  //  useEffect(() => {
  //     const role = decryptString(localStorage.getItem('role'));
  //     const pui = decryptString(localStorage.getItem('pui')||'');
  //     if(role && role === 'client' && !pui) {
  //       if(companyList && companyList.length && companyList.length === 1) {
  //         const userID = companyList[0].parent_user_id;
  //         localStorage.setItem('pui', encryptString(userID));
  //         callClientCompany(userID, false);
  //       } else if(companyList && companyList.length > 1) {
  //         setIsCompanies(true);
  //       }
  //     } else {
  //       dispatch(profileInfoData());
  //       if(setTo) { setShowDashboard(true); }
  //     }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [])
   useEffect(() => {
      if(userInfo && (userInfo.first_name || userInfo.last_name)) {
        setUsername(toTitleCase(userInfo.first_name?userInfo.first_name:'') +' '+ toTitleCase(userInfo.last_name?userInfo.last_name:''));
      }
   }, [userInfo]);

  // client-company API call - clientCompanyApi
  const callClientCompany = (userId) => {
    try {
      setLoading(true);
      yapilyLocalStorageClear(); // clear yapily storage if eixst
      HttpCall(`${clientCompanyApi}`, "POST", {parent_user_id: userId}).then((res) => {
          if(res && (res.status === 200 || res.status === 201) && res.data && res.data.token) {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('pid', encryptString(userId));
                if(res.data.is_client) {
                  navigate('/client-dashboard'); // default redirect to dashboard
                  window.location.reload();
                } else {
                  localStorage.setItem('role', encryptString('funded'));
                  localStorage.setItem('refresh','true');
                  navigate('/getFunded',{state: { email: userInfo.email } });
                }
          }
          setLoading(false);
      }).catch((error) => {
          setLoading(false);
          ErrorHandler(error);
      });
    } catch (ex) {
        setLoading(false);
    }
  }
   
  return (
    <>
          <header className="header-shadow" style={{ position: "sticky" }}>
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <Link to="/" className="header-logo d-inline-block">
                  <img src='/img/Barlow.jpg' alt="Barlow Marshall" />
                </Link>
                <nav className="header-nav d-flex align-items-center navlink ">
                  <ul className="header-menu mt-3 mx-4 p-0 d-flex align-items-center list-unstyled nav-list border-end border-dark">
                    <li>
                      <NavLink
                        activeclassname="active"
                        className="text-decoration-none"
                        to={admin?"/admin-dashboard":"/client-dashboard"}
                      >
                        Dashboard
                      </NavLink>
                    </li>
   {admin && <li>
                      <NavLink
                        activeclassname="active-link"
                        className="text-decoration-none"
                        to="/admin-view-application"
                      >
                        View Applications
                      </NavLink>
                    </li>}
         { (Object.keys((profileData && profileData.offer)||{}).length !== 0 || (profileData  && profileData.application_status==='ineligible') || admin ) &&   <li>
                      <NavLink
                        activeclassname="active-link"
                        className="text-decoration-none"
                        to={admin?"/admin-view-offer":"/client-viewoffer"}
                      >
                       {admin ? 'View Offers' : 'View Offer'}
                      </NavLink>
                    </li>}
              {   ( (Object.keys((profileData && profileData.offer)||{}).length !== 0 && profileData  &&profileData.offer && profileData.offer.status ==='accepted')  || admin )  &&
                    <li>
                      <NavLink
                        activeclassname="active-link"
                        className="text-decoration-none"
                        to={admin?"/admin-ledger":"/client-ledger"}
                      >
                     Ledger
                      </NavLink>
                    </li>
              }
              { (Object.keys((profileData && profileData.offer)||{}).length !== 0 && profileData  &&profileData.offer && profileData.offer.status ==='accepted')  &&
                    <li>
                      <NavLink activeclassname="active-link" className="text-decoration-none" 
                        to={yapilyStatus&&yapilyStatus?.verification_status===null?"/client-yapily/integration":"/client-yapily/dashboard"} >
                        Open Banking
                      </NavLink>
                    </li>
              }
                  </ul>
                  <ul className="m-0 p-0 d-flex align-items-center list-unstyled button-links">
                    <div>
                      <Link
                        className="dropdown-toggle align-items-center d-flex"
                        to="/"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={profileimage}
                          className="rounded-circle"
                          height="30"
                          width="40"
                          alt="Portrait of a Man"
                          loading="lazy"
                        />
                        <span className="ml-1"> <b>{username?username:''}</b> </span>
                      </Link>
                      <ul
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="navbarDropdownMenuAvatar"
                      >
                        <li className="m-0">
                          <Link className="dropdown-item" to={admin?"/admin-dashboard":"/profile"}>
                            My profile
                          </Link>
                        </li>
                        <li className="m-0">
                          <Link className="dropdown-item" to="/">
                            Settings
                          </Link>
                        </li>
                        {companies&&companies.length > 1 &&
                            companies.map((comp,i) =>
                              comp.parent_user_id !== pid ? 
                                <li className="m-0" key={i}>
                                  <a className="dropdown-item" href={void(0)} onClick={()=>callClientCompany(comp.parent_user_id)}>
                                    Switch to {comp.company_name}
                                  </a>
                                </li>
                              : ''
                            )
                        }
                        <li className="m-0">
                          <div
                            onClick={logoutHandler}
                            className="dropdown-item"
                          >
                            Logout
                          </div>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </nav>
              </div>
            </div>
          </header>
          {/* Make Company Selection : If user has multiple company */}
          {/* {companies&&companies.length > 0 &&
            <div  className="bg-light" style={{minHeight: "75vh"}}>
                <div className="container">
                    <div className="row py-3">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mt-3">
                        <div className="h3">Select Company</div>
                        <div className="mb-2">
                          <select className="form-select" name="company" value="" onChange={onSelectCompany}
                            placeholder="Select Company" aria-label="Default select example">
                              <option disabled={true} value="">Select Company</option>
                              {companies.map((comp,i) => <option key={i} value={comp.parent_user_id}>{comp.company_name}</option> )}
                          </select>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          } */}
    </>
  );
};

export default memo(NavBar);
