import { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { handleError, HttpCall } from "../services/UseHttps";
import { debounce, countryList, getCountryCode, sicCodes } from '../services/CommonFunction';
import Select from "react-select";
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
// import { PhoneNumber } from "../redux/action/phonecode";
import ClipLoader from "react-spinners/ClipLoader";
import { companyDetailsAPI, companyNameSearchAPI } from "../config";
import { getFundedData, getFundedDetailsData } from "../redux/action/getfunded";
import { PhoneNumberApi } from "../redux/service/ApiConfig";
import { ErrorHandler } from "../components/Auth/errorHandler";

const CompanyDetails = ({ ...props }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [companySearchResult, setCompanySearchResult] = useState([]); // company name search results
    const [selectedValue, setSelectedValue] = useState(null); // selected company name
    const [isLoading, setIsLoading] = useState(false); // loader
    const [isSaveLoading, setIsSaveLoading] = useState(false); // loader
    // eslint-disable-next-line
    const propsCDR = props.companyDetailsRes && props.companyDetailsRes.company_details ? props.companyDetailsRes.company_details : {};
    const propsCRDR = props.companyDetailsRes && props.companyDetailsRes.revenue_details ? props.companyDetailsRes.revenue_details : {};
    const propsCPDR = props.companyDetailsRes && props.companyDetailsRes.personal_details ? props.companyDetailsRes.personal_details : {};

    const getfundedDetailsFirst = useSelector((state) => state.GetFunded && state.GetFunded.getfundedDetails);
    const getStatusFirst = useSelector((state) => state.GetFunded && state.GetFunded.status);
    const getfundedError = useSelector((state) => state.GetFunded && state.GetFunded.error);

    const [isValidPhone, setIsValidPhone] = useState(false);
    const [phoneErrormsg, setPhoneErrormsg] = useState(false);
    // const phoneErrormsg = useSelector((state) => state.PhoneNumber && state.PhoneNumber.error);
    // const phoneresponse = useSelector((state) => state.PhoneNumber && state.PhoneNumber.phonenumber && state.PhoneNumber.phonenumber.data && state.PhoneNumber.phonenumber.data.is_valid);
    let numberRequest = {
        'phone_number': "",
        'country_code': ""
    };

    const [initialValues, setInitialValues] = useState({
        companyname: propsCDR.company_name ? propsCDR.company_name : '',
        company_number: propsCDR.company_number ? propsCDR.company_number : '',
        company_type: propsCDR.company_type ? propsCDR.company_type : '',
        country: propsCDR.country_of_incorporation ? getCountryCode(propsCDR.country_of_incorporation) : 'GB', // default selected 
        typeofbusiness: propsCDR.type_of_business ? propsCDR.type_of_business : '', // default selected 
        sic_code: propsCDR.sic_code ? propsCDR.sic_code : '',
        annualrecurring: propsCRDR.annual_recurring_revenue ? propsCRDR.annual_recurring_revenue : '',
        tradinghistory: propsCRDR.trading_history ? propsCRDR.trading_history : '', // default selected 
        anycomment: propsCRDR.comments ? propsCRDR.comments : '',
        firstname: propsCPDR.first_name ? propsCPDR.first_name : '',
        lastname: propsCPDR.last_name ? propsCPDR.last_name : '',
        phonenumber: propsCPDR.phone_number ? propsCPDR.phone_number : '',
    });

    // set compnay name if already exist (from signup response)
    useEffect(() => {
        if (propsCDR && propsCDR.company_name) {
            const cn = propsCDR.company_name;
            setCompanySearchResult([{ value: cn, label: cn }]);
            setSelectedValue({ value: cn, label: cn });
        }
    }, [propsCDR]);

    // set compnay name if already exist (from signup response)
    useEffect(() => {
        if (propsCPDR && propsCPDR.first_name) {
            setInitialValues({
                companyname: propsCDR.company_name ? propsCDR.company_name : '',
                company_number: propsCDR.company_number ? propsCDR.company_number : '',
                company_type: propsCDR.company_type ? propsCDR.company_type : '',
                country: propsCDR.country_of_incorporation ? getCountryCode(propsCDR.country_of_incorporation) : 'GB', // default selected 
                typeofbusiness: propsCDR.type_of_business ? propsCDR.type_of_business : '', // default selected 
                sic_code: propsCDR.sic_code ? propsCDR.sic_code : '',
                annualrecurring: propsCRDR.annual_recurring_revenue ? propsCRDR.annual_recurring_revenue : '',
                tradinghistory: propsCRDR.trading_history ? propsCRDR.trading_history : '', // default selected 
                anycomment: propsCRDR.comments ? propsCRDR.comments : '',
                firstname: propsCPDR.first_name ? propsCPDR.first_name : '',
                lastname: propsCPDR.last_name ? propsCPDR.last_name : '',
                phonenumber: propsCPDR.phone_number ? propsCPDR.phone_number : ''
            });
            if(propsCPDR.phone_number && propsCPDR.phone_number !== '') {
                setIsValidPhone(true);
            }
        }
    }, [propsCRDR, propsCPDR]);

    // Submit handle error
    useEffect(() => {
        if (getfundedError && getfundedDetailsFirst === "") {
            setIsSaveLoading(false);
        }
    }, [getfundedError,getfundedDetailsFirst]);

    useEffect(() => {
        setIsLoading(false);
        if (getfundedDetailsFirst && getfundedDetailsFirst !== null && getStatusFirst === 'FIRST' 
            && phoneErrormsg === false && isValidPhone === true) {
            Swal.fire({
                icon: 'success',
                title: 'Your company details have been saved',
                showConfirmButton: true,
                text: 'Thank you for submitting your company details.'
            });
            props.moveTab('second');
            props.setStepCompletion('first');
            setIsSaveLoading(false);
            const paramBody = { email: props.email };
            dispatch(getFundedData(paramBody));
        }
        // eslint-disable-next-line
    }, [getfundedDetailsFirst, getStatusFirst]);

    const phoneValue = (value, country) => {
        numberRequest = {
            'phone_number': value,
            'country_code': country.iso2
        }
    };
    const handleBlurs = (e) => {
        setLoading(true);
        // dispatch(PhoneNumber(numberRequest))
        try {
            PhoneNumberApi(numberRequest).then((res) => {
                if (res&&res.data&&res.data.is_valid === true) {
                    setLoading(false);
                    setIsValidPhone(true);
                    setPhoneErrormsg(false);
                } else {
                    setLoading(false);
                    setIsValidPhone(false);
                    setPhoneErrormsg(true);
                }
            })
        } catch (err) {
            setLoading(false);
            setIsValidPhone(false);
            setPhoneErrormsg(true);
            ErrorHandler(err)
        }
    }

    useEffect(() => {
        if (initialValues) {
            props.companyTab(initialValues);
        }        
        // eslint-disable-next-line
    }, [initialValues]);

    const formik = useFormik({
        enableReinitialize: true,
        // validate,
        initialValues,
        // errormsg,
        onSubmit: (values) => {
            const allValues = {
                'company_name': values.companyname,
                'company_number': values.company_number,
                'company_type': values.company_type,
                'country_of_incorporation': values.country,
                'type_of_business': values.typeofbusiness,
                'sic_code': values.sic_code,
                'annual_recurring_revenue': values.annualrecurring,
                'trading_history': values.tradinghistory,
                'comments': values.anycomment,
                'first_name': values.firstname,
                'last_name': values.lastname,
                'phone_number': values.phonenumber,
                'status': 'DRAFT'
            };
            props.companyTab(values)
            setIsSaveLoading(true);
            dispatch(getFundedDetailsData(allValues, 'FIRST'));
        },
        validationSchema: yup.object({
            companyname: yup.string().trim().required('Company name is required'),
            country: yup.string().trim().required('Please select a country'),
            typeofbusiness: yup.string().trim().required('Please select a business type'),
            sic_code: yup.string().trim('SIC code cannot include leading and trailing spaces').strict(true)
                .required('SIC code is required')
                .min(4, "Minimum SIC code should be 4 digits")
                .max(5, "Maximum SIC code should be 5 digits")
                .matches(/^\d{4,5}$/, 'SIC code should be numbers only'),
            annualrecurring: yup.string().trim().required('Required annual recurring revenue'),
            tradinghistory: yup.string().trim().required('Please select a trading history'),
            anycomment: yup.string().trim().required('Please leave a comments'),
            firstname: yup.string().trim().required('First name is required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for first name"),
            lastname: yup.string().trim().required('Last name is required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for last name"),
            phonenumber: yup.string().required('Mobile number is required')
                .min(8, "Minimum number should be 8 digits")
                .min(12, "Maximum number should be 12 digits"),
        }),
    });

    // Call debounce function for company name search
    const handleCompanyNameChange = debounce((eVal) => { onCompanyNameChange(eVal); });
    // Call Company name search API and set search result
    function onCompanyNameChange(value) {
        if (value && value.trim() !== '') {
            setIsLoading(true);
            HttpCall(`${companyNameSearchAPI}`, "POST", { "q": value }).then((res) => {
                const comanyResponse = res && res.data && res.data.data && res.data.data && res.data.data.items && res.data.data.items.length > 0 ? 
                    res.data.data.items.filter(comp => comp.title && comp.company_number) : []; // get only company which has company number
                if (comanyResponse && comanyResponse.length > 0) {
                    const optionsData = comanyResponse.map((el) => { return { value: el.title, label: el.title } });
                    setOptions(optionsData);
                    setCompanySearchResult(comanyResponse);
                } else {
                    setCompanySearchResult([]);
                }
                setIsLoading(false);
            }).catch((error) => {
                handleError(error);
                setCompanySearchResult([]);
                setIsLoading(false);
            });
        }
    }
    // Set selected company details : 
    const setActiveCompanyDetail = (detail) => {
        if (detail && detail.value) {
            setSelectedValue(detail);
            const data = companySearchResult.filter((el) => el.title === detail.value);
            if (data && data.length > 0) {
                // // set details
                // setInitialValues({ ...initialValues, companyname: data[0].title,
                //     company_number: data[0].company_number, company_type: data[0].company_type, // typeofbusiness: data[0].company_type
                // });
                getCompanyDetails(data[0].company_number, data[0].title, data[0].company_type);
            }
        } else {
            setSelectedValue(null);
            setInitialValues({ ...initialValues, companyname: '', company_number: '', company_type:'', sic_code: '' });
        }
    }
    // get company detaile based on company number (check/get sic_code)
    function getCompanyDetails(companyNumber, title, companyType) {
        if (companyNumber && companyNumber.trim() !== '') {
            HttpCall(`${companyDetailsAPI}${companyNumber}`, "GET").then((res) => {
                if (res && res.data && res.data.data && res.data.data.sic_codes && res.data.data.sic_codes.length > 0) {
                    const sicCode = res.data.data.sic_codes[0];
                    setInitialValues({ ...initialValues, companyname: title, company_number: companyNumber, company_type: companyType, sic_code: sicCode });
                } else {
                    setInitialValues({ ...initialValues, companyname: title, company_number: companyNumber, company_type: companyType, sic_code: '' });
                }
            }).catch((error) => { handleError(error);});
        }
    }

    return (
        <>
        { isSaveLoading ? <ClipLoader size={250} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" /> :
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-0">
                            <div className="card-body">
                                <div className="col-lg-8">
                                    <form className="custom-form" onSubmit={formik.handleSubmit}>
                                        <div className="accordion" id="accordionExample2">
                                            <div className="accordion-item mt-3">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <b> Company Details
                                                        </b>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingFour">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <div className="ui-widget">
                                                                <Select  // openMenuOnFocus={true} 
                                                                    options={options}
                                                                    value={selectedValue}
                                                                    onChange={setActiveCompanyDetail}
                                                                    onInputChange={handleCompanyNameChange}
                                                                    isClearable={true}
                                                                    className="form-select"
                                                                    placeholder="Company Name (Trading company)"
                                                                    isLoading={isLoading}
                                                                />
                                                                {formik.errors.companyname && formik.touched.companyname && (
                                                                    <div className="text-danger">{formik.errors.companyname}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <select className="form-select"
                                                                name="country"
                                                                value={formik.values.country}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                aria-label="Default select example">
                                                                <option disabled>Country of Incorporation</option>
                                                                {countryList && countryList.length > 0 &&
                                                                    countryList.map((cntr, i) => <option key={i} value={cntr.code}>{cntr.name+' ('+cntr.code+')'}</option>)
                                                                }
                                                            </select>
                                                            {formik.errors.country && formik.touched.country && (
                                                                <div className="text-danger">{formik.errors.country}</div>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <select className="form-select"
                                                                name="typeofbusiness"
                                                                value={formik.values.typeofbusiness}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                aria-label="Default select example">
                                                                <option disabled={true} value="">Type of business</option>
                                                                <option value="SaaS">SaaS</option>
                                                                <option value="Other recurring revenue">Other recurring revenue</option>
                                                                <option value="Re-occurring revenue">Re-occurring revenue</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                            {formik.errors.typeofbusiness && formik.touched.typeofbusiness && (
                                                                <div className="text-danger">{formik.errors.typeofbusiness}</div>
                                                            )}
                                                        </div>
                                                        <div className="">
                                                            <select className="form-select"
                                                                name="sic_code"
                                                                value={formik.values.sic_code}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                aria-label="Select SIC Code">
                                                                <option disabled={true} value="">Nature Of Business(SIC Code)</option>
                                                                {sicCodes && sicCodes.length > 0 &&
                                                                    sicCodes.map((cd, i) => <option key={i} value={cd?.Code}>{cd?.Code+' ('+cd?.Description+')'}</option>)
                                                                }
                                                            </select>
                                                            {/* <input type="text" name="sic_code" value={formik.values.sic_code}
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} className="form-control"
                                                                placeholder="SIC Code" id="inputsic" aria-describedby="FundHelp" />*/}
                                                            {formik.errors.sic_code && formik.touched.sic_code && ( <div className="text-danger">{formik.errors.sic_code}</div>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFive">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        <b> Revenue Details
                                                        </b>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingFive">
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <input type="text"
                                                                name="annualrecurring"
                                                                value={formik.values.annualrecurring}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                className="form-control"
                                                                placeholder="Annual Recurring Revenue"
                                                                id="exampleInputFund"
                                                                aria-describedby="FundHelp" />
                                                            {formik.errors.annualrecurring && formik.touched.annualrecurring && (
                                                                <div className="text-danger">{formik.errors.annualrecurring}</div>
                                                            )}
                                                        </div>
                                                        <div className="mb-3">
                                                            <select className="form-select"
                                                                name="tradinghistory"
                                                                value={formik.values.tradinghistory}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                placeholder="Trading History (Months Since First Revenue)"
                                                                aria-label="Default select example">
                                                                <option disabled={true} value="">Trading History (Months Since First Revenue)</option>
                                                                <option value="<6 months">&lt;6 months</option>
                                                                <option value="6 - 12 months">6 - 12 months</option>
                                                                <option value="12 - 36 months">12 - 36 months</option>
                                                                <option value="36 months+">36 months+</option>
                                                            </select>
                                                            {formik.errors.tradinghistory && formik.touched.tradinghistory && (
                                                                <div className="text-danger">{formik.errors.tradinghistory}</div>
                                                            )}
                                                        </div>
                                                        <div className="">
                                                            <textarea className="form-control" style={{minHeight:80}}
                                                                name="anycomment"
                                                                value={formik.values.anycomment}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="exampleFormControlTextarea1" placeholder="Any Other Comments" rows="3"></textarea>
                                                            {formik.errors.anycomment && formik.touched.anycomment && (
                                                                <div className="text-danger">{formik.errors.anycomment}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingSix">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <b>Personal Details</b>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingSix">
                                                    <div className="accordion-body">
                                                        <div className="mb-3 shadow">
                                                            <input type="text" className="form-control"
                                                                placeholder="First name"
                                                                name="firstname"
                                                                value={formik.values.firstname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="exampleInputBusiness" aria-describedby="businessHelp" />
                                                            {formik.errors.firstname && formik.touched.firstname && (
                                                                <div className="text-danger">{formik.errors.firstname}</div>
                                                            )}
                                                        </div>
                                                        <div className="mb-3 shadow">
                                                            <input type="text" className="form-control"
                                                                placeholder="Last name"
                                                                name="lastname"
                                                                value={formik.values.lastname}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                id="exampleInputBusiness" aria-describedby="businessHelp" />
                                                            {formik.errors.lastname && formik.touched.lastname && (
                                                                <div className="text-danger">{formik.errors.lastname}</div>
                                                            )}
                                                        </div>
                                                        <div className="shadow">
                                                            <PhoneInput
                                                                country={"gb"}
                                                                name="phonenumber"
                                                                countryCodeEditable={true}
                                                                value={formik.values.phonenumber}
                                                                onChange={formik.handleChange('phonenumber')}
                                                                className=""
                                                                placeholder="Phone Number"
                                                                isValid={(value, country) => phoneValue(value, country)}
                                                                onBlur={(e) => handleBlurs(e)}
                                                                disabled
                                                            />
                                                            {formik.errors.phonenumber && formik.touched.phonenumber && (
                                                                <div className="text-danger">{formik.errors.phonenumber}</div>
                                                            )
                                                            }
                                                            {
                                                                phoneErrormsg === true ? <div className='text-danger'
                                                                > Please enter valid number </div> : ''
                                                            }
                                                            {loading && <ClipLoader size={100} css={{ display: 'block', float: 'right', borderColor: 'green', marginTop: '-80px', marginLeft: '80px',position: 'absolute' }} color="green" /> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-custom btn-custom-2 mt-3"
                                            disabled={!isValidPhone || loading || phoneErrormsg}>
                                            Save
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
};

export default CompanyDetails;