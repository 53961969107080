import axios from "axios";
import CryptoJS from 'crypto-js';
import { BASE_URL } from "../config";

// debounce function
export const debounce = (func, timeout = 1000) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const downloadFile = (fileUrl, filename) => {
    const headers = {'Access-Control-Allow-Origin':'*'};
    axios({
          url: `${BASE_URL+fileUrl}`,
          method: "GET",
          headers: headers,
          responseType: "blob" // important
      }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
      });
  }

// convert string into title-case
export const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export const countryList = [
    {name: 'United Kingdom', code: 'GB'},
    {name: 'Afghanistan', code: 'AF'}, 
    {name: 'Åland Islands', code: 'AX'}, 
    {name: 'Albania', code: 'AL'}, 
    {name: 'Algeria', code: 'DZ'}, 
    {name: 'American Samoa', code: 'AS'}, 
    {name: 'AndorrA', code: 'AD'}, 
    {name: 'Angola', code: 'AO'}, 
    {name: 'Anguilla', code: 'AI'}, 
    {name: 'Antarctica', code: 'AQ'}, 
    {name: 'Antigua and Barbuda', code: 'AG'}, 
    {name: 'Argentina', code: 'AR'}, 
    {name: 'Armenia', code: 'AM'}, 
    {name: 'Aruba', code: 'AW'}, 
    {name: 'Australia', code: 'AU'}, 
    {name: 'Austria', code: 'AT'}, 
    {name: 'Azerbaijan', code: 'AZ'}, 
    {name: 'Bahamas', code: 'BS'}, 
    {name: 'Bahrain', code: 'BH'}, 
    {name: 'Bangladesh', code: 'BD'}, 
    {name: 'Barbados', code: 'BB'}, 
    {name: 'Belarus', code: 'BY'}, 
    {name: 'Belgium', code: 'BE'}, 
    {name: 'Belize', code: 'BZ'}, 
    {name: 'Benin', code: 'BJ'}, 
    {name: 'Bermuda', code: 'BM'}, 
    {name: 'Bhutan', code: 'BT'}, 
    {name: 'Bolivia', code: 'BO'}, 
    {name: 'Bosnia and Herzegovina', code: 'BA'}, 
    {name: 'Botswana', code: 'BW'}, 
    {name: 'Bouvet Island', code: 'BV'}, 
    {name: 'Brazil', code: 'BR'}, 
    {name: 'British Indian Ocean Territory', code: 'IO'}, 
    {name: 'Brunei Darussalam', code: 'BN'}, 
    {name: 'Bulgaria', code: 'BG'}, 
    {name: 'Burkina Faso', code: 'BF'}, 
    {name: 'Burundi', code: 'BI'}, 
    {name: 'Cambodia', code: 'KH'}, 
    {name: 'Cameroon', code: 'CM'}, 
    {name: 'Canada', code: 'CA'}, 
    {name: 'Cape Verde', code: 'CV'}, 
    {name: 'Cayman Islands', code: 'KY'}, 
    {name: 'Central African Republic', code: 'CF'}, 
    {name: 'Chad', code: 'TD'}, 
    {name: 'Chile', code: 'CL'}, 
    {name: 'China', code: 'CN'}, 
    {name: 'Christmas Island', code: 'CX'}, 
    {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
    {name: 'Colombia', code: 'CO'}, 
    {name: 'Comoros', code: 'KM'}, 
    {name: 'Congo', code: 'CG'}, 
    {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
    {name: 'Cook Islands', code: 'CK'}, 
    {name: 'Costa Rica', code: 'CR'}, 
    {name: 'Cote D\'Ivoire', code: 'CI'}, 
    {name: 'Croatia', code: 'HR'}, 
    {name: 'Cuba', code: 'CU'}, 
    {name: 'Cyprus', code: 'CY'}, 
    {name: 'Czech Republic', code: 'CZ'}, 
    {name: 'Denmark', code: 'DK'}, 
    {name: 'Djibouti', code: 'DJ'}, 
    {name: 'Dominica', code: 'DM'}, 
    {name: 'Dominican Republic', code: 'DO'}, 
    {name: 'Ecuador', code: 'EC'}, 
    {name: 'Egypt', code: 'EG'}, 
    {name: 'El Salvador', code: 'SV'}, 
    {name: 'Equatorial Guinea', code: 'GQ'}, 
    {name: 'Eritrea', code: 'ER'}, 
    {name: 'Estonia', code: 'EE'}, 
    {name: 'Ethiopia', code: 'ET'}, 
    {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    {name: 'Faroe Islands', code: 'FO'}, 
    {name: 'Fiji', code: 'FJ'}, 
    {name: 'Finland', code: 'FI'}, 
    {name: 'France', code: 'FR'}, 
    {name: 'French Guiana', code: 'GF'}, 
    {name: 'French Polynesia', code: 'PF'}, 
    {name: 'French Southern Territories', code: 'TF'}, 
    {name: 'Gabon', code: 'GA'}, 
    {name: 'Gambia', code: 'GM'}, 
    {name: 'Georgia', code: 'GE'}, 
    {name: 'Germany', code: 'DE'}, 
    {name: 'Ghana', code: 'GH'}, 
    {name: 'Gibraltar', code: 'GI'}, 
    {name: 'Greece', code: 'GR'}, 
    {name: 'Greenland', code: 'GL'}, 
    {name: 'Grenada', code: 'GD'}, 
    {name: 'Guadeloupe', code: 'GP'}, 
    {name: 'Guam', code: 'GU'}, 
    {name: 'Guatemala', code: 'GT'}, 
    {name: 'Guernsey', code: 'GG'}, 
    {name: 'Guinea', code: 'GN'}, 
    {name: 'Guinea-Bissau', code: 'GW'}, 
    {name: 'Guyana', code: 'GY'}, 
    {name: 'Haiti', code: 'HT'}, 
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    {name: 'Holy See (Vatican City State)', code: 'VA'}, 
    {name: 'Honduras', code: 'HN'}, 
    {name: 'Hong Kong', code: 'HK'}, 
    {name: 'Hungary', code: 'HU'}, 
    {name: 'Iceland', code: 'IS'}, 
    {name: 'India', code: 'IN'}, 
    {name: 'Indonesia', code: 'ID'}, 
    {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
    {name: 'Iraq', code: 'IQ'}, 
    {name: 'Ireland', code: 'IE'}, 
    {name: 'Isle of Man', code: 'IM'}, 
    {name: 'Israel', code: 'IL'}, 
    {name: 'Italy', code: 'IT'}, 
    {name: 'Jamaica', code: 'JM'}, 
    {name: 'Japan', code: 'JP'}, 
    {name: 'Jersey', code: 'JE'}, 
    {name: 'Jordan', code: 'JO'}, 
    {name: 'Kazakhstan', code: 'KZ'}, 
    {name: 'Kenya', code: 'KE'}, 
    {name: 'Kiribati', code: 'KI'}, 
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
    {name: 'Korea, Republic of', code: 'KR'}, 
    {name: 'Kuwait', code: 'KW'}, 
    {name: 'Kyrgyzstan', code: 'KG'}, 
    {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
    {name: 'Latvia', code: 'LV'}, 
    {name: 'Lebanon', code: 'LB'}, 
    {name: 'Lesotho', code: 'LS'}, 
    {name: 'Liberia', code: 'LR'}, 
    {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    {name: 'Liechtenstein', code: 'LI'}, 
    {name: 'Lithuania', code: 'LT'}, 
    {name: 'Luxembourg', code: 'LU'}, 
    {name: 'Macao', code: 'MO'}, 
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
    {name: 'Madagascar', code: 'MG'}, 
    {name: 'Malawi', code: 'MW'}, 
    {name: 'Malaysia', code: 'MY'}, 
    {name: 'Maldives', code: 'MV'}, 
    {name: 'Mali', code: 'ML'}, 
    {name: 'Malta', code: 'MT'}, 
    {name: 'Marshall Islands', code: 'MH'}, 
    {name: 'Martinique', code: 'MQ'}, 
    {name: 'Mauritania', code: 'MR'}, 
    {name: 'Mauritius', code: 'MU'}, 
    {name: 'Mayotte', code: 'YT'}, 
    {name: 'Mexico', code: 'MX'}, 
    {name: 'Micronesia, Federated States of', code: 'FM'}, 
    {name: 'Moldova, Republic of', code: 'MD'}, 
    {name: 'Monaco', code: 'MC'}, 
    {name: 'Mongolia', code: 'MN'}, 
    {name: 'Montserrat', code: 'MS'}, 
    {name: 'Morocco', code: 'MA'}, 
    {name: 'Mozambique', code: 'MZ'}, 
    {name: 'Myanmar', code: 'MM'}, 
    {name: 'Namibia', code: 'NA'}, 
    {name: 'Nauru', code: 'NR'}, 
    {name: 'Nepal', code: 'NP'}, 
    {name: 'Netherlands', code: 'NL'}, 
    {name: 'Netherlands Antilles', code: 'AN'}, 
    {name: 'New Caledonia', code: 'NC'}, 
    {name: 'New Zealand', code: 'NZ'}, 
    {name: 'Nicaragua', code: 'NI'}, 
    {name: 'Niger', code: 'NE'}, 
    {name: 'Nigeria', code: 'NG'}, 
    {name: 'Niue', code: 'NU'}, 
    {name: 'Norfolk Island', code: 'NF'}, 
    {name: 'Northern Mariana Islands', code: 'MP'}, 
    {name: 'Norway', code: 'NO'}, 
    {name: 'Oman', code: 'OM'}, 
    {name: 'Pakistan', code: 'PK'}, 
    {name: 'Palau', code: 'PW'}, 
    {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
    {name: 'Panama', code: 'PA'}, 
    {name: 'Papua New Guinea', code: 'PG'}, 
    {name: 'Paraguay', code: 'PY'}, 
    {name: 'Peru', code: 'PE'}, 
    {name: 'Philippines', code: 'PH'}, 
    {name: 'Pitcairn', code: 'PN'}, 
    {name: 'Poland', code: 'PL'}, 
    {name: 'Portugal', code: 'PT'}, 
    {name: 'Puerto Rico', code: 'PR'}, 
    {name: 'Qatar', code: 'QA'}, 
    {name: 'Reunion', code: 'RE'}, 
    {name: 'Romania', code: 'RO'}, 
    {name: 'Russian Federation', code: 'RU'}, 
    {name: 'RWANDA', code: 'RW'}, 
    {name: 'Saint Helena', code: 'SH'}, 
    {name: 'Saint Kitts and Nevis', code: 'KN'}, 
    {name: 'Saint Lucia', code: 'LC'}, 
    {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
    {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    {name: 'Samoa', code: 'WS'}, 
    {name: 'San Marino', code: 'SM'}, 
    {name: 'Sao Tome and Principe', code: 'ST'}, 
    {name: 'Saudi Arabia', code: 'SA'}, 
    {name: 'Senegal', code: 'SN'}, 
    {name: 'Serbia and Montenegro', code: 'CS'}, 
    {name: 'Seychelles', code: 'SC'}, 
    {name: 'Sierra Leone', code: 'SL'}, 
    {name: 'Singapore', code: 'SG'}, 
    {name: 'Slovakia', code: 'SK'}, 
    {name: 'Slovenia', code: 'SI'}, 
    {name: 'Solomon Islands', code: 'SB'}, 
    {name: 'Somalia', code: 'SO'}, 
    {name: 'South Africa', code: 'ZA'}, 
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
    {name: 'Spain', code: 'ES'}, 
    {name: 'Sri Lanka', code: 'LK'}, 
    {name: 'Sudan', code: 'SD'}, 
    {name: 'Suriname', code: 'SR'}, 
    {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    {name: 'Swaziland', code: 'SZ'}, 
    {name: 'Sweden', code: 'SE'}, 
    {name: 'Switzerland', code: 'CH'}, 
    {name: 'Syrian Arab Republic', code: 'SY'}, 
    {name: 'Taiwan, Province of China', code: 'TW'}, 
    {name: 'Tajikistan', code: 'TJ'}, 
    {name: 'Tanzania, United Republic of', code: 'TZ'}, 
    {name: 'Thailand', code: 'TH'}, 
    {name: 'Timor-Leste', code: 'TL'}, 
    {name: 'Togo', code: 'TG'}, 
    {name: 'Tokelau', code: 'TK'}, 
    {name: 'Tonga', code: 'TO'}, 
    {name: 'Trinidad and Tobago', code: 'TT'}, 
    {name: 'Tunisia', code: 'TN'}, 
    {name: 'Turkey', code: 'TR'}, 
    {name: 'Turkmenistan', code: 'TM'}, 
    {name: 'Turks and Caicos Islands', code: 'TC'}, 
    {name: 'Tuvalu', code: 'TV'}, 
    {name: 'Uganda', code: 'UG'}, 
    {name: 'Ukraine', code: 'UA'}, 
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United States', code: 'US'}, 
    {name: 'United States Minor Outlying Islands', code: 'UM'}, 
    {name: 'Uruguay', code: 'UY'}, 
    {name: 'Uzbekistan', code: 'UZ'}, 
    {name: 'Vanuatu', code: 'VU'}, 
    {name: 'Venezuela', code: 'VE'}, 
    {name: 'Viet Nam', code: 'VN'}, 
    {name: 'Virgin Islands, British', code: 'VG'}, 
    {name: 'Virgin Islands, U.S.', code: 'VI'}, 
    {name: 'Wallis and Futuna', code: 'WF'}, 
    {name: 'Western Sahara', code: 'EH'}, 
    {name: 'Yemen', code: 'YE'}, 
    {name: 'Zambia', code: 'ZM'}, 
    {name: 'Zimbabwe', code: 'ZW'} 
];
export const getCountryCode = (name) => {
    const cntRes = countryList.filter(cnty => cnty.name === name);
    if(cntRes && cntRes.length > 0) { return cntRes[0].code; } else { return name;}
}
export const getDateDDMMYYYY = (d) => {
    const today = new Date(d);
    if(today && today.getFullYear()) {
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return yyyy + '-' + mm + '-' + dd;
    } else {
        return d;
    }
}
// const monthNames = ["January", "February", "March", "April", "May", "June",
//     "July", "August", "September", "October", "November", "December"];
// export const getDateDDMonthYYYY = (d) => {
//     const today = new Date(d);
//     const yyyy = today.getFullYear();
//     let mon = monthNames[today.getMonth()]; // Months start at 0!
//     let dd = today.getDate();
//     if (dd < 10) dd = '0' + dd;
//     return dd + ' ' + mon + ' ' + yyyy;
// }
export const getTodayYYYYMMDD = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return yyyy+'-'+mm+'-'+dd;
}
// clear storage
export const localStorageClear = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userData');
    localStorage.removeItem('refresh');
    localStorage.removeItem('fromLogin');
    localStorage.removeItem('cmplt');
    localStorage.removeItem('pid');
    localStorage.removeItem('mdralt'); // modulr dashboard alert notification
    localStorage.removeItem('penoff'); // offer is pending, then redirect user to view-offer page
    yapilyLocalStorageClear();
}
// clear yapily storage
export const yapilyLocalStorageClear = () => {
    localStorage.removeItem('yps'); // yapily popup popup view
    localStorage.removeItem('ypsex'); // yapily expired-consent popup view
    localStorage.removeItem('intId'); // yapily institution id store
    localStorage.removeItem('tokenData'); // yapily instituion details with token
    localStorage.removeItem('fromYDS'); // from yapily dashboard to yapily verify page check
}
// display last 4 character eg. XXXX1234
export const maskSecureString = (str) => {
    if(str) {
        return str.replace(/.(?=.{4})/g, 'X');
    }
    return str;
}
export const maskSecureString2 = (str) => {
    if(str) {
        return str.replace(/.(?=.{2})/g, 'X');
    }
    return str;
}
// encrypt and decrypt with crypto-js
export const encryptString = (str) => {
    var key = process.env.REACT_APP_AES_ENCRYPTION_KEY;
    var ciphertext = CryptoJS.AES.encrypt(str, key).toString();
    return ciphertext;
}
export const decryptString = (ciphertext) => {
    var key = process.env.REACT_APP_AES_ENCRYPTION_KEY;
    var bytes = CryptoJS.AES.decrypt(ciphertext, key);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}
export const decryptStringEmail = (text) => {
    var key = process.env.REACT_APP_AES_ENCRYPTION_KEY;
    var randomKey = process.env.REACT_APP_AES_ENCRYPTION_IV;
    key = CryptoJS.enc.Utf8.parse(key);
    var iv = CryptoJS.enc.Utf8.parse(randomKey);
    var bytes = CryptoJS.AES.decrypt(text, key, {iv:iv,mode: CryptoJS.mode.CBC});
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}
// formate comma saparated amount (10000 -> 10,000).
export const formatAmount = (amt) => {
    amt = amt ? Number((amt.toString()).replace(/[^0-9\.-]+/g,"")) : amt; // amt?(amt.toString()).replace(/\,/g,''):amt;
    return amt ? Number(amt).toLocaleString(undefined, { maximumFractionDigits: 2 }) : amt;
}

export const sicCodes = [{
    "Code": "01110",
    "Description": "Growing of cereals (except rice), leguminous crops and oil seeds "
  },
  {
    "Code": "01120",
    "Description": "Growing of rice "
  },
  {
    "Code": "01130",
    "Description": "Growing of vegetables and melons, roots and tubers "
  },
  {
    "Code": "01140",
    "Description": "Growing of sugar cane "
  },
  {
    "Code": "01150",
    "Description": "Growing of tobacco "
  },
  {
    "Code": "01160",
    "Description": "Growing of fibre crops "
  },
  {
    "Code": "01190",
    "Description": "Growing of other non-perennial crops "
  },
  {
    "Code": "01210",
    "Description": "Growing of grapes "
  },
  {
    "Code": "01220",
    "Description": "Growing of tropical and subtropical fruits "
  },
  {
    "Code": "01230",
    "Description": "Growing of citrus fruits "
  },
  {
    "Code": "01240",
    "Description": "Growing of pome fruits and stone fruits "
  },
  {
    "Code": "01250",
    "Description": "Growing of other tree and bush fruits and nuts "
  },
  {
    "Code": "01260",
    "Description": "Growing of oleaginous fruits "
  },
  {
    "Code": "01270",
    "Description": "Growing of beverage crops "
  },
  {
    "Code": "01280",
    "Description": "Growing of spices, aromatic, drug and pharmaceutical crops "
  },
  {
    "Code": "01290",
    "Description": "Growing of other perennial crops "
  },
  {
    "Code": "01300",
    "Description": "Plant propagation "
  },
  {
    "Code": "01410",
    "Description": "Raising of dairy cattle "
  },
  {
    "Code": "01420",
    "Description": "Raising of other cattle and buffaloes "
  },
  {
    "Code": "01430",
    "Description": "Raising of horses and other equines "
  },
  {
    "Code": "01440",
    "Description": "Raising of camels and camelids "
  },
  {
    "Code": "01450",
    "Description": "Raising of sheep and goats "
  },
  {
    "Code": "01460",
    "Description": "Raising of swine/pigs "
  },
  {
    "Code": "01470",
    "Description": "Raising of poultry "
  },
  {
    "Code": "01490",
    "Description": "Raising of other animals "
  },
  {
    "Code": "01500",
    "Description": "Mixed farming "
  },
  {
    "Code": "01610",
    "Description": "Support activities for crop production "
  },
  {
    "Code": "01621",
    "Description": "Farm animal boarding and care "
  },
  {
    "Code": "01629",
    "Description": "Support activities for animal production (other than farm animal boarding and care) n.e.c. "
  },
  {
    "Code": "01630",
    "Description": "Post-harvest crop activities "
  },
  {
    "Code": "01640",
    "Description": "Seed processing for propagation "
  },
  {
    "Code": "01700",
    "Description": "Hunting, trapping and related service activities "
  },
  {
    "Code": "02100",
    "Description": "Silviculture and other forestry activities "
  },
  {
    "Code": "02200",
    "Description": "Logging "
  },
  {
    "Code": "02300",
    "Description": "Gathering of wild growing non-wood products "
  },
  {
    "Code": "02400",
    "Description": "Support services to forestry "
  },
  {
    "Code": "03110",
    "Description": "Marine fishing "
  },
  {
    "Code": "03120",
    "Description": "Freshwater fishing "
  },
  {
    "Code": "03210",
    "Description": "Marine aquaculture "
  },
  {
    "Code": "03220",
    "Description": "Freshwater aquaculture "
  },
  {
    "Code": "05101",
    "Description": "Deep coal mines "
  },
  {
    "Code": "05102",
    "Description": "Open cast coal working "
  },
  {
    "Code": "05200",
    "Description": "Mining of lignite "
  },
  {
    "Code": "06100",
    "Description": "Extraction of crude petroleum "
  },
  {
    "Code": "06200",
    "Description": "Extraction of natural gas "
  },
  {
    "Code": "07100",
    "Description": "Mining of iron ores "
  },
  {
    "Code": "07210",
    "Description": "Mining of uranium and thorium ores "
  },
  {
    "Code": "07290",
    "Description": "Mining of other non-ferrous metal ores "
  },
  {
    "Code": "08110",
    "Description": "Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate "
  },
  {
    "Code": "08120",
    "Description": "Operation of gravel and sand pits; mining of clays and kaolin "
  },
  {
    "Code": "08910",
    "Description": "Mining of chemical and fertilizer minerals "
  },
  {
    "Code": "08920",
    "Description": "Extraction of peat "
  },
  {
    "Code": "08930",
    "Description": "Extraction of salt "
  },
  {
    "Code": "08990",
    "Description": "Other mining and quarrying n.e.c. "
  },
  {
    "Code": "09100",
    "Description": "Support activities for petroleum and natural gas mining "
  },
  {
    "Code": "09900",
    "Description": "Support activities for other mining and quarrying "
  },
  {
    "Code": "10110",
    "Description": "Processing and preserving of meat "
  },
  {
    "Code": "10120",
    "Description": "Processing and preserving of poultry meat "
  },
  {
    "Code": "10130",
    "Description": "Production of meat and poultry meat products "
  },
  {
    "Code": "10200",
    "Description": "Processing and preserving of fish, crustaceans and molluscs "
  },
  {
    "Code": "10310",
    "Description": "Processing and preserving of potatoes "
  },
  {
    "Code": "10320",
    "Description": "Manufacture of fruit and vegetable juice "
  },
  {
    "Code": "10390",
    "Description": "Other processing and preserving of fruit and vegetables "
  },
  {
    "Code": "10410",
    "Description": "Manufacture of oils and fats "
  },
  {
    "Code": "10420",
    "Description": "Manufacture of margarine and similar edible fats "
  },
  {
    "Code": "10511",
    "Description": "Liquid milk and cream production "
  },
  {
    "Code": "10512",
    "Description": "Butter and cheese production "
  },
  {
    "Code": "10519",
    "Description": "Manufacture of other milk products "
  },
  {
    "Code": "10520",
    "Description": "Manufacture of ice cream "
  },
  {
    "Code": "10611",
    "Description": "Grain milling "
  },
  {
    "Code": "10612",
    "Description": "Manufacture of breakfast cereals and cereals-based food "
  },
  {
    "Code": "10620",
    "Description": "Manufacture of starches and starch products "
  },
  {
    "Code": "10710",
    "Description": "Manufacture of bread; manufacture of fresh pastry goods and cakes "
  },
  {
    "Code": "10720",
    "Description": "Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes "
  },
  {
    "Code": "10730",
    "Description": "Manufacture of macaroni, noodles, couscous and similar farinaceous products "
  },
  {
    "Code": "10810",
    "Description": "Manufacture of sugar "
  },
  {
    "Code": "10821",
    "Description": "Manufacture of cocoa and chocolate confectionery "
  },
  {
    "Code": "10822",
    "Description": "Manufacture of sugar confectionery "
  },
  {
    "Code": "10831",
    "Description": "Tea processing "
  },
  {
    "Code": "10832",
    "Description": "Production of coffee and coffee substitutes "
  },
  {
    "Code": "10840",
    "Description": "Manufacture of condiments and seasonings "
  },
  {
    "Code": "10850",
    "Description": "Manufacture of prepared meals and dishes "
  },
  {
    "Code": "10860",
    "Description": "Manufacture of homogenized food preparations and dietetic food "
  },
  {
    "Code": "10890",
    "Description": "Manufacture of other food products n.e.c. "
  },
  {
    "Code": "10910",
    "Description": "Manufacture of prepared feeds for farm animals "
  },
  {
    "Code": "10920",
    "Description": "Manufacture of prepared pet foods "
  },
  {
    "Code": "11010",
    "Description": "Distilling, rectifying and blending of spirits "
  },
  {
    "Code": "11020",
    "Description": "Manufacture of wine from grape "
  },
  {
    "Code": "11030",
    "Description": "Manufacture of cider and other fruit wines "
  },
  {
    "Code": "11040",
    "Description": "Manufacture of other non-distilled fermented beverages "
  },
  {
    "Code": "11050",
    "Description": "Manufacture of beer "
  },
  {
    "Code": "11060",
    "Description": "Manufacture of malt "
  },
  {
    "Code": "11070",
    "Description": "Manufacture of soft drinks; production of mineral waters and other bottled waters "
  },
  {
    "Code": "12000",
    "Description": "Manufacture of tobacco products "
  },
  {
    "Code": "13100",
    "Description": "Preparation and spinning of textile fibres "
  },
  {
    "Code": "13200",
    "Description": "Weaving of textiles "
  },
  {
    "Code": "13300",
    "Description": "Finishing of textiles "
  },
  {
    "Code": "13910",
    "Description": "Manufacture of knitted and crocheted fabrics "
  },
  {
    "Code": "13921",
    "Description": "Manufacture of soft furnishings "
  },
  {
    "Code": "13922",
    "Description": "manufacture of canvas goods, sacks, etc. "
  },
  {
    "Code": "13923",
    "Description": "manufacture of household textiles "
  },
  {
    "Code": "13931",
    "Description": "Manufacture of woven or tufted carpets and rugs "
  },
  {
    "Code": "13939",
    "Description": "Manufacture of other carpets and rugs "
  },
  {
    "Code": "13940",
    "Description": "Manufacture of cordage, rope, twine and netting "
  },
  {
    "Code": "13950",
    "Description": "Manufacture of non-wovens and articles made from non-wovens, except apparel "
  },
  {
    "Code": "13960",
    "Description": "Manufacture of other technical and industrial textiles "
  },
  {
    "Code": "13990",
    "Description": "Manufacture of other textiles n.e.c. "
  },
  {
    "Code": "14110",
    "Description": "Manufacture of leather clothes "
  },
  {
    "Code": "14120",
    "Description": "Manufacture of workwear "
  },
  {
    "Code": "14131",
    "Description": "Manufacture of other men's outerwear "
  },
  {
    "Code": "14132",
    "Description": "Manufacture of other women's outerwear "
  },
  {
    "Code": "14141",
    "Description": "Manufacture of men's underwear "
  },
  {
    "Code": "14142",
    "Description": "Manufacture of women's underwear "
  },
  {
    "Code": "14190",
    "Description": "Manufacture of other wearing apparel and accessories n.e.c. "
  },
  {
    "Code": "14200",
    "Description": "Manufacture of articles of fur "
  },
  {
    "Code": "14310",
    "Description": "Manufacture of knitted and crocheted hosiery "
  },
  {
    "Code": "14390",
    "Description": "Manufacture of other knitted and crocheted apparel "
  },
  {
    "Code": "15110",
    "Description": "Tanning and dressing of leather; dressing and dyeing of fur "
  },
  {
    "Code": "15120",
    "Description": "Manufacture of luggage, handbags and the like, saddlery and harness "
  },
  {
    "Code": "15200",
    "Description": "Manufacture of footwear "
  },
  {
    "Code": "16100",
    "Description": "Sawmilling and planing of wood "
  },
  {
    "Code": "16210",
    "Description": "Manufacture of veneer sheets and wood-based panels "
  },
  {
    "Code": "16220",
    "Description": "Manufacture of assembled parquet floors "
  },
  {
    "Code": "16230",
    "Description": "Manufacture of other builders' carpentry and joinery "
  },
  {
    "Code": "16240",
    "Description": "Manufacture of wooden containers "
  },
  {
    "Code": "16290",
    "Description": "Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials "
  },
  {
    "Code": "17110",
    "Description": "Manufacture of pulp "
  },
  {
    "Code": "17120",
    "Description": "Manufacture of paper and paperboard "
  },
  {
    "Code": "17211",
    "Description": "Manufacture of corrugated paper and paperboard, sacks and bags "
  },
  {
    "Code": "17219",
    "Description": "Manufacture of other paper and paperboard containers "
  },
  {
    "Code": "17220",
    "Description": "Manufacture of household and sanitary goods and of toilet requisites "
  },
  {
    "Code": "17230",
    "Description": "Manufacture of paper stationery "
  },
  {
    "Code": "17240",
    "Description": "Manufacture of wallpaper "
  },
  {
    "Code": "17290",
    "Description": "Manufacture of other articles of paper and paperboard n.e.c. "
  },
  {
    "Code": "18110",
    "Description": "Printing of newspapers "
  },
  {
    "Code": "18121",
    "Description": "Manufacture of printed labels "
  },
  {
    "Code": "18129",
    "Description": "Printing n.e.c. "
  },
  {
    "Code": "18130",
    "Description": "Pre-press and pre-media services "
  },
  {
    "Code": "18140",
    "Description": "Binding and related services "
  },
  {
    "Code": "18201",
    "Description": "Reproduction of sound recording "
  },
  {
    "Code": "18202",
    "Description": "Reproduction of video recording "
  },
  {
    "Code": "18203",
    "Description": "Reproduction of computer media "
  },
  {
    "Code": "19100",
    "Description": "Manufacture of coke oven products "
  },
  {
    "Code": "19201",
    "Description": "Mineral oil refining "
  },
  {
    "Code": "19209",
    "Description": "Other treatment of petroleum products (excluding petrochemicals manufacture) "
  },
  {
    "Code": "20110",
    "Description": "Manufacture of industrial gases "
  },
  {
    "Code": "20120",
    "Description": "Manufacture of dyes and pigments "
  },
  {
    "Code": "20130",
    "Description": "Manufacture of other inorganic basic chemicals "
  },
  {
    "Code": "20140",
    "Description": "Manufacture of other organic basic chemicals "
  },
  {
    "Code": "20150",
    "Description": "Manufacture of fertilizers and nitrogen compounds "
  },
  {
    "Code": "20160",
    "Description": "Manufacture of plastics in primary forms "
  },
  {
    "Code": "20170",
    "Description": "Manufacture of synthetic rubber in primary forms "
  },
  {
    "Code": "20200",
    "Description": "Manufacture of pesticides and other agrochemical products "
  },
  {
    "Code": "20301",
    "Description": "Manufacture of paints, varnishes and similar coatings, mastics and sealants "
  },
  {
    "Code": "20302",
    "Description": "Manufacture of printing ink "
  },
  {
    "Code": "20411",
    "Description": "Manufacture of soap and detergents "
  },
  {
    "Code": "20412",
    "Description": "Manufacture of cleaning and polishing preparations "
  },
  {
    "Code": "20420",
    "Description": "Manufacture of perfumes and toilet preparations "
  },
  {
    "Code": "20510",
    "Description": "Manufacture of explosives "
  },
  {
    "Code": "20520",
    "Description": "Manufacture of glues "
  },
  {
    "Code": "20530",
    "Description": "Manufacture of essential oils "
  },
  {
    "Code": "20590",
    "Description": "Manufacture of other chemical products n.e.c. "
  },
  {
    "Code": "20600",
    "Description": "Manufacture of man-made fibres "
  },
  {
    "Code": "21100",
    "Description": "Manufacture of basic pharmaceutical products "
  },
  {
    "Code": "21200",
    "Description": "Manufacture of pharmaceutical preparations "
  },
  {
    "Code": "22110",
    "Description": "Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres "
  },
  {
    "Code": "22190",
    "Description": "Manufacture of other rubber products "
  },
  {
    "Code": "22210",
    "Description": "Manufacture of plastic plates, sheets, tubes and profiles "
  },
  {
    "Code": "22220",
    "Description": "Manufacture of plastic packing goods "
  },
  {
    "Code": "22230",
    "Description": "Manufacture of builders ware of plastic "
  },
  {
    "Code": "22290",
    "Description": "Manufacture of other plastic products "
  },
  {
    "Code": "23110",
    "Description": "Manufacture of flat glass "
  },
  {
    "Code": "23120",
    "Description": "Shaping and processing of flat glass "
  },
  {
    "Code": "23130",
    "Description": "Manufacture of hollow glass "
  },
  {
    "Code": "23140",
    "Description": "Manufacture of glass fibres "
  },
  {
    "Code": "23190",
    "Description": "Manufacture and processing of other glass, including technical glassware "
  },
  {
    "Code": "23200",
    "Description": "Manufacture of refractory products "
  },
  {
    "Code": "23310",
    "Description": "Manufacture of ceramic tiles and flags "
  },
  {
    "Code": "23320",
    "Description": "Manufacture of bricks, tiles and construction products, in baked clay "
  },
  {
    "Code": "23410",
    "Description": "Manufacture of ceramic household and ornamental articles "
  },
  {
    "Code": "23420",
    "Description": "Manufacture of ceramic sanitary fixtures "
  },
  {
    "Code": "23430",
    "Description": "Manufacture of ceramic insulators and insulating fittings "
  },
  {
    "Code": "23440",
    "Description": "Manufacture of other technical ceramic products "
  },
  {
    "Code": "23490",
    "Description": "Manufacture of other ceramic products n.e.c. "
  },
  {
    "Code": "23510",
    "Description": "Manufacture of cement "
  },
  {
    "Code": "23520",
    "Description": "Manufacture of lime and plaster "
  },
  {
    "Code": "23610",
    "Description": "Manufacture of concrete products for construction purposes "
  },
  {
    "Code": "23620",
    "Description": "Manufacture of plaster products for construction purposes "
  },
  {
    "Code": "23630",
    "Description": "Manufacture of ready-mixed concrete "
  },
  {
    "Code": "23640",
    "Description": "Manufacture of mortars "
  },
  {
    "Code": "23650",
    "Description": "Manufacture of fibre cement "
  },
  {
    "Code": "23690",
    "Description": "Manufacture of other articles of concrete, plaster and cement "
  },
  {
    "Code": "23700",
    "Description": "Cutting, shaping and finishing of stone "
  },
  {
    "Code": "23910",
    "Description": "Production of abrasive products "
  },
  {
    "Code": "23990",
    "Description": "Manufacture of other non-metallic mineral products n.e.c. "
  },
  {
    "Code": "24100",
    "Description": "Manufacture of basic iron and steel and of ferro-alloys "
  },
  {
    "Code": "24200",
    "Description": "Manufacture of tubes, pipes, hollow profiles and related fittings, of steel "
  },
  {
    "Code": "24310",
    "Description": "Cold drawing of bars "
  },
  {
    "Code": "24320",
    "Description": "Cold rolling of narrow strip "
  },
  {
    "Code": "24330",
    "Description": "Cold forming or folding "
  },
  {
    "Code": "24340",
    "Description": "Cold drawing of wire "
  },
  {
    "Code": "24410",
    "Description": "Precious metals production "
  },
  {
    "Code": "24420",
    "Description": "Aluminium production "
  },
  {
    "Code": "24430",
    "Description": "Lead, zinc and tin production "
  },
  {
    "Code": "24440",
    "Description": "Copper production "
  },
  {
    "Code": "24450",
    "Description": "Other non-ferrous metal production "
  },
  {
    "Code": "24460",
    "Description": "Processing of nuclear fuel "
  },
  {
    "Code": "24510",
    "Description": "Casting of iron "
  },
  {
    "Code": "24520",
    "Description": "Casting of steel "
  },
  {
    "Code": "24530",
    "Description": "Casting of light metals "
  },
  {
    "Code": "24540",
    "Description": "Casting of other non-ferrous metals "
  },
  {
    "Code": "25110",
    "Description": "Manufacture of metal structures and parts of structures "
  },
  {
    "Code": "25120",
    "Description": "Manufacture of doors and windows of metal "
  },
  {
    "Code": "25210",
    "Description": "Manufacture of central heating radiators and boilers "
  },
  {
    "Code": "25290",
    "Description": "Manufacture of other tanks, reservoirs and containers of metal "
  },
  {
    "Code": "25300",
    "Description": "Manufacture of steam generators, except central heating hot water boilers "
  },
  {
    "Code": "25400",
    "Description": "Manufacture of weapons and ammunition "
  },
  {
    "Code": "25500",
    "Description": "Forging, pressing, stamping and roll-forming of metal; powder metallurgy "
  },
  {
    "Code": "25610",
    "Description": "Treatment and coating of metals "
  },
  {
    "Code": "25620",
    "Description": "Machining "
  },
  {
    "Code": "25710",
    "Description": "Manufacture of cutlery "
  },
  {
    "Code": "25720",
    "Description": "Manufacture of locks and hinges "
  },
  {
    "Code": "25730",
    "Description": "Manufacture of tools "
  },
  {
    "Code": "25910",
    "Description": "Manufacture of steel drums and similar containers "
  },
  {
    "Code": "25920",
    "Description": "Manufacture of light metal packaging "
  },
  {
    "Code": "25930",
    "Description": "Manufacture of wire products, chain and springs "
  },
  {
    "Code": "25940",
    "Description": "Manufacture of fasteners and screw machine products "
  },
  {
    "Code": "25990",
    "Description": "Manufacture of other fabricated metal products n.e.c. "
  },
  {
    "Code": "26110",
    "Description": "Manufacture of electronic components "
  },
  {
    "Code": "26120",
    "Description": "Manufacture of loaded electronic boards "
  },
  {
    "Code": "26200",
    "Description": "Manufacture of computers and peripheral equipment "
  },
  {
    "Code": "26301",
    "Description": "Manufacture of telegraph and telephone apparatus and equipment "
  },
  {
    "Code": "26309",
    "Description": "Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment "
  },
  {
    "Code": "26400",
    "Description": "Manufacture of consumer electronics "
  },
  {
    "Code": "26511",
    "Description": "Manufacture of electronic measuring, testing etc. equipment, not for industrial process control "
  },
  {
    "Code": "26512",
    "Description": "Manufacture of electronic industrial process control equipment "
  },
  {
    "Code": "26513",
    "Description": "Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control "
  },
  {
    "Code": "26514",
    "Description": "Manufacture of non-electronic industrial process control equipment "
  },
  {
    "Code": "26520",
    "Description": "Manufacture of watches and clocks "
  },
  {
    "Code": "26600",
    "Description": "Manufacture of irradiation, electromedical and electrotherapeutic equipment "
  },
  {
    "Code": "26701",
    "Description": "Manufacture of optical precision instruments "
  },
  {
    "Code": "26702",
    "Description": "Manufacture of photographic and cinematographic equipment "
  },
  {
    "Code": "26800",
    "Description": "Manufacture of magnetic and optical media "
  },
  {
    "Code": "27110",
    "Description": "Manufacture of electric motors, generators and transformers "
  },
  {
    "Code": "27120",
    "Description": "Manufacture of electricity distribution and control apparatus "
  },
  {
    "Code": "27200",
    "Description": "Manufacture of batteries and accumulators "
  },
  {
    "Code": "27310",
    "Description": "Manufacture of fibre optic cables "
  },
  {
    "Code": "27320",
    "Description": "Manufacture of other electronic and electric wires and cables "
  },
  {
    "Code": "27330",
    "Description": "Manufacture of wiring devices "
  },
  {
    "Code": "27400",
    "Description": "Manufacture of electric lighting equipment "
  },
  {
    "Code": "27510",
    "Description": "Manufacture of electric domestic appliances "
  },
  {
    "Code": "27520",
    "Description": "Manufacture of non-electric domestic appliances "
  },
  {
    "Code": "27900",
    "Description": "Manufacture of other electrical equipment "
  },
  {
    "Code": "28110",
    "Description": "Manufacture of engines and turbines, except aircraft, vehicle and cycle engines "
  },
  {
    "Code": "28120",
    "Description": "Manufacture of fluid power equipment "
  },
  {
    "Code": "28131",
    "Description": "Manufacture of pumps "
  },
  {
    "Code": "28132",
    "Description": "Manufacture of compressors "
  },
  {
    "Code": "28140",
    "Description": "Manufacture of taps and valves "
  },
  {
    "Code": "28150",
    "Description": "Manufacture of bearings, gears, gearing and driving elements "
  },
  {
    "Code": "28210",
    "Description": "Manufacture of ovens, furnaces and furnace burners "
  },
  {
    "Code": "28220",
    "Description": "Manufacture of lifting and handling equipment "
  },
  {
    "Code": "28230",
    "Description": "Manufacture of office machinery and equipment (except computers and peripheral equipment) "
  },
  {
    "Code": "28240",
    "Description": "Manufacture of power-driven hand tools "
  },
  {
    "Code": "28250",
    "Description": "Manufacture of non-domestic cooling and ventilation equipment "
  },
  {
    "Code": "28290",
    "Description": "Manufacture of other general-purpose machinery n.e.c. "
  },
  {
    "Code": "28301",
    "Description": "Manufacture of agricultural tractors "
  },
  {
    "Code": "28302",
    "Description": "Manufacture of agricultural and forestry machinery other than tractors "
  },
  {
    "Code": "28410",
    "Description": "Manufacture of metal forming machinery "
  },
  {
    "Code": "28490",
    "Description": "Manufacture of other machine tools "
  },
  {
    "Code": "28910",
    "Description": "Manufacture of machinery for metallurgy "
  },
  {
    "Code": "28921",
    "Description": "Manufacture of machinery for mining "
  },
  {
    "Code": "28922",
    "Description": "Manufacture of earthmoving equipment "
  },
  {
    "Code": "28923",
    "Description": "Manufacture of equipment for concrete crushing and screening and roadworks "
  },
  {
    "Code": "28930",
    "Description": "Manufacture of machinery for food, beverage and tobacco processing "
  },
  {
    "Code": "28940",
    "Description": "Manufacture of machinery for textile, apparel and leather production "
  },
  {
    "Code": "28950",
    "Description": "Manufacture of machinery for paper and paperboard production "
  },
  {
    "Code": "28960",
    "Description": "Manufacture of plastics and rubber machinery "
  },
  {
    "Code": "28990",
    "Description": "Manufacture of other special-purpose machinery n.e.c. "
  },
  {
    "Code": "29100",
    "Description": "Manufacture of motor vehicles "
  },
  {
    "Code": "29201",
    "Description": "Manufacture of bodies (coachwork) for motor vehicles (except caravans) "
  },
  {
    "Code": "29202",
    "Description": "Manufacture of trailers and semi-trailers "
  },
  {
    "Code": "29203",
    "Description": "Manufacture of caravans "
  },
  {
    "Code": "29310",
    "Description": "Manufacture of electrical and electronic equipment for motor vehicles and their engines "
  },
  {
    "Code": "29320",
    "Description": "Manufacture of other parts and accessories for motor vehicles "
  },
  {
    "Code": "30110",
    "Description": "Building of ships and floating structures "
  },
  {
    "Code": "30120",
    "Description": "Building of pleasure and sporting boats "
  },
  {
    "Code": "30200",
    "Description": "Manufacture of railway locomotives and rolling stock "
  },
  {
    "Code": "30300",
    "Description": "Manufacture of air and spacecraft and related machinery "
  },
  {
    "Code": "30400",
    "Description": "Manufacture of military fighting vehicles "
  },
  {
    "Code": "30910",
    "Description": "Manufacture of motorcycles "
  },
  {
    "Code": "30920",
    "Description": "Manufacture of bicycles and invalid carriages "
  },
  {
    "Code": "30990",
    "Description": "Manufacture of other transport equipment n.e.c. "
  },
  {
    "Code": "31010",
    "Description": "Manufacture of office and shop furniture "
  },
  {
    "Code": "31020",
    "Description": "Manufacture of kitchen furniture "
  },
  {
    "Code": "31030",
    "Description": "Manufacture of mattresses "
  },
  {
    "Code": "31090",
    "Description": "Manufacture of other furniture "
  },
  {
    "Code": "32110",
    "Description": "Striking of coins "
  },
  {
    "Code": "32120",
    "Description": "Manufacture of jewellery and related articles "
  },
  {
    "Code": "32130",
    "Description": "Manufacture of imitation jewellery and related articles "
  },
  {
    "Code": "32200",
    "Description": "Manufacture of musical instruments "
  },
  {
    "Code": "32300",
    "Description": "Manufacture of sports goods "
  },
  {
    "Code": "32401",
    "Description": "Manufacture of professional and arcade games and toys "
  },
  {
    "Code": "32409",
    "Description": "Manufacture of other games and toys, n.e.c. "
  },
  {
    "Code": "32500",
    "Description": "Manufacture of medical and dental instruments and supplies "
  },
  {
    "Code": "32910",
    "Description": "Manufacture of brooms and brushes "
  },
  {
    "Code": "32990",
    "Description": "Other manufacturing n.e.c. "
  },
  {
    "Code": "33110",
    "Description": "Repair of fabricated metal products "
  },
  {
    "Code": "33120",
    "Description": "Repair of machinery "
  },
  {
    "Code": "33130",
    "Description": "Repair of electronic and optical equipment "
  },
  {
    "Code": "33140",
    "Description": "Repair of electrical equipment "
  },
  {
    "Code": "33150",
    "Description": "Repair and maintenance of ships and boats "
  },
  {
    "Code": "33160",
    "Description": "Repair and maintenance of aircraft and spacecraft "
  },
  {
    "Code": "33170",
    "Description": "Repair and maintenance of other transport equipment n.e.c. "
  },
  {
    "Code": "33190",
    "Description": "Repair of other equipment "
  },
  {
    "Code": "33200",
    "Description": "Installation of industrial machinery and equipment "
  },
  {
    "Code": "35110",
    "Description": "Production of electricity "
  },
  {
    "Code": "35120",
    "Description": "Transmission of electricity "
  },
  {
    "Code": "35130",
    "Description": "Distribution of electricity "
  },
  {
    "Code": "35140",
    "Description": "Trade of electricity "
  },
  {
    "Code": "35210",
    "Description": "Manufacture of gas "
  },
  {
    "Code": "35220",
    "Description": "Distribution of gaseous fuels through mains "
  },
  {
    "Code": "35230",
    "Description": "Trade of gas through mains "
  },
  {
    "Code": "35300",
    "Description": "Steam and air conditioning supply "
  },
  {
    "Code": "36000",
    "Description": "Water collection, treatment and supply "
  },
  {
    "Code": "37000",
    "Description": "Sewerage "
  },
  {
    "Code": "38110",
    "Description": "Collection of non-hazardous waste "
  },
  {
    "Code": "38120",
    "Description": "Collection of hazardous waste "
  },
  {
    "Code": "38210",
    "Description": "Treatment and disposal of non-hazardous waste "
  },
  {
    "Code": "38220",
    "Description": "Treatment and disposal of hazardous waste "
  },
  {
    "Code": "38310",
    "Description": "Dismantling of wrecks "
  },
  {
    "Code": "38320",
    "Description": "Recovery of sorted materials "
  },
  {
    "Code": "39000",
    "Description": "Remediation activities and other waste management services "
  },
  {
    "Code": "41100",
    "Description": "Development of building projects "
  },
  {
    "Code": "41201",
    "Description": "Construction of commercial buildings "
  },
  {
    "Code": "41202",
    "Description": "Construction of domestic buildings "
  },
  {
    "Code": "42110",
    "Description": "Construction of roads and motorways "
  },
  {
    "Code": "42120",
    "Description": "Construction of railways and underground railways "
  },
  {
    "Code": "42130",
    "Description": "Construction of bridges and tunnels "
  },
  {
    "Code": "42210",
    "Description": "Construction of utility projects for fluids "
  },
  {
    "Code": "42220",
    "Description": "Construction of utility projects for electricity and telecommunications "
  },
  {
    "Code": "42910",
    "Description": "Construction of water projects "
  },
  {
    "Code": "42990",
    "Description": "Construction of other civil engineering projects n.e.c. "
  },
  {
    "Code": "43110",
    "Description": "Demolition "
  },
  {
    "Code": "43120",
    "Description": "Site preparation "
  },
  {
    "Code": "43130",
    "Description": "Test drilling and boring "
  },
  {
    "Code": "43210",
    "Description": "Electrical installation "
  },
  {
    "Code": "43220",
    "Description": "Plumbing, heat and air-conditioning installation "
  },
  {
    "Code": "43290",
    "Description": "Other construction installation "
  },
  {
    "Code": "43310",
    "Description": "Plastering "
  },
  {
    "Code": "43320",
    "Description": "Joinery installation "
  },
  {
    "Code": "43330",
    "Description": "Floor and wall covering "
  },
  {
    "Code": "43341",
    "Description": "Painting "
  },
  {
    "Code": "43342",
    "Description": "Glazing "
  },
  {
    "Code": "43390",
    "Description": "Other building completion and finishing "
  },
  {
    "Code": "43910",
    "Description": "Roofing activities "
  },
  {
    "Code": "43991",
    "Description": "Scaffold erection "
  },
  {
    "Code": "43999",
    "Description": "Other specialised construction activities n.e.c. "
  },
  {
    "Code": "45111",
    "Description": "Sale of new cars and light motor vehicles "
  },
  {
    "Code": "45112",
    "Description": "Sale of used cars and light motor vehicles "
  },
  {
    "Code": "45190",
    "Description": "Sale of other motor vehicles "
  },
  {
    "Code": "45200",
    "Description": "Maintenance and repair of motor vehicles "
  },
  {
    "Code": "45310",
    "Description": "Wholesale trade of motor vehicle parts and accessories "
  },
  {
    "Code": "45320",
    "Description": "Retail trade of motor vehicle parts and accessories "
  },
  {
    "Code": "45400",
    "Description": "Sale, maintenance and repair of motorcycles and related parts and accessories "
  },
  {
    "Code": "46110",
    "Description": "Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods "
  },
  {
    "Code": "46120",
    "Description": "Agents involved in the sale of fuels, ores, metals and industrial chemicals "
  },
  {
    "Code": "46130",
    "Description": "Agents involved in the sale of timber and building materials "
  },
  {
    "Code": "46140",
    "Description": "Agents involved in the sale of machinery, industrial equipment, ships and aircraft "
  },
  {
    "Code": "46150",
    "Description": "Agents involved in the sale of furniture, household goods, hardware and ironmongery "
  },
  {
    "Code": "46160",
    "Description": "Agents involved in the sale of textiles, clothing, fur, footwear and leather goods "
  },
  {
    "Code": "46170",
    "Description": "Agents involved in the sale of food, beverages and tobacco "
  },
  {
    "Code": "46180",
    "Description": "Agents specialised in the sale of other particular products "
  },
  {
    "Code": "46190",
    "Description": "Agents involved in the sale of a variety of goods "
  },
  {
    "Code": "46210",
    "Description": "Wholesale of grain, unmanufactured tobacco, seeds and animal feeds "
  },
  {
    "Code": "46220",
    "Description": "Wholesale of flowers and plants "
  },
  {
    "Code": "46230",
    "Description": "Wholesale of live animals "
  },
  {
    "Code": "46240",
    "Description": "Wholesale of hides, skins and leather "
  },
  {
    "Code": "46310",
    "Description": "Wholesale of fruit and vegetables "
  },
  {
    "Code": "46320",
    "Description": "Wholesale of meat and meat products "
  },
  {
    "Code": "46330",
    "Description": "Wholesale of dairy products, eggs and edible oils and fats "
  },
  {
    "Code": "46341",
    "Description": "Wholesale of fruit and vegetable juices, mineral water and soft drinks "
  },
  {
    "Code": "46342",
    "Description": "Wholesale of wine, beer, spirits and other alcoholic beverages "
  },
  {
    "Code": "46350",
    "Description": "Wholesale of tobacco products "
  },
  {
    "Code": "46360",
    "Description": "Wholesale of sugar and chocolate and sugar confectionery "
  },
  {
    "Code": "46370",
    "Description": "Wholesale of coffee, tea, cocoa and spices "
  },
  {
    "Code": "46380",
    "Description": "Wholesale of other food, including fish, crustaceans and molluscs "
  },
  {
    "Code": "46390",
    "Description": "Non-specialised wholesale of food, beverages and tobacco "
  },
  {
    "Code": "46410",
    "Description": "Wholesale of textiles "
  },
  {
    "Code": "46420",
    "Description": "Wholesale of clothing and footwear "
  },
  {
    "Code": "46431",
    "Description": "Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played "
  },
  {
    "Code": "46439",
    "Description": "Wholesale of radio, television goods &amp; electrical household appliances (other than records, tapes, CD's &amp; video tapes and the equipment used for playing them) "
  },
  {
    "Code": "46440",
    "Description": "Wholesale of china and glassware and cleaning materials "
  },
  {
    "Code": "46450",
    "Description": "Wholesale of perfume and cosmetics "
  },
  {
    "Code": "46460",
    "Description": "Wholesale of pharmaceutical goods "
  },
  {
    "Code": "46470",
    "Description": "Wholesale of furniture, carpets and lighting equipment "
  },
  {
    "Code": "46480",
    "Description": "Wholesale of watches and jewellery "
  },
  {
    "Code": "46491",
    "Description": "Wholesale of musical instruments "
  },
  {
    "Code": "46499",
    "Description": " Wholesale of household goods (other than musical instruments) n.e.c "
  },
  {
    "Code": "46510",
    "Description": "Wholesale of computers, computer peripheral equipment and software "
  },
  {
    "Code": "46520",
    "Description": "Wholesale of electronic and telecommunications equipment and parts "
  },
  {
    "Code": "46610",
    "Description": "Wholesale of agricultural machinery, equipment and supplies "
  },
  {
    "Code": "46620",
    "Description": "Wholesale of machine tools "
  },
  {
    "Code": "46630",
    "Description": "Wholesale of mining, construction and civil engineering machinery "
  },
  {
    "Code": "46640",
    "Description": "Wholesale of machinery for the textile industry and of sewing and knitting machines "
  },
  {
    "Code": "46650",
    "Description": "Wholesale of office furniture "
  },
  {
    "Code": "46660",
    "Description": "Wholesale of other office machinery and equipment "
  },
  {
    "Code": "46690",
    "Description": "Wholesale of other machinery and equipment "
  },
  {
    "Code": "46711",
    "Description": "Wholesale of petroleum and petroleum products "
  },
  {
    "Code": "46719",
    "Description": "Wholesale of other fuels and related products "
  },
  {
    "Code": "46720",
    "Description": "Wholesale of metals and metal ores "
  },
  {
    "Code": "46730",
    "Description": "Wholesale of wood, construction materials and sanitary equipment "
  },
  {
    "Code": "46740",
    "Description": "Wholesale of hardware, plumbing and heating equipment and supplies "
  },
  {
    "Code": "46750",
    "Description": "Wholesale of chemical products "
  },
  {
    "Code": "46760",
    "Description": "Wholesale of other intermediate products "
  },
  {
    "Code": "46770",
    "Description": "Wholesale of waste and scrap "
  },
  {
    "Code": "46900",
    "Description": "Non-specialised wholesale trade "
  },
  {
    "Code": "47110",
    "Description": "Retail sale in non-specialised stores with food, beverages or tobacco predominating "
  },
  {
    "Code": "47190",
    "Description": "Other retail sale in non-specialised stores "
  },
  {
    "Code": "47210",
    "Description": "Retail sale of fruit and vegetables in specialised stores "
  },
  {
    "Code": "47220",
    "Description": "Retail sale of meat and meat products in specialised stores "
  },
  {
    "Code": "47230",
    "Description": "Retail sale of fish, crustaceans and molluscs in specialised stores "
  },
  {
    "Code": "47240",
    "Description": "Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores "
  },
  {
    "Code": "47250",
    "Description": "Retail sale of beverages in specialised stores "
  },
  {
    "Code": "47260",
    "Description": "Retail sale of tobacco products in specialised stores "
  },
  {
    "Code": "47290",
    "Description": "Other retail sale of food in specialised stores "
  },
  {
    "Code": "47300",
    "Description": "Retail sale of automotive fuel in specialised stores "
  },
  {
    "Code": "47410",
    "Description": "Retail sale of computers, peripheral units and software in specialised stores "
  },
  {
    "Code": "47421",
    "Description": "Retail sale of mobile telephones "
  },
  {
    "Code": "47429",
    "Description": "Retail sale of telecommunications equipment other than mobile telephones "
  },
  {
    "Code": "47430",
    "Description": "Retail sale of audio and video equipment in specialised stores "
  },
  {
    "Code": "47510",
    "Description": "Retail sale of textiles in specialised stores "
  },
  {
    "Code": "47520",
    "Description": "Retail sale of hardware, paints and glass in specialised stores "
  },
  {
    "Code": "47530",
    "Description": "Retail sale of carpets, rugs, wall and floor coverings in specialised stores "
  },
  {
    "Code": "47540",
    "Description": "Retail sale of electrical household appliances in specialised stores "
  },
  {
    "Code": "47591",
    "Description": "Retail sale of musical instruments and scores "
  },
  {
    "Code": "47599",
    "Description": "Retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store "
  },
  {
    "Code": "47610",
    "Description": "Retail sale of books in specialised stores "
  },
  {
    "Code": "47620",
    "Description": "Retail sale of newspapers and stationery in specialised stores "
  },
  {
    "Code": "47630",
    "Description": "Retail sale of music and video recordings in specialised stores "
  },
  {
    "Code": "47640",
    "Description": "Retail sale of sports goods, fishing gear, camping goods, boats and bicycles "
  },
  {
    "Code": "47650",
    "Description": "Retail sale of games and toys in specialised stores "
  },
  {
    "Code": "47710",
    "Description": "Retail sale of clothing in specialised stores "
  },
  {
    "Code": "47721",
    "Description": "Retail sale of footwear in specialised stores "
  },
  {
    "Code": "47722",
    "Description": "Retail sale of leather goods in specialised stores "
  },
  {
    "Code": "47730",
    "Description": "Dispensing chemist in specialised stores "
  },
  {
    "Code": "47741",
    "Description": "Retail sale of hearing aids "
  },
  {
    "Code": "47749",
    "Description": "Retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c. "
  },
  {
    "Code": "47750",
    "Description": "Retail sale of cosmetic and toilet articles in specialised stores "
  },
  {
    "Code": "47760",
    "Description": "Retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores "
  },
  {
    "Code": "47770",
    "Description": "Retail sale of watches and jewellery in specialised stores "
  },
  {
    "Code": "47781",
    "Description": "Retail sale in commercial art galleries "
  },
  {
    "Code": "47782",
    "Description": "Retail sale by opticians "
  },
  {
    "Code": "47789",
    "Description": "Other retail sale of new goods in specialised stores (not commercial art galleries and opticians) "
  },
  {
    "Code": "47791",
    "Description": "Retail sale of antiques including antique books in stores "
  },
  {
    "Code": "47799",
    "Description": "Retail sale of other second-hand goods in stores (not incl. antiques) "
  },
  {
    "Code": "47810",
    "Description": "Retail sale via stalls and markets of food, beverages and tobacco products "
  },
  {
    "Code": "47820",
    "Description": "Retail sale via stalls and markets of textiles, clothing and footwear "
  },
  {
    "Code": "47890",
    "Description": "Retail sale via stalls and markets of other goods "
  },
  {
    "Code": "47910",
    "Description": "Retail sale via mail order houses or via Internet "
  },
  {
    "Code": "47990",
    "Description": "Other retail sale not in stores, stalls or markets "
  },
  {
    "Code": "49100",
    "Description": "Passenger rail transport, interurban "
  },
  {
    "Code": "49200",
    "Description": "Freight rail transport "
  },
  {
    "Code": "49311",
    "Description": "Urban and suburban passenger railway transportation by underground, metro and similar systems "
  },
  {
    "Code": "49319",
    "Description": "Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar) "
  },
  {
    "Code": "49320",
    "Description": "Taxi operation "
  },
  {
    "Code": "49390",
    "Description": "Other passenger land transport "
  },
  {
    "Code": "49410",
    "Description": "Freight transport by road "
  },
  {
    "Code": "49420",
    "Description": "Removal services "
  },
  {
    "Code": "49500",
    "Description": "Transport via pipeline "
  },
  {
    "Code": "50100",
    "Description": "Sea and coastal passenger water transport "
  },
  {
    "Code": "50200",
    "Description": "Sea and coastal freight water transport "
  },
  {
    "Code": "50300",
    "Description": "Inland passenger water transport "
  },
  {
    "Code": "50400",
    "Description": "Inland freight water transport "
  },
  {
    "Code": "51101",
    "Description": "Scheduled passenger air transport "
  },
  {
    "Code": "51102",
    "Description": "Non-scheduled passenger air transport "
  },
  {
    "Code": "51210",
    "Description": "Freight air transport "
  },
  {
    "Code": "51220",
    "Description": "Space transport "
  },
  {
    "Code": "52101",
    "Description": "Operation of warehousing and storage facilities for water transport activities "
  },
  {
    "Code": "52102",
    "Description": "Operation of warehousing and storage facilities for air transport activities "
  },
  {
    "Code": "52103",
    "Description": "Operation of warehousing and storage facilities for land transport activities "
  },
  {
    "Code": "52211",
    "Description": "Operation of rail freight terminals "
  },
  {
    "Code": "52212",
    "Description": "Operation of rail passenger facilities at railway stations "
  },
  {
    "Code": "52213",
    "Description": "Operation of bus and coach passenger facilities at bus and coach stations "
  },
  {
    "Code": "52219",
    "Description": "Other service activities incidental to land transportation, n.e.c. "
  },
  {
    "Code": "52220",
    "Description": "Service activities incidental to water transportation "
  },
  {
    "Code": "52230",
    "Description": "Service activities incidental to air transportation "
  },
  {
    "Code": "52241",
    "Description": "Cargo handling for water transport activities "
  },
  {
    "Code": "52242",
    "Description": "Cargo handling for air transport activities "
  },
  {
    "Code": "52243",
    "Description": "Cargo handling for land transport activities "
  },
  {
    "Code": "52290",
    "Description": "Other transportation support activities "
  },
  {
    "Code": "53100",
    "Description": "Postal activities under universal service obligation "
  },
  {
    "Code": "53201",
    "Description": "Licensed carriers"
  },
  {
    "Code": "53202",
    "Description": "Unlicensed carriers"
  },
  {
    "Code": "55100",
    "Description": "Hotels and similar accommodation "
  },
  {
    "Code": "55201",
    "Description": "Holiday centres and villages "
  },
  {
    "Code": "55202",
    "Description": "Youth hostels "
  },
  {
    "Code": "55209",
    "Description": "Other holiday and other collective accommodation "
  },
  {
    "Code": "55300",
    "Description": "Recreational vehicle parks, trailer parks and camping grounds "
  },
  {
    "Code": "55900",
    "Description": "Other accommodation "
  },
  {
    "Code": "56101",
    "Description": "Licenced restaurants "
  },
  {
    "Code": "56102",
    "Description": "Unlicenced restaurants and cafes "
  },
  {
    "Code": "56103",
    "Description": "Take-away food shops and mobile food stands "
  },
  {
    "Code": "56210",
    "Description": "Event catering activities "
  },
  {
    "Code": "56290",
    "Description": "Other food services "
  },
  {
    "Code": "56301",
    "Description": "Licenced clubs "
  },
  {
    "Code": "56302",
    "Description": "Public houses and bars "
  },
  {
    "Code": "58110",
    "Description": "Book publishing "
  },
  {
    "Code": "58120",
    "Description": "Publishing of directories and mailing lists "
  },
  {
    "Code": "58130",
    "Description": "Publishing of newspapers "
  },
  {
    "Code": "58141",
    "Description": "Publishing of learned journals "
  },
  {
    "Code": "58142",
    "Description": "Publishing of consumer and business journals and periodicals "
  },
  {
    "Code": "58190",
    "Description": "Other publishing activities "
  },
  {
    "Code": "58210",
    "Description": "Publishing of computer games "
  },
  {
    "Code": "58290",
    "Description": "Other software publishing "
  },
  {
    "Code": "59111",
    "Description": "Motion picture production activities "
  },
  {
    "Code": "59112",
    "Description": "Video production activities "
  },
  {
    "Code": "59113",
    "Description": "Television programme production activities "
  },
  {
    "Code": "59120",
    "Description": "Motion picture, video and television programme post-production activities "
  },
  {
    "Code": "59131",
    "Description": "Motion picture distribution activities "
  },
  {
    "Code": "59132",
    "Description": "Video distribution activities "
  },
  {
    "Code": "59133",
    "Description": "Television programme distribution activities "
  },
  {
    "Code": "59140",
    "Description": "Motion picture projection activities "
  },
  {
    "Code": "59200",
    "Description": "Sound recording and music publishing activities "
  },
  {
    "Code": "60100",
    "Description": "Radio broadcasting "
  },
  {
    "Code": "60200",
    "Description": "Television programming and broadcasting activities "
  },
  {
    "Code": "61100",
    "Description": "Wired telecommunications activities "
  },
  {
    "Code": "61200",
    "Description": "Wireless telecommunications activities "
  },
  {
    "Code": "61300",
    "Description": "Satellite telecommunications activities "
  },
  {
    "Code": "61900",
    "Description": "Other telecommunications activities "
  },
  {
    "Code": "62011",
    "Description": "Ready-made interactive leisure and entertainment software development "
  },
  {
    "Code": "62012",
    "Description": "Business and domestic software development "
  },
  {
    "Code": "62020",
    "Description": "Information technology consultancy activities "
  },
  {
    "Code": "62030",
    "Description": "Computer facilities management activities "
  },
  {
    "Code": "62090",
    "Description": "Other information technology service activities "
  },
  {
    "Code": "63110",
    "Description": "Data processing, hosting and related activities "
  },
  {
    "Code": "63120",
    "Description": "Web portals "
  },
  {
    "Code": "63910",
    "Description": "News agency activities "
  },
  {
    "Code": "63990",
    "Description": "Other information service activities n.e.c. "
  },
  {
    "Code": "64110",
    "Description": "Central banking "
  },
  {
    "Code": "64191",
    "Description": "Banks "
  },
  {
    "Code": "64192",
    "Description": "Building societies "
  },
  {
    "Code": "64201",
    "Description": "Activities of agricultural holding companies "
  },
  {
    "Code": "64202",
    "Description": "Activities of production holding companies "
  },
  {
    "Code": "64203",
    "Description": "Activities of construction holding companies "
  },
  {
    "Code": "64204",
    "Description": "Activities of distribution holding companies "
  },
  {
    "Code": "64205",
    "Description": "Activities of financial services holding companies "
  },
  {
    "Code": "64209",
    "Description": "Activities of other holding companies n.e.c. "
  },
  {
    "Code": "64301",
    "Description": "Activities of investment trusts "
  },
  {
    "Code": "64302",
    "Description": "Activities of unit trusts "
  },
  {
    "Code": "64303",
    "Description": "Activities of venture and development capital companies "
  },
  {
    "Code": "64304",
    "Description": "Activities of open-ended investment companies "
  },
  {
    "Code": "64305",
    "Description": "Activities of property unit trusts "
  },
  {
    "Code": "64306",
    "Description": "Activities of real estate investment trusts "
  },
  {
    "Code": "64910",
    "Description": "Financial leasing "
  },
  {
    "Code": "64921",
    "Description": "Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors "
  },
  {
    "Code": "64922",
    "Description": "Activities of mortgage finance companies "
  },
  {
    "Code": "64929",
    "Description": "Other credit granting n.e.c. "
  },
  {
    "Code": "64991",
    "Description": "Security dealing on own account "
  },
  {
    "Code": "64992",
    "Description": "Factoring "
  },
  {
    "Code": "64999",
    "Description": "Financial intermediation not elsewhere classified "
  },
  {
    "Code": "65110",
    "Description": "Life insurance "
  },
  {
    "Code": "65120",
    "Description": "Non-life insurance "
  },
  {
    "Code": "65201",
    "Description": "Life reinsurance "
  },
  {
    "Code": "65202",
    "Description": "Non-life reinsurance "
  },
  {
    "Code": "65300",
    "Description": "Pension funding "
  },
  {
    "Code": "66110",
    "Description": "Administration of financial markets "
  },
  {
    "Code": "66120",
    "Description": "Security and commodity contracts dealing activities "
  },
  {
    "Code": "66190",
    "Description": "Activities auxiliary to financial intermediation n.e.c. "
  },
  {
    "Code": "66210",
    "Description": "Risk and damage evaluation "
  },
  {
    "Code": "66220",
    "Description": "Activities of insurance agents and brokers "
  },
  {
    "Code": "66290",
    "Description": "Other activities auxiliary to insurance and pension funding "
  },
  {
    "Code": "66300",
    "Description": " Fund management activities "
  },
  {
    "Code": "68100",
    "Description": "Buying and selling of own real estate "
  },
  {
    "Code": "68201",
    "Description": "Renting and operating of Housing Association real estate "
  },
  {
    "Code": "68202",
    "Description": "Letting and operating of conference and exhibition centres "
  },
  {
    "Code": "68209",
    "Description": "Other letting and operating of own or leased real estate "
  },
  {
    "Code": "68310",
    "Description": "Real estate agencies "
  },
  {
    "Code": "68320",
    "Description": "Management of real estate on a fee or contract basis "
  },
  {
    "Code": "69101",
    "Description": "Barristers at law "
  },
  {
    "Code": "69102",
    "Description": "Solicitors "
  },
  {
    "Code": "69109",
    "Description": "Activities of patent and copyright agents; other legal activities n.e.c. "
  },
  {
    "Code": "69201",
    "Description": "Accounting and auditing activities "
  },
  {
    "Code": "69202",
    "Description": "Bookkeeping activities "
  },
  {
    "Code": "69203",
    "Description": "Tax consultancy "
  },
  {
    "Code": "70100",
    "Description": "Activities of head offices "
  },
  {
    "Code": "70210",
    "Description": "Public relations and communications activities "
  },
  {
    "Code": "70221",
    "Description": "Financial management "
  },
  {
    "Code": "70229",
    "Description": "Management consultancy activities other than financial management "
  },
  {
    "Code": "71111",
    "Description": "Architectural activities "
  },
  {
    "Code": "71112",
    "Description": "Urban planning and landscape architectural activities "
  },
  {
    "Code": "71121",
    "Description": "Engineering design activities for industrial process and production "
  },
  {
    "Code": "71122",
    "Description": "Engineering related scientific and technical consulting activities "
  },
  {
    "Code": "71129",
    "Description": "Other engineering activities "
  },
  {
    "Code": "71200",
    "Description": "Technical testing and analysis "
  },
  {
    "Code": "72110",
    "Description": "Research and experimental development on biotechnology "
  },
  {
    "Code": "72190",
    "Description": "Other research and experimental development on natural sciences and engineering "
  },
  {
    "Code": "72200",
    "Description": "Research and experimental development on social sciences and humanities "
  },
  {
    "Code": "73110",
    "Description": "Advertising agencies "
  },
  {
    "Code": "73120",
    "Description": "Media representation services "
  },
  {
    "Code": "73200",
    "Description": "Market research and public opinion polling "
  },
  {
    "Code": "74100",
    "Description": "specialised design activities "
  },
  {
    "Code": "74201",
    "Description": "Portrait photographic activities "
  },
  {
    "Code": "74202",
    "Description": "Other specialist photography "
  },
  {
    "Code": "74203",
    "Description": "Film processing "
  },
  {
    "Code": "74209",
    "Description": "Photographic activities not elsewhere classified "
  },
  {
    "Code": "74300",
    "Description": "Translation and interpretation activities "
  },
  {
    "Code": "74901",
    "Description": "Environmental consulting activities "
  },
  {
    "Code": "74902",
    "Description": "Quantity surveying activities "
  },
  {
    "Code": "74909",
    "Description": "Other professional, scientific and technical activities n.e.c. "
  },
  {
    "Code": "74990",
    "Description": "Non-trading company <span class=\"hidden\">non trading</span>"
  },
  {
    "Code": "75000",
    "Description": "Veterinary activities "
  },
  {
    "Code": "77110",
    "Description": "Renting and leasing of cars and light motor vehicles "
  },
  {
    "Code": "77120",
    "Description": "Renting and leasing of trucks and other heavy vehicles "
  },
  {
    "Code": "77210",
    "Description": "Renting and leasing of recreational and sports goods "
  },
  {
    "Code": "77220",
    "Description": "Renting of video tapes and disks "
  },
  {
    "Code": "77291",
    "Description": "Renting and leasing of media entertainment equipment "
  },
  {
    "Code": "77299",
    "Description": "Renting and leasing of other personal and household goods "
  },
  {
    "Code": "77310",
    "Description": "Renting and leasing of agricultural machinery and equipment "
  },
  {
    "Code": "77320",
    "Description": "Renting and leasing of construction and civil engineering machinery and equipment "
  },
  {
    "Code": "77330",
    "Description": "Renting and leasing of office machinery and equipment (including computers) "
  },
  {
    "Code": "77341",
    "Description": "Renting and leasing of passenger water transport equipment "
  },
  {
    "Code": "77342",
    "Description": "Renting and leasing of freight water transport equipment "
  },
  {
    "Code": "77351",
    "Description": "Renting and leasing of air passenger transport equipment "
  },
  {
    "Code": "77352",
    "Description": "Renting and leasing of freight air transport equipment "
  },
  {
    "Code": "77390",
    "Description": "Renting and leasing of other machinery, equipment and tangible goods n.e.c. "
  },
  {
    "Code": "77400",
    "Description": "Leasing of intellectual property and similar products, except copyright works "
  },
  {
    "Code": "78101",
    "Description": "Motion picture, television and other theatrical casting activities "
  },
  {
    "Code": "78109",
    "Description": "Other activities of employment placement agencies "
  },
  {
    "Code": "78200",
    "Description": "Temporary employment agency activities "
  },
  {
    "Code": "78300",
    "Description": "Human resources provision and management of human resources functions "
  },
  {
    "Code": "79110",
    "Description": "Travel agency activities "
  },
  {
    "Code": "79120",
    "Description": "Tour operator activities "
  },
  {
    "Code": "79901",
    "Description": "Activities of tourist guides "
  },
  {
    "Code": "79909",
    "Description": "Other reservation service activities n.e.c. "
  },
  {
    "Code": "80100",
    "Description": "Private security activities "
  },
  {
    "Code": "80200",
    "Description": "Security systems service activities "
  },
  {
    "Code": "80300",
    "Description": "Investigation activities "
  },
  {
    "Code": "81100",
    "Description": "Combined facilities support activities "
  },
  {
    "Code": "81210",
    "Description": "General cleaning of buildings "
  },
  {
    "Code": "81221",
    "Description": "Window cleaning services "
  },
  {
    "Code": "81222",
    "Description": "Specialised cleaning services "
  },
  {
    "Code": "81223",
    "Description": "Furnace and chimney cleaning services "
  },
  {
    "Code": "81229",
    "Description": "Other building and industrial cleaning activities "
  },
  {
    "Code": "81291",
    "Description": "Disinfecting and exterminating services "
  },
  {
    "Code": "81299",
    "Description": "Other cleaning services "
  },
  {
    "Code": "81300",
    "Description": "Landscape service activities "
  },
  {
    "Code": "82110",
    "Description": "Combined office administrative service activities "
  },
  {
    "Code": "82190",
    "Description": "Photocopying, document preparation and other specialised office support activities "
  },
  {
    "Code": "82200",
    "Description": "Activities of call centres "
  },
  {
    "Code": "82301",
    "Description": "Activities of exhibition and fair organisers "
  },
  {
    "Code": "82302",
    "Description": "Activities of conference organisers "
  },
  {
    "Code": "82911",
    "Description": "Activities of collection agencies "
  },
  {
    "Code": "82912",
    "Description": "Activities of credit bureaus "
  },
  {
    "Code": "82920",
    "Description": "Packaging activities "
  },
  {
    "Code": "82990",
    "Description": "Other business support service activities n.e.c. "
  },
  {
    "Code": "84110",
    "Description": "General public administration activities "
  },
  {
    "Code": "84120",
    "Description": "Regulation of health care, education, cultural and other social services, not incl. social security "
  },
  {
    "Code": "84130",
    "Description": "Regulation of and contribution to more efficient operation of businesses "
  },
  {
    "Code": "84210",
    "Description": "Foreign affairs "
  },
  {
    "Code": "84220",
    "Description": "Defence activities "
  },
  {
    "Code": "84230",
    "Description": "Justice and judicial activities "
  },
  {
    "Code": "84240",
    "Description": "Public order and safety activities "
  },
  {
    "Code": "84250",
    "Description": "Fire service activities "
  },
  {
    "Code": "84300",
    "Description": "Compulsory social security activities "
  },
  {
    "Code": "85100",
    "Description": "Pre-primary education "
  },
  {
    "Code": "85200",
    "Description": "Primary education "
  },
  {
    "Code": "85310",
    "Description": "General secondary education "
  },
  {
    "Code": "85320",
    "Description": "Technical and vocational secondary education "
  },
  {
    "Code": "85410",
    "Description": "Post-secondary non-tertiary education "
  },
  {
    "Code": "85421",
    "Description": "First-degree level higher education "
  },
  {
    "Code": "85422",
    "Description": "Post-graduate level higher education "
  },
  {
    "Code": "85510",
    "Description": "Sports and recreation education "
  },
  {
    "Code": "85520",
    "Description": "Cultural education "
  },
  {
    "Code": "85530",
    "Description": "Driving school activities "
  },
  {
    "Code": "85590",
    "Description": "Other education n.e.c. "
  },
  {
    "Code": "85600",
    "Description": "Educational support services "
  },
  {
    "Code": "86101",
    "Description": "Hospital activities "
  },
  {
    "Code": "86102",
    "Description": "Medical nursing home activities "
  },
  {
    "Code": "86210",
    "Description": "General medical practice activities "
  },
  {
    "Code": "86220",
    "Description": "Specialists medical practice activities "
  },
  {
    "Code": "86230",
    "Description": "Dental practice activities "
  },
  {
    "Code": "86900",
    "Description": "Other human health activities "
  },
  {
    "Code": "87100",
    "Description": "Residential nursing care facilities "
  },
  {
    "Code": "87200",
    "Description": "Residential care activities for learning difficulties, mental health and substance abuse"
  },
  {
    "Code": "87300",
    "Description": "Residential care activities for the elderly and disabled "
  },
  {
    "Code": "87900",
    "Description": "Other residential care activities n.e.c. "
  },
  {
    "Code": "88100",
    "Description": "Social work activities without accommodation for the elderly and disabled "
  },
  {
    "Code": "88910",
    "Description": "Child day-care activities "
  },
  {
    "Code": "88990",
    "Description": "Other social work activities without accommodation n.e.c. "
  },
  {
    "Code": "90010",
    "Description": "Performing arts "
  },
  {
    "Code": "90020",
    "Description": "Support activities to performing arts "
  },
  {
    "Code": "90030",
    "Description": "Artistic creation "
  },
  {
    "Code": "90040",
    "Description": "Operation of arts facilities "
  },
  {
    "Code": "91011",
    "Description": "Library activities "
  },
  {
    "Code": "91012",
    "Description": "Archives activities "
  },
  {
    "Code": "91020",
    "Description": "Museums activities "
  },
  {
    "Code": "91030",
    "Description": "Operation of historical sites and buildings and similar visitor attractions "
  },
  {
    "Code": "91040",
    "Description": "Botanical and zoological gardens and nature reserves activities "
  },
  {
    "Code": "92000",
    "Description": "Gambling and betting activities "
  },
  {
    "Code": "93110",
    "Description": "Operation of sports facilities "
  },
  {
    "Code": "93120",
    "Description": "Activities of sport clubs "
  },
  {
    "Code": "93130",
    "Description": "Fitness facilities "
  },
  {
    "Code": "93191",
    "Description": "Activities of racehorse owners "
  },
  {
    "Code": "93199",
    "Description": "Other sports activities "
  },
  {
    "Code": "93210",
    "Description": "Activities of amusement parks and theme parks "
  },
  {
    "Code": "93290",
    "Description": "Other amusement and recreation activities n.e.c. "
  },
  {
    "Code": "94110",
    "Description": "Activities of business and employers membership organisations "
  },
  {
    "Code": "94120",
    "Description": "Activities of professional membership organisations "
  },
  {
    "Code": "94200",
    "Description": "Activities of trade unions "
  },
  {
    "Code": "94910",
    "Description": "Activities of religious organisations "
  },
  {
    "Code": "94920",
    "Description": "Activities of political organisations "
  },
  {
    "Code": "94990",
    "Description": "Activities of other membership organisations n.e.c. "
  },
  {
    "Code": "95110",
    "Description": "Repair of computers and peripheral equipment "
  },
  {
    "Code": "95120",
    "Description": "Repair of communication equipment "
  },
  {
    "Code": "95210",
    "Description": "Repair of consumer electronics "
  },
  {
    "Code": "95220",
    "Description": "Repair of household appliances and home and garden equipment "
  },
  {
    "Code": "95230",
    "Description": "Repair of footwear and leather goods "
  },
  {
    "Code": "95240",
    "Description": "Repair of furniture and home furnishings "
  },
  {
    "Code": "95250",
    "Description": "Repair of watches, clocks and jewellery "
  },
  {
    "Code": "95290",
    "Description": "Repair of personal and household goods n.e.c. "
  },
  {
    "Code": "96010",
    "Description": "Washing and (dry-)cleaning of textile and fur products "
  },
  {
    "Code": "96020",
    "Description": "Hairdressing and other beauty treatment "
  },
  {
    "Code": "96030",
    "Description": "Funeral and related activities "
  },
  {
    "Code": "96040",
    "Description": "Physical well-being activities "
  },
  {
    "Code": "96090",
    "Description": "Other service activities n.e.c. "
  },
  {
    "Code": "97000",
    "Description": "Activities of households as employers of domestic personnel "
  },
  {
    "Code": "98000",
    "Description": " Residents property management"
  },
  {
    "Code": "98100",
    "Description": "Undifferentiated goods-producing activities of private households for own use "
  },
  {
    "Code": "98200",
    "Description": "Undifferentiated service-producing activities of private households for own use "
  },
  {
    "Code": "99000",
    "Description": "Activities of extraterritorial organisations and bodies "
  },
  {
    "Code": "99999",
    "Description": "Dormant Company "
  }
];
