import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SupportUsers from './SupportUsers';
import UserDetails from './UserDetails';

const MyProfile = (props) => {
    useEffect(() => { document.title = props.title }, []);
    const userData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj);
    const applicationData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);

    return (
        <>
            <NavBar />

            <div  className="bg-light" style={{minHeight: "75vh"}}>
                <div className="container">
                    <div className="row py-3">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Tabs className="dashboard-tabs">
                                <TabList>
                                    <Tab style={{ width: "50%" }}> My Profile</Tab>
                                    <Tab style={{ width: "50%" }}> Support Users</Tab>
                                </TabList>
                                <TabPanel>
                                    <UserDetails user={userData} />
                                </TabPanel>
                                <TabPanel>
                                    <SupportUsers user={userData} appliData={applicationData} />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
};

export default MyProfile;