import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { handleError, HttpCall } from "../../services/UseHttps";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { listAdminOfferData } from "../../redux/action/offerList";
import { effectiveDateUpdateAPI } from "../../config";
import { getTodayYYYYMMDD } from "../../services/CommonFunction";

const EffectiveDateUpdate = (props) => {
  const companyName = props.offerData && props.offerData.company_name ? props.offerData.company_name : '';
  const offerId = props.offerData && props.offerData.id ? props.offerData.id : '';
  const effectiveFromDate = props.offerData && props.offerData.effective_from ? props.offerData.effective_from : '';
  const [initialValues, setInitialValues] = useState({
    effective_from: effectiveFromDate,
  });
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const minimumDate = date.toLocaleDateString('en-ca');
  useEffect(() => {
    setInitialValues({effective_from: effectiveFromDate});
  },[effectiveFromDate]);
  const dispatch = useDispatch();
  // validate fields
  const validate = (values) => {
    let errors = {};
    if (values.effective_from ==='') {
      errors.effective_from = "Effective from date is required";
    } else {
      const today = new Date(getTodayYYYYMMDD());
      const effDate = new Date(values.effective_from);
      if(effDate.getTime() <= today.getTime()) {
        errors.effective_from = "Please select effective from date greater than today date.";
      }
    }
    return errors;
  };
  const offerFromDateUpdate = (values) => {
    const today = new Date(getTodayYYYYMMDD());
    const effDate = new Date(values.effective_from);
    if(effDate.getTime() <= today.getTime()) {
      Swal.fire({title: "Error", text: "Please select effective from date greater than today date.",icon: "error"});
      return;
    } else {
      Swal.fire({
        title: "Are you sure you want to update effective from date?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          HttpCall(`${effectiveDateUpdateAPI+offerId}`, "patch", {effective_from:values.effective_from})
            .then((res) => {
              if(res && res.status && (res.status === 200 || res.status === 201)) {
                formik.resetForm();
                Swal.fire({
                  title: "Done!",
                  text: "Offer effective from date successfully updated",
                  icon: "success",
                });
                dispatch(listAdminOfferData());
                props.setModalShow(false);
              }
            }).catch((error) => {
              formik.resetForm();
              handleError(error);
            });
        }
      });
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: offerFromDateUpdate,
    validate,
  });
  const renderErrorMessage = (field) => {
    return (
      formik.touched[field] && (
        <div className="text-danger">{formik.errors[field]}</div>
      )
    );
  };
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={() => formik.resetForm()}>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Effective Date ({companyName})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card ">
          <div className="card-body">
            <form className="custom-form" onSubmit={formik.handleSubmit} method="POST">
              <div className="mb-3">
                  <div className="ui-widget">
                    <label>Effective From Date:</label>
                    <input type="date" autoComplete="off" 
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Effective From Date" aria-describedby=""
                      name="effective_from" value={formik?.value?.effective_from} 
                      onChange={formik.handleBlur} onBlur={formik.handleBlur}
                      {...formik.getFieldProps("effective_from")}  min={minimumDate}
                    />
                  </div>
                  {renderErrorMessage("effective_from")}
              </div>
              <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow">Update</button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EffectiveDateUpdate;
