import * as typeS from "../service/types";
// import Swal from 'sweetalert2'
import { GetTokenApi } from "../service/ApiConfig";
// import { getTokenSuccess } from "./getfunded";

export const loginRequest = (login) => {
  return {
    type: typeS.LOGIN_REQUEST,
    payload: login,
  };
};

export const loginSuccess = (login) => {
  return {
    type: typeS.LOGIN_SUCCESS,
    payload: login,
  };
};

export const loginError = (error) => {
  return {
    type: typeS.LOGIN_ERROR,
    payload: error,
  };
};

export const logInData = (data, setErrorMsg) => {
  return (dispatch) => {
    try {
      GetTokenApi(data.request).then((res) => {
        if (res && res.data && res.status && res.status === 200) {
          dispatch(loginSuccess(res?.data));
        } else {
          dispatch(loginError());
          if(res.response && res.response && res.response.data && (res.response.status === 401 || res.response.status === 404)) {
            setErrorMsg(res.response.data.message ? "Login Error : "+res.response.data.message : "Login Error : Invalid Username Or Password");
          } else {
            setErrorMsg('Login Error : Invalid Username Or Password');
          }
        }
      }).catch((err) => {
        dispatch(loginError(err.message));
        setErrorMsg('Login Error : Something went wrong');
      });
    } catch (err) {
      dispatch(loginError(err));
      setErrorMsg('Login Error : Something went wrong');
    }
  };
}
