import {
  ADMIN_OFFER_LIST_INITIAL,
  ADMIN_OFFER_LIST_SUCCESS,
  ADMIN_OFFER_LIST_ERROR
} from "../service/types";

const InitState = {
  loading: false,
  listAdminOfferData: [],
  error: "",
};
const OfferList = (state = InitState, action) => {
  switch (action.type) {
    case ADMIN_OFFER_LIST_INITIAL:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_OFFER_LIST_SUCCESS:
      return {
        loading: false,
        listAdminOfferData: action.payload,
        error: "",

      };
    case ADMIN_OFFER_LIST_ERROR:
      return {
        loader: false,
        listAdminOfferData: [],
        error: action.error,
      };
    default:
      return state;
  }
};

export default OfferList;