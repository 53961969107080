import logo from "../../img/logo-light.png";
import Loginpic from "../../img/img-19.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { completeProfileApi, generateOtpAPI, verifyEmailTokenApi, verifyOtpAPI } from "../../config";
import { HttpCall } from "../../services/UseHttps";
import { PhoneNumberApi } from "../../redux/service/ApiConfig";
import { decryptStringEmail } from "../../services/CommonFunction";

const CompleteProfile = (props) => {
    const pageUrl = window.location.href;
    const url = new URL(pageUrl)
    var queryToken = url.searchParams.get("token");
    var queEmail = url.searchParams.get('email');
    var queryEmail = decryptStringEmail(queEmail?queEmail.replaceAll(' ', '+'):'');
    const navigate = useNavigate();
    useEffect(() => {
        document.title = props.title;
        localStorage.setItem('token', queryToken);
        if(!queryToken || queryToken === null || !queryEmail || queryEmail === null) {
            Swal.fire({icon: 'error',title: 'Error', text: 'Token or email not found.'});
            navigate('/login');
        } else {
            checkValidateToken(queryToken);
        }
    }, []);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownCon, setPasswordShownCon] = useState(false);
    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/;
    const [requestBody, setRequestBody] = useState({});
    const userSchema = Yup.object().shape({
        email: Yup.string().required('Email address is required').email('Email address is invalid'),
        phonenumber: Yup.string().required('Mobile number is required').max(15, "Maximum mobile number should be of 15 digits"),
        password: Yup.string().required('Password is required')
        .matches(passPattern, 'Password length should be minimum 8 characters and must contain at least one lowercase letter, uppercase letter, number and special character(!,@,#,$,%,^,&,*)'),
        confirm_password: Yup.string()//.required('Confirm password is required'),
    });
    const override = css`display: block;margin: 0 auto;border-color: green;`;
    // phone vars
    const [loadingPhone, setLoadingPhone] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [phoneErrormsg, setPhoneErrormsg] = useState(false);
    const [loadingOtp, setLoadingOtp] = useState(false);
    let numberRequest = { 'phone_number': "", 'country_code': "" }
    // otp
    const [showOTP, setShowOTP] = useState(false);
    const [otpCount, setOtpCount] = useState(0);
    const [otpError, setOtpError] = useState('');
    const [otpSuccess, setOtpSuccess] = useState("");
    const numePattern = /^\d+$/;

    const handleSubmit = (formValues) => {
        try {
            if(formValues.password === formValues.confirm_password) {
                setErrorMsg('');
                setLoading(true);   
                const formObject = { 'email': formValues.email, 'token': queryToken, 'phone_number': `+${formValues.phonenumber}`, 'password': formValues.password };
                setRequestBody(formObject);
                sendUserOTP(`+${formValues.phonenumber}`, false);
            } else {
                if(formValues.password !== formValues.confirm_password) {
                    // Swal.fire({icon: 'error',title: 'Error', text: 'Both password are not same.'});
                    return;
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: 'Token not found.'});
                }
            }
        } catch(err) {
            setLoading(false);
        }
    }
    const completeProfileContinue = () => {
        HttpCall(`${completeProfileApi}`, "POST", requestBody).then((res) => {
            if (res && res.status && (res.status === 200 || res.status === 201)) {
                Swal.fire({icon: 'success',title: 'Success', text: 'Your registration successfully completed.'});
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                Swal.fire({icon: 'error',title: 'Error', text: 'Your registration is failed.'});
            }
            setLoading(false);
        }).catch((error) => {
            Swal.fire({icon: 'error',title: 'Error',
                text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Your registration is failed.'
            });
            setLoading(false);
        });
    }

    const phoneValue = (value, country) => {
        numberRequest = { 'phone_number': value, 'country_code': country.iso2 }
    };
    const handleBlurs = (e) => {
        try {
            if(numberRequest&&numberRequest['phone_number']&&numberRequest['phone_number'].length > 4) {
                setPhoneErrormsg(false);
            } else {
                setPhoneErrormsg(true);
                return;
            }
            setLoadingPhone(true);
            PhoneNumberApi(numberRequest).then((res) => {
                if (res&&res.data&&res.data.is_valid === true) {
                    setLoadingPhone(false);
                    setIsValidPhone(true);
                    setPhoneErrormsg(false);
                } else {
                    setLoadingPhone(false);
                    setIsValidPhone(false);
                    setPhoneErrormsg(true);
                }
            })
        } catch (err) {
            setLoadingPhone(false);
            setIsValidPhone(false);
            setPhoneErrormsg(true);
        }
    }

    // otp
    const otpSchema = Yup.object().shape({
        otp: Yup.string().required('Verification code is required.')
            .matches(numePattern, 'Verification code is allows only numbers.')
            .max(10, "Please enter valid verification code.")
    });
    const sendUserOTP = (mobile, isResendOtp) => {
        try {
            if(isResendOtp) { setLoadingOtp(true); } else { setLoading(true); }
            HttpCall(`${generateOtpAPI}`, "POST", { "phone_number": mobile }).then((res) => {
                if (res && res.status && (res.status === 200 || res.status === 201)) {
                    setShowOTP(true);
                    setOtpSuccess('Verification code has been sent to your registered mobile number.');
                    window.scrollTo(0, 0);
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: 'Fail to send verification code on mobile number.'});
                }
                setLoading(false);
                setLoadingOtp(false);
            }).catch((error) => {
                Swal.fire({icon: 'error',title: 'Error',
                    text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to send verification code.'
                });
                setLoading(false);
                setLoadingOtp(false);
                setOtpSuccess('');
            });
        } catch(err) {
            setLoading(false);
            setLoadingOtp(false);
            setOtpSuccess('');
        }
    }
    const closeOtpModel = () => {
        setShowOTP(false);
        setLoading(false);
        setLoadingOtp(false);
        setOtpCount(0);
        setOtpError('');
        setOtpSuccess('');
    }
    const verifyOTPHandle = (values) => {
        try {
            if(values && values.otp) {
                setOtpSuccess('');
                setLoadingOtp(false);
                let body = { "phone_number": requestBody.phone_number, "otp": values.otp };
                HttpCall(`${verifyOtpAPI}`, "POST", body).then((res) => {
                    if (res && res.data && res.status && (res.status === 200 || res.status === 201)) {
                        if(res.data["otp_status"] === "approved") {
                            setShowOTP(false);
                            // Swal.fire({icon: 'success',title: 'Success', text: 'Verification code is verified successfully.'});
                            completeProfileContinue();
                        } else {
                            setOtpError('Verification code does not match.');
                        }
                    } else {
                        setOtpError('Fail to verify verification code.');
                    }
                    setLoadingOtp(false);
                }).catch((error) => {
                    Swal.fire({icon: 'error',title: 'Error',
                        text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to verify otp.'
                    });
                    setLoadingOtp(false);
                });
            }
        } catch(err) {
            setLoadingOtp(false);
        }
    }
    const reSendOtpHandle = () => {
        setOtpSuccess('');
        if(otpCount < 3) {
            if(requestBody && requestBody.phone_number) {
                setOtpCount(otpCount+1);
                sendUserOTP(requestBody.phone_number, true);// setShowOTP(true); // reset otp here
            } else {
                setOtpError('Mobile number not found to send verification code.')
            }
        } else {
            setOtpError('You exceeded re-send verification code limit.');
        }
    }

    // check token is valid or expired
    const checkValidateToken = (token) => {
        setLoadingPage(true);
        HttpCall(`${verifyEmailTokenApi}`, "POST", {token}).then((res) => {
            if (res && res.data && res.data.status === false) {
                Swal.fire({icon: 'error',title: 'Error', text: res.data&&res.data.message?res.data.message:'Invalid Token Found.'});
                navigate('/login');
            }
            setLoadingPage(false);
        }).catch((error) => {
            Swal.fire({icon: 'error',title: 'Error', text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Invalid Token Found.'});
            setLoadingPage(false);
        });
    }

    return (
        <>
            <section className="overflow-hidden div-height">
                <div className="row div-height">
                    {loadingPage ? <ClipLoader css={override} size={150} />
                    :
                    <>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="background h-100">
                            <div className="text-right px-4 pt-3 pb-5">
                                <Link to="/">
                                    <img src={logo} alt="logo" width="200" height="20" className="" />
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">

                                    <img className="text-center" src={Loginpic} alt="welcome" height="350" width="400" />
                                    <h1 className="text-white mb-4">Barlow Marshall</h1>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-4">
                                <div>
                                    <Link className="text-white PX-2" to={'/terms-conditions'}>Terms and Conditions</Link>
                                    <span className="text-white mx-2"> | </span>
                                    <Link className="text-white px-2" to={'/privacy-policy'}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                    {!showOTP ? 
                            <div className="signup-box p-5 mt-2">
                                <h1 className="text-center mb-4"><b>Complete Verification</b></h1>
                                {errorMsg && <div className="alert alert-danger" role="alert">{errorMsg}</div> }
                                <Formik
                                    initialValues={{ email: queryEmail, phonenumber:"", password: "", confirm_password: "" }}
                                    validationSchema={userSchema} onSubmit={(values) => { handleSubmit(values) }}
                                >
                                    {({ touched, errors, values, handleChange}) =>
                                        !loading ? (
                                            <div>
                                                <Form className="custom-form">
                                                    <div className="mb-3">
                                                        <Field readOnly={true}
                                                            type="email" name="email" id="email" autoComplete="off" placeholder="Email Address"
                                                            className={`mt-2 form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                        />
                                                        <ErrorMessage component="div" name="email" className="invalid-feedback"/>
                                                    </div>
                                                    <div className="shadow" style={{marginBottom:10}}>
                                                        <PhoneInput
                                                            country={"gb"} name="phonenumber" placeholder="Phone Number"
                                                            countryCodeEditable={true} autoComplete="off"
                                                            value={values.phonenumber} onChange={handleChange('phonenumber')}
                                                            className={errors&&errors.phonenumber ? "is-invalid" : ""}
                                                            isValid={(value, country) => phoneValue(value, country)}
                                                            onBlur={(e) => handleBlurs(e)}
                                                        />
                                                        <ErrorMessage component="div" name="phonenumber" className="invalid-feedback" />
                                                        { phoneErrormsg === true ? <div className='text-danger'> Please enter valid number </div> : '' }
                                                        {loadingPhone && <ClipLoader size={70} css={{ display: 'block', float: 'right', borderColor: 'green', marginTop: '-65px', marginLeft: '100px',position: 'absolute' }} color="green" /> }
                                                    </div>
                                                    <div className="input-group mb-3" style={{zIndex:0}}>
                                                        <Field
                                                            type={passwordShown ? "text" : "password"}
                                                            name="password" value={values.password} onChange={handleChange}
                                                            id="password" placeholder="Password" autoComplete="off"
                                                            className={`mt-2 form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                        />
                                                        <div className="mt-2">
                                                            <div className="input-group-text" tabIndex="0" onKeyDown={(e) => e.keyCode === 32 ? setPasswordShown(!passwordShown) : () => {} }>
                                                                {passwordShown ? <i className="far fa-eye" id="togglePassword" onClick={()=> setPasswordShown(!passwordShown) } ></i> : <i className="far fa-eye-slash" id="togglePassword" onClick={()=>setPasswordShown(!passwordShown)} ></i>}

                                                            </div>
                                                        </div>
                                                        <ErrorMessage component="div" name="password" className="invalid-feedback"/>
                                                    </div>
                                                    <div className="input-group mb-3" style={{zIndex:0}}>
                                                        <Field
                                                            type={passwordShownCon ? "text" : "password"}
                                                            name="confirm_password" value={values.confirm_password} onChange={handleChange}
                                                            id="confirm_password" placeholder="Confirm Password" autoComplete="off"
                                                            className={`mt-2 form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                        />
                                                        <div className="mt-2">
                                                            <div className="input-group-text" tabIndex="0" onKeyDown={(e) => e.keyCode === 32 ? setPasswordShownCon(!passwordShownCon) : () => {} }>
                                                                {passwordShownCon ? <i className="far fa-eye" id="togglePassword" onClick={()=> setPasswordShownCon(!passwordShownCon) } ></i> : <i className="far fa-eye-slash" id="togglePassword" onClick={()=>setPasswordShownCon(!passwordShownCon)} ></i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {touched.confirm_password && values.confirm_password === '' &&  <div style={{width:'100%',marginTop:'-10px',marginBottom:10,fontSize:'.875em',color:'#dc3545'}}>Confirm password is required.</div>
                                                        }
                                                        {touched.password && touched.confirm_password && values.password && values.confirm_password && 
                                                            values.password !== values.confirm_password &&  <div style={{width:'100%',marginTop:'-10px',marginBottom:10,fontSize:'.875em',color:'#dc3545'}}>Both password are not same.</div>
                                                        }
                                                    <button type="submit" className="mb-4 btn btn-custom btn-custom-2 btn-custom-no-white-shadow">
                                                        {loading ? <><ClipLoader css={override} size={150} /></> : <>Submit</> }
                                                    </button>
                                                    <Link to="/login" className="text-decoration-none" style={{float:'right'}}>Back to Login</Link>
                                                    <br />
                                                </Form>
                                                <br />
                                            </div>
                                        ) : (
                                            <> <ClipLoader css={override} size={150} /> </>
                                        )
                                    }
                                </Formik>
                            </div>
                        :
                            <div className="signup-box p-5 mt-2">
                                <h1 className="text-center"><b>Verify OTP</b></h1>
                                <Formik initialValues={{ otp: "" }} validationSchema={otpSchema}
                                    onSubmit={(values) => { verifyOTPHandle(values) }}>
                                {({ touched, errors, values, handleChange}) =>
                                    <Form className="custom-form">
                                        {
                                            loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                            :
                                            <>                                            
                                                { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' }
                                                <div className="mb-3">
                                                    <Field
                                                        type="text" name="otp" value={values.otp} onChange={handleChange}
                                                        id="otp" placeholder="Enter OTP" autoComplete="off"
                                                        aria-describedby="businessHelp" className={`mt-2 form-control
                                                        ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                                                    />
                                                    <ErrorMessage component="div" name="otp" className="invalid-feedback"/>
                                                    { otpError ? <div className='alert alert-danger'> {otpError} </div> : '' }
                                                </div>
                                                <div className="mb-3">
                                                    <button type="button" className="btn btn-link" onClick={reSendOtpHandle}>Resend OTP</button>
                                                    <button type="button" className="btn btn-link" onClick={closeOtpModel} style={{float:"right"}}>Back to Get Funded</button>
                                                </div>
                                                <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                                                    >Verify</button>
                                            </>
                                        }
                                    </Form>
                                    }
                                </Formik>
                            </div>
                    }
                    </div>
                    </>
                    }
                </div>
            </section>
        </>
    )
};

export default CompleteProfile;