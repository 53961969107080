import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import InfoBar from "../../components/UI/InfoBar";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineController,
  BarElement,
} from "chart.js";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useNavigate } from "react-router-dom";
import { MDBDataTableV5 } from "mdbreact";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { handleError, HttpCall } from "../../services/UseHttps";
import { adminDashboardModulrTransAPI, adminDashboardRecentTransAPI, adminDashboardRepaymentGraphAPI, adminDashboardRepaymentProgressGraphAPI, adminDashboardTransDataAPI } from "../../config";
import { formatAmount, getDateDDMMYYYY } from "../../services/CommonFunction";
import { ErrorHandler } from "../../components/Auth/errorHandler";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineController,
  BarElement
);

const Dashboard = (props) => {
  const screen1 = useFullScreenHandle();
  const screen2 = useFullScreenHandle();
  const [styleObjRepay, setStyleObjRepay] = useState({ width: "90%" });
  const [styleObjRepayProg, setStyleObjRepayProg] = useState({ width: "90%" });
  // recent transaction for all client
  const [transactionData, setTransactionData] = useState([]);
  const [loadingData, setLoadingData] = useState([]);
  const [mdbColHeaders, setMdbColHeaders] = useState([
    {
      label: "Client Name",
      field: "client_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Last 30 days Repayments",
      field: "last_30_day_repayment",
      sort: "asc",
      width: 150,
    },
    {
      label: "Current Pending Transactions",
      field: "current_pending_transactions",
      sort: "asc",
      width: 150,
    },
    {
      label: "Repayments Made",
      field: "repayments_made",
      sort: "asc",
      width: 150,
    },
    {
      label: "Percentage Repaid",
      field: "percentage_repaid",
      sort: "asc",
      width: 150,
    },
    {
      label: "Full Repayment Due",
      field: "full_repayment",
      sort: "asc",
      width: 150,
    },
    {
      label: "Full Repayment Outstanding",
      field: "outstanding_repayment",
      sort: "asc",
      width: 150,
    },
    {
      label: "Deal Duration (Months) - Time Since Start Of Deal",
      field: "deal_duration",
      sort: "asc",
      width: 150,
    },
  ]);
  const [recentTransactionData, setRecentTransactionData] = useState({columns: mdbColHeaders,rows: []});
  useEffect(() => {
    document.title = props.title;
    getRepaymentGraph('yearly','');
    getRepaymentProgressGraph();
    getDashboardTransactionData();
    getClientRecentTransactions();
  }, []);
  const navigate = useNavigate();
  /* Bar Chart */
  const [monthVal, setMonthVal] = useState(''); 
  const [weekVal, setWeekVal] = useState(''); 
  const [dailyVal, setDailyVal] = useState(''); 
  const [YMWDObject, setYMWDObject] = useState({month:[],week:[],day:[]});
  const [optionsBar, setOptionsBar] = useState({
    responsive: true,
    type: "bar",
    plugins: {
      title: {
        display: false,
        text: "Month",
        fontSize: 20,
        position: "bottom",
      },
      legend: {
        display: true,
        position: "top",
      },
    }
  });
  const [dataBar, setDataBar] = useState({labels : [],datasets: []});
  const colors = ['#27274d','#5499c7','#6ba2e6','#2283FB','#2b6dbf'];
  const [loadingBar, setLoadingBar] = useState(false);
  // const state = {
  //   labels: [
  //     "05 May 2022",
  //     "06 May 2022",
  //     "07 May 2022",
  //     "08 May 2022",
  //     "09 May 2022",
  //     "10 May 2022",
  //     "11 May 2022",
  //     "12 May 2022",
  //     "13 May 2022",
  //     "14 May 2022",
  //   ],
  //   datasets: [
  //     {
  //       barPercentage: 0.8,
  //       barThickness: 29,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Client 1",
  //       backgroundColor: "#27274d",
  //       borderColor: "#27274d",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [5000, 600, 1500, 2000, 6000, 600, 6000, 4000, 5000, 600],
  //     },
  //     {
  //       barPercentage: 0.8,
  //       barThickness: 29,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Client 2",
  //       backgroundColor: "#5499c7",
  //       borderColor: "#5499c7",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [800, 900, 1800, 2100, 4000, 4000, 400, 4000, 4000, 4000],
  //     },
  //     {
  //       barPercentage: 0.8,
  //       barThickness: 29,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Client 3",
  //       backgroundColor: "#6ba2e6",
  //       borderColor: "#6ba2e6",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [1000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 2000],
  //     },
  //     {
  //       barPercentage: 0.8,
  //       barThickness: 29,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Client 4",
  //       backgroundColor: "#2283FB ",
  //       borderColor: "#2283FB ",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000],
  //     },
  //     {
  //       barPercentage: 0.8,
  //       barThickness: 29,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Client 5",
  //       backgroundColor: "#2b6dbf",
  //       borderColor: "#2b6dbf",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [5000, 600, 1500, 2000, 6000, 600, 6000, 4000, 5000, 600],
  //     },
  //   ],
  // };
  // const options = {
  //   responsive: true,
  //   type: "bar",
  //   // scales: {
  //   //     xAxes: [{
  //   //         stacked: true
  //   //     }],
  //   //     yAxes: [{
  //   //         stacked: true
  //   //     }]
  //   // },
  //   // onClick: function (e) {
  //   //   Navigate({
  //   //     pathname: '/admin/ledger',
  //   //     query: { id: 'hello' }
  //   // });
  //   onClick: (e, element) => {
  //     if (element.length > 0) {
  //       let index = element[0].datasetIndex;
  //       // let data = state.datasets[index];
  //       // Navigate({
  //       //   pathname: "/admin-ledger",
  //       //   state: { id: index },
  //       // });
  //       navigate('/admin-ledger',{state:{id:1,name:'sabaoon'}});
  //     }
  //   },
  //   plugins: {
  //     title: {
  //       display: false,
  //       text: "Month",
  //       fontSize: 20,
  //       position: "bottom",
  //     },
  //     legend: {
  //       display: true,
  //       position: "top",
  //     },
  //   },
  // };

  /* Bar Chart-2 */
  // const state2 = {
  //   labels: [
  //     "Client A",
  //     "Client B",
  //     "Client C",
  //     "Client D",
  //     "Client E",
  //     "Client F",
  //     "Client G",
  //     "Client H",
  //     "Client I",
  //     "Client J",
  //   ],
  //   datasets: [
  //     {
  //       indexAxis: "y",
  //       barPercentage: 0.8,
  //       barThickness: 15,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Repayment Made",
  //       backgroundColor: "#2283FB",
  //       borderColor: "#2283FB",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [20, 60, 15, 20, 60, 6, 60, 40, 50, 60],
  //     },
  //     {
  //       indexAxis: "y",
  //       barPercentage: 0.8,
  //       barThickness: 15,
  //       maxBarThickness: 20,
  //       minBarLength: 4,
  //       label: "Full Repayment Due",
  //       backgroundColor: "#6ba2e6",
  //       borderColor: "#6ba2e6",
  //       borderWidth: 1,
  //       stack: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [9, 18, 21, 40, 40, 4, 40, 40, 40, 40],
  //     },
  //   ],
  // };
  const [dataProgressBar, setDataProgressBar] = useState({labels : [],datasets: []});
  const [optionsProgressBar, setOptionsProgressBar] = useState({
    responsive: true, type: "bar",
    // scales: { xAxes: [{ stacked: true }], yAxes: [{ stacked: true }] },
    plugins: {
      title: { display: true, text: "Repayment Progress", fontSize: 20, position: "bottom" },
      legend: { display: true, position: "bottom" },
    },
  });
  const [loadingProg, setLoadingProg] = useState(false);

  // admin client's modulr transactions
  const [transactions, setTransactions] = useState([]);
  const [tranBalance, setTranBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('Modulr transaction records not found.');
  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nPages, setNpages] = useState(0);

  useEffect(() => {
    fetchAdminDashboardData();
  }, [currentPage, recordsPerPage]);

  // get transactions data
  const getDashboardTransactionData = () => {
    const recordData = [
      {
        id: "1",
        title: "Current Pending Transactions",
        value: "0",
      },
      {
        id: "2",
        title: "Repayments Made",
        value: "0",
      },
      {
        id: "3",
        title: "Full Repayment Due",
        value: "0",
      },
      {
        id: "4",
        title: "Full Repayment Outstanding",
        value: "0",
      },
    ];
    try {
        setLoadingData(true);
        HttpCall(`${adminDashboardTransDataAPI}`, "GET").then((res) => {
            if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
                const record = res.data.data;
                record.non_modulr_pending_transactions = record.non_modulr_pending_transactions ? formatAmount(record.non_modulr_pending_transactions):'0';
                record.repayments_made = record.repayments_made ? '£'+formatAmount(record.repayments_made):'0';
                record.full_repayment_amount = record.full_repayment_amount ? '£'+formatAmount(record.full_repayment_amount):'0';
                record.outstanding_repayment = record.outstanding_repayment ? '£'+formatAmount(record.outstanding_repayment):'0';
                recordData[0].value = record.non_modulr_pending_transactions;
                recordData[1].value = record.repayments_made;
                recordData[2].value = record.full_repayment_amount;
                recordData[3].value = record.outstanding_repayment;
                setTransactionData(recordData);
                setLoadingData(false);
            } else {
                setTransactionData(recordData);
                setLoadingData(false);
            }
        }).catch((error) => {
            setLoadingData(false);
            setTransactionData(recordData);
            ErrorHandler(error);
        });
    } catch (ex) {
      setLoadingData(false);
    }
  }
  // get all clients recent transactions
  const getClientRecentTransactions = () => {
    try {
        HttpCall(`${adminDashboardRecentTransAPI}`, "GET").then((res) => {
            if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data && res.data.data.length > 0) {
                const record = res.data.data;
                record.forEach((item) => {
                  item.last_30_day_repayment = item.last_30_day_repayment ? '£'+formatAmount(item.last_30_day_repayment):'0';
                  item.repayments_made = item.repayments_made ? '£'+formatAmount(item.repayments_made):'0';
                  item.full_repayment = item.full_repayment ? '£'+formatAmount(item.full_repayment):'0';
                  item.outstanding_repayment = item.outstanding_repayment ? '£'+formatAmount(item.outstanding_repayment):'0';
                });
                setRecentTransactionData({ ...recentTransactionData, rows: record?record:[] });
            } else {
                setRecentTransactionData({ ...recentTransactionData, rows:[]});
            }
        }).catch((error) => {
            setRecentTransactionData({ ...recentTransactionData, rows:[]});
            ErrorHandler(error);
        });
    } catch (ex) {
        // console.log('error:',ex);
    }
  }

  // fetch modulr dashboard data
  const fetchAdminDashboardData = () => {
      try {
          setLoading(true);
          HttpCall(`${adminDashboardModulrTransAPI}?page=${currentPage}&size=${recordsPerPage}`, "get")
            .then((res) => {
              if(res && res.status === 200 && res.data && res.data.status === true && res.data.data){
                  setTransactions(res.data.data.transactions?res.data.data.transactions:[]);
                  setTranBalance(res.data.data.balance?res.data.data.balance:0);
                  const totRecs = res.data.data.total_records?res.data.data.total_records:0;
                  setTotalRecords(totRecs);
                  setNpages(Math.ceil(totRecs / recordsPerPage));
              }
              setLoading(false);
            }).catch((er) => {
                  setTransactions([]);
                  setTranBalance(0);
                  setTotalRecords(0);
                  setLoading(false);
                  handleError(er, true);
            });
      } catch(error) {
          setTransactions([]);
          setTranBalance(0);
          setTotalRecords(0);
          setLoading(false);
          handleError(error)
      }
  };

  // on records per page filter
  const selectRecordPerPage = (e) => {
      setRecordsPerPage(e.target.value);
  };
  // paginate button click
  const nextPage = () => {
      if(currentPage !== nPages) {
          setCurrentPage(currentPage + 1);
      }
  }
  const prevPage = () => {
      if(currentPage !== 0) {
          setCurrentPage(currentPage - 1)
      }
  }

  // change graph
  const changeRepaymentGraph = (value,selValue) => {
    if(selValue === 'All') {
      getRepaymentGraph('yearly', selValue);
    } else {
      getRepaymentGraph(value, selValue);
    }
  }
  // get all clients repayment graph details
  // monthly?selected_date=2022, weekly?selected_date=2022-10, daily?selected_date=2022-09-25_2022-10-01
  const getRepaymentGraph = (isSelection, value) => {
    try {
        const lastStr = isSelection === 'yearly' ? 'yearly' : `${isSelection}?selected_date=${value}`;
        const fetchApiUrl = `${adminDashboardRepaymentGraphAPI+lastStr}`;
        setLoadingBar(true);
        HttpCall(fetchApiUrl, "GET").then((res) => {
            if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
              const record = res.data.data;
                let axisLabels = Object.keys(record);
                let uniqueCompany = [];
                let datasetClient = [];
                let rawData = [];
                if(axisLabels && axisLabels.length > 0) {
                  if(isSelection === 'yearly') {
                    setYMWDObject({month: axisLabels, week:[], day:[]});
                    setMonthVal('');
                    setWeekVal('');
                    setDailyVal('');
                  } else if(isSelection === 'monthly') {
                    setYMWDObject({...YMWDObject ,week: axisLabels, day:[]});
                    setWeekVal('');
                    setDailyVal('');
                  } else if(isSelection === 'weekly') {
                    setYMWDObject({...YMWDObject, day: axisLabels});
                    setDailyVal('');
                  }
                  axisLabels.forEach((itm,index) => {
                    const datasetKeys = Object.keys(record[itm]);
                      rawData[index] = [];
                      datasetKeys.forEach((ds) => {
                          rawData[index].push(record[itm][ds]);
                          if(record[itm]&&record[itm][ds] && !uniqueCompany.includes(record[itm][ds]['company_name'])) {
                            uniqueCompany.push(record[itm][ds]['company_name']);
                            const color = colors[(Math.floor(Math.random() * colors.length))];
                            const setObject = { barPercentage: 0.8, barThickness: 29, maxBarThickness: 20, minBarLength: 4,
                              label: record[itm][ds]['company_name'],
                              backgroundColor: color, borderColor: color,
                              borderWidth: 1, stack: 1,
                              hoverBackgroundColor: "rgba(255,99,132,0.4)", hoverBorderColor: "rgba(255,99,132,1)",
                              data: [],
                            };
                            datasetClient.push(setObject);
                          }
                      });
                  });
                  if(rawData && rawData.length > 0) {
                    rawData.forEach((raw) => {
                      datasetClient.forEach((cl,yi) => {
                        let yoAmount = 0;
                        raw.forEach((ele) => {
                          if(ele.company_name === cl.label) {
                            yoAmount = ele.repaid_amount;
                          };
                        });
                        datasetClient[yi].data.push(yoAmount?yoAmount:0);
                      });
                    });
                  }
                  setDataBar({ labels : axisLabels, datasets: datasetClient });
                } else {
                  setDataBar({ labels : [], datasets: [] });
                }
                setLoadingBar(false);
            } else {
                setLoadingBar(false);
                if(res && res.data && res.data.message) {
                    Swal.fire({ title: "Error", text: res.data.message ? res.data.message : 'Graph fetch failed', icon: "error", button: false});
                }
            }
        }).catch((error) => {
            setLoadingBar(false);
            handleError(error);
        });
    } catch (ex) {
        setLoadingBar(false);
    }
  }
  // get all clients repayment progress graph
  const getRepaymentProgressGraph = () => {
    try {
        setLoadingProg(true);
        HttpCall(adminDashboardRepaymentProgressGraphAPI, "GET").then((res) => {
            if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
              const record = res.data.data;
                let monthsLabels = Object.keys(record);
                if(monthsLabels && monthsLabels.length > 0) {
                    const clientNames = [];
                    const percentagePaid = [];
                    const percentageDue = [];
                    monthsLabels.forEach((itm) => {
                      clientNames.push(record[itm]['company_name']);
                      const repaidPerc = record[itm]&&record[itm]['repaid_percentage'] ? record[itm]['repaid_percentage'] : 0;
                      const actualPerc = repaidPerc > 100 ? 100 : repaidPerc < 0 ? 0 : repaidPerc;
                      percentagePaid.push(actualPerc);
                      percentageDue.push(100 - actualPerc);
                    });
                    setDataProgressBar({ labels : clientNames,
                        datasets: [
                          { indexAxis: "y", // barPercentage: 0.8, barThickness: 15, maxBarThickness: 20, minBarLength: 4, 
                            label: "Repayment Made",
                            backgroundColor: "#2283FB", borderColor: "#2283FB", 
                            borderWidth: 1, stack: 1, 
                            hoverBackgroundColor: "rgba(255,99,132,0.4)", hoverBorderColor: "rgba(255,99,132,1)", 
                            data: percentagePaid
                          },
                          { indexAxis: "y", // barPercentage: 0.8, barThickness: 15, maxBarThickness: 20, minBarLength: 4, 
                            label: "Full Repayment Due",
                            backgroundColor: "#6ba2e6", borderColor: "#6ba2e6",
                            borderWidth: 1, stack: 1, 
                            hoverBackgroundColor: "rgba(255,99,132,0.4)", hoverBorderColor: "rgba(255,99,132,1)", 
                            data: percentageDue
                          }
                        ],
                    });
                    setLoadingProg(false);
                  } else {
                  setDataProgressBar({ labels : [], datasets: []}); setLoadingProg(false);
                }
            } else {
                setDataProgressBar({ labels : [], datasets: []});
                setLoadingProg(false);
                if(res && res.data && res.data.message) {
                    Swal.fire({ title: "Error", text: res.data.message ? res.data.message : 'Graph fetch failed', icon: "error", button: false});
                }
            }
        }).catch((error) => {
          setDataProgressBar({ labels : [], datasets: []});
          setLoadingProg(false);
          handleError(error);
        });
    } catch (ex) {
        setLoadingProg(false);
    }
  }

  // check detaction of zoom for graphs
  const reportChange = useCallback((state, handle) => {
    if (handle === screen1) {
      if(state) {
        setStyleObjRepay({width:"100%", height:"100%"});
      } else {
        setStyleObjRepay({width:"90%"});
      }
    }
    if (handle === screen2) {
      if(state) {
        setStyleObjRepayProg({width:"100%", height:"100%"});
      } else {
        setStyleObjRepayProg({width:"90%"});
      }
    }
  }, [screen1, screen2]);

  return (
    <>
      <NavBar  admin="true"/>
      <div className="container ">
        <InfoBar data={transactionData} loadingData={loadingData} />
        <div className="card">
          <div className="card-body">
            <div className="charts-title">All Client Repayments</div>
            {
              loadingBar ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
              :
              <>
                <div style={{marginTop:'-25px'}}>
                  {YMWDObject &&
                  <>
                      <div className="row" style={{float:'right',paddingLeft:30}}>
                        <button onClick={screen1.enter} title="Zoom" style={{borderRadius:5,backgroundColor:'#fff',padding:'5px 10px 5px 10px',border:'1px solid #0d6efd'}}><i className="fa fa-expand"></i></button>
                      </div>
                      {YMWDObject.day && YMWDObject.day.length > 0 &&
                        <div className="row" style={{float:'right',paddingLeft:30}}>
                          <select placeholder="Show Daily" className="form-select" value={dailyVal} onChange={(e)=>{ setDailyVal(e.target.value); changeRepaymentGraph('daily',e.target.value) }}>
                              <option disabled value={''}>Show Daily</option>
                              {YMWDObject.day.map((dy,i) => 
                                <option key={i} value={dy}>{dy}</option>
                              )}
                          </select>
                        </div>
                      }
                      {YMWDObject.week && YMWDObject.week.length > 0 &&
                        <div className="row" style={{float:'right',paddingLeft:30}}>
                          <select placeholder="Show Weekly" className="form-select" value={weekVal} onChange={(e)=>{ setWeekVal(e.target.value); changeRepaymentGraph('weekly',e.target.value) }}>
                              <option disabled value={''}>Show Weekly</option>
                              {YMWDObject.week.map((wk,i) => 
                                <option key={i} value={wk}>{wk}</option>
                              )}
                          </select>
                        </div>
                      }
                      {YMWDObject.month && YMWDObject.month.length > 0 &&
                      <div className="row" style={{float:'right'}}>
                        <select placeholder="Show Monthly" className="form-select" value={monthVal} onChange={(e)=> { setMonthVal(e.target.value); changeRepaymentGraph('monthly',e.target.value)} }>
                            <option disabled value={''}>Show Monthly</option>
                            <option value={'All'}>Show All Yearly</option>
                            {YMWDObject.month.map((mon,i) => 
                              <option key={i} value={mon}>{mon}</option>
                            )}
                        </select>
                      </div>
                      }
                  </>
                  }
                </div>
                <FullScreen handle={screen1} onChange={reportChange}>
                  <Bar data={dataBar} options={optionsBar} style={styleObjRepay} className="mt-5" />
                </FullScreen>
              </>
            }
          </div>
        </div>
        <div className="card mt-4">
          <div className="card-body">
            <div className="charts-title"> Repayments Progress
              <button onClick={screen2.enter} title="Zoom" style={{float:'right',borderRadius:5,backgroundColor:'#fff',padding:'5px 10px 5px 10px',border:'1px solid #0d6efd'}}><i className="fa fa-expand"></i></button>
            </div>
            {
              loadingProg ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
              :
              <>
                <FullScreen handle={screen2} onChange={reportChange}>
                  <Bar data={dataProgressBar}  options={optionsProgressBar}
                    style={styleObjRepayProg} className="mt-5" />
                </FullScreen>
              </>
            }
          </div>
        </div>
        <div className="card mt-4">
            <div className="card-body">
                <h5 className=""><b>Recent Transactions</b></h5>
                { recentTransactionData&&recentTransactionData.rows&&recentTransactionData.rows.length > 0 ? 
                    <MDBDataTableV5
                        striped bordered hover
                        data={recentTransactionData}  searchBottom={false} //searchTop
                        fullPagination entriesOptions={[5, 10, 20, 50, 100]}
                        paging={!(recentTransactionData&&recentTransactionData.rows&&recentTransactionData.rows.length <= "4")}
                    />
                    :
                    <div className="dierector-content mt-3 "
                        style={{
                            borderRight: "15px solid #003056",
                            borderLeft: "15px solid #003056",
                        }} >
                        <p className="table-font-color m-0"> <b>Recent Transactions not found.</b> </p>
                    </div>
                }
            </div>
        </div>

        <div className="card mt-4">
          <div className="card-body">
            <h2> Client's Modulr Transactions </h2>
            <h5>Balance : {tranBalance?'£'+formatAmount(tranBalance):''}</h5>
            <table className="table table-bordered table-striped" >
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Posted Date</th>
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions && transactions.length > 0 && transactions.map((tran,i) =>
                            <tr key={i}>
                                <td>{tran.id?tran.id:''}</td>
                                <td>{tran.transactionDate ? getDateDDMMYYYY(tran.transactionDate) : ''}</td>
                                <td>{tran.postedDate ? getDateDDMMYYYY(tran.postedDate) : ''}</td>
                                <td>{tran.amount?'£'+formatAmount(tran.amount):'0'}</td>
                                <td>{tran.currency?tran.currency:''}</td>
                                <td>{tran.description ? tran.description:''}</td>
                            </tr>
                        )}
                    </tbody>
                    {transactions && transactions.length <= 0 && 
                        <tfoot>
                            <tr><td colSpan={8}>
                                <div className="dierector-content mt-3 "
                                    style={{
                                        borderRight: "15px solid #003056",
                                        borderLeft: "15px solid #003056",
                                    }} >
                                    <p className="table-font-color m-0"> <b>{message}</b> </p>
                                </div>
                            </td></tr>
                        </tfoot>
                    }
                </table>
                {transactions && transactions.length > 0 &&
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <label>Rows per page : </label>
                            <select placeholder="Select" value={recordsPerPage} onChange={selectRecordPerPage}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                            </select>
                        </div>
                        <ul className="pagination">
                            <li className={currentPage !== 0 ? "page-item ":"page-item disabled"}>
                                <a className="page-link" tabIndex="-1" onClick={prevPage}>Previous</a>
                            </li>
                            <li className={currentPage !== nPages && nPages > 1 && (currentPage+1 < nPages) ? "page-item ":"page-item disabled"}>
                                <a className="page-link"  onClick={nextPage}>Next</a>
                            </li>
                        </ul>
                    </div>
                }
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
