import logo from "../../img/logo-light.png";
import Loginpic from "../../img/img-19.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logInData, loginSuccess } from "../../redux/action/login";
import { useEffect, useState } from "react";
import { getFundedData, getTokenSuccess } from "../../redux/action/getfunded";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { HttpCall } from "../../services/UseHttps";
import { clientCompanyApi, generateOtpAPI, verifyOtpAPI } from "../../config";
import Swal from "sweetalert2";
// import Modal from "react-bootstrap/Modal";
import { decryptString, encryptString } from "../../services/CommonFunction";
import { ErrorHandler } from "../../components/Auth/errorHandler";
const LoginForm = (props) => {
    useEffect(() => { document.title = props.title;
    }, []);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    
    // otp
    const [showOTP, setShowOTP] = useState(false);
    const [otpCount, setOtpCount] = useState(0);
    const [otpError, setOtpError] = useState('');
    const [loadingOtp, setLoadingOtp] = useState(false);
    const [otpSuccess, setOtpSuccess] = useState("");
    const numePattern = /^\d+$/;

    const Navigate = useNavigate();
    const dispatch = useDispatch();

    const [requestBody, setRequestBody] = useState({}); 
    const LoginSuccessData = useSelector((state) => state.LogIn && state.LogIn.loginData);
    const LoginErrorData = useSelector((state) => state.LogIn && state.LogIn.error);
    const token = useSelector((state) => state.GetFunded && state.GetFunded.token);
    const isClient = useSelector((state) => state.GetFunded && state.GetFunded.is_client)
    const signupRes = useSelector((state) => state.GetFunded.getfundedData);
    const signInError = useSelector((state) => state);
    // company selection
    const [companies, setCompanies] = useState([]);
    const [isCompanies, setIsCompanies] = useState(false);

    useEffect(() => {
        if(LoginSuccessData && LoginSuccessData.token && requestBody && requestBody.email) {
            setShowOTP(true);
            setOtpSuccess('Verification code has been sent to your registered mobile number.');
        }
    },[LoginSuccessData]);
    useEffect(() => {
        if(LoginErrorData) {
            setLoading(false);
            setLoadingOtp(false);
        }
    },[LoginErrorData]);
    
    useEffect(() => {
        if (token && isClient === false) {
            localStorage.setItem('token', token);
            const userData = localStorage.getItem('userData');
            if(userData) {
                const user = userData ? JSON.parse(decryptString(userData)) : {};
                localStorage.setItem('userData', encryptString(JSON.stringify({ email: user.email })));
            } else {
                localStorage.setItem('userData', encryptString(JSON.stringify({email: requestBody.email})));
            }
            if(requestBody && requestBody.email) {
                localStorage.setItem('fromLogin', 'true');
                dispatch(getFundedData({'email': requestBody.email}));
            }
            setLoading(false)
            setLoadingOtp(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    useEffect(() => {
        if (signupRes) {
            Navigate('/getFunded',{state: { signupRes, email: requestBody.email } });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signupRes])

    useEffect(() => {
        if (signInError) {
            setLoading(false);
            setLoadingOtp(false);
        }
    }, [signInError]);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email address is required')
            .email('Email address is invalid'),
        password: Yup.string()
            .required('Password is required')
    });
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: green;
    `;
    const handleSubmit = (formValues) => {
        setErrorMsg('');
        const loginValue = {
            'email': formValues.email,
            'password': formValues.password
        }
        setRequestBody({'email': formValues.email, 'password': formValues.password});
        const allRequest = {
            request : loginValue,
            Navigate: Navigate
        };
        setLoading(true);
        dispatch(logInData(allRequest, setErrorMsg));   
    }

    // continue login if otp verified.
    const loginContinue = () => {
        if(LoginSuccessData && LoginSuccessData.token) {
            localStorage.setItem('token', LoginSuccessData.token);
            const is_admin = LoginSuccessData.is_admin;
            // const is_client = LoginSuccessData.is_client;
            const companyList = LoginSuccessData.company_list ? LoginSuccessData.company_list : [];
            setCompanies(companyList);
            if (is_admin===true) {
                dispatch(loginSuccess(""));
                Navigate('/admin-dashboard');
                localStorage.setItem('role', encryptString('admin'));
                return;         
            }
            // if user is not admin then give company selection
            if(companyList && companyList.length && companyList.length === 1) {
                const userID = companyList[0].parent_user_id;
                setIsCompanies(false);
                callClientCompany(userID);
            } else if(companyList && companyList.length > 1) {
                setIsCompanies(true);
            } else {
                dispatch(loginSuccess(""));
                Swal.fire({icon: 'error',title: 'Login Error', text: 'User is not linked with any company.'});
                return;
            }
            // if (is_admin===false && is_client === false) {
            //     // Navigate('/getFunded')
            //     dispatch(getTokenSuccess({data:LoginSuccessData}));
            //     localStorage.setItem('role', encryptString('funded'));
            //     return;         
            // }
            // localStorage.setItem('role', encryptString('client'));
            // localStorage.setItem('cmplt', encryptString(JSON.stringify(companyList)));
            // Navigate('/client-dashboard')
        }
    }
    // client-company API call - clientCompanyApi
    const callClientCompany = (userId) => {
        try {
            setLoading(true);
            HttpCall(`${clientCompanyApi}`, "POST", {parent_user_id: userId}).then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data && res.data.token) {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('pid', encryptString(userId));
                    setIsCompanies(false);
                    // is_client false then redirect to getFunded page
                    if (res.data.is_admin===false && res.data.is_client === false) {
                        dispatch(getTokenSuccess({data: res.data}));
                        localStorage.setItem('role', encryptString('funded'));
                        return;
                    }
                    localStorage.setItem('role', encryptString('client'));
                    localStorage.setItem('cmplt', encryptString(JSON.stringify(companies)));
                    Navigate('/client-dashboard')
                    dispatch(loginSuccess(""));
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                ErrorHandler(error);
            });
        } catch (ex) {
            setLoading(false);
        }
    }
    // company selection
    const onSelectCompany = (e) => {
        callClientCompany(e.target.value);
    }

    // otp
    const otpSchema = Yup.object().shape({
        otp: Yup.string().required('Verification code is required')
            .matches(numePattern, 'Verification code is allows only numbers.')
            .max(10, "Please enter valid verification code.")
    });
    const sendUserOTP = (email) => {
        try {
            setOtpSuccess('');
            setLoadingOtp(true);
            HttpCall(`${generateOtpAPI}`, "POST", { "email": email }).then((res) => {
                if (res && res.status && (res.status === 200 || res.status === 201)) {
                    // setShowOTP(true);
                    setOtpSuccess('Verification code sent to your registred mobile number.');
                    // Swal.fire({icon: 'success',title: 'Success', text: 'Verification code sent to your registred mobile number.'});
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: 'Fail to send verification code on mobile number.'});
                }
                setLoading(false);
                setLoadingOtp(false);
            }).catch((error) => {
                Swal.fire({icon: 'error',title: 'Error',
                    text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to send verification code.'
                });
                setLoading(false);
                setLoadingOtp(false);
            });
        } catch(err) {
            setLoading(false);
            setLoadingOtp(false);
        }
    }
    const closeOtpModel = () => {
        setShowOTP(false);
        setLoading(false);
        setLoadingOtp(false);
        setOtpCount(0);
        setOtpError('');
        setOtpSuccess('');
    }
    const verifyOTPHandle = (values) => {
        try {
            if(values && values.otp) {
                setOtpSuccess('');
                setLoadingOtp(true);
                let body = { "email": requestBody.email, "otp": values.otp };
                HttpCall(`${verifyOtpAPI}`, "POST", body).then((res) => {
                    if (res && res.data && res.status && (res.status === 200 || res.status === 201)) {
                        if(res.data["otp_status"] === "approved") {
                            setShowOTP(false);
                            Swal.fire({icon: 'success',title: 'Success', text: 'Verification code is verified successfully.'});
                            loginContinue();
                        } else {
                            setOtpError('Verification code does not match.');
                        }
                    } else {
                        setOtpError('Fail to verify code.');
                    }
                    setLoadingOtp(false);
                }).catch((error) => {
                    Swal.fire({icon: 'error',title: 'Error',
                        text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to verify verification code.'
                    });
                    setLoadingOtp(false);
                });
            }
        } catch(err) {
            setLoadingOtp(false);
        }
    }
    const reSendOtpHandle = () => {
        setOtpSuccess('');
        if(otpCount < 3) {
            if(requestBody && requestBody.email) {
                setOtpCount(otpCount+1);
                sendUserOTP(requestBody.email);
            } else {
                setOtpError('Email-id not found to send verification code.')
            }
        } else {
            setOtpError('You exceeded re-send verification code limit.');
        }
    }

    return (
        <>
            <section className="overflow-hidden div-height">
                <div className="row div-height">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="background h-100">
                            <div className="text-right px-4 pt-3 pb-5">

                                <Link to="/">
                                    <img src={logo} alt="logo" width="200" height="20" className="" />
                                </Link>

                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">

                                    <img className="text-center" src={Loginpic} alt="welcome" height="350" width="400" />
                                    <h1 className="text-white mb-4">Barlow Marshall</h1>
                                    {/* <p className="text-white mb-4">Lorem ipsum dolor sit amet, cons aring elit sed dllao the eimod tempor inciunt ullaco laboris aliquip alora ullaco laboris aliquip inciunt ullaco.</p> */}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-4">
                                <div>
                                    <Link className="text-white PX-2" to={'/terms-conditions'}>Terms and Conditions</Link>
                                    <span className="text-white mx-2"> | </span>
                                    <Link className="text-white px-2" to={'/privacy-policy'}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="signup-box p-5 mt-2">
                            {!showOTP && !isCompanies ? 
                                <>
                                    <h1 className="text-center mb-4"><b>Log In</b></h1>
                                    {errorMsg && <div className="alert alert-danger" role="alert">{errorMsg}</div> }
                                    <Formik
                                        initialValues={{ email: "", password: "", checkbox: false }}
                                        validationSchema={LoginSchema}
                                        onSubmit={(values) => {
                                            handleSubmit(values)
                                        }}
                                    >
                                        {({ touched, errors, isSubmitting, values, }) =>
                                            !loading ? (
                                                <div>
                                                    <Form className="custom-form">
                                                        <div className="mb-3">
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                placeholder="Email address"
                                                                id="email"
                                                                autoComplete="off"
                                                                className={`mt-2 form-control
                                ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                            />

                                                            <ErrorMessage
                                                                component="div"
                                                                name="email"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="input-group mb-3">
                                                            <Field
                                                                type={passwordShown ? "text" : "password"}
                                                                name="password"
                                                                placeholder="Password"
                                                                id="password"
                                                                autoComplete="off"
                                                                className={`mt-2 form-control
                                ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                            />
                                                            <div className="mt-2">
                                                                <div className="input-group-text" tabIndex="0" onKeyDown={(e) => e.keyCode === 32 ? setPasswordShown(!passwordShown) : () => {} }>
                                                                    {passwordShown ? <i className="far fa-eye" id="togglePassword" onClick={()=> setPasswordShown(!passwordShown) } ></i> : <i className="far fa-eye-slash" id="togglePassword" onClick={()=>setPasswordShown(!passwordShown)} ></i>}

                                                                </div>
                                                            </div>
                                                            <ErrorMessage
                                                                component="div"
                                                                name="password"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <button type="submit" className="mb-4 btn btn-custom btn-custom-2 btn-custom-no-white-shadow">
                                                            {loading ? <><ClipLoader css={override} size={150} /></> : <>Login</> }
                                                        </button>
                                                        <br />
                                                    </Form>
                                                    <Link to="/forgot-password" className="text-decoration-none">Forgot your password?</Link>
                                                    <br />
                                                </div>
                                            ) : (
                                                <> <ClipLoader css={override} size={150} /> </>
                                            )
                                        }
                                    </Formik>
                                </>
                            : showOTP && !isCompanies ?
                                <>
                                    <h1 className="text-center mb-4"><b>Verify OTP</b></h1>
                                    <Formik initialValues={{ otp: "" }} validationSchema={otpSchema}
                                        onSubmit={(values) => { verifyOTPHandle(values) }}>
                                    {({ touched, errors, values, handleChange}) =>
                                        <Form className="custom-form">
                                            {loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                                :
                                                <>
                                                    { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' }
                                                    <div className="mb-3">
                                                        <Field
                                                            type="text" name="otp" value={values.otp} onChange={handleChange}
                                                            id="otp" placeholder="Enter OTP" autoComplete="off"
                                                            aria-describedby="businessHelp" className={`mt-2 form-control
                                                            ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                                                        />
                                                        <ErrorMessage component="div" name="otp" className="invalid-feedback"/>
                                                        { otpError ? <div className='alert alert-danger'> {otpError} </div> : '' }
                                                    </div>
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-link" onClick={reSendOtpHandle}>Resend OTP</button>
                                                        <button type="button" className="btn btn-link" onClick={closeOtpModel} style={{float:'right'}}>Back to login</button>
                                                    </div>
                                                    <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                                                        >Verify</button>
                                                    {/* { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' } */}
                                                </>
                                            }
                                        </Form>
                                        }
                                    </Formik>
                                </>
                            :
                                <>
                                    {/* Make Company Selection : If user has multiple company */}
                                    {isCompanies&&companies&&companies.length > 0 && <>
                                        <h1 className="text-center mb-4"><b>Select Company</b></h1>
                                        <div className="mb-2">
                                            <select className="form-select" name="company" value="" onChange={onSelectCompany}
                                                placeholder="Select Company" aria-label="Default select example">
                                                <option disabled={true} value="">Select Company</option>
                                                {companies.map((comp,i) => <option key={i} value={comp.parent_user_id}>{comp.company_name}</option> )}
                                            </select>
                                        </div>
                                    </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* <Modal show={showOTP} onHide={closeOtpModel} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"> Verify OTP </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card ">
                        <div className="card-body">
                        <Formik initialValues={{ otp: "" }}
                            validationSchema={otpSchema}
                            onSubmit={(values) => { verifyOTPHandle(values) }}
                        >
                        {({ touched, errors, values, handleChange}) =>
                            <Form className="custom-form">
                                {loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                    :
                                    <>
                                        <div className="mb-3">
                                            <Field
                                                type="text" name="otp" value={values.otp} onChange={handleChange}
                                                id="otp" placeholder="Enter OTP" autoComplete="off"
                                                aria-describedby="businessHelp" className={`mt-2 form-control
                                                ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                                            />
                                            <ErrorMessage component="div" name="otp" className="invalid-feedback"/>
                                            { otpError ? <div className='alert alert-danger'> {otpError} </div> : '' }
                                        </div>
                                        <div className="mb-3">
                                            <button type="button" className="btn btn-link" onClick={reSendOtpHandle}>Resend OTP</button>
                                        </div>
                                        <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                                            >Verify</button>
                                        { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' }
                                    </>
                                }
                            </Form>
                            }
                        </Formik>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    )
};

export default LoginForm;