import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { decryptString } from "../../services/CommonFunction";
import { HttpCall } from "../../services/UseHttps";
import { yapilyConsentAccountsApi } from "../../config";
import { ErrorHandler } from "../../components/Auth/errorHandler";

const YapilyVerifyPage = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const decyptData = localStorage.getItem('role');
    const role = decyptData ? decryptString(decyptData) : null;
    const pageUrl = window.location.href;
    const url = new URL(pageUrl);
    var consent = getQueryVariable('consent'); // url.searchParams.get("consent");
    var applicationUserId = url.searchParams.get("application-user-id");
    var userUuid = url.searchParams.get('user-uuid');
    var institution = url.searchParams.get('institution');
    const error = url.searchParams.get('error');
    const errorSource = url.searchParams.get('error-source');
    const errorDescription = url.searchParams.get('error-description');
    const data = { consent, applicationUserId, userUuid, institution, error, errorSource, errorDescription };
    const tokenData = localStorage.getItem('tokenData');
    const fromYDS = localStorage.getItem('fromYDS');
    useEffect(() => {
        fetchData();
        document.title = props.title;
    }, []);
    
    // get multiple consent list from query-string
    function getQueryVariable(variable) {
        const consents = [];
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                consents.push(decodeURIComponent(pair[1]));
            }
        }
        return consents;
    }

    const fetchData = () => {
        if (role === 'client' && data && tokenData) {
            setLoading(true);
            const tokenDataJson = JSON.parse(tokenData);
            if(data && data.consent && data.consent.length > 0 && tokenDataJson && tokenDataJson.consent_id) {
                const param = {consent_created_at: tokenDataJson.consent_created_at,
                    consent_expires_at:tokenDataJson.consent_expires_at, consent_id:tokenDataJson.consent_id,
                    consent_tokens:data.consent, institution_id:tokenDataJson.institution_id};
                const requestMethod = fromYDS ? "PATCH" : "POST";
                HttpCall(`${yapilyConsentAccountsApi}`, requestMethod, param).then((res) => {
                    if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
                      Swal.fire({ icon: 'success', title: 'Success', text: 'Authorization completed successfully.' });
                    //   localStorage.setItem('tokenData', JSON.stringify(res.data.data?res.data.data:[]));
                      localStorage.removeItem('tokenData');
                      localStorage.removeItem('intId');
                      localStorage.removeItem('fromYDS');
                      navigate('/client-yapily/dashboard');
                    } else {
                        if(res.data&&res.data?.info&&res.data?.info!==''){
                            Swal.fire({ icon: 'info',iconColor: '#ff0101', 
                            title: 'IMPORTANT', customClass : { title: 'swal2-info-title'},
                              text: res.data?.info ? res.data?.info : 'Authorize Accounts not found.' });
                        } else {
                            Swal.fire({ icon: 'error', title: 'Error', text: res.data&&res.data?.error ? res.data?.error : 'Authorize Accounts not found.' });
                        }
                        localStorage.removeItem('tokenData');
                        localStorage.removeItem('fromYDS');
                        navigate('/login');
                    }
                    setLoading(false);
                  }).catch((error) => {
                    setLoading(false);
                    ErrorHandler(error);
                  });
            } else if (data && data.error) {
                Swal.fire({ icon: 'error', title: 'Error', text: data.error+' - Authorization failed' });
                localStorage.removeItem('tokenData');
                localStorage.removeItem('fromYDS');
                navigate('/login');
            }
            setLoading(false);
        } else {
            Swal.fire({ icon: 'error', title: 'Error', text: 'Please SignUp Again' });
            localStorage.removeItem('tokenData');
            localStorage.removeItem('fromYDS');
            navigate('/login');
        }
    }

    return (
        <React.Fragment>
            {
                !loading ?
                    <>
                        <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                    </>
                    :
                    null
            }
        </React.Fragment>
    )
};

export default YapilyVerifyPage;