import OverviewLeft from "../../img/check-dashboard.png";
import OverviewRight from "../../img/check-inner-circle.png";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { useEffect, useState } from "react";
import { toTitleCase } from "../../services/CommonFunction";
import { useSelector } from "react-redux";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement
);



// const activeStep =5;

const Overview = ({profileData}) => {
    const userData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj);
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        if(userData && userData.user_id) {
            // setFirstName(toTitleCase(userData.first_name?userData.first_name:''));
            if(profileData && profileData.offer && profileData.offer.status) {
                if(profileData.offer.status && profileData.offer.status === 'pending') {
                    setActiveStep(4); // setActiveStep(1); // setActiveStep(2) setActiveStep(3)
                } else if(profileData.offer.status && profileData.offer.status === 'accepted') {
                    setActiveStep(5);
                }
            }
        }
    }, [userData]);

    return (
        <>
            {/* <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h6>Application Status</h6>
                            <hr />
                            <Doughnut
                                data={data}
                                options={options}
                                style={{ width: "70%" }}

                            />
                            <span className="Applied">Applied</span>
                            <span className="verification">KYC verification</span>
                            <span className="tooltip-lebel">Eligible</span>
                            <span className="Awaiting">Awaiting Offer</span>
                            <div className="text-center chart-lebel">
                                <h2 className="text-primary m-0"><b>18</b></h2>
                                <p className="text-primary m-0"><b>Applications</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                    <div className="card">
                        <div className="card-body">
                            <h6>Different Bank Accounts Transactions Over Time</h6>
                            <hr />
                            <Line data={linedata}
                                options={optionss}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                    <div className="card">
                        <div className="card-body">
                            <h6>Annual Revenue</h6>
                            <hr />
                            <Doughnut
                                data={annualdata}
                                options={annualoption}
                            />
                            <div className="text-center chart-lebel-annual">
                                <h1 className="text-primary m-0"><b>25%</b></h1>
                                <p className="text-primary m-0"><b>Revenue</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                    <div className="card h-100">
                        <div className="card-body">
                            <h6>Recent Transactions</h6>
                            <hr />
                            <table id="tableDataRecent" className="table table-bordered table-striped mb-0"
                                cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Reason</th>
                                        <th>Description</th>
                                        <th>Debited</th>
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>11 july 2019</td>
                                        <td>Ecom</td>
                                        <td>For friends</td>
                                        <td>£2000</td>
                                        <td>£9000</td>
                                    </tr>
                                    <tr>
                                        <td>12 july 2022</td>
                                        <td>Porsonal</td>
                                        <td>For parents</td>
                                        <td>£223</td>
                                        <td>£9000</td>
                                    </tr>
                                    <tr>
                                        <td>11 july 2022</td>
                                        <td>Ecom</td>
                                        <td>EMI</td>
                                        <td>£700</td>
                                        <td>£9000</td>
                                    </tr>
                                    <tr>
                                        <td>11 july 2019</td>
                                        <td>Food</td>
                                        <td>Swiggy</td>
                                        <td>£1200</td>
                                        <td>£9000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <div className="card">
                        <div className="card-body row">
                            <div className="col-12">Welcome to Barlow Marshall, {toTitleCase(userData.first_name?userData.first_name:'')}</div>
                            <hr />
                            <div className="col-12">
                                {/* <img src="./assets/images/check-dashboard.png" /> */}
                                <img src={OverviewLeft} alt="OverviewLeft" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <div className="card">
                        <div className="card-body row">
                            <div className="col-sm-12 col-lg-6">
                                <div className="outer-circle">
                                    <div className={`outer-dots ${activeStep === 1 ? 'outer-dots-active1' : 'outer-dots-animation'} ${activeStep > 1 ? 'previous-active' : ''}`}></div>
                                    <div className={`outer-dots ${activeStep === 1 ? 'outer-dots-active2-shift' : activeStep === 2 ? 'outer-dots-active2' : 'outer-dots-animation'} ${activeStep > 2 ? 'previous-active' : ''}`}></div>
                                    <div className={`outer-dots ${activeStep === 4 || activeStep === 5 ? 'outer-dots-active3-shift' : activeStep === 3 ? 'outer-dots-active3' : 'outer-dots-animation'} ${activeStep > 3 ? 'previous-active' : ''}`}></div>
                                    <div className={`outer-dots ${activeStep === 5 ? 'outer-dots-active4-shift' : activeStep === 4 ? 'outer-dots-active4' : 'outer-dots-animation'} ${activeStep > 4 ? 'previous-active' : ''}`}></div>
                                    <div className={`outer-dots ${activeStep === 5 ? 'outer-dots-active5' : 'outer-dots-animation'}`}></div>
                                    <div className="inner-circle">
                                        {/* <img src="./assets/images/check-inner-circle.png" /> */}
                                        <div className="m-auto">
                                            <img src={OverviewRight} alt="OverviewRight" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-6 check-list-container">
                                <div className={`check-list ${activeStep === 1 && 'active-check-list'} ${activeStep > 1 ? 'previous-active-text' : ''}`} >
                                    <div>Submit Application</div>
                                    <p>
                                        Your application has been submitted. Our team is currently
                                        checking your application. You will hear from us within 7
                                        working days.
                                    </p>
                                </div>
                                <div className={`check-list ${activeStep === 2 && 'active-check-list'} ${activeStep > 2 ? 'previous-active-text' : ''}`}>
                                    <div>Checking Eligibility</div>
                                    <p>
                                        Your application has been submitted. Our team is currently
                                        checking your application. You will hear from us within 7
                                        working days.
                                    </p>
                                </div>
                                <div className={`check-list ${activeStep === 3 && 'active-check-list'} ${activeStep > 3 ? 'previous-active-text' : ''}`}>
                                    <div>KYC Verification</div>
                                    <p>
                                        Your application has been submitted. Our team is currently
                                        checking your application. You will hear from us within 7
                                        working days.
                                    </p>
                                </div>
                                <div className={`check-list ${activeStep === 4 && 'active-check-list'} ${activeStep > 4 ? 'previous-active-text' : ''}`}>
                                    <div>Awaiting Verfication</div>
                                    <p>
                                        Your application has been submitted. Our team is currently
                                        checking your application. You will hear from us within 7
                                        working days.
                                    </p>
                                </div>
                                <div className={`check-list ${activeStep === 5 && 'active-check-list'} ${activeStep > 5 ? 'previous-active-text' : ''}`}>
                                    <div>Accept Offer</div>
                                    <p>
                                        Your application has been submitted. Our team is currently
                                        checking your application. You will hear from us within 7
                                        working days.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Overview;