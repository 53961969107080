import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from 'react-redux';
import { CodatLinkData } from "../redux/action/codat";
import { useNavigate } from "react-router-dom";
import { getFundedData } from "../redux/action/getfunded";
import Swal from 'sweetalert2';
import { decryptString } from "../services/CommonFunction";

const CodatLinkVerify = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const codatData = useSelector((state) => state.Codat && state.Codat.codatLinkData);
    const codatDataError = useSelector((state) => state.Codat && state.Codat.errors);
    const decyptData = localStorage.getItem('userData');
    const signupData = decyptData ? JSON.parse(decryptString(decyptData)) : null;
    const signupRes = useSelector((state) => state && state.GetFunded && state.GetFunded.getfundedData); // signup response data

    const pageUrl = window.location.href
    const url = new URL(pageUrl)
    var companyId = url.searchParams.get("companyId");
    var flow = url.searchParams.get('flow');
    var statusCode = url.searchParams.get('statuscode');
    const errorMessage = url.searchParams.get('errormessage');
    const data = { companyId, flow, statusCode, errorMessage };

    useEffect(() => {
        fetchData();
        document.title = props.title;
    }, []);

    const fetchData = () => {
        if (!signupData) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please SignUp Again',
            });
            navigate('/get-funded');
        }
        if (data && signupData && data.companyId) {
            setLoading(true)
            dispatch(CodatLinkData(data));
        }
    }

    // useEffect(()=>{
    //     dispatch(CodatLinkData(data));
    // }, [data, signupData])

    useEffect(() => {
        if (codatData) {
            setLoading(false);
            if (codatData && codatData.status && (codatData.status === 201||codatData.status === 200)) {
                dispatch(getFundedData(signupData)); // call signup api after success codat verification...
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: codatData && codatData.message ? codatData.message : 'Your codat account verification failed.',
                });
                navigate('/getFunded');
            }
        }
    }, [codatData]);

    useEffect(() => {
        if (codatDataError) {
            if (codatDataError.status === 404) {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: codatDataError.data.message,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: codatDataError.data.message,
                });
                navigate('/get-funded');
            }
        }
    }, [codatDataError]);

    useEffect(() => {
        if (signupRes) {
            setLoading(false);
            navigate('/getFunded', { state: { signupRes, email: signupData.email } });
        }
    }, [signupRes]);


    return (
        <React.Fragment>
            {
                !loading ?
                    <>
                        <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                    </>
                    :
                    null
            }
        </React.Fragment>
    )
};

export default CodatLinkVerify;