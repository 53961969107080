import * as typeS from "../service/types";
import Swal from 'sweetalert2'
import { listTransactions } from "../service/ApiConfig";

export const listTransactionRequest = () => {
    return {
      type: typeS.LIST_TRANSACTION_REQUEST,
    };
  };
  
  export const listTransactionSuccess = (list) => {
    return {
      type: typeS.LIST_TRANSACTION_SUCCESS,
      payload: list,
    };
  };
  
  export const listTransactionError = (error) => {
    return {
      type: typeS.LIST_TRANSACTION_ERROR,
      payload: error,
    };
  };
  
  export const listTransactionData = (setLoading,clientid,source,days,status,account_number,currentPage,recordsPerPage) => {
    return (dispatch) => {
      try {
        listTransactions(clientid,source,days,status,account_number,currentPage,recordsPerPage).then((res) => {
          if (res&&res.status === 200) {
            dispatch(listTransactionSuccess(res?.data?.data));
            setLoading(false)
          } else {
            dispatch(listTransactionError("err.message"));
            setLoading(false);
          }
        }).catch((err) => {
          dispatch(listTransactionError(err));
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err,
          })
        });
      } catch (err) {
        // console.log(err);
      }
    };
  }
  