import * as types from "../service/types";

const InitState = {
  loading: false,
  ledgerPendingData: "",
  ledgerSettleInitiatedData: "",
  ledgerSettleData: "",
  ledgerChallengeData: "",
  error: "",
  settledData:"",
};
const LedgerTransaction = (state = InitState, action) => {
  switch (action.type) {
    case types.LEDGER_TRANSACTION:
      return {
        ...state,
        loading: true,
      };
    case types.LEDGER_TRANSACTION_SUCCESS:
      return {
        loading: false,
        ledgerPendingData: action.payload,
        error: "",
      };
    case types.LEDGER_TRANSACTION_FAILED:
      return {
        loading: true,
        ledgerPendingData: "",
        error: true,
      };
    case types.LEDGER_TRANSACTION_SETTLED_SUCCESS:
      return {
        loading: false,
        ledgerSettleData: action.payload,
        error: "",
      };
    case types.LEDGER_TRANSACTION_SETTLED_FAILED:
      return {
        loading: true,
        ledgerSettleData: "",
        error: true,
      };
    case types.LEDGER_TRANSACTION_CHELLENGED_SUCCESS:
      return {
        loading: false,
        ledgerChallengeData: action.payload,
        error: "",
      };
    case types.LEDGER_TRANSACTION_CHELLENGED_FAILED:
      return {
        loading: true,
        ledgerChallengeData: "",
        error: true,
      };
    case types.SETTLED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SETTLED_REQUEST_SUCCESS:
      return {
        loading: false,
        settledData: action.payload,
        error: "",
      };
    case types.SETTLED_REQUEST_FAILED:
      return {
        loading: true,
        settledData: "",
        error: true,
      };
    case types.SETTLED_INITIATED_REQUEST_SUCCESS:
      return {
        loading: false,
        ledgerSettleInitiatedData: action.payload,
        error: "",
      };
    case types.SETTLED_INITIATED_REQUEST_FAILED:
      return {
        loading: false,
        ledgerSettleInitiatedData: "",
        error: true,
      };
    default:
      return state;
  }
};

export default LedgerTransaction;