import React from "react";
import backed from "../img/venture-logo.svg";
import { Link } from "react-router-dom";

const HomePageBanner = () => {
  return (
    <>
      <section className="background-home-banner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="home-banner-sub-div">
                <div className="home-banner-inner">
                  <div className="home-banner-main-content">
                    <div className="home-banner-main-title">
                      FINANCING SaaS TO SCALE
                    </div>
                    <div className="home-banner-main-text">
                      We provide fast, flexible non-dilutive finance of between
                      £100k and <br />
                      £1.5m specifically designed to help SaaS scale
                    </div>
                  </div>
                  <button className="home-banner-main-button">
                    <Link to='/get-funded' style={{ color: 'white' }}>
                      Get Funded
                    </Link>
                  </button>
                </div>
              </div>
              {/* venture content starts here */}
              <div className="home-venture-content">
                <div className="home-venture-detail">
                  Backed by the UK’s leading
                  Digital Venture Builder
                </div>
                <div className="home-venture-logo align-self-center">
                  <img src={backed} alt="Blenheim Chalcot" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomePageBanner;
