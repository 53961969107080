import * as types from "../service/types";

const InitState = {
  loading: false,
  listChallengedTransactions: "",
  error: "",
};
const ListChallengedTransactionReducer = (state = InitState, action) => {
  switch (action.type) {
    case types.LIST_CHALLENGED_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LIST_CHALLENGED_TRANSACTION_REQUEST_SUCCESS:
      return {
        loading: false,
        listChallengedTransactions: action.payload,
        error: "",
      };
    case types.LIST_CHALLENGED_TRANSACTION_REQUEST_FAILED:
      return {
        loader: false,
        listChallengedTransactions: "",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ListChallengedTransactionReducer;