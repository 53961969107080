// import Modulr from "./dashboardTabs/Modulr";
// import NonModulr from "./dashboardTabs/NonModulr";
import Overview from "./dashboardTabs/Overview";
// import Summary from "./dashboardTabs/Summary";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import OverviewSummary from "./ledger/Overview";
import Sales from "./ledger/Sales";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate } from "react-router-dom";

const ClientDashboard = (props) => {
    const Navigate = useNavigate();
    const [modulrAlertShow, setModulrAlertShow] = useState(false);
    const profileData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);
    const userData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj);
    const modulrStatus = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.modulr_obj);
    const yapilyStatus = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.yapily_obj);
    useEffect(() => {
        // navigate to offer page if offer is pending
        const isViewOfferNavigated = localStorage.getItem('penoff');
        if((profileData&&profileData.offer&&profileData.offer.status&&profileData.offer.status==='pending') && !isViewOfferNavigated) {
            Navigate('/client-viewoffer');
            localStorage.setItem('penoff', 'true'); // offer is pending
        }
    });
    useEffect(() => {
        document.title = props.title;
        const modulrNoti = localStorage.getItem('mdralt');
        if(modulrNoti&&modulrNoti==='true') {
            setModulrAlertShow(false);
        } else {
            setModulrAlertShow(true);
        }
    }, []);
    // const [showDashboard, setShowDashboard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [yapilyShow, setYapilyShow] = useState('');
    const [modalYapilyExpShow, setModalYapilyExpShow] = useState(false);
    const [yapilyExpData, setYapilyExpData] = useState([]);

    // display yapily popup if offer-accepted and yapily is not integrated by client(display once after login)...
    useEffect(() => {
        const yaipyDisp = localStorage.getItem('yps');
        const offerStatus = profileData && profileData?.offer && profileData?.offer?.status;
        // if yapily enabled - yapily_integration_is_active === true && Parent User
        if((yapilyStatus&&yapilyStatus?.yapily_integration_is_active===true) && (userData && userData?.parent===1)) {
            if(yapilyStatus && yaipyDisp !== 'true' && offerStatus === 'accepted') {
                if(yapilyStatus?.verification_status&&yapilyStatus?.verification_status !== null) {
                    setYapilyShow('true');
                } else {
                    setYapilyShow('false');
                }
            } else { setYapilyShow(''); }
            // open yapily expired consent display model
            const yaipyExpConsDisp = localStorage.getItem('ypsex');
            if(yapilyStatus&&yapilyStatus?.consent_expiry_alert && yapilyStatus?.consent_expiry_alert?.data && yaipyExpConsDisp !== 'true' && offerStatus === 'accepted') {
                const yapData = yapilyStatus?.consent_expiry_alert?.data;
                const filterData = [];
                if(yapData) {
                    for(let key in yapData) {
                        if(yapData[key]&&yapData[key]>0) {
                            filterData.push({day: key, count: yapData[key]});
                        }
                    }
                }
                if(filterData && filterData.length > 0) {
                    setYapilyExpData(filterData);
                    if (Swal.isVisible()) {
                        Swal.close();
                        openYapilyExpModel();
                    } else {
                        openYapilyExpModel();
                    }
                }
            }
        }
    }, [yapilyStatus]);

    // hide modulr notofication alert
    const hideModulrAlert = () => {
        localStorage.setItem('mdralt','true');
        setModulrAlertShow(false);
    }

    // hide yapily notofication alert
    const hideYapilyAlert = () => {
        localStorage.setItem('yps','true');
        setYapilyShow('');
    }

    // open-close yapily inte model
    const openYapilyExpModel = () => {
        setModalYapilyExpShow(true);
        localStorage.setItem('ypsex','true');
    }
    const closeYapilyExpModel = () => {
        setModalYapilyExpShow(false);
    }

    return (
        <>
            <NavBar />

            <div  className="bg-light" style={{minHeight: "75vh"}}>
                <div className="container">
                    <div className="row py-3">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            {profileData && profileData.offer && profileData.offer.status && profileData.offer.status ==='accepted' && modulrAlertShow &&
                                <div>
                                    {    // verification_status = None | UNVERIFIED | VERIFIED
                                        modulrStatus&&modulrStatus.verification_status&&modulrStatus.verification_status === 'VERIFIED' ? 
                                            <div className="alert alert-success noti-alert alert-dismissible">
                                                <button onClick={hideModulrAlert} type="button" className="btn-close" style={{padding:'1rem 1rem', zIndex:0}}></button>
                                                <strong>Verified!</strong> Your modulr account is verified.
                                            </div>
                                        :
                                            <div className="alert alert-danger noti-alert alert-dismissible">
                                                <button onClick={hideModulrAlert} type="button" className="btn-close" style={{padding:'1rem 1rem', zIndex:0}}></button>
                                                <strong>Unverified!</strong> Your modulr account is not verified.
                                            </div>
                                    }
                                </div>
                            }
                            {yapilyShow? <div>
                                {yapilyShow === 'true' ? 
                                    <div className="alert alert-success noti-alert alert-dismissible">
                                        <button onClick={hideYapilyAlert} type="button" className="btn-close" style={{padding:'1rem 1rem', zIndex:0}}></button>
                                        Open banking (Yapily) solution has been integrated into your account.
                                    </div>
                                : yapilyShow === 'false' ?
                                    <div className="alert alert-danger noti-alert alert-dismissible">
                                        <button onClick={hideYapilyAlert} type="button" className="btn-close" style={{padding:'1rem 1rem', zIndex:0}}></button>
                                        <strong><Link to="/client-yapily/integration">Click here</Link></strong> to integrate the open banking (Yapily) solution into your account.
                                    </div>
                                :''}
                            </div> :''}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Tabs className="dashboard-tabs">
                                <TabList>
                                    {profileData && profileData.offer && profileData.offer.status && profileData.offer.status ==='accepted' ? 
                                        <>
                                            <Tab style={{ width: "50%" }}> Summary</Tab>
                                            <Tab style={{ width: "50%" }}> Sales & Repayments</Tab>
                                        </>
                                    :   
                                        <Tab style={{ width: "100%" }}> Overview</Tab> 
                                    }
                                    {/* <Tab disabledClassName="disable-tabs" disabled={!(Object.keys((profileData && profileData.offer)||{}).length !== 0 && profileData && profileData.offer && profileData.offer.status ==='accepted')} style={{ width: "25%" }}> Modulr Accounts</Tab>
                                    <Tab disabledClassName="disable-tabs" disabled={!(Object.keys((profileData && profileData.offer)||{}).length !== 0 && profileData && profileData.offer && profileData.offer.status ==='accepted')} style={{ width: "25%" }}>Non Modulr Accounts</Tab> */}
                                </TabList>

                                {profileData && profileData.offer && profileData.offer.status && profileData.offer.status ==='accepted' ? 
                                <>
                                    <TabPanel>
                                        <OverviewSummary transactionTabs={'Overview'} />
                                    </TabPanel>
                                    <TabPanel>
                                        <Sales transactionTabs={'Sales'} />
                                    </TabPanel>
                                </>
                                : 
                                    <TabPanel>
                                        <Overview userData={userData} profileData={profileData}/>
                                    </TabPanel>
                                }
                                {/* 
                                 // <TabPanel>
                                //     <Summary />
                                // </TabPanel>
                                <TabPanel>
                                    <Modulr />
                                </TabPanel>
                                <TabPanel>
                                    <NonModulr />
                                </TabPanel> */}
                            </Tabs>
                            {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation" style={{ width: "33.3%" }}>
                                    <button className="nav-link w-100 active" id="tab1tab" data-bs-toggle="tab"
                                        data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1"
                                        aria-selected="false">
                                        Overview
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: "33.3%" }}>
                                    <button className="nav-link w-100" id="tab2tab" data-bs-toggle="tab" data-bs-target="#tab2"
                                        type="button" role="tab" aria-controls="tab2" aria-selected="true">
                                        Summary
                                    </button>

                                </li>
                                <li className="nav-item" role="presentation" style={{ width: "33.3%" }}>
                                    <button className="nav-link w-100" id="tab3tab" data-bs-toggle="tab" data-bs-target="#tab3"
                                        type="button" role="tab" aria-controls="tab3" aria-selected="false">
                                        Modulr Accounts
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ width: "33.3%" }}>
                                    <button className="nav-link w-100" id="tab4tab" data-bs-toggle="tab" data-bs-target="#tab4"
                                        type="button" role="tab" aria-controls="tab4" aria-selected="false">
                                        Non Modulr Accounts
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab4tab">
                                    <Overview />
                                </div>
                                <div className="tab-pane fade show" id="tab2" role="tabpanel" aria-labelledby="tab1tab">
                                    <Summary />
                                </div>
                                <div className="tab-pane fade  " id="tab3" role="tabpanel" aria-labelledby="tab2tab">
                                    <Modulr />
                                </div>
                                <div className="tab-pane fade " id="tab4" role="tabpanel" aria-labelledby="tab3tab">
                                    <NonModulr />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* yapily expired consent details display model */}
            <Modal show={modalYapilyExpShow} onHide={closeYapilyExpModel} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Yapily Consent Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{margin:5, padding:15}}>
                        { loading ? <ClipLoader size={120} css={{display: "block", margin: "0 auto", borderColor: "green", position: 'absolute',top:0,left:320}} color="green" /> : ''}
                        {yapilyExpData&&yapilyExpData.map((d,i) =>
                            <div key={i}>
                               Your <b>{d?.count}</b> yapily consent going to expire <span><b>{d?.day==='0'?'Today':' after '+d?.day+' days.'}</b></span>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <Footer />
        </>
    )
};

export default ClientDashboard;