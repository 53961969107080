import React, { useEffect, useState } from "react";
import logo from "../../img/logo-light.png";
import Loginpic from "../../img/img-19.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getFundedData, getTokenData, getTokenSuccess } from "../../redux/action/getfunded";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import PhoneInput from "react-phone-input-2";
import { PhoneNumberApi } from "../../redux/service/ApiConfig";
// import Modal from "react-bootstrap/Modal";
import { HttpCall } from "../../services/UseHttps";
import { checkUserAPI, generateEmailOtpAPI, generateOtpAPI, verifyEmailOtpAPI, verifyOtpAPI } from "../../config";
import { decryptString, encryptString } from "../../services/CommonFunction";

const SignUpForm = (props) => {
    useEffect(() => { document.title = props.title }, []);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const re = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm;
    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/;
    const [loading, setLoading] = useState(false);
    const [requestBody, setRequestBody] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    // phone vars
    const [loadingPhone, setLoadingPhone] = useState(false);
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [phoneErrormsg, setPhoneErrormsg] = useState(false);
    const [loadingOtp, setLoadingOtp] = useState(false);
    let numberRequest = { 'phone_number': "", 'country_code': "" }
    // otp
    const [showOTP, setShowOTP] = useState(false);
    const [otpCount, setOtpCount] = useState(0);
    const [otpError, setOtpError] = useState('');
    const [otpSuccess, setOtpSuccess] = useState("");
    const numePattern = /^\d+$/;
    // Email otp
    const [showEmailOTP, setShowEmailOTP] = useState(false);
    const [emailOtpCount, setEmailOtpCount] = useState(0);
    const [emailOtpError, setEmailOtpError] = useState('');
    const [emailOtpSuccess, setEmailOtpSuccess] = useState("");

    const token = useSelector((state) => state.GetFunded && state.GetFunded.token)
    const isClient = useSelector((state) => state.GetFunded && state.GetFunded.is_client)
    const isAdmin = useSelector((state) => state.GetFunded && state.GetFunded.is_admin)
    const emailError = useSelector((state) => state.GetFunded && state.GetFunded.error)
    const signupRes = useSelector((state) => state.GetFunded.getfundedData);

    // is is_client or is_admin true then redirect to login page.
    useEffect(() => {
        if (token && isClient === false) {
            localStorage.setItem('token', token);
            const userData = localStorage.getItem('userData');
            if(userData) {
                const user = userData ? JSON.parse(decryptString(userData)) : {};
                localStorage.setItem('userData', encryptString(JSON.stringify({ email: user.email })));
                if(user && user.email) {
                    dispatch(getFundedData({ email: user.email }));
                }
            } else {
                localStorage.setItem('userData', encryptString(JSON.stringify(requestBody)));
                if(requestBody && requestBody.email) {
                    dispatch(getFundedData(requestBody));
                }
            }
            setLoading(false)
            setLoadingOtp(false)
        }
        else if (isClient) {
            Swal.fire({
                icon: 'success',
                title: 'Already submitted',
                text: 'Please login, your application already submitted.'
            });
            dispatch(getTokenSuccess({ data: { token: null, is_client: null, is_admin: null } }));
            navigate('/login');
        }
        else if (isAdmin) {
            dispatch(getTokenSuccess({ data: { token: null, is_client: null, is_admin: null } }));
            navigate('/login');
        }
        // eslint-disable-next-line
    }, [token, isClient, isAdmin]);

    // redirect to getFunded page when /signup api response comes
    useEffect(() => {
        if (signupRes) {
            localStorage.setItem('role', encryptString('funded'));
            navigate('/getFunded', { state: { signupRes, email: requestBody.email } });
        }
        // eslint-disable-next-line
    }, [signupRes]);

    useEffect(() => {
        if (emailError) {
            setLoading(false);
        }
    }, [emailError]);

    const signupSchema = Yup.object().shape({
        fname: Yup.string().required('First name is required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for first name"),
        lname: Yup.string().required('Last name is required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for last name"),
        companywebsite: Yup.string()
            .required('Company website is required')
            .matches(re, 'Company website URL is not valid'),
        annualrevenue: Yup.object().required('Annual revenue is required'),
        email: Yup.string()
            .required('Email address is required')
            .email('Email address is invalid'),
        phonenumber: Yup.string().required('Mobile number is required')
            // .min(8, "Minimum number should be 8 digits"), 
            .max(15, "Maximum mobile number should be of 15 digits"),
        password: Yup.string().required('Password is required')
            .matches(passPattern, 'Password length should be minimum 8 characters and must contain at least one lowercase letter, uppercase letter, number and special character(!,@,#,$,%,^,&,*)'),
    });


    const handleSubmit = (e) => {
        if(phoneErrormsg === false && isValidPhone === true && e && e.phonenumber) {
            setLoading(true);
            const value = {
                "first_name": e.fname,
                "last_name": e.lname,
                "website": e.companywebsite && (e.companywebsite.startsWith('http://') || e.companywebsite.startsWith('https://')) ? e.companywebsite : 'http://'+e.companywebsite,
                "email": e.email,
                "phone_number": `+${e.phonenumber}`,
                "password": e.password,
                "annual_revenue": e.annualrevenue?.label
            }
            // check email before sign up
            const isSuccess = checkIsValidUser(value).then(result => {
                if(result) {
                    setRequestBody(value);
                    sendUserOTP(`+${e.phonenumber}`, false);
                    generateEmailOTP(value, false);
                }
            });
        } else {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Entered mobile number is not valid.'
            });
        }
    }

    // check if user exist then redirect to login
    const checkIsValidUser = (value) => {
        return new Promise( (resolve, reject) => {
            try {
                const params = {email: value.email, password: value.password};
                window.scrollTo(0, 0);
                HttpCall(`${checkUserAPI}`, "POST", params).then((res) => {
                    if (res && res.status && (res.status === 200 || res.status === 201) && res.data) {
                        // new user continue valification
                        if(res.data.type === 'new') { resolve(true); }
                        // user already exist, back to login
                        else if(res.data.type === 'main') {
                            Swal.fire({icon: 'error', title: 'Already submitted', text: 'Please login, your application already submitted.'});
                            dispatch(getTokenSuccess({ data: { token: null, is_client: null, is_admin: null } }));
                            navigate('/login');
                            resolve(false);
                        }
                        // user is support user then complete sign up
                        else if(res.data.type === 'support' && res.data.token) {
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('userData', encryptString(JSON.stringify(value)));
                            if(value && value.email) {
                                dispatch(getFundedData(value));
                            }
                            resolve(false);
                        }
                        else if(res.data.type === 'mail') {
                            Swal.fire({icon: 'error', title: 'Error', text: 'Please Check mail for verification.'});
                            dispatch(getTokenSuccess({ data: { token: null, is_client: null, is_admin: null } }));
                            navigate('/login');
                            resolve(false);
                        }
                    } else { resolve(false); }
                }).catch((error) => {
                    setLoading(false);
                    resolve(false);
                });
            } catch(err) {
                setLoading(false);
                resolve(false);
            }
        });
    }

    const signUpContinue = () => {
        setLoading(true);
        let data = {
            "email": requestBody.email,
            "password": requestBody.password,
            "is_signup" : true
        }
        localStorage.removeItem('userData');
        dispatch(getTokenData(data, setLoading));
    }

    const options = [
        { label: 'Less than £10k', value: 'Less than £10k' },
        { label: '£10k - £50k', value: '£10k - £50k' },
        { label: '£50k - £100k', value: '£50k - £100k' },
        { label: '£100k - £500k', value: '£100k - £500k' },
        { label: 'More than £500k', value: 'More than £500k' },
    ];

    const style = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none"
        })
    };

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const phoneValue = (value, country) => {
        numberRequest = { 'phone_number': value, 'country_code': country.iso2 }
    };
    const handleBlurs = (e) => {
        try {
            if(numberRequest&&numberRequest['phone_number']&&numberRequest['phone_number'].length > 4) {
                setPhoneErrormsg(false);
            } else {
                setPhoneErrormsg(true);
                return;
            }
            setLoadingPhone(true);
            PhoneNumberApi(numberRequest).then((res) => {
                if (res&&res.data&&res.data.is_valid === true) {
                    setLoadingPhone(false);
                    setIsValidPhone(true);
                    setPhoneErrormsg(false);
                } else {
                    setLoadingPhone(false);
                    setIsValidPhone(false);
                    setPhoneErrormsg(true);
                }
            })
        } catch (err) {
            setLoadingPhone(false);
            setIsValidPhone(false);
            setPhoneErrormsg(true);
        }
    }

    // otp
    const otpSchema = Yup.object().shape({
        otp: Yup.string().required('Verification code is required.')
            .matches(numePattern, 'Verification code is allows only numbers.')
            .max(10, "Please enter valid verification code.")
    });
    const sendUserOTP = (mobile, isResendOtp) => {
        try {
            if(isResendOtp) { setLoadingOtp(true); } else { setLoading(true); }
            HttpCall(`${generateOtpAPI}`, "POST", { "phone_number": mobile }).then((res) => {
                if (res && res.status && (res.status === 200 || res.status === 201)) {
                    setShowOTP(true);
                    setOtpSuccess('Verification code has been sent to your registered mobile number.');
                    window.scrollTo(0, 0);
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: 'Fail to send verification code on mobile number.'});
                }
                setLoading(false);
                setLoadingOtp(false);
            }).catch((error) => {
                Swal.fire({icon: 'error',title: 'Error',
                    text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to send verification code.'
                });
                setLoading(false);
                setLoadingOtp(false);
                setOtpSuccess('');
            });
        } catch(err) {
            setLoading(false);
            setLoadingOtp(false);
            setOtpSuccess('');
        }
    }
    const closeOtpModel = () => {
        setShowOTP(false);
        setLoading(false);
        setLoadingOtp(false);
        setOtpCount(0);
        setOtpError('');
        setOtpSuccess('');
    }
    const verifyOTPHandle = (values) => {
        try {
            if(values && values.otp) {
                setOtpSuccess('');
                setLoadingOtp(false);
                let body = { "phone_number": requestBody.phone_number, "otp": values.otp };
                HttpCall(`${verifyOtpAPI}`, "POST", body).then((res) => {
                    if (res && res.data && res.status && (res.status === 200 || res.status === 201)) {
                        if(res.data["otp_status"] === "approved") {
                            setShowOTP(false);
                            Swal.fire({icon: 'success',title: 'Success', text: 'Verification code is verified successfully.'});
                            setShowEmailOTP(true); // signUpContinue();
                        } else {
                            setOtpError('Verification code does not match.');
                        }
                    } else {
                        setOtpError('Fail to verify verification code.');
                    }
                    setLoadingOtp(false);
                }).catch((error) => {
                    Swal.fire({icon: 'error',title: 'Error',
                        text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to verify otp.'
                    });
                    setLoadingOtp(false);
                });
            }
        } catch(err) {
            setLoadingOtp(false);
        }
    }
    const reSendOtpHandle = () => {
        setOtpSuccess('');
        if(otpCount < 3) {
            if(requestBody && requestBody.phone_number) {
                setOtpCount(otpCount+1);
                sendUserOTP(requestBody.phone_number, true);// setShowOTP(true); // reset otp here
            } else {
                setOtpError('Mobile number not found to send verification code.')
            }
        } else {
            setOtpError('You exceeded re-send verification code limit.');
        }
    }

    // Email otp generate/verify
    const emailOtpSchema = Yup.object().shape({
        code: Yup.string().required('Email verification code is required.')
            .max(10, "Please enter valid email verification code.")
    });
    const generateEmailOTP = (reqBody, isResendOtp) => {
        try {
            if(isResendOtp) { setLoadingOtp(true); } else { setLoading(true); }
            const params = { email: reqBody.email, name: reqBody.first_name };
            HttpCall(`${generateEmailOtpAPI}`, "POST", params).then((res) => {
                if (res && res.status && (res.status === 200 || res.status === 201)) {
                    setShowEmailOTP(true);
                    setEmailOtpSuccess('Verification code has been sent to your Email-Id.');
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: 'Fail to send verification code on your Email-Id.'});
                }
                setLoading(false);
                setLoadingOtp(false);
            }).catch((error) => {
                Swal.fire({icon: 'error',title: 'Error',
                    text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to send verification code.'
                });
                setLoading(false);
                setLoadingOtp(false);
                setEmailOtpSuccess('');
            });
        } catch(err) {
            setLoading(false);
            setLoadingOtp(false);
            setEmailOtpSuccess('');
        }
    }
    const verifyEmailOTPHandle = (values) => {
        try {
            if(values && values.code) {
                setEmailOtpSuccess('');
                setLoadingOtp(false);
                let body = { "email": requestBody.email, "otp": values.code };
                HttpCall(`${verifyEmailOtpAPI}`, "POST", body).then((res) => {
                    if (res && res.data && res.status && (res.status === 200 || res.status === 201)) {
                        if(res.data["otp_verification_status"] && res.data["otp_verification_status"] === "True") {
                            setShowEmailOTP(false);
                            Swal.fire({icon: 'success',title: 'Success', focusConfirm:true, text: 'Email verification done successfully.'});
                            signUpContinue();
                        } else {
                            setEmailOtpError('Email verification code does not match.');
                        }
                    } else {
                        setEmailOtpError('Fail to verify email verification code.');
                    }
                    setLoadingOtp(false);
                }).catch((error) => {
                    Swal.fire({icon: 'error',title: 'Error',
                        text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to verify email code.'
                    });
                    setLoadingOtp(false);
                });
            }
        } catch(err) {
            setLoadingOtp(false);
        }
    }
    const closeEmailOtpModel = () => {
        setShowEmailOTP(false);
        setLoading(false);
        setLoadingOtp(false);
        setEmailOtpCount(0);
        setEmailOtpError('');
        setEmailOtpSuccess('');
    }
    const reSendEmailOtpHandle = () => {
        setEmailOtpSuccess('');
        if(emailOtpCount < 3) {
            if(requestBody && requestBody.email) {
                setEmailOtpCount(emailOtpCount+1);
                generateEmailOTP(requestBody, true);
            } else {
                setEmailOtpError('Email-Id not found to send verification code.')
            }
        } else {
            setEmailOtpError('You exceeded re-send email verification code limit.');
        }
    }

    return (
        <>
            <section className="overflow div-height">
                <div className="row div-height">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="background h-100">
                            <div className="text-right px-4 pt-3 pb-5">
                                <Link to="/">
                                    <img src={logo} alt="logo" width="200" height="20" className="" />
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <img className="text-center" src={Loginpic} alt="welcome" height="350" width="400" />
                                    <h1 className="text-white mb-4">Barlow Marshall</h1>
                                    <p className="text-white mb-4">Find out how much you can get.</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-5 mt-5">
                                <div>
                                    <Link className="text-white PX-2" to={'/terms-conditions'}>Terms and Conditions</Link>
                                    <span className="text-white mx-2"> | </span>
                                    <Link className="text-white px-2" to={'/privacy-policy'}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <Link to="/login" className="btn btn-custom btn-custom-2 me-3 mt-2 float-end">Login</Link>
                        {!showOTP && !showEmailOTP ? 
                            <>
                                <div className="signup-box p-5 mt-2">
                                    <h1 className="text-center"><b>Get funded</b></h1>
                                    <p className="text-center">Tell us about you and your company.</p>

                                    <Formik
                                        initialValues={{ email: "", phonenumber:"", password: "", fname: "", lname: "", companywebsite: "", annualrevenue: null }}
                                        validationSchema={signupSchema}
                                        onSubmit={(values) => {
                                            handleSubmit(values)
                                        }}
                                    >
                                        {({ touched, errors, values,
                                            handleChange,
                                            setFieldValue }) =>
                                            !loading ? (
                                                <div>
                                                    <Form className="custom-form">
                                                        <div className="mb-3">
                                                            <Field autoFocus={true}
                                                                type="text"
                                                                name="fname"
                                                                value={values.fname}
                                                                onChange={handleChange}
                                                                placeholder="First Name"
                                                                autoComplete="off"
                                                                id="fname"
                                                                aria-describedby="businessHelp"
                                                                className={`mt-2 form-control
                                ${touched.fname && errors.fname ? "is-invalid" : ""}`}
                                                            />

                                                            <ErrorMessage
                                                                component="div"
                                                                name="fname"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Field
                                                                type="text"
                                                                name="lname"
                                                                value={values.lname}
                                                                onChange={handleChange}
                                                                placeholder="Last Name"
                                                                autoComplete="off"
                                                                id="lname"
                                                                aria-describedby="businessHelp"
                                                                className={`mt-2 form-control
                                ${touched.lname && errors.lname ? "is-invalid" : ""}`}
                                                            />

                                                            <ErrorMessage
                                                                component="div"
                                                                name="lname"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                placeholder="Email Address"
                                                                id="email"
                                                                autoComplete="off"
                                                                className={`mt-2 form-control
                                ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                            />

                                                            <ErrorMessage
                                                                component="div"
                                                                name="email"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="shadow" style={{marginBottom:10}}>
                                                            <PhoneInput
                                                                country={"gb"}
                                                                name="phonenumber"
                                                                countryCodeEditable={true}
                                                                value={values.phonenumber}
                                                                onChange={handleChange('phonenumber')}
                                                                className={errors&&errors.phonenumber ? "is-invalid" : ""}
                                                                placeholder="Phone Number"
                                                                isValid={(value, country) => phoneValue(value, country)}
                                                                onBlur={(e) => handleBlurs(e)}
                                                                autoComplete="off"
                                                            />
                                                            <ErrorMessage component="div" name="phonenumber" className="invalid-feedback" />
                                                            { phoneErrormsg === true ? <div className='text-danger'> Please enter valid number </div> : '' }
                                                            {loadingPhone && <ClipLoader size={70} css={{ display: 'block', float: 'right', borderColor: 'green', marginTop: '-65px', marginLeft: '100px',position: 'absolute' }} color="green" /> }
                                                        </div>
                                                        <div className="input-group mb-3" style={{zIndex:0}}>
                                                            <Field
                                                                type={passwordShown ? "text" : "password"}
                                                                name="password"
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                placeholder="Create Password"
                                                                id="password"
                                                                autoComplete="off"
                                                                className={`mt-2 form-control
                                ${touched.password && errors.password ? "is-invalid" : ""}`}
                                                            />
                                                            <div className="mt-2">
                                                                <div className="input-group-text" tabIndex="0" onKeyDown={(e) => e.keyCode === 32 ? togglePassword() : () => {} }>
                                                                    {passwordShown ? <i className="far fa-eye" id="togglePassword" onClick={togglePassword} ></i> : <i className="far fa-eye-slash" id="togglePassword" onClick={togglePassword} ></i>}

                                                                </div>
                                                            </div>
                                                            <ErrorMessage
                                                                component="div"
                                                                name="password"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Field
                                                                type="text"
                                                                name="companywebsite"
                                                                value={values.companywebsite}
                                                                onChange={handleChange}
                                                                placeholder="Company Website"
                                                                autoComplete="off"
                                                                id="companywebsite"
                                                                aria-describedby="businessHelp"
                                                                className={`mt-2 form-control
                                ${touched.companywebsite && errors.companywebsite ? "is-invalid" : ""}`}
                                                            />

                                                            <ErrorMessage
                                                                component="div"
                                                                name="companywebsite"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3 select-box">
                                                            <Select
                                                                name="annualrevenue"
                                                                id="annualrevenue"
                                                                value={values.annualrevenue}
                                                                placeholder="Annual Recurring Revenue - Please Select"
                                                                styles={style}
                                                                onChange={options => setFieldValue("annualrevenue", options)}
                                                                options={options}
                                                                // onBlur={handleBlur}
                                                                className={`select-box-inner
                            ${touched.annualrevenue && errors.annualrevenue ? "is-invalid" : ""}`}
                                                                aria-label="Default select example" />
                                                            {errors && errors.annualrevenue && <div className="invalid-feedback text-danger">Annual Revenue is required</div>}
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary bg-primary w-100 Submit text-white text-decoration-none"
                                                        >
                                                            {loading ?
                                                                <><ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" /></>
                                                                : <>Check Eligibility</>
                                                            }

                                                        </button>
                                                    </Form>
                                                </div>
                                            ) : (
                                                <>
                                                    <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                                </>
                                            )
                                        }
                                    </Formik>
                                </div>
                            </>
                        : showOTP ?
                            <div className="signup-box p-5 mt-2">
                                <h1 className="text-center"><b>Verify OTP</b></h1>
                                <Formik initialValues={{ otp: "" }} validationSchema={otpSchema}
                                    onSubmit={(values) => { verifyOTPHandle(values) }}>
                                {({ touched, errors, values, handleChange}) =>
                                    <Form className="custom-form">
                                        {
                                            loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                            :
                                            <>                                            
                                                { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' }
                                                <div className="mb-3">
                                                    <Field
                                                        type="text" name="otp" value={values.otp} onChange={handleChange}
                                                        id="otp" placeholder="Enter OTP" autoComplete="off"
                                                        aria-describedby="businessHelp" className={`mt-2 form-control
                                                        ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                                                    />
                                                    <ErrorMessage component="div" name="otp" className="invalid-feedback"/>
                                                    { otpError ? <div className='alert alert-danger'> {otpError} </div> : '' }
                                                </div>
                                                <div className="mb-3">
                                                    <button type="button" className="btn btn-link" onClick={reSendOtpHandle}>Resend OTP</button>
                                                    <button type="button" className="btn btn-link" onClick={closeOtpModel} style={{float:"right"}}>Back to Get Funded</button>
                                                </div>
                                                <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                                                    >Verify</button>
                                            </>
                                        }
                                    </Form>
                                    }
                                </Formik>
                            </div>
                            :
                            <div className="signup-box p-5 mt-2">
                                <h1 className="text-center"><b>Verify Email</b></h1>
                                <Formik initialValues={{ code: "" }} validationSchema={emailOtpSchema}
                                    onSubmit={(values) => { verifyEmailOTPHandle(values) }}>
                                {({ touched, errors, values, handleChange}) =>
                                    <Form className="custom-form">
                                        {
                                            loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                            :
                                            <>                                            
                                                { emailOtpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {emailOtpSuccess} </div> : '' }
                                                <div className="mb-3">
                                                    <Field type="text" name="code" value={values&&values.code?values.code:''} onChange={handleChange}
                                                        id="code" placeholder="Enter Code" autoComplete="off"
                                                        aria-describedby="" className={`mt-2 form-control
                                                        ${touched.code && errors.code ? "is-invalid" : ""}`}
                                                    />
                                                    <ErrorMessage component="div" name="code" className="invalid-feedback"/>
                                                    { emailOtpError ? <div className='alert alert-danger'> {emailOtpError} </div> : '' }
                                                </div>
                                                <div className="mb-3">
                                                    <button type="button" className="btn btn-link" onClick={reSendEmailOtpHandle}>Resend Code</button>
                                                    <button type="button" className="btn btn-link" onClick={closeEmailOtpModel} style={{float:"right"}}>Back to Get Funded</button>
                                                </div>
                                                <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow">Verify</button>
                                            </>
                                        }
                                    </Form>
                                    }
                                </Formik>
                            </div>
                        }
                    </div>
                </div>
            </section>

        {/* <Modal show={showOTP} onHide={closeOtpModel} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"> Verify OTP </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card ">
                    <div className="card-body">
                    <Formik initialValues={{ otp: "" }}
                        validationSchema={otpSchema}
                        onSubmit={(values) => { verifyOTPHandle(values) }}
                    >
                    {({ touched, errors, values, handleChange}) =>
                        <Form className="custom-form">
                            {
                                loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                :
                                <>
                                    <div className="mb-3">
                                        <Field
                                            type="text" name="otp" value={values.otp} onChange={handleChange}
                                            id="otp" placeholder="Enter OTP" autoComplete="off"
                                            aria-describedby="businessHelp" className={`mt-2 form-control
                                            ${touched.otp && errors.otp ? "is-invalid" : ""}`}
                                        />
                                        <ErrorMessage component="div" name="otp" className="invalid-feedback"/>
                                        { otpError ? <div className='alert alert-danger'> {otpError} </div> : '' }
                                    </div>
                                    <div className="mb-3">
                                        <button type="button" className="btn btn-link" onClick={reSendOtpHandle}>Resend OTP</button>
                                    </div>
                                    <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                                        >Verify</button>
                                    { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' }
                                </>
                            }
                        </Form>
                        }
                    </Formik>
                    </div>
                </div>
            </Modal.Body>
        </Modal> */}
        </>
    )
};
export default SignUpForm;