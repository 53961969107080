import React, { useEffect, useState } from 'react';
// import { Container, Row } from 'react-bootstrap';
import './YapilyDashboard.css';
import FormB from 'react-bootstrap/Form';
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import { ErrorHandler } from '../../components/Auth/errorHandler';
import { getYapilyUserAccountsApi, getYapilyUserAccountSyncApi, getYapilyUserConsentExtendApi, getYapilyUserConsentRevokeApi, getYapilyUserInstitutionApi, yapilyUserAccountAuthorisationApi, yapilyUserAccountsApi } from '../../config';
import { HttpCall } from '../../services/UseHttps';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const YapilyDashboard = ({ fromYapilyTab, isParent, isYapActive, yapilyError, setCurrState }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [institutionList, setInstitutionList] = useState([]);
  const [selectedInsti, setSelectedInsti] = useState({});
  const [instiActAccountList, setInstiActAccountList] = useState([]);
  const [showRevoke, setShowRevoke] = useState(false);
  const [revokeData, setRevokeData] = useState(null);
  const [loadingRevoke, setLoadingRevoke] = useState(false);

  useEffect(() => {
    getYapilyInstitutions();
    localStorage.removeItem('bank'); // if bank exist then remove
  }, []);

  // get institutions list
  const getYapilyInstitutions = () => {
    try {
      // setLoading(true);
      HttpCall(`${getYapilyUserInstitutionApi}`, "GET").then((res) => {
        if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
          const rawData = res.data?.data ? res.data?.data : [];
          if(rawData&&rawData.length > 0) {
            rawData.forEach(async (element) => {
              if(element&&element.consent_expires_at) {
                element['consentExp'] = await checkExpireyDate(element.consent_expires_at);
              }
            });
            setInstitutionList(rawData);
          } else {
            setInstitutionList([]);
          }
        } else {
          setInstitutionList([]);
        }
        // setLoading(false);
      }).catch((error) => {
        // setLoading(false);
        setInstitutionList([]);
        ErrorHandler(error);
      });
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails get accounts.'
      });
      // setLoading(false);
    }
  }

  const onClickAddInstitution = () => {
    setCurrState({ yapilyBtn: false, institution: true, auth: false, consentData: {}, accounts: false, dashboard: false });
  }

  // get accounts of banks
  const getConsentAndAccounts = (institu) => {
    try {
      if (institu && institu.yapily_institution_id) {
        if(selectedInsti?.consent_id === institu?.consent_id) {
          setSelectedInsti({});
          return;
        }
        setSelectedInsti(institu);
        setLoading(true);
        const accAPI =`${getYapilyUserAccountsApi}?institution_id=${institu.yapily_institution_id}&consent_id=${institu?.consent_id}`;
        HttpCall(accAPI, "GET").then((res) => {
          if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
            setInstiActAccountList(res.data?.data && res.data?.data.length > 0 ? res.data?.data[0] : []);
          } else {
            setInstiActAccountList([]);
          }
          setLoading(false);
        }).catch((error) => {
          setLoading(false);
          setInstiActAccountList([]);
          ErrorHandler(error);
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails get accounts.'
      });
      setLoading(false);
    }
  }

  // check date expiration
  const checkExpireyDate = (consentDate) => {
    try {
      // const today = new Date();
      // const utcTimeNOW = new Date(Date.now()+(new Date().getTimezoneOffset()*60000)).getTime();
      const utcToday = new Date(new Date().toUTCString().slice(0, -3));
      const conDate = new Date(consentDate);
      if(conDate.getTime() < utcToday.getTime()) {
        const datestr = nth(conDate.getDate(), conDate.toLocaleString('en-us',{month:'short', year:'numeric'}));
        return {isExp:true, strDate: datestr};
      } else {
        const diffDays = conDate.getTime() - utcToday.getTime();
        var days = Math.round(diffDays / (1000 * 3600 * 24));
        var dayStr = days===0?'Today': (days+' days');
        const datestr = nth(conDate.getDate(), conDate.toLocaleString('en-us',{month:'short', year:'numeric'}) + ' ('+dayStr+')');
        return {isExp:false, strDate: datestr};
      }
    } catch(ex) {
      console.log('ex : ', ex);
      return {isExp:false, strDate:''};
    }
  }
  const nth = function(d, str) {
    if (d > 3 && d < 21) return <span>{d}<sup>th</sup>{' '+str}</span>;
    switch (d % 10) {
      case 1:  return <span>{d}<sup>st</sup>{' '+str}</span>;
      case 2:  return <span>{d}<sup>nd</sup>{' '+str}</span>;
      case 3:  return <span>{d}<sup>rd</sup>{' '+str}</span>;
      default: return <span>{d}<sup>th</sup>{' '+str}</span>;
    }
  }

  // add more accounts in institution
  const onClickAddAccounts = (institution_id, user_id) => {
    try {
      setLoading(true);
      HttpCall(`${yapilyUserAccountAuthorisationApi}`, "POST", { institution_id, user_id }).then((res) => {
        if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
          Swal.fire({ icon: 'success', title: 'Success', text: 'Please authorize your banking credentials.' });
          localStorage.setItem('intId', institution_id);
          localStorage.setItem('fromYDS', 'true');
          if(fromYapilyTab){
            setCurrState({ yapilyBtn: false, institution: false, auth: true, consentData: res.data.data, accounts: false, dashboard: false });
          } else {
            localStorage.setItem('tokenData', JSON.stringify(res.data.data));
            navigate('/client-yapily/auth');
          }
          // call save api
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.error ? res.data.error : 'Fails to save yapily user institution.' });
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        ErrorHandler(error);
      });
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails to save yapily user institution.'
      });
      setLoading(false);
    }
  }

  // sync account
  const onClickSyncAccount = (accountId, status, isSyncAll, dailyLmitExceed) => {
    try {
      if(!status) { return; }
      if(dailyLmitExceed) { return; }
      setLoading(true);
      const params = {account_ids: isSyncAll ? accountId : [accountId] };
      HttpCall(`${getYapilyUserAccountSyncApi}`, "PUT", params).then((res) => {
        if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
          Swal.fire({ icon: 'success', title: 'Success', text: res.data&&res.data.message ? res.data.message :'Account Synced successfully.' });
          resetAndClear();
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.message ? res.data.message : 'Fails to sync yapily user account.' });
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        ErrorHandler(error);
      });
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails to save yapily user institution.'
      });
      setLoading(false);
    }
  }
  // sync all accounts
  const onClickSyncAllAccounts = () => {
    try{
      setLoading(true);
      HttpCall(getYapilyUserAccountsApi, "GET").then((res) => {
        if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
          const accounts = res.data?.data;
          if(accounts&&accounts.length > 0) {
            onClickSyncAccount(accounts, true, true, false); // sync all accounts
          } else {
            Swal.fire({ icon: 'error', title: 'Error', text: 'Accounts not found to sync.'});
          }
        } else {
          if(res.data&&res.data?.info&&res.data?.info!==''){
            Swal.fire({ icon: 'info',iconColor: '#ff0101', 
            title: 'IMPORTANT', customClass : { title: 'swal2-info-title'},
              text: res.data?.info ? res.data?.info : 'Accounts not found to sync.' });
          } else {
              Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.error ? res.data.error+' to sync.' : 'Accounts not found to sync.' });
          }
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        ErrorHandler(error);
      });
    } catch(e) {
      console.log('e:',e);
    }
  }

  // sync & unsync account value= 1:true, 0:false
  const handleSyncUnSyncAccount = (accountId, value) => {
    if(!isParent || !isYapActive) return;
    const param = { account_update_parameters: [{ yapily_user_account_id: accountId, update_column_values: { is_sync: value } }] };
    HttpCall(`${yapilyUserAccountsApi}`, "PATCH", param).then((res) => {
      if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
        Swal.fire({ icon: 'success', title: 'Success', text: res.data.data });
        resetAndClear();
      } else {
        Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.error ? res.data.error : 'Fails to sync accounts.' });
        setLoading(false);
      }
    }).catch((error) => {
      setLoading(false);
      ErrorHandler(error);
    });
  }

  // extend consent
  const onExtendConsetClick = (consentId) => {
    try {
      setLoading(true);
      HttpCall(`${getYapilyUserConsentExtendApi}`, "PATCH", {consent_id:consentId}).then((res) => {
        if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
          Swal.fire({ icon: 'success', title: 'Success', focusConfirm:true, text: 'Consent extended successfully.' });
          resetAndClear();
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.error ? res.data.error : 'Fails to extend consent.' });
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        ErrorHandler(error);
      });
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails to extend consent.'
      });
      setLoading(false);
    }
  }
  // revoke consent
  const onClickWithdraw = (event,consentId) => {
    event.currentTarget.blur();
    setRevokeData({consentId, error:'', check: false});
    setShowRevoke(true);
  };
  const handleCloseRevoke = () => {
    setShowRevoke(false);
    setRevokeData(null);
    setLoadingRevoke(false);
  };
  const onRevokeConfirmClick = () => {
    try {
      if(revokeData?.consentId && revokeData?.check) {
        setLoadingRevoke(true);
        HttpCall(`${getYapilyUserConsentRevokeApi}`, "PATCH", {consent_id:revokeData?.consentId}).then((res) => {
          if (res && (res.status === 200 || res.status === 201) && res.data && res.data.status) {
            Swal.fire({ icon: 'success', title: 'Success', focusConfirm:true, returnFocus:false,  text: 'Consent revoked successfully.' });
            handleCloseRevoke()
            resetAndClear();
          } else {
            setRevokeData({...revokeData, error:(res.data && res.data.error ? res.data.error : 'Fails to revoke consent.') });
            // Swal.fire({ icon: 'error', title: 'Error', text: res.data && res.data.error ? res.data.error : 'Fails to revoke consent.' });
          }
          setLoadingRevoke(false);
        }).catch((error) => {
          setLoadingRevoke(false);
          ErrorHandler(error);
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error', title: 'Error',
        text: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Fails to revoke consent.'
      });
      setLoadingRevoke(false);
    }
  }

  // clear/reset render and refetch data
  const resetAndClear = () => {
    setSelectedInsti({});
    getYapilyInstitutions();
  }

  return (
    <>
    <div className="mb-4">
      <div className="card">
        <div className="card-body">
          <div className="col-12">
            {isYapActive===false ?
              <div className="alert alert-danger noti-alert alert-dismissible">{yapilyError?yapilyError:''}</div>
            : ''}
            <div className="tab-content" id="yapilyTabContent">
              <h4>Dashboard Institutions</h4>
              <p>View linked institutions</p>
              {isParent && isYapActive ? <div>
                {fromYapilyTab ? 
                  <button className="btn btn-outline-primary" onClick={onClickAddInstitution} style={{ position: 'absolute', float: 'right', right: 10, top: 25 }} title='Add new institution or account'>
                    <i className="fas fa-plus"></i> Institution / Account
                  </button>
                :''}
                <button className="btn btn-outline-primary" onClick={onClickSyncAllAccounts} style={{ position: 'absolute', float: 'right', right: fromYapilyTab?205:10, top: 25 }} title='Sync All Accounts'>
                  <i className="fas fa-retweet"></i> Sync All Accounts
                </button>
              </div>: ''}
              <div className="accordion" id="accordionYapily">
                {institutionList && institutionList.length > 0 && institutionList.map((insti, index) => {
                  return (
                    <div className="accordion-item mt-3" key={index}>
                      <div className="accordion-header" id="headingOne">
                        <div className="row">
                          <div className="col-sm-1" style={{cursor:'pointer',display:'flex',justifyContent:'space-between'}} onClick={() => { getConsentAndAccounts(insti) }}>
                            <img src={insti?.icon_url} className="rounded img-das-yapi" alt={insti?.full_name} />
                          </div>
                          <div className="col-sm-3" style={{cursor:'pointer'}} onClick={() => { getConsentAndAccounts(insti) }}>
                            <label style={{cursor:'pointer'}}>
                              <b style={{ marginLeft: 10 }}> {insti?.full_name}</b><br />
                              <span style={{ marginLeft: 10 }}>{insti?.total_accounts} bank accounts</span>
                            </label>
                            <label style={{float:'right',margin:10, cursor:'pointer'}} data-bs-toggle="collapse"
                              data-bs-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                              <i className={"fas fa-" + (selectedInsti && selectedInsti.consent_id === insti.consent_id ? 'angle-up' : 'angle-down')}
                                style={{ left: 20, fontSize: 25 }}></i>
                            </label>
                          </div>
                          <div className="col-sm-5" style={{display:'flex',justifyContent:'space-between'}}>
                            <label style={{margin:10}}>
                              {insti?.consentExp&&insti?.consentExp?.isExp ?
                              <>
                                <span><i className="fa fa-times-circle" style={{color:'red', paddingRight:10}}></i>Consent Expired: {insti?.consentExp?.strDate}</span>
                              </>
                              :
                              <>
                                <span><i className="fa fa-check-circle" style={{color:'green', paddingRight:10}}></i>Consent Expires: {insti?.consentExp?.strDate}</span>
                              </>
                              }
                            </label>
                          </div>
                          <div className="col-sm-3" style={{display:'flex',justifyContent:'space-between'}}>
                            {isParent && isYapActive?
                              <label style={{margin:10}}>
                                {insti?.consentExp&&insti?.consentExp?.isExp ?
                                <>
                                  {fromYapilyTab?<button className="btn btn-outline-primary btn-sm" style={{marginLeft:25}} onClick={(e)=>onClickWithdraw(e,insti?.consent_id)}>Withdraw</button>:''}
                                  <button className="btn btn-consent-extend btn-sm" onClick={() => onClickAddAccounts(insti?.institution_id, insti?.user_id)}>Reconnect</button>
                                </>
                                :
                                <>
                                  {fromYapilyTab?<button className="btn btn-outline-primary btn-sm" style={{marginLeft:25}} onClick={(e)=>onClickWithdraw(e,insti?.consent_id)}>Withdraw</button>:''}
                                  <button className="btn btn-consent-extend btn-sm" onClick={()=>onExtendConsetClick(insti?.consent_id)}>
                                    <i className="fa fa-refresh" aria-hidden="true"></i> Renew
                                  </button>
                                </>
                                }
                              </label>
                            :''}
                          </div>
                        </div>
                      </div>
                      <div id={"collapseOne" + index} className={"accordion-collapse collapse " + (selectedInsti && selectedInsti.consent_id === insti.consent_id ? 'show' : '')} aria-labelledby="headingOne">
                        <div className="accordion-body" style={{backgroundColor: '#f8f8f8'}}>
                          {loading ?
                            <ClipLoader size={100} css={{ display: "block", margin: "0 auto", borderColor: "green" }} color="green" />
                            :
                            <>
                              <div className="container">
                                <div className="tab-content">
                                  <div id="consent1" className="container tab-pane active">
                                  {instiActAccountList&&instiActAccountList?.accounts?.length > 0 ?
                                    <table className="table table-hover" style={{textAlign: 'center'}}>
                                      <thead>
                                        <tr>
                                          <th>Sort Code</th>
                                          <th>Account Number</th>
                                          <th>Status</th>
                                          <th>Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {instiActAccountList?.accounts&&instiActAccountList?.accounts.map((acc, ind) => {
                                          return (
                                          <tr key={ind}>
                                            <td>{acc&&acc.sort_code?acc.sort_code:'-'}</td>
                                            <td>{acc?.account_number}</td>
                                            <td className='chkCenter'>
                                            {insti?.consentExp&&insti?.consentExp?.isExp  ? <>
                                                <FormB.Check type="switch" id="custom-switch" label={acc?.status?'Sync':'Un-sync'} checked={acc?.status?true:false} disabled />
                                              </>
                                            : <>
                                              {acc && acc?.status ?
                                                <FormB.Check type="switch" id="custom-switch" label="Sync" checked={true} onChange={() => handleSyncUnSyncAccount(acc?.id, 0)} disabled={!isParent || !isYapActive}/>
                                                :
                                                <FormB.Check type="switch" id="custom-switch" label="Un-sync" checked={false} onChange={() => handleSyncUnSyncAccount(acc?.id, 1)} disabled={!isParent || !isYapActive}/>
                                              }
                                            </>
                                            }
                                            </td>
                                            <td>
                                              {insti?.consentExp&&insti?.consentExp?.isExp ? <></> :
                                                <>{isParent && isYapActive ? <>
                                                  <button className="btn btn-yapi-sync btn-sm" onClick={() => onClickSyncAccount(acc?.id, acc?.status, false, (acc?.cron_run_count >= 4))} 
                                                    disabled={!acc?.status || (acc?.cron_run_count >= 4)} >Manual Sync</button><br/>
                                                    {acc?.cron_run_count >= 4 ? <small style={{fontSize:10,fontWeight:'bold',color:'red'}}>Daily transaction fetch limit exceeded</small>:''}
                                                  </>
                                                  :''}
                                                </>
                                              }
                                            </td>
                                          </tr>
                                          )
                                        })
                                        }
                                      </tbody>
                                    </table>
                                  :
                                    <div>Institution consents accounts not found.</div>
                                  }
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
                }
                {institutionList && institutionList.length <= 0 &&
                  <div>Institutions not found.</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* withdraw consent confirm popup  */}
    <Modal show={showRevoke} onHide={handleCloseRevoke} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Withdraw Consent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                      You are about to withdraw a consent. Withdrawing this consent will be permanent action which cannot be undone<br />
                    </div>
                    <div className='form-check' style={{marginTop:10}}>
                    <input type="checkbox" name="acceptTerms" value={revokeData?.check}
                      checked={revokeData?.check ? true : false}
                      onChange={(e)=>{ setRevokeData({...revokeData, check: e?.target?.checked});}}
                      className="form-check-input" id="exampleCheck99" />
                      <label className="form-check-label" htmlFor="exampleCheck99">
                        I understand that this action cannot be undone
                      </label>
                    </div>
                    {revokeData&&revokeData?.error && <div className="alert alert-danger">{revokeData?.error}</div>}
                    {loadingRevoke&&
                      <div className='with-overlay'>
                        <div className='with-overlay-content'>
                            <ClipLoader size={50} css={{ display: "block", margin: "0 auto", borderColor: "green" }} color="green" />
                        </div>
                      </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{display:'flex',justifyContent: 'center'}}>
                  <div>
                    <button variant="primary" type="button"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue"
                        onClick={() => onRevokeConfirmClick()}
                        disabled={loadingRevoke || !revokeData?.check}>
                        Confirm
                    </button>
                    <button variant="secondary" type="button" name="reasonname"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-danger"
                        type="button" onClick={handleCloseRevoke}>
                        Cancel
                    </button>
                  </div>
                </Modal.Footer>
            </Modal>
    </>
  )
}

export default YapilyDashboard;
