import React, { useEffect, useState } from "react";
import { ledgerPendingData, ledgerSettledInitiateData, settledApi } from "../../redux/action/ledgerTransaction";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { formatAmount } from "../../services/CommonFunction";
import { Modal } from "react-bootstrap";

const Transactions = ({ applicationId, modulrRuleId }) => {

    const dispatch = useDispatch();
    const [datatable, setDatatable] = useState([]);
    const [datatableSettleInit, setDatatableSettleInit] = useState([]);
    const [pendingCheck, setPendingCheck] = useState([]);
    const ledgerPendingDatas = useSelector((state) => state.ledgerTransaction && state.ledgerTransaction.ledgerPendingData && state.ledgerTransaction.ledgerPendingData.data && state.ledgerTransaction.ledgerPendingData.data && state.ledgerTransaction.ledgerPendingData.data.data);
    const ledgerSettleInitiatedDatas = useSelector(state => state.ledgerTransaction && state.ledgerTransaction.ledgerSettleInitiatedData && state.ledgerTransaction.ledgerSettleInitiatedData.data && state.ledgerTransaction.ledgerSettleInitiatedData.data && state.ledgerTransaction.ledgerSettleInitiatedData.data.data);
    const settledData = useSelector(state => state.ledgerTransaction && state.ledgerTransaction.settledData);
    const settledDataError = useSelector(state => state.ledgerTransaction);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheckAny, setIsCheckAny] = useState(false);
    const [isCheckAllSetInit, setIsCheckAllSetInit] = useState(false);
    const [isCheckAnySetInit, setIsCheckAnySetInit] = useState(false);
    const [isRender, setIsRender] = useState(false);
    // reason check
    const [show, setShow] = useState(false);
    const [showInit, setShowInit] = useState(false);
    const [recordIds, setRecordIds] = useState([]);
    const [reason, setReason] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [submitAction, setSubmitAction] = useState('');
    useEffect(() => {
        if (ledgerPendingDatas) {
            setDatatable(ledgerPendingDatas)
        } else {
            if(ledgerPendingDatas === null ) { setDatatable([]); }
        }
    }, [ledgerPendingDatas])
    useEffect(() => {
        if (ledgerSettleInitiatedDatas) {
            setDatatableSettleInit(ledgerSettleInitiatedDatas)
        } else {
           if(ledgerSettleInitiatedDatas === null) { setDatatableSettleInit([]); }
        }
    }, [ledgerSettleInitiatedDatas])
    useEffect(() => {
        if (settledData) {
            Swal.fire({
                title: "Success!",
                text: settledData&&settledData.message?settledData.message:`Request successfully ${submitAction==='settled'?'settled':'initiated for settlement'}!`,
                icon: "success", focusConfirm:true, returnFocus:false,
                // timer: 2000,
                // button: false,
            });
            dispatch(ledgerPendingData('pending', applicationId));
            dispatch(ledgerSettledInitiateData('settle_initiated', applicationId));
            if(isCheckAny) { setIsCheckAny(false); }
            if(isCheckAll) { setIsCheckAll(false); }
            if(isCheckAnySetInit) { setIsCheckAnySetInit(false); }
            if(isCheckAllSetInit) { setIsCheckAllSetInit(false); }
            handleClose();
            setLoadingSubmit(false);
        }
    }, [settledData])
    useEffect(() => {
        setLoadingSubmit(false);
    }, [settledDataError])

    useEffect(() => {
        if (applicationId) {
            dispatch(ledgerPendingData('pending', applicationId));
            dispatch(ledgerSettledInitiateData('settle_initiated', applicationId));
        }
    }, [applicationId])
    // useEffect(() => {
    //     dispatch(ledgerSettledData('settled'));
    // }, [])

    const addOrRemove = (name) => {
        const newStudents = [...pendingCheck];
        const index = newStudents.indexOf(name);
        if (index === -1) {
            newStudents.push(name);
        } else {
            newStudents.splice(index, 1);
        }
        setPendingCheck(newStudents);

    }

    // Pending => Settlement Initiate Actions
    // select all pending transactions
    const handleSelectAll = e => {
        setIsCheckAll(e.target.checked);
        if(e.target.checked) { setIsCheckAny(true);} else { setIsCheckAny(false); }
        let allCheck = datatable;
        for (let item of allCheck) {
            item.checked = e.target.checked;
        }
        setDatatable(allCheck);
    };
    // select single pending transactions
    const singleSelect = (e) => {
        let allData = datatable;
        allData.forEach(element => {
            if (element.id === e.target.value) {
                element.checked = e.target.checked
            }
        });
        if(allData.some((val) => val.checked === true)) { setIsCheckAny(true);} else { setIsCheckAny(false); }
        if (allData.every((val, i, arr) => val.checked === true)) {
            setIsCheckAll(true);
        } else {
            setIsCheckAll(false);
        }
        setDatatable(allData);
        setIsRender(!isRender)
    }
    // submit for selected/checked Pending => Settlement Initiate transactions
    const handlemultiSattled = () => {
        let idArr = []
        datatable.map((items) => {
            if (items.checked) {
                idArr.push(items.id)
            }
        });
        if(idArr && idArr.length > 0) {
           setShow(true);
        }
        setRecordIds(idArr);
    }
    const handleSattled = (values) => {
        if(values && values.length > 0) {
            setShow(true);
        }
        setRecordIds(values);
    }
    const submitPendingTransactions = () => {
        setErrorMsg('');
        // if (!reason || reason === '') { setErrorMsg('Please enter valid reason'); return; }
        if (reason && reason.length > 100) { setErrorMsg('Maximum length for reason is 100 character.'); return; }
        if (recordIds && recordIds.length <= 0) { setErrorMsg('Record is not selected'); return; }
        if (recordIds && recordIds.length > 0) {
            setLoadingSubmit(true);
            const settledData = {
                status: 'settle_initiated',
                reason: reason ? ('Settlement Initiated: Admin - '+reason) : 'Settlement Initiated: Admin',
                transaction_id: recordIds,
                account_type: 'non_modulr'
            }
            setSubmitAction('settle_initiated');
            dispatch(settledApi(settledData));
            return;
        }
    }
    // clear data 
    const handleClose = () => {
        setShow(false);
        setShowInit(false);
        setReason('');
        setRecordIds([]);
        setErrorMsg('');
        setSubmitAction('');
    };

    // settlement initiated actions
    const handleSettInitSelectAll = e => {
        setIsCheckAllSetInit(e.target.checked);
        if(e.target.checked) { setIsCheckAnySetInit(true);} else { setIsCheckAnySetInit(false); }
        let allCheck = datatableSettleInit;
        for (let item of allCheck) {
            item.checked = e.target.checked;
        }
        setDatatableSettleInit(allCheck);
    };
    const singleSelectSettInit = (e) => {
        let allData = datatableSettleInit;
        allData.forEach(element => {
            if (element.non_modulr_transaction_id === e.target.value) {
                element.checked = e.target.checked
            }
        });
        if(allData.some((val) => val.checked === true)) { setIsCheckAnySetInit(true);} else { setIsCheckAnySetInit(false); }
        if (allData.every((val, i, arr) => val.checked === true)) {
            setIsCheckAllSetInit(true);
        } else {
            setIsCheckAllSetInit(false);
        }
        setDatatableSettleInit(allData);
        setIsRender(!isRender)
    }
    const handleMultiSettleInit = () => {
        if(!modulrRuleId) { return; } // modulr rules not created then do not allow settle transactions
        let idArr = []
        datatableSettleInit.map((items) => {
            if (items.checked) {
                idArr.push(items.non_modulr_transaction_id)
            }
        })
        if(idArr && idArr.length > 0) {
            setShowInit(true);
        }
        setRecordIds(idArr);
    }
    const handleSettleInit = (values) => {
        if(!modulrRuleId) { return; } // modulr rules not created then do not allow settle transactions
        if(values && values.length > 0) {
            setShowInit(true);
        }
        setRecordIds(values);
    }
    const submitSettleInitTransactions = () => {
        if(!modulrRuleId) { return; } // modulr rules not created then do not allow settle transactions
        setErrorMsg('');
        // if (!reason || reason === '') { setErrorMsg('Please enter valid reason'); return; }
        if (reason && reason.length > 100) { setErrorMsg('Maximum length for reason is 100 character.'); return; }
        if (recordIds && recordIds.length <= 0) { setErrorMsg('Record is not selected'); return; }
        if (recordIds && recordIds.length > 0) {
            setLoadingSubmit(true);
            const settledData = {
                status: 'settled',
                reason: reason ? ('Settled: Admin - '+reason) : 'Settled: Admin',
                transaction_id: recordIds,
                account_type: 'non_modulr'
            }
            setSubmitAction('settled');
            dispatch(settledApi(settledData))
        }
    }

    // filter records by account number
    const getDownloadRecords = async () => {
        let accountNumbers = [];
        let splitedTransactions = {};
        datatableSettleInit.forEach((item) => {
            const object = {'Sort Code':item.sort_code, 'Account Number':item.account_number, 'Date':item.date, 'Description': item.description, 'Currency': item.currency,
                'Currency Debit': item.currency_debit ? Number(parseFloat(item.currency_debit).toFixed(2)) : item.currency_debit,
                'Currency Credit': item.currency_credit ? Number(parseFloat(item.currency_credit).toFixed(2)) : item.currency_credit,
                'Currency to GBP Exchange': item.gbp_exchange_rate,
                'Debit (Received)': item.debit_received ? Number(parseFloat(item.debit_received).toFixed(2)) : item.debit_received,
                'Credit (Spent)': item.credit_spent ? Number(parseFloat(item.credit_spent).toFixed(2)) : item.credit_spent, 'split (%)': item.split_percentage,
                'Split Amount': item.repayment ? Number(parseFloat(item.repayment).toFixed(2)) : item.repayment, 
                'Client Amount': item.client_amount ? Number(parseFloat(item.client_amount).toFixed(2)) : item.client_amount, 'reason': item.reason};
            if(!accountNumbers.includes(item.account_number)) {
                accountNumbers.push(item.account_number);
                splitedTransactions[item.account_number]=[object]
            } else {
                splitedTransactions[item.account_number].push(object);
            }
        });
        var dataLength = Object.keys(splitedTransactions).length;
        if(splitedTransactions && dataLength > 0) {
            await downloadExcelReport(splitedTransactions);
        } else {
            Swal.fire({icon: 'error',title: 'Error', text: 'Records not found to download report.'});
        }
    }

    // download excel-sheet
    const downloadExcelReport = async (transactionData) => {
        try {
            var fileType = 'xlsx';
            var fileFormat = 'binary';
            const workbook = XLSX.utils.book_new();
            for (let tran in transactionData) {
                if(transactionData[tran] && transactionData[tran].length > 0) {
                    const sheet = XLSX.utils.json_to_sheet(transactionData[tran]);
                    XLSX.utils.book_append_sheet(workbook, sheet, tran);
                }
            }
            const excelBuffer = XLSX.write(workbook, { bookType: fileType, type: 'array', cellStyles:true });
            const finalData = new Blob([excelBuffer], { type: fileFormat });
            FileSaver.saveAs(finalData, "settlement_report.xlsx");
        } catch(err) {
            // console.log('file donaload error : ', err);
        }
    }

    return (
        <>
            <div className="mb-4">
                <div className="card">
                    <div className="card-body">
                        <h4 className="my-3"><b>Non Modulr Account </b></h4>
                        {/* SETTLEMENT INITIATED TRANSACTIONS */}
                        { datatableSettleInit && datatableSettleInit.length > 0 && <>
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-3"><b>Settlement Initiated Transactions</b></h5>
                                <div>
                                    <button type="submit" onClick={(e) => handleMultiSettleInit(e)} title="Settle Selected Transactions"
                                        disabled={!isCheckAnySetInit || !modulrRuleId} className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue">Settle</button>
                                    <button type="submit" onClick={getDownloadRecords} title="Download Transactions"
                                        className="btn btn-custom-no-white-shadow  btn-success">
                                            <i className="fa fa-file-excel" aria-hidden="true" style={{marginRight:10}}></i>Download</button>
                                </div>
                            </div>
                            {!modulrRuleId ?
                                <div>
                                    <h6 className="text-danger"><b>*Modulr rule is not created, you can't settle transaction.</b></h6>
                                </div> : ''
                            }
                            <div className='table-responsive' style={{height:'70vh',marginBottom:'10px'}}>
                            <table className="table table-bordered table-striped mb-4">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th style={{ width: "5%" }}><input
                                            name="checkboxinit"
                                            id="checkboxinit"
                                            autoComplete="off"
                                            type="checkbox"
                                            checked={isCheckAllSetInit}
                                            onChange={(e) => handleSettInitSelectAll(e)}
                                            className="form-check-input mx-3"
                                        /></th>
                                        <th>Source</th>
                                        <th>Sort Code</th>
                                        <th>Account Number</th>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Reference</th>
                                        <th>Currency</th>
                                        <th>Currency Debit</th>
                                        <th>Currency Credit</th>
                                        <th>Currency to GBP Exchange</th>
                                        <th>GBP Debit (Received)</th>
                                        <th>GBP Credit (Spent)</th>
                                        <th>Split %</th>
                                        <th>Split Amount</th>
                                        <th>Client Amount</th>
                                        <th style={{minWidth:'270px'}}>Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        datatableSettleInit.map((item, id) => {
                                            return item.status == "settle_initiated" ?
                                                <tr key={id}>
                                                    <td>
                                                        <div style={{ width: "100%", textAlign: "center" }}>
                                                            <i onClick={()=>handleSettleInit([item.non_modulr_transaction_id])}
                                                            className="fa-solid fa-circle-check text-primary cursor-pointer mx-1"
                                                            data-bs-placement="top" title="Settle Transaction" ></i>
                                                        </div>
                                                    </td>
                                                    <td><input
                                                        name="checkboxitm"
                                                        autoComplete="off"
                                                        type="checkbox"
                                                        value={item.non_modulr_transaction_id}
                                                        checked={item.checked !== undefined ? item.checked : false}
                                                        onChange={(e) => singleSelectSettInit(e)}
                                                        className="form-check-input mx-3"
                                                    /></td>
                                                    <td>{item&&item.source&&
                                                            item.source === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}>
                                                              <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i>
                                                            </div>
                                                          : item.source === "yapily"? <div style={{ width: "100%", textAlign: "center" }}>
                                                              <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i>
                                                            </div> :'-'}
                                                    </td>
                                                    <td>{item.sort_code?item.sort_code:''}</td>
                                                    <td>{item.account_number}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.reference}</td>
                                                    <td>{item&&item.currency?(item.currency).toUpperCase():''}</td>
                                                    <td>{item&&item.currency_debit?`${(item.currency).toUpperCase()=='USD'?'$':(item.currency).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(item.currency_debit):'0'}</td>
                                                    <td>{item&&item.currency_credit?`${(item.currency).toUpperCase()=='USD'?'$':(item.currency).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(item.currency_credit):'0'}</td>
                                                    <td>{item&&item.gbp_exchange_rate?item.gbp_exchange_rate:'0'}</td>
                                                    <td>{item.debit_received?'£'+formatAmount(item.debit_received):'0'}</td>
                                                    <td>{item.credit_spent?'£'+formatAmount(item.credit_spent):'0'}</td>
                                                    <td>{item.split_percentage?item.split_percentage:'0'}</td>
                                                    <td>{item.repayment?'£'+formatAmount(item.repayment):'0'}</td>
                                                    <td>{item.client_amount?'£'+formatAmount(item.client_amount):'0'}</td>
                                                    <td>{item.reason}</td>
                                                </tr>
                                                : ''
                                        })
                                    }
                                </tbody>
                            </table>
                            </div>
                            </>
                        }

                        {/* PENDING TRANSACTIONS */}
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-3"><b>Pending Transactions</b></h5>
                            <div>
                                <button type="submit" disabled={!isCheckAny}
                                    onClick={(e) => handlemultiSattled(e)} title="Initiate settlement for selected transactions"
                                    className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue">Initiate Settlement</button>
                            </div>
                        </div>
                        {
                            applicationId ? <>
                                <div className='table-responsive' style={{height:'70vh'}}>
                                <table className="table table-bordered table-striped mb-4">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th style={{ width: "5%" }}><input
                                                name="checkbox"
                                                id="checkbox"
                                                autoComplete="off"
                                                type="checkbox"
                                                checked={isCheckAll}
                                                onChange={(e) => handleSelectAll(e)}
                                                className="form-check-input mx-3"
                                            /></th>
                                            <th>Source</th>
                                            <th>Sort Code</th>
                                            <th>Account Number</th>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Reference</th>
                                            <th>Currency</th>
                                            <th>Currency Debit</th>
                                            {/* <th>Currency Credit</th> */}
                                            <th>Currency to GBP Exchange</th>
                                            <th>GBP Debit (Received)</th>
                                            <th style={{minWidth:'270px'}}>Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                        {
                                            (datatable && datatable.length > 0) &&
                                            datatable.map((item, id) => {
                                                return item.status == "pending" || item.status == "challenge_rejected" ?
                                                    <tr key={id}>
                                                        <td>
                                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                                <i onClick={()=>handleSattled([item.id])}
                                                                className="fa-solid fa-circle-check text-warning cursor-pointer mx-1"
                                                                data-bs-placement="top" title="Initiate Settlement Transaction" ></i>
                                                                {   item.status === "challenge_rejected" &&
                                                                    <i className="fa-solid fa-circle-info text-danger cursor-pointer mx-1"
                                                                    data-bs-placement="top" title={'Reason : '+item.challenged_rejected_reason} ></i>
                                                                }
                                                            </div>
                                                            {/* <button type="button"
                                                            onClick={() => handleSattled([item.id])}
                                                            className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue">Settle</button> */}
                                                        </td>
                                                        <td><input
                                                            name="checkbox"
                                                            autoComplete="off"
                                                            type="checkbox"
                                                            value={item.id}
                                                            checked={item.checked !== undefined ? item.checked : false}
                                                            onChange={(e) => singleSelect(e)}
                                                            className="form-check-input mx-3"
                                                        /></td>
                                                        <td>{item&&item.source&&
                                                                item.source === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}>
                                                                  <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i>
                                                                </div>
                                                              : item.source === "yapily"? <div style={{ width: "100%", textAlign: "center" }}>
                                                                  <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i>
                                                                </div> :'-'}
                                                        </td>
                                                        <td>{item.sort_code?item.sort_code:''}</td>
                                                        <td>{item.account_number}</td>
                                                        <td>{item.date}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.reference}</td>
                                                        <td>{item&&item.currency?(item.currency).toUpperCase():''}</td>
                                                        <td>{item&&item.currency_debit?`${(item.currency).toUpperCase()=='USD'?'$':(item.currency).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(item.currency_debit):'0'}</td>
                                                        <td>{item&&item.gbp_exchange_rate?item.gbp_exchange_rate:'0'}</td>
                                                        <td>{item.debit_received?'£'+formatAmount(item.debit_received):'0'}</td>
                                                        <td>{item.challenged_reason}</td>
                                                    </tr>
                                                    : ''
                                            })
                                        }
                                    </tbody>
                                    <tfoot>
                                    {
                                            (datatable && datatable.length <= 0) && 
                                            <tr><td colSpan={11}>Pending transaction records not found.</td></tr>
                                    }
                                    </tfoot>
                        
                                </table>
                                </div>
                                {/* <div className="text-center"><b>{datatable && datatable === 0 ?'Please Select Client':'No Data Found !!'}</b></div> */}
                            </> : <div
                                className="dierector-content mt-3 "
                                style={{
                                    borderRight: "15px solid #003056",
                                    borderLeft: "15px solid #003056",
                                }}
                            >
                                <p className="table-font-color m-0">
                                    <b>'Please Select Client'</b>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* model for settele and settlement initated */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure want to initiate settlement?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="h5">Reason</label>
                        <textarea type="text" className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Please enter reason"
                            maxLength={100}></textarea>
                        {
                            reason.length > 0 ? <p className={reason.length === 100 ? "text-danger": "font-weight-bold"}>Reason must be no longer than 100 characters ({reason.length} / 100)</p> : ''
                        }
                    </div>
                    {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" type="button" name="reasonname"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-danger"
                        type="button" onClick={handleClose}>
                        Close
                    </button>
                    <button variant="primary" type="button"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue"
                        onClick={() => submitPendingTransactions()}
                        disabled={loadingSubmit}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={showInit} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure want to settle?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label className="h5">Reason</label>
                        <textarea type="text" className="form-control" value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Please enter reason"
                            maxLength={100}></textarea>
                        {
                            reason.length > 0 ? <p className={reason.length === 100 ? "text-danger": "font-weight-bold"}>Reason must be no longer than 100 characters ({reason.length} / 100)</p> : ''
                        }
                    </div>
                    {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" type="button" name="reasonname"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-danger"
                        type="button" onClick={handleClose}>
                        Close
                    </button>
                    <button variant="primary" type="button"
                        className="btn btn-custom1 btn-custom-no-white-shadow  btn-custom-blue"
                        onClick={() => submitSettleInitTransactions()}
                        disabled={loadingSubmit}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default Transactions;