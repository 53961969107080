// import Swal from 'sweetalert2/dist/sweetalert2.js'
import Swal from "sweetalert2";
import { BASE_URL } from "../config.js";
import { localStorageClear } from "./CommonFunction.js";
import CryptoJS from 'crypto-js';
const axios = require("axios");
// For Post Api Calls And Put
export const HttpCall = async (method, type, body) => {
  return new Promise(async function (resolve, reject) {
    var key = process.env.REACT_APP_AES_ENCRYPTION_KEY;
    var randomKey = process.env.REACT_APP_AES_ENCRYPTION_IV;
    var messageKey = process.env.REACT_APP_AUTH_TOKEN_OTP;
    key = CryptoJS.enc.Utf8.parse(key);
    var iv = CryptoJS.enc.Utf8.parse(randomKey)
    var encrypted = CryptoJS.AES.encrypt(messageKey, key, {iv:iv,mode: CryptoJS.mode.CBC});
    encrypted = encrypted.toString();
    const url = BASE_URL + method;
    const token = localStorage.getItem('token');
    axios({
      method: type,
      url: url,
      data: body,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization": token,
        "x-access-health": encrypted
      },
      // auth: { username: '2bdb4838-fc3b-4a84-aa5c-2f38bc97e7dd', password: '' }
    })
      .then((response) => {
        if (response && response.status === 200) {
          // if (!(response.config.method === "get")) {

          //   Swal.fire({
          //     position: "center",
          //     type: "error",
          //     title: response.data.message,
          //   });
          // }

          return resolve(response);
        }
        return resolve(response);
      })
      .catch((err) => {

        return reject(err);
      });
  });
};
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    Object.assign(config.headers, {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    });

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {


    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

//For Api Error Handling Globaly
export const handleError = (error, isSwal=false) => {
  if (error && error.response && error.response.status === 403) {
    localStorageClear();
    return Swal.fire({
      position: "center",
      type: "error",
      title: "Invalid User",
    }).then((ok) => {
      window.location.assign("/");
    });
  } else if (error && error.response && error.response.status === 404) {
    Swal.fire({
      position: "center",
      type: "error",
      title: error.response.data && error.response.data.error ? error.response.data.error : error.response.data.message ? error.response.data.message : '',
    });
  } else if (error && error.response && error.response.status === 500) {
    Swal.fire({
      position: "center",
      type: "error",
      title: error.response.data && error.response.data.error ? error.response.data.error : error.response.data.message ? error.response.data.message : '',
    });
  } else if (error && error.response && error.response.status === 400 && !isSwal) {
    Swal.fire({
      position: "center",
      type: "error",
      title: error.response.data && error.response.data.error ? error.response.data.error : error.response.data.message ? error.response.data.message : '',
    });
  } else if (error && error.response && error.response.status === 401) {
    Swal.fire({
      position: "center",
      type: "error",
      title: error.response.data && error.response.data.error ? error.response.data.error : error.response.data.message ? error.response.data.message : '',
    });
    localStorageClear();
    window.location.assign("/login");
  } else {
    // Swal.fire({
    //   position: "center",
    //   type: "error",
    //   title: "Unknown Error",
    // });
  }
};
//for delete
