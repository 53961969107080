export const LOAD_USERS_LOADING = 'REDUX_THUNK_LOAD_USERS_LOADING';
export const LOAD_USERS_SUCCESS = 'REDUX_THUNK_LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'REDUX_THUNK_LOAD_USERS_ERROR';

export const SET_NAME = "SET_NAME";

export const CODAT_REQUEST = "CODAT_REQUEST";
export const CODAT_SUCCESS = "CODAT_SUCCESS";
export const CODAT_ERROR = "CODAT_ERROR";

export const CODAT_LINK_REQUEST = "CODAT_LINK_REQUEST";
export const CODAT_LINK_SUCCESS = "CODAT_LINK_SUCCESS";
export const CODAT_LINK_ERROR = "CODAT_LINK_ERROR";

export const TRUELAYER_REQUEST = "TRUELAYER_REQUEST";
export const TRUELAYER_SUCCESS = "TRUELAYER_SUCCESS";
export const TRUELAYER_ERROR = "TRUELAYER_ERROR";

export const GET_FUNDED_REQUEST = "GET_FUNDED_REQUEST";
export const GET_FUNDED_SUCCESS = "GET_FUNDED_SUCCESS";
export const GET_FUNDED_ERROR = "GET_FUNDED_ERROR";

export const GET_TOKEN = "GET_TOKEN";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_ERROR = "GET_TOKEN_ERROR";

export const GET_FUNDED_DETAILS = "GET_FUNDED_DETAILS";
export const GET_FUNDED_DETAILS_SUCCESS = "GET_FUNDED_DETAILS_SUCCESS";
export const GET_FUNDED_DETAILS_ERROR = "GET_FUNDED_DETAILS_ERROR";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOADER_START = "LOADER_START";
export const LOADER_STOP = "LOADER_STOP";

export const LIST_APPLICATION_REQUEST = "LIST_APPLICATION_REQUEST";
export const LIST_APPLICATION_SUCCESS = "LIST_APPLICATION_SUCCESS";
export const LIST_APPLICATION_ERROR = "LIST_APPLICATION_ERROR";

export const LIST_TRANSACTION_REQUEST = "LIST_TRANSACTION_REQUEST";
export const LIST_TRANSACTION_SUCCESS = "LIST_TRANSACTION_SUCCESS";
export const LIST_TRANSACTION_ERROR = "LIST_TRANSACTION_ERROR";

export const LIST_CLIENT_REQUEST = "LIST_CLIENT_REQUEST";
export const LIST_CLIENT_SUCCESS = "LIST_CLIENT_SUCCESS";
export const LIST_CLIENT_ERROR = "LIST_CLIENT_ERROR";

// Admin Offer List
export const ADMIN_OFFER_LIST_INITIAL = "ADMIN_OFFER_LIST_INITIAL";
export const ADMIN_OFFER_LIST_SUCCESS = "ADMIN_OFFER_LIST_SUCCESS";
export const ADMIN_OFFER_LIST_ERROR = "ADMIN_OFFER_LIST_ERROR";

export const PROFILE_INFO_REQUEST = "PROFILE_INFO_REQUEST";
export const PROFILE_INFO_SUCCESS = "PROFILE_INFO_SUCCESS";
export const PROFILE_INFO_ERROR = "PROFILE_INFO_ERROR";

export const PHONE_NUMBER = "PHONE_NUMBER";
export const PHONE_NUMBER_SUCCESS = "PHONE_NUMBER_SUCCESS"; 
export const PHONE_NUMBER_FAILED = "PHONE_NUMBER_FAILED";

export const LEDGER_TRANSACTION = "LEDGER_TRANSACTION";
export const LEDGER_TRANSACTION_SUCCESS = "LEDGER_TRANSACTION_SUCCESS"; 
export const LEDGER_TRANSACTION_FAILED = "LEDGER_TRANSACTION_FAILED";

export const LEDGER_TRANSACTION_SETTLED = "LEDGER_TRANSACTION_SETTLED";
export const LEDGER_TRANSACTION_SETTLED_SUCCESS = "LEDGER_TRANSACTION_SETTLED_SUCCESS"; 
export const LEDGER_TRANSACTION_SETTLED_FAILED = "LEDGER_TRANSACTION_SETTLED_FAILED";

export const LEDGER_TRANSACTION_CHELLENGED = "LEDGER_TRANSACTION_CHELLENGED";
export const LEDGER_TRANSACTION_CHELLENGED_SUCCESS = "LEDGER_TRANSACTION_CHELLENGED_SUCCESS"; 
export const LEDGER_TRANSACTION_CHELLENGED_FAILED = "LEDGER_TRANSACTION_CHELLENGED_FAILED";

export const SETTLED_INITIATED_REQUEST = "SETTLED_INITIATED_REQUEST";
export const SETTLED_INITIATED_REQUEST_SUCCESS = "SETTLED_INITIATED_REQUEST_SUCCESS"; 
export const SETTLED_INITIATED_REQUEST_FAILED = "SETTLED_INITIATED_REQUEST_FAILED";

export const SETTLED_REQUEST = "SETTLED_REQUEST";
export const SETTLED_REQUEST_SUCCESS = "SETTLED_REQUEST_SUCCESS"; 
export const SETTLED_REQUEST_FAILED = "SETTLED_REQUEST_FAILED";

export const LIST_CHALLENGED_TRANSACTION_REQUEST = "LIST_CHALLENGED_TRANSACTION_REQUEST";
export const LIST_CHALLENGED_TRANSACTION_REQUEST_SUCCESS = "LIST_CHALLENGED_TRANSACTION_REQUEST_SUCCESS"; 
export const LIST_CHALLENGED_TRANSACTION_REQUEST_FAILED = "LIST_CHALLENGED_TRANSACTION_REQUEST_FAILED";