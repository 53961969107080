import * as types from "../service/types";

const InitState = {
  loading: false,
  codatdata: "",
  error: false,
  codatLinkData: '',
  errors: ''
};
const Codat = (state = InitState, action) => {
  switch (action.type) {
    case types.CODAT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.CODAT_SUCCESS:
      return {
        loading: false,
        codatdata: action.payload,
        error: "",
      };
    case types.CODAT_ERROR:
      return {
        loader: false,
        codatdata: "",
        error: action.error,
      };
      case types.CODAT_LINK_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case types.CODAT_LINK_SUCCESS:
        return {
          loading: false,
          codatLinkData: action.payload.data,
          error: false,
        };
      case types.CODAT_LINK_ERROR:
        return {
          loader: false,
          codatLinkData: "",
          errors: action.payload.response,
        };
    default:
      return state;
  }
};

export default Codat;