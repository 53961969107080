import React from "react";
import { toTitleCase } from "../../services/CommonFunction";

const UserDetails = ({user}) => {
    return (
        <div className="accordion" id="accordionExample">
            <div className="accordion-item">
                <div id="collapseOne" className="accordion-collapse" aria-labelledby="headingOne">
                    <div className="accordion-body">
                        <span className="d-flex">
                        <p className="mx-3 w-40 my-0 table-font-color">
                            <b>Name</b>
                        </p>
                        <p className="w-60 my-0 table-font-color">
                        {user&&user.first_name?(toTitleCase(user.first_name||'')+' '+toTitleCase(user.last_name||'')):'-'}
                        </p>
                        </span>
                        <hr />
                        <span className="d-flex">
                        <p className="mx-3 w-40 my-0 table-font-color">
                            <b>Email</b>
                        </p>
                        <p className="w-60 my-0 table-font-color">
                        {user&&user.email?user.email:'-'}
                        </p>
                        </span>
                        <hr />
                        <span className="d-flex">
                        <p className="mx-3 w-40 my-0 table-font-color">
                            <b>Mobile Number</b>
                        </p>
                        <p className="w-60 my-0 table-font-color">
                            {user&&user.phone_number?user.phone_number:'-'}
                        </p>
                        </span>
                        <hr />
                        <span className="d-flex">
                        <p className="mx-3 w-40 my-0 table-font-color">
                            <b>Is Parent User</b>
                        </p>
                        <p className="w-60 my-0 table-font-color">{user&&user.parent?'Yes':'No'}</p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UserDetails;