import React, { useState, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import NavBarGetFunded from "../components/NavBarGetFunded";
import CompanyDetails from "./CompanyDetails";
import ApplicationDetails from "./ApplicationDetails";
import ConnectionDetails from "./ConnectionDetails";
import check from "../img/check.svg";
import Footer from "../components/Footer";
import { getFundedData } from "../redux/action/getfunded";
import { useDispatch, useSelector } from "react-redux";
import { decryptString, encryptString } from "../services/CommonFunction";

const Index = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const email = state && state.email ? state.email : null;
  const companyDetailsRes = (state && state.signupRes && state.signupRes.company_details_section) ? state.signupRes.company_details_section : '';
  const applicationDetailsRes = (state && state.signupRes && state.signupRes.application_details_section) ? state.signupRes.application_details_section : '';
  const codatDetailsRes = (state && state.signupRes && state.signupRes.codat_details) ? state.signupRes.codat_details : null;
  const userName = {
    fname: companyDetailsRes && companyDetailsRes.personal_details && companyDetailsRes.personal_details.first_name,
    lname: companyDetailsRes && companyDetailsRes.personal_details && companyDetailsRes.personal_details.last_name
  };
    // check reload page
  const [refresh, setRefresh] = useState(localStorage.getItem('refresh'));
  const [fromLogin, setFromLogin] = useState(localStorage.getItem('fromLogin'));
  const signupResult = useSelector((state) => state.GetFunded.getfundedData);
  const [stepCompletion, setStepCompletion] = useState(null);
  const [value, setValue] = useState("first");
  const [companyValue, setCompanyValue] = useState({});

  useEffect(() => {
    document.title = props.title;
    // check reload page
    if(refresh && refresh === 'true' && fromLogin !== 'true') {
      reLoadSignupData();
    } else {
      localStorage.removeItem('refresh');
      localStorage.removeItem('fromLogin');
    }
    // check page reload & call signup api again
    window.addEventListener("beforeunload", isPageRefresh);
    return () => {
      window.removeEventListener("beforeunload", isPageRefresh);
    };
  }, []);
  const isPageRefresh = (e) => {
    localStorage.setItem('refresh','true');
  }
  
  const reLoadSignupData = () => {
    if(email && email !== undefined) {
      dispatch(getFundedData({email}));
    } else {
      const userData = localStorage.getItem('userData');
      if(userData) {
          const user = userData ? JSON.parse(decryptString(userData)) : {};
          if(user && user.email) { dispatch(getFundedData({email:user.email})); }
      }
    }
    localStorage.removeItem('refresh');
    localStorage.removeItem('fromLogin');
  }
  // redirect to getFunded page when /signup api response comes
  useEffect(() => {
    if (signupResult) {
        localStorage.setItem('role', encryptString('funded'));
        navigate('/getFunded', { state: { signupRes: signupResult, email } });
    }
    // eslint-disable-next-line
  }, [signupResult]);
  // check is Company/KYC step completed.
  useEffect(() => {
    if(codatDetailsRes && codatDetailsRes.link_authorization && codatDetailsRes.link_authorization === 'LINKED') {
        setStepCompletion('second');
    }
    else if(companyDetailsRes && companyDetailsRes.company_details && companyDetailsRes.company_details.company_name 
      && companyDetailsRes.company_details.country_of_incorporation && companyDetailsRes.personal_details.first_name 
      && companyDetailsRes.personal_details.phone_number && companyDetailsRes.revenue_details && companyDetailsRes.revenue_details.annual_recurring_revenue) {
        setStepCompletion('first');
    }
  }, [companyDetailsRes, codatDetailsRes]);

  useEffect(() => {
      if(stepCompletion === 'first') {
        setValue('second');
      } else if(stepCompletion === 'second') {
        setValue('third');
      } else {
        setValue('first');
      }
  },[stepCompletion]);

  const nextSlide = () => {
    if (value === 'first' && (stepCompletion === 'first' || stepCompletion === 'second')) {
      setValue('second');
    }
    if (value === 'second' && (stepCompletion === 'second')) {
      setValue('third');
    }
  }

  const prevSlide = () => {
    if (value === 'third') {
      setValue('second');
    }
    if (value === 'second') {
      setValue('first');
    }
  }

  const handleTabChange = () => {
    if (value === 'first') {
      setValue('second');
    }
    if (value === 'second') {
      setValue('third');
    }
    if (value === 'third') {
      setValue('first');
    }
  }

  const moveTab = (value) => {
      setValue(value);
    
  }
  const companyTab = (value) => {
      setCompanyValue(value)
    
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value])

  return (
    <>
      <NavBarGetFunded userName={userName} />
      <main style={{ paddingTop: "8%" }} className="bg-light">
        <section className="sectionone bg-light pb-5" style={{minHeight: '70vh'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <h5 className="mb-0">Sections</h5>
                <h6 className="mb-3" style={{ fontWeight: 500 }}>
                  <p className="m-0 h6 complete">{value === 'first' ? 'Company Details' : value === 'second' ? 'Connections' : 'Any Other Documents'}</p>
                </h6>
              </div>
              <div className="col-lg-9">
                <div className="d-flex justify-content-end mb-3 pb-1">
                  <div>
                    <button className="btn btn-light btn-prev me-3" onClick={prevSlide} disabled={value === 'first' ? true : false}>
                      <i aria-hidden className="fa fa-arrow-left mx-2"
                      ></i>Previous Section
                    </button>
                    <button className="btn btn-primary bg-primary text-white" onClick={nextSlide}
                      disabled={ value === 'first' && !stepCompletion ? true : value === 'second' && stepCompletion === 'first' ? true : value === 'third' ? true : false }>
                      Next Section<i aria-hidden className="fa fa-arrow-right mx-2" > </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Tab.Container activeKey={value} onSelect={(k) => handleTabChange} defaultActiveKey="first">
              <div className="row">
                <div className="col-lg-3">
                  <Nav variant="pills" className="nav nav-tabs flex-column" align="left">
                    <Nav.Item className="nav-item">
                      <Nav.Link className={value === 'first' ? 'nav-link nav-links w-100 text-start' : 'blue'} eventKey="first"><b>Company Details</b>
                        {
                          !stepCompletion  ?
                            <p className="m-0 h6 complete">Incomplete</p>
                            :
                            <div className="row">
                              <div className="col-lg-8">
                                <p className="m-0 h6 complete">Completed</p>
                              </div>
                              <div className="col-lg-4">
                                <div className="float-end">
                                  <img src={check} alt="checkimage" />
                                </div>
                              </div>
                            </div>
                        }
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className={value === 'first' || value === 'second' ? 'nav-link nav-links w-100 text-start' : 'blue'} eventKey="second"><b>Connections</b>
                        {
                          !stepCompletion || stepCompletion === 'first' ?
                            <p className="m-0 h6 complete">Incomplete</p>
                            :
                            <div className="row">
                              <div className="col-lg-8">
                                <p className="m-0 h6 complete">Completed</p>
                              </div>
                              <div className="col-lg-4">
                                <div className="float-end">
                                  <img src={check} alt="checkimage" />
                                </div>
                              </div>
                            </div>
                        }
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="nav-item">
                      <Nav.Link className={value === 'first' || value === 'second' || value === 'third' ? 'nav-link nav-links w-100 text-start' : 'blue'} eventKey="third">
                        <b>Any Other Documents</b>
                        {
                          value === 'first' || value === 'second' || value === 'third' ?
                            <p className="m-0 h6 complete">Incomplete</p>
                            :
                            <div className="row">
                              <div className="col-lg-8">
                                <p className="m-0 h6 complete">Completed</p>
                              </div>
                              <div className="col-lg-4">
                                <div className="float-end">
                                  <img src={check} alt="checkimage" />
                                </div>
                              </div>
                            </div>
                        }
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-lg-9" style={{ margin: "0 auto" }}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <CompanyDetails moveTab={moveTab} companyTab={companyTab} companyDetailsRes={companyDetailsRes} setStepCompletion={setStepCompletion} email={email} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <ConnectionDetails moveTab={moveTab} setStepCompletion={setStepCompletion} companyValue={companyValue} codatDetailsRes={codatDetailsRes} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <ApplicationDetails moveTab={moveTab} companyValue={companyValue} applicationDetailsRes={applicationDetailsRes} codatDetailsRes={codatDetailsRes} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
};

export default Index;