import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import { handleError, HttpCall } from "../../services/UseHttps";
import OfferDetails from "./OfferDetails";
import { MDBDataTableV5 } from "mdbreact";
// import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { listApplicationData } from "../../redux/action/listApplication";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
// import { downloadFile } from "../../services/CommonFunction";
import { BASE_URL, makeOfferAPI, setInEligibleAPI } from "../../config";
import CodatRecords from "./CodatRecords";
import { getTodayYYYYMMDD } from "../../services/CommonFunction";
const ViewApplication = (props) => {
  const [defaultDateValue, setDefaultDateValue] = useState(getSixMonthDate());
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [modalShowCodat, setModalShowCodat] = React.useState(false); // codat model
  const [codatRecords, setCodatRecords] = useState([]);
  const [loadingCodat, setLoadingCodat] = useState(true);
  const [loadingAcceptOff, setLoadingAcceptOff] = useState(false);
  // mdb-data-table
  const [mdbColHeaders, setMdbColHeaders] = useState([
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 150,
    },
    {
      label: "Company",
      field: "company_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Annual Revenue",
      field: "annual_revenue",
      sort: "asc",
      width: 150,
    },
    {
      label: "Type Of Business",
      field: "type_of_business",
      sort: "asc",
      width: 150,
    },
    // {
    //   label: "Account Detail",
    //   field: "account_details",
    //   sort: "asc",
    //   width: 150,
    // },
    {
      label: "Website",
      field: "websiteLink",
      sort: "asc",
      width: 150,
    },
    {
      label: "Codat Link",
      field: "codatLink",
      sort: "asc",
      width: 150,
    },
    {
      label: "Uploaded Files",
      field: "documentLink",
      sort: "asc",
      width: 150,
    },
    {
      label: "More Details",
      field: "moreDetails",
      sort: "asc",
      width: 150,
    },
  ]);
  const [datatable, setDatatable] = useState({
    columns: mdbColHeaders,
    rows: [],
  });
  const numDecimalPatt = /^(\d*\.{0,1}\d{0,2}$)/; // /^[0-9]*(\.[0-9]{0,2})?$/ - allow 2 decimple point value
  const createOfferhandler = (value) => {
    try {
      setLoadingAcceptOff(true);
      HttpCall(`${makeOfferAPI}`, "POST", { ...value, 
          advance_amount: +Number(value.advance_amount).toFixed(2),
          fixed_fee: +Number(value.fixed_fee).toFixed(2),
          full_repayment: +Number(value.full_repayment).toFixed(2),
          minimum_split_first_12_months: +Number(value.minimum_split_first_12_months).toFixed(2),
          minimum_split_from_12_months: +Number(value.minimum_split_from_12_months).toFixed(2),
          maximum_split: +Number(value.maximum_split).toFixed(2),
          minimum_performance: +Number(value.minimum_performance).toFixed(2),
          application_id: customerId})
        .then((res) => {
          clearOfferForm(); // formik.resetForm();
          Swal.fire({
            title: "Done!", text: "Offer Created !!", icon: "success", focusConfirm:true, returnFocus:false
          });
          dispatch(listApplicationData(setLoading));
          setModalShow(false);
          setLoadingAcceptOff(false);
        })
        .catch((error) => {
          formik.resetForm();
          handleError(error);
          setLoadingAcceptOff(false);
        });
    } catch(err) {
      setLoadingAcceptOff(false);
    }
  };
  const inEligibleHandler = (id) => {
    const payload = {
      status: "ineligible",
      reason: "application does not match required terms",
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You want to make this customer Ineligible?",
      icon: "warning",
      dangerMode: true,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((willDelete) => {
      if (willDelete?.isConfirmed) {
        HttpCall(`${setInEligibleAPI}/${id}`, "Patch", payload)
          .then((res) => {
            Swal.fire({
              title: "Done!",
              text: "This Customer is Ineligible Now",
              icon: "success",
              // timer: 2000,
              // button: false,
            });
            dispatch(listApplicationData(setLoading));
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              text: "",
              icon: "Error",
              // timer: 2000,
              // button: false,
            });
            handleError(error);
          });
      }
    });
  };

  const [tabsState, setTabsState] = useState("NEW");
  const [initialValues, setInitialValues] = useState({
    minimum_split_first_12_months: "",
    minimum_split_from_12_months: "",
    maximum_split: "",
    minimum_performance: "",
    advance_amount: "",
    full_repayment: "",
    fixed_fee: "",
    effective_from: defaultDateValue
  });
  const validate = (values) => {
    let errors = {};
    if (values.minimum_split_first_12_months ==='') {
      errors.minimum_split_first_12_months = "Minimum split (first 12 months) percentage is required";
    } else if(!numDecimalPatt.test(String(values.minimum_split_first_12_months))) {
      errors.minimum_split_first_12_months = "Minimum split (first 12 months) should have only two decimal points";
    } else if ( 0 > values.minimum_split_first_12_months || values.minimum_split_first_12_months > 100) {
      errors.minimum_split_first_12_months = "Invalid minimum split (first 12 months) percentage";
    }
    if (values.minimum_split_from_12_months === '') {
      errors.minimum_split_from_12_months = "Minimum split (from 12 months) percentage is required";
    } else if(!numDecimalPatt.test(String(values.minimum_split_from_12_months))) {
      errors.minimum_split_from_12_months = "Minimum split (from 12 months) should have only two decimal points";
    } else if (0 > values.minimum_split_from_12_months || values.minimum_split_from_12_months > 100) {
      errors.minimum_split_from_12_months = "Invalid minimum split (from 12 months) percentage";
    }
    if (values.maximum_split ==='' || values.maximum_split === 0) {
      errors.maximum_split = "Maximum split percentage is required";
    } else if(!numDecimalPatt.test(String(values.maximum_split))) {
      errors.maximum_split = "Minimum split % should have only two decimal points";
    } else if (0 > values.maximum_split || values.maximum_split > 100) {
      errors.maximum_split = "Invalid maximum split percentage";
    }
    if (values.minimum_performance ==='') {
      errors.minimum_performance = "Minimum performance per month required";
    } else if(!numDecimalPatt.test(String(values.minimum_performance))) {
      errors.minimum_performance = "Minimum performance per month should have only two decimal points";
    } else if (0 > values.minimum_performance) {
      errors.minimum_performance = "Invalid minimum performance per month amount";
    }
    if (values.advance_amount ==='') {
      errors.advance_amount = "Advance amount is required";
    } else if(!numDecimalPatt.test(String(values.advance_amount))) {
      errors.advance_amount = "Advance amount should have only two decimal points";
    }
    if (values.full_repayment ==='') {
      errors.full_repayment = "Full repayment is required";
    }
    if (values.fixed_fee ==='') {
      errors.fixed_fee = "Fixed fee is required";
    } else if(!numDecimalPatt.test(String(values.fixed_fee))) {
      errors.fixed_fee = "Advance amount should have only two decimal points";
    }
    if (values.effective_from ==='') {
      errors.effective_from = "Effective from date is required";
    } else {
      const today = new Date(getTodayYYYYMMDD());
      const effDate = new Date(values.effective_from);
      if(effDate.getTime() < today.getTime()) {
        errors.effective_from = "Please select effective from date bigger or equal today date.";
      }
    }
    return errors;
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize:true,
    onSubmit: createOfferhandler,
    validate,
  });
  // reset-value
  const clearOfferForm = () => {
    setModalShow(false); // formik.resetForm();
    setInitialValues({
      minimum_split_first_12_months: "",
      minimum_split_from_12_months: "",
      maximum_split: "",
      minimum_performance: "",
      advance_amount: "",
      full_repayment: "",
      fixed_fee: "",
      effective_from: defaultDateValue
    });
  }
  const [TableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [ selectedOfferId, setSelectedOfferId ] = useState(null);
  const [dataFlag, setDataFlag] = useState(true);
  const dispatch = useDispatch();
  const select = useSelector(
    (state) =>
      state &&
      state.ListApplication &&
      state.ListApplication.listApplicationData
  );
  useEffect(() => {
    dispatch(listApplicationData(setLoading));
    document.title = props.title;
  }, []);
  useEffect(() => {
    const newArray = select && select.sort(SortArray);
    const newArrayNONempty = (newArray || []).filter((em) => em.name !== "");
    const newArrayempty = (newArray || []).filter((em) => em.name === "");
    const finalArray = (newArrayNONempty || []).concat(newArrayempty);
    setTableData(finalArray || []);
  }, [select]);
  function SortArray(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (dataFlag) {
      tabsHandler(tabsState);
      return;
    }
    setDataFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TableData]);

  // const dataKey =
  //   TableData[0] &&
  //   Object.keys((({ id, moreDetails, ...o }) => o)(TableData[0]));

  const tabsHandler = (status) => {
    setFilterTableData(
      TableData && TableData.filter((el) => el.app_status === status)
    );
    setTabsState(status);
    // set mdb model value
    if (
      status === "offer_given" ||
      status === "offerAccepted" ||
      status === "offerRejected" ||
      status === "ineligible"
    ) {
      const statusColumn = mdbColHeaders.map((el) => {
        if (el.label === "Action") {
          el.label = "Status";
        }
        return el;
      });

      setMdbColHeaders(statusColumn);
    } else {
      const statusColumn = mdbColHeaders.map((el) => {
        if (el.label === "Status") {
          el.label = "Action";
        }
        return el;
      });

      setMdbColHeaders(statusColumn);
    }
    // eslint-disable-next-line
    const filtData = TableData.filter((el) => {
      let act = "";
      if (el.app_status === "NEW" || el.app_status === "newChanges") {
        act = (
          <div style={{ width: "100%", textAlign: "center" }}>
            <i
              onClick={() => {
                setCustomerId(el.id);
                setModalShow(true);
              }}
              className="fa-solid fa-circle-plus text-primary cursor-pointer mx-1"
              data-bs-placement="top"
              title="Make Offer"
            ></i>
            <i
              onClick={() => {
                if (el.app_status !== "inEligible") inEligibleHandler(el.id);
              }}
              className="fa-solid fa-circle-xmark text-danger mx-1 cursor-pointer"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Ineligible"
            ></i>
          </div>
        );
      } else if (el.app_status === "ineligible") {
        act = (
          <div style={{ width: "100%", textAlign: "center" }}>
            <i
              className="fa-solid fa-circle-xmark text-danger mx-1 "
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Ineligible"
            ></i>
          </div>
        );
      } else if (el.app_status === "offer_given") {
        act = (el.offer_status)==='accepted' ? (
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-success mx-1 text-center w-100"
            title="Offer Accepted"
          ></i>
        ): (el.offer_status)==='rejected' ?(
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-danger mx-1 text-center w-100"
            title="Offer Rejected"
          ></i>
        ): (el.offer_status)==='expired' ?(
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-danger mx-1 text-center w-100"
            title="Offer Expired"
          ></i>
        ):(el.offer_status)==='query'?(
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-primary mx-1 text-center w-100"
            title="Offer Query"
          ></i>
        ):(
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-warning mx-1 text-center w-100"
            title="Offer Awaiting"
          ></i>
        );
      } else if (el.app_status === "offerAccepted") {
        act = (
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-success mx-1 text-center w-100"
            title="Offer Accepted"
          ></i>
        );
      } else if (el.app_status === "offerRejected") {
        act = (
          <i
            onClick={() => setCustomerId(el.id)}
            className="fa-solid fa-circle text-danger mx-1 text-center w-100"
            title="Offer Rejected"
          ></i>
        );
      }
      if (el.type_of_business) {
        el.type_of_business = el.type_of_business.toUpperCase();
      }
      if (el.codat_link) {
        let codeAtLinkUrl = el.codat_link;
        if (el.codat_link.charAt(0) === "/") { codeAtLinkUrl = el.codat_link.substring(1); }
        el.codatLink = (
          <button style={{padding:0}}
            className="btn btn-link text-nowrap text-decoration-underline"
            onClick={()=>viewCodatData(el.id, codeAtLinkUrl)}
          > {" "} Click here{" "} </button>
        );
      }
      if (el.website) {
        el.websiteLink = (
          <a
            className="text-nowrap text-decoration-underline"
            href={el.website} target="_blank"
          > {" "} Click here{" "} </a>
        );
      }
      if (el.documents) {
        let documentUrl = el.documents;
        if (el.documents.charAt(0) === "/") { documentUrl = el.documents.substring(1); }
        el.documentLink = <a
            className="text-nowrap text-decoration-underline"
            href={BASE_URL + documentUrl} download target="_blank"
          > {" "} Download {" "} </a>;
      }
      el.moreDetails = (
        <i
          onClick={() => setSelectedOfferId(el.id)}
          className="fa-solid fa-up-right-from-square text-primary cursor-pointer"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal3"
        ></i>
      );
      if (el.app_status === status) {
        el.action = act;
        return el;
      }
    });
    setDatatable({ ...datatable, rows: filtData?filtData:[] });
    setTimeout(() => { setLoading(false); }, 1000);
  };
  const renderErrorMessage = (field) => {
    return (
      formik.touched[field] && (
        <div className="text-danger">{formik.errors[field]}</div>
      )
    );
  };

  const getRecordsCountByStatus = (status) => {
    return TableData?.length && TableData.filter((el) => el.app_status == status).length;
  }

  // codeat view 
  const viewCodatData = (id, codat_link) => {
    setModalShowCodat(true);
    setLoadingCodat(true);
    HttpCall(`${codat_link}`, "GET")
    .then((res) => {
      if(res && res.status === 200 && res.data && res.data.results) {
        setCodatRecords(res.data.results);
      }
      setLoadingCodat(false);
    })
    .catch((error) => {
      handleError(error);
      setLoadingCodat(false);
    });
  }
  const closeCodatModel = () => {
    setModalShowCodat(false);
    setCodatRecords([]);
    setLoadingCodat(false);
  }
  useEffect(() => {
    if (!formik.values.advance_amount && formik.values.advance_amount !==0 ) {
      setInitialValues({...formik.values,full_repayment:''});
      return;
    }
    if (!formik.values.fixed_fee && formik.values.fixed_fee !==0 ) {
      setInitialValues({...formik.values,full_repayment:''});
      return;
    }
    if (formik.values.advance_amount !==undefined && formik.values.fixed_fee!==undefined ) {
      const fp = (formik.values.advance_amount || 0) + (formik.values.fixed_fee || 0);
      setInitialValues({...formik.values,full_repayment: Number(fp).toFixed(2) });
    }
  }, [formik.values])

  // change set effective date
  const effectiveDateChange = () => {
    setDefaultDateValue(getSixMonthDate());
  }
  // set effective date with six month add
  function getSixMonthDate() {
    const todayDate = new Date();
    const date = todayDate.setMonth(todayDate.getMonth() + 6); // add six month
    return (new Date(date).toISOString().split('T')[0]); // yyyy-mm-dd
  }

  return (
    <>
      <NavBar admin="true" />
      <main>
        <section className="sectionone bg-light section-height">
          <div className="container">
            <h3 className="mb-3  my-2" style={{ fontWeight: 400 }}>
              View Application
            </h3>
            <div className="row">
              <div className="col-12">
                <ul
                  className="nav nav-tabs flex-nowrap"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => { setTabsState("NEW"); dispatch(listApplicationData(setLoading));} /*</ul>tabsHandler("NEW")*/}
                  >
                    <button
                      className="nav-link w-100 active"
                      id="tab1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1"
                      type="button"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                    >
                      New{" "}
                      <span className="count rounded">
                        {/* {notificationData.new} */}
                        { getRecordsCountByStatus('NEW') }
                      </span>
                    </button>
                  </li>
                  {/* <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => tabsHandler("newChanges")}
                  >
                    <button
                      className="nav-link w-100"
                      id="tab2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab2"
                      type="button"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      New changes{" "}
                      <span className="count rounded">
                        {notificationData.newChanges}
                      </span>
                    </button>
                  </li> */}
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => {setTabsState("ineligible"); dispatch(listApplicationData(setLoading))} /*tabsHandler("ineligible")*/}
                  >
                    <button
                      className="nav-link w-100"
                      id="tab3-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab3"
                      type="button"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      Ineligible{" "}
                      <span className="count rounded">
                      { getRecordsCountByStatus('ineligible') }
                      </span>
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => { setTabsState("offer_given"); dispatch(listApplicationData(setLoading)); } /*tabsHandler("offer_given")*/}
                  >
                    <button
                      className="nav-link w-100"
                      id="tab4-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab4"
                      type="button"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      Offers made{" "}
                      <span className="count rounded">
                      { getRecordsCountByStatus('offer_given') }
                      </span>
                    </button>
                  </li>
                  {/*<li
                    className="nav-item"
                    role="presentation"
                    onClick={() => tabsHandler("offerAccepted")}
                  >
                    <button
                      className="nav-link w-100"
                      id="tab5-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab5"
                      type="button"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      Offers - accepted{" "}
                      <span className="count rounded">
                        {notificationData.offerAccepted}
                      </span>
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => tabsHandler("offerRejected")}
                  >
                      Offers - Rejected/other{" "}
                      <span className="count rounded">
                        {notificationData.offerRejected}
                      </span>
                  </li> */}
                </ul>
              </div>
              <div className="col-12">
                <div className="tab-content" id="myTabContent">
                  <div className="mb-4">
                    {filterTableData&&filterTableData.length === 0 && (
                      <div
                        className="dierector-content mt-3 "
                        style={{
                          borderRight: "15px solid #003056",
                          borderLeft: "15px solid #003056",
                        }}
                      >
                        <p className="table-font-color m-0">
                          <b>View Application records not found.</b>
                        </p>
                      </div>
                    )}
                    {filterTableData&&filterTableData.length > 0 &&
                      // <table className="table table-bordered">
                      //   <thead>
                      //     <tr>{tableHeader()}</tr>
                      //   </thead>
                      //   <tbody>{tableBody()}</tbody>
                      // </table>
                      (!loading ? (
                        <MDBDataTableV5
                          striped
                          bordered
                          hover
                          // noRecordsFoundLabel="true"
                          data={datatable}
                          // autoWidth
                          // searching={false}
                          fullPagination
                          searchTop
                          searchBottom={false}
                          entriesOptions={[2, 5, 10, 20, 50, 100]}
                          paging={!(filterTableData.length <= "4")}
                          className={`${
                            filterTableData.length <= "4"
                              ? "hide-pagination"
                              : ""
                          }`}
                          // pagingTop searchTop
                          // theadColor="blue lighten-5" entries={100} pagesAmount={4} order={['uptime', 'asc']} className="mx-auto"
                        />
                      ) : (
                        <>
                          <ClipLoader
                            size={150}
                            css={{
                              display: "block",
                              margin: "0 auto",
                              borderColor: "green",
                            }}
                            color="green"
                          />
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* modal */}
      <Footer />

      <OfferDetails id={selectedOfferId} customerId={customerId} />
      <Modal
        show={modalShow}
        onHide={clearOfferForm}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Make Offer
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card ">
            <div className="card-body">
              <form
                className="custom-form"
                onSubmit={formik.handleSubmit}
                method="POST"
              >
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Advance Amount</label> */}
                    <input
                      min={0}
                      step={'.01'}
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Advance Amount"
                      aria-describedby=""
                      autoComplete="off"
                      name="advance_amount"
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() } 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      {...formik.getFieldProps("advance_amount")}
                      />
                  </div>
                  {renderErrorMessage("advance_amount")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Fixed Fee</label> */}
                    <input
                      min={0}
                      step={'.01'}
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Fixed Fee"
                      aria-describedby=""
                      autoComplete="off"
                      name="fixed_fee"
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() } 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      {...formik.getFieldProps("fixed_fee")}
                    />
                  </div>
                  {renderErrorMessage("fixed_fee")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Full Repayment</label> */}
                    <input
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Full Repayment"
                      aria-describedby=""
                      autoComplete="off"
                      readOnly={true}
                      name="full_repayment"
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() } 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      {...formik.getFieldProps("full_repayment")}
                    />
                  </div>
                  {renderErrorMessage("full_repayment")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Minimum Split</label> */}
                    <input
                      min={0}
                      max={100}
                      step={'.01'}
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Minimum Split (first 12 months) %"
                      aria-describedby=""
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() }
                      autoComplete="off"
                      name="minimum_split_first_12_months"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      {...formik.getFieldProps("minimum_split_first_12_months")}
                    />
                  </div>
                  {renderErrorMessage("minimum_split_first_12_months")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Minimum Split</label> */}
                    <input
                      min={0}
                      max={100}
                      step={'.01'}
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Minimum Split (from 12 months) %"
                      aria-describedby=""
                      autoComplete="off"
                      name="minimum_split_from_12_months"
                      onChange={formik.handleChange}
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() }
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      {...formik.getFieldProps("minimum_split_from_12_months")}
                    />
                  </div>
                  {renderErrorMessage("minimum_split_from_12_months")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Maximum Split</label> */}
                    <input
                      min={0}
                      max={100}
                      step={'.01'}
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Maximum Split %"
                      aria-describedby=""
                      name="maximum_split"
                      autoComplete="off"
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      {...formik.getFieldProps("maximum_split")}
                    />
                  </div>
                  {renderErrorMessage("maximum_split")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    {/* <label>Minimum Performance</label> */}
                    <input
                      min={0}
                      step={'.01'}
                      type="number"
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Minimum Performance per Month"
                      aria-describedby=""
                      name="minimum_performance"
                      autoComplete="off"
                      onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' ||  (evt.target.value.length===0 && evt.key==='.')) && evt.preventDefault() }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onWheel={(e) => e.target.blur()}
                      value={formik?.value?.minimum_performance}
                      {...formik.getFieldProps("minimum_performance")}
                    />
                  </div>
                  {renderErrorMessage("minimum_performance")}
                </div>
                <div className="mb-3">
                  <div className="ui-widget">
                    <label>Effective From Date:</label>
                    <input type="date" readOnly disabled
                      className="form-control company-name ui-autocomplete-input inputClass"
                      placeholder="Effective From Date" aria-describedby=""
                      name="effective_from" autoComplete="off" value={defaultDateValue} 
                      onChange={effectiveDateChange} onBlur={effectiveDateChange}
                      min={new Date().toLocaleDateString('en-ca')}
                    />
                  </div>
                  {renderErrorMessage("effective_from")}
                </div>
                <button disabled={loadingAcceptOff}
                  type="submit"
                  className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
                >
                  Create Offer
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* model for view codat records */}
      <Modal
        show={modalShowCodat}
        onHide={closeCodatModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton onHide={closeCodatModel }>
          <Modal.Title id="contained-modal-title-vcenter">
            Codat Account Listing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card ">
            <div className="card-body">
              {loadingCodat ? 
              <ClipLoader
              size={150}
              css={{
                display: "block",
                margin: "0 auto",
                borderColor: "green",
              }}
              color="green"
            /> : 
              <CodatRecords codatRecords={codatRecords} />
            }
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default ViewApplication;
