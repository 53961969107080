import React from "react";
const ViewOfferQuery = (props) => {

    return (
        <div className="modal fade" id="exampleModalQuery" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-header">
                            <div className="modal-title">
                                <h3 id="exampleModalLabel" className="m-0">View Query</h3>
                            </div>
                            <button
                                type="button"
                                className="btn-close ms-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={props.closeQueryModel}
                            ></button>
                        </div>
                        <div className="offer-container">
                            {props.offerQueryText &&
                                <div className="dierector-content mt-3" style={{ width: '100%' }}>
                                    <p className="table-font-color m-0">
                                        <b>Query : </b>{props.offerQueryText}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewOfferQuery;