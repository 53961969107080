import { Line, Doughnut } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement
} from 'chart.js';
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { MDBDataTableV5 } from "mdbreact";
import { HttpCall } from "../../services/UseHttps";
import { overviewSectionGraphAPI } from "../../config";
import { formatAmount } from "../../services/CommonFunction";
import { ErrorHandler } from "../../components/Auth/errorHandler";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement
);

const OverviewSummary = (props) => {
    const overviewTab = props.transactionTabs;
    const applicationId = useSelector(state => state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj && state.profileInfo.profileData.application_obj.application_id);;
    const [loading, setLoading] = useState(false);
    const [overviewData, setOverviewData] = useState({});
    const [mdbColHeaders, setMdbColHeaders] = useState([
        {
          label: "Month",
          field: "date",
          sort: "asc",
          width: 150,
        },
        {
          label: "Amount",
          field: "outstanding",
          sort: "asc",
          width: 150,
        }
      ]);
      const [overviewTableData, setOverviewTableData] = useState({columns: mdbColHeaders,rows: []});
    // const [overviewTableData, setOverviewTableData] = useState([]);
    const [optionsLine, setOptionsLine] = useState({
        responsive: true,
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false,
                position: "bottom"
            },
        }
    });
    const [dataLine, setDataLine] = useState({labels : [],datasets: []});

    // repayment Doughnut chart 
    const optionsDoughnut = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: { legend: { position: "bottom", align: "start"},
        // tooltip: {
        //     callbacks: {
        //       label: function(context){
        //         var data = context.dataset.data,
        //             label = context.label,
        //             currentValue = context.raw,
        //             total = 0;
        //         for( var i = 0; i < data.length; i++ ){
        //           total += data[i];
        //         }
        //         var percentage = parseFloat((currentValue/total*100).toFixed(1));
      
        //         return label + ": " +currentValue + ' (' + percentage + '%)';
        //       }
        //     }
        //   }
        },
    };
    // 'blue', 'red',  // '#2283FB', 'lightgrey',
    // 'rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)',  //  'rgba(255, 99, 132, 1)',  'rgba(54, 162, 235, 1)',
    const [dataDoughnut, setDataDoughnut] = useState({
        labels: ['Modulr', 'Non-Modulr'],
        datasets: [
          { label: 'Transaction Repayments', data: [],
            backgroundColor: ['rgb(91, 155, 213)','rgb(38, 68, 120)'], borderColor: ['#2283FB', 'lightgrey'],
            borderWidth: 1,
          },
        ],
    });

    useEffect(() => {
        if(overviewTab === 'Overview' && applicationId) {
            getGraphOverview();
        }
    }, [overviewTab, applicationId]);

    // get all overview graph details
    const getGraphOverview = () => {
        try {
            setLoading(true);
            HttpCall(`${overviewSectionGraphAPI+applicationId}`, "GET").then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
                    const record = res.data.data;
                    setOverviewData(record);
                    const modRepay = record.modulr_repaid_amount?record.modulr_repaid_amount:0;
                    const nonModRepay = record.non_modulr_repaid_amount?record.non_modulr_repaid_amount:0;
                    const total = modRepay + nonModRepay;
                    var percentageMod = total > 0 ? parseFloat((modRepay/total*100).toFixed(1)) : 0;
                    var percentageNonMod = total > 0 ? parseFloat((nonModRepay/total*100).toFixed(1)) : 0;
                    setDataDoughnut({ labels: [`Modulr (${percentageMod}%) `,`Non-Modulr (${percentageNonMod}%) `],
                        datasets: [ { label: 'Transaction Repayments', data: [modRepay, nonModRepay],
                        backgroundColor: [ 'rgb(91, 155, 213)','rgb(38, 68, 120)'], borderColor: [ '#2283FB', 'lightgrey'], borderWidth: 1}
                    ]});
                    let monthsLabels = Object.keys(record.month_wise_outstanding);
                    if(monthsLabels && monthsLabels.length > 0) {
                        let tableRecord = [];
                        let outstandAmt = [];
                        monthsLabels.forEach((itm, ind) => {
                            outstandAmt.push(record.month_wise_outstanding[itm]['outstanding']);
                            // tableRecord.push({date: itm, ...record.month_wise_outstanding[itm] });
                            if(ind !== 0) {
                                tableRecord.push({date: itm, outstanding: record.month_wise_outstanding[itm]['outstanding']?'£'+formatAmount(record.month_wise_outstanding[itm]['outstanding']):'' });
                            }
                        });
                        setDataLine({
                            labels : monthsLabels,
                            datasets: [
                                {
                                    label: 'Outstanding-',
                                    data: outstandAmt,
                                    fill: false,
                                    borderColor: "#2283FB",
                                }
                            ],
                        });

                        setOverviewTableData({ ...overviewTableData, rows: tableRecord?tableRecord:[] });
                    } else {
                        setOverviewTableData({ ...overviewTableData, rows:[]});
                    }
                } else {
                    if(res && res.data && res.data.message) {
                        Swal.fire({ title: "Error", text: res.data.message ? res.data.message : 'Graph fetch failed', icon: "error", button: false});
                    }
                    setOverviewData({});
                    setOverviewTableData({ ...overviewTableData, rows:[]});
                    setDataDoughnut({ labels: ['Modulr', 'Non-Modulr'],
                        datasets: [ { label: 'Transaction Repayments', data: [],
                        backgroundColor: [ 'rgb(91, 155, 213)','rgb(38, 68, 120)'], borderColor: [ '#2283FB', 'lightgrey'], borderWidth: 1}
                    ]});
                }
                setLoading(false);
            }).catch((error) => {
                setOverviewData({});
                setOverviewTableData({ ...overviewTableData, rows:[]});
                setLoading(false);
                setDataDoughnut({ labels: ['Modulr', 'Non-Modulr'],
                        datasets: [ { label: 'Transaction Repayments', data: [],
                        backgroundColor: [ 'rgb(91, 155, 213)','rgb(38, 68, 120)'], borderColor: [ '#2283FB', 'lightgrey'], borderWidth: 1}
                ]});
                ErrorHandler(error);
            });
        } catch (ex) {
            setOverviewData({});
            setOverviewTableData({ ...overviewTableData, rows:[]});
            setLoading(false);
        }
    }

    return (
        <>
            <div className="mb-4">
                {loading ?
                    <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                :
                    <>
                        <div className="card">
                            <div className="card-body">

                                <ul className="text-center d-flex list-unstyled align-items-end m-auto">
                                    <li className="border-line client-overview" role="presentation"
                                        style={{ width: "33.3%" }}>
                                        <h4 className="text-muted m-0">{overviewData && overviewData.current_date ? overviewData.current_date : '-'}</h4>
                                        <p className="p-0">Date</p>
                                    </li>
                                    <li className="border-line client-overview" role="presentation"
                                        style={{ width: "33.3%" }}>
                                        <h4 className="text-muted m-0">{overviewData && overviewData.total_advance_amount ? '£'+formatAmount(overviewData.total_advance_amount) : '0'}</h4>
                                        <p className="p-0">Total Advance Amount</p>
                                    </li>
                                    <li className="border-line client-overview" role="presentation"
                                        style={{ width: "33.3%" }}>
                                        <h4 className="text-muted m-0">{overviewData && overviewData.total_outstanding ? '£'+formatAmount(overviewData.total_outstanding) : '0'}</h4>
                                        <p className="p-0">Outstanding Balance</p>
                                    </li>
                                    <li className=" client-overview" role="presentation" style={{ width: "33.3%" }}>
                                        <h4 className="text-muted m-0">{overviewData && overviewData.total_repaid_amount ? '£'+formatAmount(overviewData.total_repaid_amount) : '0'}</h4>
                                        <p className="p-0">Paid Back</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="charts-title">Outstanding Balance</div>
                                        <Line data={dataLine} options={optionsLine} className='mt-5'
                                        height={110}  style={{ width: "100%", padding: "0px 25px" }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <div className="table-body">
                                            <div className="charts-title" style={{paddingBottom:30}}>Modulr & Non-Modulr Repayments</div>
                                            <Doughnut data={dataDoughnut} options={optionsDoughnut}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <h5 className=""><b>Recent Transactions (Outstanding)</b></h5>
                                { overviewTableData&&overviewTableData.rows&&overviewTableData.rows.length > 0 ? 
                                    <MDBDataTableV5
                                        striped bordered hover
                                        data={overviewTableData}  searchBottom={false} //searchTop
                                        fullPagination entriesOptions={[5, 10, 20, 50, 100]}
                                        paging={!(overviewTableData&&overviewTableData.rows&&overviewTableData.rows.length <= "4")}
                                    />
                                :
                                    <div className="dierector-content mt-3 "
                                        style={{
                                            borderRight: "15px solid #003056",
                                            borderLeft: "15px solid #003056",
                                        }} >
                                        <p className="table-font-color m-0"> <b>Recent Transactions not found.</b> </p>
                                    </div>
                                }
                                {/* <table id="tableData" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    {overviewTableData && overviewTableData.length > 0 ?
                                        <tbody>
                                            {overviewTableData.map((rec,ind) => 
                                                <tr key={ind}>
                                                    <td>{rec.date?rec.date:''}</td>
                                                    <td>{rec.outstanding?'£'+formatAmount(rec.outstanding):''}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    : 
                                        <tfoot>
                                            <tr>
                                                <td colSpan={2}>
                                                <div className="dierector-content mt-3 "
                                                style={{
                                                    borderRight: "15px solid #003056",
                                                    borderLeft: "15px solid #003056",
                                                }} >
                                                <p className="table-font-color m-0"> <b>Recent Transactions not found.</b> </p>
                                            </div></td>
                                            </tr>
                                        </tfoot>
                                    }
                                </table> */}
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
};

export default OverviewSummary;