import React, { useEffect, useState } from 'react';
import YapilyDisclaimer from './YapilyDisclaimer';
import YapilyFooter from './YapilyFooter';

const YapilyAuth = ({currState, setCurrState, setLoading}) => {
  const [consentData, setConsentData] = useState(currState?.consentData?currState?.consentData:{});

    useEffect(() => {
      const consentRawInfo = localStorage.getItem('tokenData');
      const consentInfo = consentRawInfo ? JSON.parse(consentRawInfo):{};
      if(consentInfo&&consentInfo?.consent_id){
        setConsentData(consentInfo);
      }
      // un-mount component
      return () => { localStorage.removeItem('bank'); }
    }, []);
    const onClickBack = () => {
      localStorage.removeItem('intId');
      localStorage.removeItem('tokenData');
      localStorage.removeItem('fromYDS');
      setCurrState({yapilyBtn: false, institution:true, auth:false, consentData:{}, accounts: false, dashboard: false});
    }

    const onClickAuthButton = () => {
      const institutionId = localStorage.getItem('intId');
      const tokenData = {
        consent_created_at:consentData?.consent_created_at,
        consent_expires_at:consentData?.consent_expires_at,
        consent_id:consentData?.consent_id,
        institution_id:institutionId
      };
      localStorage.setItem('tokenData', JSON.stringify(tokenData));
      setLoading(true);
      window.location.href = consentData?.authorisation_url;
    }

    return (
      <>
      <div className="mb-4">
        <div className="card">
          <div className="card-body">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div className="">
                  <div className="">
                    <div className="">
                      <h4>Permission</h4>
                      {/* <div><button className="btn btn-outline-primary" onClick={onClickBack} style={{position:'absolute',float: 'right',right: 50,top: 25}}>Back</button></div> */}
                      <div className="">
                        <YapilyDisclaimer isAuthScreen={true} onClickBack={onClickBack} onClickAuthButton={onClickAuthButton} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <YapilyFooter />
      </>
    )
}

export default YapilyAuth;
