import * as typeS from "../service/types";
import Swal from 'sweetalert2'
import { GetFundedApi, GetFundedDetailsApi, GetTokenApi } from "../service/ApiConfig";
import { ErrorHandler } from "../../components/Auth/errorHandler";


export const getfundedRequest = (getfunded) => {
  return {
    type: typeS.GET_FUNDED_REQUEST,
    payload: getfunded,
  };
};

export const getfundedSuccess = (getfunded) => {
  return {
    type: typeS.GET_FUNDED_SUCCESS,
    payload: getfunded,
  };
};

export const getfundedError = (error) => {
  return {
    type: typeS.GET_FUNDED_ERROR,
    payload: error,
  };
};

export const getFundedData = (data) => {
  return (dispatch) => {
    try {
      GetFundedApi(data).then((res) => {
        if (res && res.status === 200) {
          const getFunded = res;
          dispatch(getfundedSuccess(getFunded));
        } else {
          dispatch(getfundedError());
        }
      }).catch((err) => {
        dispatch(getfundedError(err.message));
        ErrorHandler(err);
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Oops...',
        //   text: err,
        // })
      });
    } catch (err) {
      // console.log(err);
    }
  };
}


export const getToken = (gettokens) => {
  return {
    type: typeS.GET_TOKEN,
    payload: gettokens,
  };
};

export const getTokenSuccess = (gettokens) => {
  return {
    type: typeS.GET_TOKEN_SUCCESS,
    payload: gettokens,
  };
};

export const getTokenError = (error) => {
  return {
    type: typeS.GET_TOKEN_ERROR,
    payload: error,
  };
};

export const getTokenData = (data, setLoading) => {
  return (dispatch) => {
    try {
      GetTokenApi(data).then((res) => {
        if (res && res.status === 200) {
          const getToken = res;
          dispatch(getTokenSuccess(getToken));
        } else {
          dispatch(getTokenError());
          setLoading(false);
          if(res.response && res.response && res.response.data && (res.response.status === 401 || res.response.status === 404)) {
            Swal.fire({
              icon: 'error',
              title: 'SignUp Error',
              text: res.response.data.message ? res.response.data.message : "Invalid Username Or Password",
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'SignUp Error',
              text: "Invalid Username Or Password",
            })
          }
        }
      }).catch((err) => {
        dispatch(getTokenError(err.message));
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: err,
        })
      });
    } catch (err) {
      // console.log(err);
    }
  };
}


export const getFundedDetails = (getfundeddetails) => {
  return {
    type: typeS.GET_FUNDED_DETAILS,
    payload: getfundeddetails,
  };
};

export const getFundedDetailsSuccess = (getfundeddetails, status) => {
  return {
    type: typeS.GET_FUNDED_DETAILS_SUCCESS,
    payload: {getfundeddetails, status},
  };
};

export const getFundedDetailsError = (error) => {
  return {
    type: typeS.GET_FUNDED_DETAILS_ERROR,
    payload: error,
  };
};

export const getFundedDetailsData = (data, status) => {
  return (dispatch) => {
    try {
      GetFundedDetailsApi(data).then((res) => {
        if (res && res.status === 200) {
          const getdetails = res;
          dispatch(getFundedDetailsSuccess(getdetails, status));
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Your work has been saved',
        //     showConfirmButton: true,
        //     text: 'Thank you for submitting your application. A member of the team will be in touch shortly. Should you wish to talk to someone before then, please speak to a member of the team',
        //     footer: '<a href="#" className="text-decoration-underline">here</a>'
        // })
        // window.location.reload()
      } else {
        dispatch(getFundedDetailsError());
        Swal.fire({ icon: 'error', title: 'Error', 
          text: res && res.data && res.data.message ? res.data.message :'Application submittion is failed'
        });
      }
      }).catch((err) => {
        dispatch(getFundedDetailsError(err.message));
        ErrorHandler(err);
      });
    } catch (err) {
      // console.log(err);
    }
  };
}