import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom'
import { navLinks } from './NavLinks';
// import { useHistory } from "react-router";


const MainHeader = (props) => {

  const boxShadow = props.boxShadow;
  // const history = useHistory();
  // const navigate = useNavigate();

  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#fff") : setnavColor("#fff");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  // const signIn = () => {
  //   navigate("/")
  // }


  return (
    <div>
      <header
        style={{
          backgroundColor: navColor,
          transition: "all 1s",
          boxShadow: boxShadow ? "6px 6px 15px 0 rgb(207 211 231 / 60%)": "none"
        }}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">

            <Link to="/" className="header-logo d-inline-block">
              <img src='/img/Barlow.jpg' alt="Barlow Marshall" />
            </Link>

            <nav className="header-nav d-flex align-items-center"
            >
              {navLinks.map((link, index) => {
                return (
                  <ul key={"indexul" + index} className="header-menu m-0 p-0 d-flex align-items-center list-unstyled">
                    <li key={"indexli" + index}>

                      <Link to="/" key={index}>{link.name}</Link>

                    </li>
                  </ul>
                );
              })}
              <ul className="m-0 p-0 d-flex align-items-center list-unstyled button-links">
                <li>
                  <Link to="/get-funded" className="btn btn-custom btn-custom-2">Get Funded</Link>
                </li>
                <li>
                  <Link to='/login' className="btn btn-custom">Sign In</Link>
                  {/* <button onClick={signIn()} className="btn btn-custom">Sign In</button> */}

                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </div>
  )
};

export default MainHeader;

//  const NavItem = ({ item }) => {
//   const router = useRouter();
//   return <>{router.pathname === "/" ? item : ""}</>;
// };

// NavItem.propTypes = {
//   item: propTypes.string,
// };

// export default NavItem;