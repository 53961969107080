import { combineReducers } from 'redux';
import Codat from './codat';
import GetFunded from './getfunded';
import LogIn from './login';
import Truelayer from './truelayer';
import Loader from './loader';
import ListApplicationReducer from './listApplication';
import ProfileInfoReducer from './profileInfo';
import PhoneNumber from './phonecode';
import OfferList from './offerList';
import ListClientReducer from './listClient';
import ListTransactionReducer from './listTransactions';
import LedgerTransaction from './ledgerTransaction';
import ListChallengedTransactionReducer from './listChallengedTransactions';

const rootReducer = combineReducers({
  Codat: Codat,
  Truelayer: Truelayer,
  GetFunded: GetFunded,
  LogIn: LogIn,
  Loader: Loader,
  ListApplication:ListApplicationReducer,
  profileInfo:ProfileInfoReducer,
  PhoneNumber: PhoneNumber,
  OfferList,
  listClient:ListClientReducer,
  listTransaction:ListTransactionReducer,
  ledgerTransaction: LedgerTransaction,
  challengedTransaction:ListChallengedTransactionReducer,
});

export default rootReducer;