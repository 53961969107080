import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { handleError, HttpCall } from "../../services/UseHttps";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { listAdminOfferData } from "../../redux/action/offerList";
import { offerStatusAPI } from "../../config";

const AmendOffer = (props) => {
  const [initialValues, setInitialValues] = useState({
    minimum_split_first_12_months: "",
    minimum_split_from_12_months: "",
    maximum_split: "",
  });
  const numDecimalPatt = /^(\d*\.{0,1}\d{0,2}$)/;
  const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues({
      minimum_split_first_12_months:
        (props &&
          props.offerData &&
          props.offerData.minimum_split_first_12_months) ||
        "",
      minimum_split_from_12_months:
        (props &&
          props.offerData &&
          props.offerData.minimum_split_from_12_months) ||
        "",
      maximum_split:
        (props && props.offerData && props.offerData.maximum_split) || "",
    });
  }, [props, props.offerData]);

  const validate = (values) => {
    let errors = {};
    if (!values.minimum_split_first_12_months) {
      errors.minimum_split_first_12_months = "Minimum split (first 12 months) % is required";
    } else if(!numDecimalPatt.test(String(values.minimum_split_first_12_months))) {
      errors.minimum_split_first_12_months = "Minimum split (first 12 months) should have only two decimal points";
    } else if (0 > values.minimum_split_first_12_months || values.minimum_split_first_12_months > 100) {
      errors.minimum_split_first_12_months = "Minimum split (first 12 months) % is invalid percentage";
    }
    if (!values.minimum_split_from_12_months) {
      errors.minimum_split_from_12_months = "Minimum split (from 12 months) % is required";
    } else if(!numDecimalPatt.test(String(values.minimum_split_from_12_months))) {
      errors.minimum_split_from_12_months = "Minimum split (from 12 months) should have only two decimal points";
    } else if (0 > values.minimum_split_from_12_months || values.minimum_split_from_12_months > 100) {
      errors.minimum_split_from_12_months = "Minimum split (from 12 months) % is invalid percentage";
    }
    if (!values.maximum_split || values.maximum_split === 0) {
      errors.maximum_split = "Maximum split % is required";
    } else if(!numDecimalPatt.test(String(values.maximum_split))) {
      errors.maximum_split = "Minimum split % should have only two decimal points";
    } else if (0 > values.maximum_split || values.maximum_split > 100) {
      errors.maximum_split = "Maximum split % is invalid percentage";
    }

    return errors;
  };
  const amendOffer = (value) => {
    Swal.fire({
      title: "Are you sure you want to save the changes?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = new FormData();
        data.append("status", "amend_offer");
        data.append("minimum_split_first_12_months", +Number(value.minimum_split_first_12_months).toFixed(2));
        data.append("minimum_split_from_12_months", +Number(value.minimum_split_from_12_months).toFixed(2));
        data.append("maximum_split", +Number(value.maximum_split).toFixed(2));
        HttpCall(`${offerStatusAPI}/${props.offerData.id}`, "patch", data)
          .then((res) => {
            formik.resetForm();
            Swal.fire({
              title: "Done!",
              text: "Offer Updated !!",
              icon: "success",
              // timer: 2000,
              // button: false,
            });
            dispatch(listAdminOfferData());
            props.setModalShow(false);
          })
          .catch((error) => {
            formik.resetForm();
            handleError(error);
          });
      }
    });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: amendOffer,
    validate,
  });
  const renderErrorMessage = (field) => {
    return (
      formik.touched[field] && (
        <div className="text-danger">{formik.errors[field]}</div>
      )
    );
  };
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onHide={() => formik.resetForm()}>
        <Modal.Title id="contained-modal-title-vcenter">
          Amend Offer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card ">
          <div className="card-body">
            <form
              className="custom-form"
              onSubmit={formik.handleSubmit}
              method="POST"
            >
              <div className="mb-3">
                <div className="ui-widget">
                  <label>Minimum Split (first 12 months) %</label>
                  <input
                    min={0}
                    max={100}
                    step={".01"}
                    type="number"
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        (evt.target.value.length === 0 && evt.key === ".")) &&
                      evt.preventDefault()
                    }
                    className="form-control company-name ui-autocomplete-input inputClass"
                    placeholder="Minimum Split (first 12 months)"
                    aria-describedby=""
                    autoComplete="off"
                    name="minimum_split_first_12_months"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    {...formik.getFieldProps("minimum_split_first_12_months")}
                  />
                </div>
                {renderErrorMessage("minimum_split_first_12_months")}
              </div>
              <div className="mb-3">
                <div className="ui-widget">
                  <label>Minimum Split (from 12 months) %</label>
                  <input
                    min={0}
                    max={100}
                    step={".01"}
                    type="number"
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        (evt.target.value.length === 0 && evt.key === ".")) &&
                      evt.preventDefault()
                    }
                    className="form-control company-name ui-autocomplete-input inputClass"
                    placeholder="Minimum Split (from 12 months)"
                    aria-describedby=""
                    autoComplete="off"
                    name="minimum_split_from_12_months"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    {...formik.getFieldProps("minimum_split_from_12_months")}
                  />
                </div>
                {renderErrorMessage("minimum_split_from_12_months")}
              </div>
              <div className="mb-3">
                <div className="ui-widget">
                  <label>Maximum Split %</label>
                  <input
                    min={0}
                    max={100}
                    step={".01"}
                    type="number"
                    onKeyDown={(evt) =>
                      (evt.key === "e" ||
                        (evt.target.value.length === 0 && evt.key === ".")) &&
                      evt.preventDefault()
                    }
                    className="form-control company-name ui-autocomplete-input inputClass"
                    placeholder="Maximum Split"
                    aria-describedby=""
                    name="maximum_split"
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e) => e.target.blur()}
                    value={formik?.value?.maximum_split}
                    {...formik.getFieldProps("maximum_split")}
                  />
                </div>
                {renderErrorMessage("maximum_split")}
              </div>

              <button
                type="submit"
                className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow"
              >
                Update Offer
              </button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AmendOffer;
