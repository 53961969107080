import Swal from "sweetalert2";
import { ListAdminOffer } from "../service/ApiConfig";
import {
    ADMIN_OFFER_LIST_INITIAL,
    ADMIN_OFFER_LIST_ERROR,
    ADMIN_OFFER_LIST_SUCCESS,
} from "../service/types";

export const listAdminOfferSuccess = (list) => {
    return {
        type: ADMIN_OFFER_LIST_SUCCESS,
        payload: list,
    };
};

export const listAdminOfferError = (error) => {
    return {
        type: ADMIN_OFFER_LIST_ERROR,
        payload: error,
    };
};

export const listAdminOfferData = () => {
    return (dispatch) => {
        dispatch({type: ADMIN_OFFER_LIST_INITIAL})
        try {
           ListAdminOffer().then((res) => {
                if (res&&res.status === 200) {
                    dispatch(listAdminOfferSuccess(res?.data?.data));
                } else {
                    dispatch(listAdminOfferError("err.message"));
                }
            }).catch((err) => {
                dispatch(listAdminOfferError(err.message));
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err,
                })
            });
        } catch (err) {
            // console.log(err);
        }
    };
}