import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
    Title,
} from 'chart.js';
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import { MDBDataTableV5 } from "mdbreact";
import { salseRepaymentGraphAPI } from "../../config";
import { handleError, HttpCall } from "../../services/UseHttps";
import { formatAmount } from "../../services/CommonFunction";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

const Sales = ({ applicationId }) => {
    const [loading, setLoading] = useState(false);
    const [graphData, setGraphData] = useState({});
    const [mdbColHeaders, setMdbColHeaders] = useState([
        {
          label: "Month",
          field: "date",
          sort: "asc",
          width: 150,
        },
        {
          label: "Outstanding Balance",
          field: "outstanding_balance",
          sort: "asc",
          width: 150,
        },
        {
          label: "Principle Outstanding",
          field: "principal_outstanding",
          sort: "asc",
          width: 150,
        },
        {
          label: "Fixed Fee Outstanding",
          field: "fixed_fee_outstanding",
          sort: "asc",
          width: 150,
        },
        {
          label: "Trading Receipts",
          field: "total_receipts_inc_split",
          sort: "asc",
          width: 150,
        },
        {
          label: "BM Split",
          field: "bm_split",
          sort: "asc",
          width: 150,
        },
        {
          label: "Client Receipt",
          field: "receipt_after_split",
          sort: "asc",
          width: 150,
        },
        {
          label: "Split Towards Principal Repayment",
          field: "split_towards_principal_repayment",
          sort: "asc",
          width: 150,
        },
        {
          label: "Split Towards Fixed Fee Repayment",
          field: "split_towards_fixed_fee_repayment",
          sort: "asc",
          width: 150,
        }
      ]);
    const [graphTableData, setGraphTableData] = useState({columns: mdbColHeaders,rows: []});
    const [optionsBar, setOptionsBar] = useState({
        plugins: {
          title: {display: true, text: 'Total Receipt Inc. Split'},
          legend: { display: true, position: "bottom"}
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        }
    });
    const [dataBar, setDataBar] = useState({labels : [],datasets: []});
    const chartRef = useRef();
    const [isGraphMonthly, setIsGraphMonthly] = useState(true); // show graph monthly or daily wise.
    const [yearMonth, setYearMonth] = useState('');
    // modulr Chart
    const [graphDataModulr, setGraphDataModulr] = useState({});
    const [optionsBarModulr, setOptionsBarModulr] = useState({
        plugins: {
          title: {display: true, text: 'Total Receipt Inc. Split'},
          legend: { display: true, position: "bottom"}
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        }
    });
    const [dataBarModulr, setDataBarModulr] = useState({labels : [],datasets: []});
    // non-modulr Chart
    const [graphDataNonModulr, setGraphDataNonModulr] = useState({});
    const [optionsBarNonModulr, setOptionsBarNonModulr] = useState({
        plugins: {
          title: {display: true, text: 'Total Receipt Inc. Split'},
          legend: { display: true, position: "bottom"}
        },
        responsive: true,
        scales: {
          x: { stacked: true },
          y: { stacked: true },
        }
    });
    const [dataBarNonModulr, setDataBarNonModulr] = useState({labels : [],datasets: []});

    useEffect(() => {
        if(applicationId) {
            getAllGraphDetails();
            getModulrGraphDetails();
            getNonModulrGraphDetails();
        }
    }, [applicationId, isGraphMonthly]);

    // get all transactions (modulr & non_modulr)
    const getAllGraphDetails = () => {
        try {
            setLoading(true);
            const fetchApiUrl = isGraphMonthly ? `${salseRepaymentGraphAPI+applicationId}/all` : `${salseRepaymentGraphAPI+applicationId}/all/${yearMonth}`;
            HttpCall(fetchApiUrl, "GET").then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
                    const record = res.data.data;
                    if(isGraphMonthly) { setGraphData(record); } // update main data if monthly view
                    let monthsLabels = Object.keys(record.receipts);
                    if(monthsLabels && monthsLabels.length > 0) {
                        let tableRecord = [];
                        let bmSplit = [];
                        let clientAmt = [];
                        monthsLabels.forEach((itm) => {
                            bmSplit.push(record.receipts[itm]['bm_split']); // total_receipts_inc_split
                            clientAmt.push(record.receipts[itm]['receipt_after_split']);
                            tableRecord.push({date: itm, //...record.receipts[itm]
                                bm_split: record.receipts[itm]['bm_split'] ? '£'+formatAmount(record.receipts[itm]['bm_split']) :'0',
                                receipt_after_split: record.receipts[itm]['receipt_after_split'] ? '£'+formatAmount(record.receipts[itm]['receipt_after_split']) :'0',
                                total_receipts_inc_split: record.receipts[itm]['total_receipts_inc_split'] ? '£'+formatAmount(record.receipts[itm]['total_receipts_inc_split']) :'0',
                                outstanding_balance: record.receipts[itm]['outstanding_balance'] ? '£'+formatAmount(record.receipts[itm]['outstanding_balance']) :'0',
                                principal_outstanding: record.receipts[itm]['principal_outstanding'] ? '£'+formatAmount(record.receipts[itm]['principal_outstanding']) :'0',
                                fixed_fee_outstanding: record.receipts[itm]['fixed_fee_outstanding'] ? '£'+formatAmount(record.receipts[itm]['fixed_fee_outstanding']) :'0',
                                split_towards_principal_repayment: record.receipts[itm]['split_towards_principal_repayment'] ? '£'+formatAmount(record.receipts[itm]['split_towards_principal_repayment']) :'0',
                                split_towards_fixed_fee_repayment: record.receipts[itm]['split_towards_fixed_fee_repayment'] ? '£'+formatAmount(record.receipts[itm]['split_towards_fixed_fee_repayment']) :'0'
                            });
                        });
                        setDataBar({
                            labels : monthsLabels,
                            datasets: [
                                {
                                    label: '*Client Receipts After Split ',
                                    data: clientAmt,
                                    backgroundColor: 'rgb(91, 155, 213)',
                                    barThickness: 16,
                                },
                                {
                                    label: '*BM Split',
                                    data: bmSplit,
                                    backgroundColor: 'rgb(38, 68, 120)',
                                    barThickness: 16
                                },
                            ],
                        });
                        if(isGraphMonthly) { setGraphTableData({...graphTableData, rows: tableRecord?tableRecord:[] }); }
                    } else {
                        if(isGraphMonthly) { 
                            setGraphData({});
                            setGraphTableData({...graphTableData, rows:[]});
                        }
                    }
                } else {
                    if(res && res.data && res.data.message) {
                        Swal.fire({ title: "Error", text: res.data.message ? res.data.message : 'Graph fetch failed', icon: "error", button: false});
                    }
                    setGraphData({});
                    setGraphTableData({...graphTableData, rows:[]});
                }
                setLoading(false);
            }).catch((error) => {
                setGraphData({});
                setGraphTableData({...graphTableData, rows:[]});
                setLoading(false);
                handleError(error);
            });
        } catch (ex) {
            setGraphData({});
            setGraphTableData({...graphTableData, rows:[]});
            setLoading(false);
        }
    }

    // get Modulr transactions graph data
    const getModulrGraphDetails = () => {
        try {
            const fetchApiUrl = isGraphMonthly ? `${salseRepaymentGraphAPI+applicationId}/modulr` : `${salseRepaymentGraphAPI+applicationId}/modulr/${yearMonth}`;
            HttpCall(fetchApiUrl, "GET").then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
                    const record = res.data.data;
                    setGraphDataModulr(record);
                    let monthsLabels = Object.keys(record.receipts);
                    if(monthsLabels && monthsLabels.length > 0) {
                        let tableRecord = [];
                        let bmSplit = [];
                        let clientAmt = [];
                        monthsLabels.forEach((itm) => {
                            bmSplit.push(record.receipts[itm]['bm_split']); // total_receipts_inc_split
                            clientAmt.push(record.receipts[itm]['receipt_after_split']);
                            tableRecord.push({date: itm, ...record.receipts[itm] });
                        });
                        setDataBarModulr({ labels : monthsLabels,
                            datasets: [
                                { label: 'Client Receipts After Split ', data: clientAmt, backgroundColor: 'rgb(91, 155, 213)', barThickness: 16 },
                                { label: 'BM Split', data: bmSplit, backgroundColor: 'rgb(38, 68, 120)', barThickness: 16 }
                            ],
                        });
                    } else { setGraphDataModulr({}); setDataBarModulr({ labels:[],datasets: [] }); }
                } else { setGraphDataModulr({}); setDataBarModulr({ labels:[],datasets: [] }); }
            }).catch((error) => { setGraphDataModulr({}); setDataBarModulr({ labels:[],datasets: [] }); handleError(error); });
        } catch (ex) { }
    }
    // get Non-modulr transactions graph data
    const getNonModulrGraphDetails = () => {
        try {
            const fetchApiUrl = isGraphMonthly ? `${salseRepaymentGraphAPI+applicationId}/non_modulr` : `${salseRepaymentGraphAPI+applicationId}/non_modulr/${yearMonth}`;
            HttpCall(fetchApiUrl, "GET").then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
                    const record = res.data.data;
                    setGraphDataNonModulr(record);
                    let monthsLabels = Object.keys(record.receipts);
                    if(monthsLabels && monthsLabels.length > 0) {
                        let tableRecord = [];
                        let bmSplit = [];
                        let clientAmt = [];
                        monthsLabels.forEach((itm) => {
                            bmSplit.push(record.receipts[itm]['bm_split']); // total_receipts_inc_split
                            clientAmt.push(record.receipts[itm]['receipt_after_split']);
                            tableRecord.push({date: itm, ...record.receipts[itm] });
                        });
                        setDataBarNonModulr({ labels : monthsLabels,
                            datasets: [
                                { label: 'Client Receipts After Split ', data: clientAmt, backgroundColor: 'rgb(91, 155, 213)', barThickness: 16 },
                                { label: 'BM Split', data: bmSplit, backgroundColor: 'rgb(38, 68, 120)', barThickness: 16 }
                            ],
                        });
                    } else { setGraphDataNonModulr({}); setDataBarNonModulr({ labels:[],datasets: [] }); }
                } else { setGraphDataNonModulr({}); setDataBarNonModulr({ labels:[],datasets: [] }); }
            }).catch((error) => { setGraphDataNonModulr({}); setDataBarNonModulr({ labels:[],datasets: [] }); handleError(error); });
        } catch (ex) { }
    }


    // bar click
    const onBarAllTransactionClick = (event) => {
        const eleClicked = getElementAtEvent(chartRef.current, event);
        if(eleClicked && eleClicked.length > 0) {
            const index = eleClicked[0]['index'];
            if(graphTableData.rows && graphTableData.rows[index] && graphTableData.rows[index]['date']) {
                setYearMonth(graphTableData.rows[index]['date']);
                setIsGraphMonthly(false);
            }
        }
    }

    const backToMonthly = () => { setIsGraphMonthly(true); setYearMonth(''); }

    return (
        <>
            <div className="mb-4">
            {
                loading ?
                    <><ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" /></>
                    :
                <>
                    <div className="card">
                        <div className="card-body">
                            <ul className="text-center d-flex list-unstyled align-items-end m-auto">
                                <li className="border-line client-overview" role="presentation"
                                    style={{ width: "33.3%" }}>
                                    <h4 className="text-muted m-0">{graphData && graphData.current_date ? graphData.current_date : '-'}</h4>
                                    <p className="p-0">Date</p>
                                </li>
                                <li className="border-line client-overview" role="presentation"
                                    style={{ width: "33.3%" }}>
                                    <h4 className="text-muted m-0">{graphData && graphData.avg_receipts ? '£'+formatAmount(graphData.avg_receipts) : '0'}</h4>
                                    <p className="p-0">Average Monthly Receipts</p>
                                </li>
                                <li className="border-line client-overview" role="presentation"
                                    style={{ width: "33.3%" }}>
                                    <h4 className="text-muted m-0">{graphData && graphData.receipts_this_month ? '£'+formatAmount(graphData.receipts_this_month) : '0'}</h4>
                                    <p className="p-0">Receipts This Month</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="charts-title mb-2">Total Cash Receipts Since Start of Advance
                                        {isGraphMonthly ? <div><small className="text-primary" style={{fontSize:10}}>
                                            <i className="fa fa-info-circle" aria-hidden="true"></i> To view the daily records click on the respective month bar.</small>
                                        </div>
                                        : <button type="button" className="btn btn-primary" onClick={backToMonthly} style={{float:'right',backgroundColor:'#0d6efd'}} title="Back to monthly wise records"><i className="fa fa-arrow-left" aria-hidden="true"></i> Monthly Records</button>
                                        }
                                    </div>
                                    <Bar options={optionsBar} data={dataBar} ref={chartRef} onClick={onBarAllTransactionClick}
                                        height={110} style={{ width: "100%", padding: "0px 25px" }} />
                                    {/* height: "300px" */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="charts-title">Modulr Account Transactions</div>
                                    <Bar options={optionsBarModulr} data={dataBarModulr}
                                        className='mt-5'
                                        style={{ width: "100%", height: "400px", padding: "0px 25px" }}
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="charts-title">Non Modulr Transactions</div>
                                    <Bar options={optionsBarNonModulr} data={dataBarNonModulr}
                                        className='mt-5'
                                        style={{ width: "100%", height: "400px", padding: "0px 25px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-body">
                        <h5 className=""><b>Total Cash Receipts</b></h5>
                        {graphTableData&&graphTableData.rows&&graphTableData.rows.length > 0 ?
                            <MDBDataTableV5
                                    striped bordered hover
                                    data={graphTableData}  searchBottom={false} //searchTop
                                    fullPagination entriesOptions={[5, 10, 20, 50, 100]}
                                    paging={!(graphTableData&&graphTableData.rows&&graphTableData.rows.length <= "4")}
                                />
                        :
                            <div className="dierector-content mt-3 "
                                style={{
                                    borderRight: "15px solid #003056",
                                    borderLeft: "15px solid #003056",
                                }} >
                                <p className="table-font-color m-0"> <b>Cash receipt records not found.</b> </p>
                            </div>
                        }
                        {/* <table id="tableData" className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Trading Receipts</th>
                                    <th>BM Split</th>
                                    <th>Client Receipt</th>
                                </tr>
                            </thead>
                            {graphTableData && graphTableData.length > 0 ? 
                                <tbody>
                                        {graphTableData.map((item, ind) => 
                                            <tr key={ind}>
                                                <td>{item.date ? item.date : ''}</td>
                                                <td>{item.total_receipts_inc_split ? '£'+formatAmount(item.total_receipts_inc_split) : ''}</td>
                                                <td>{item.bm_split ? '£'+formatAmount(item.bm_split) : ''}</td>
                                                <td>{item.receipt_after_split ? '£'+formatAmount(item.receipt_after_split) : ''}</td>
                                            </tr>
                                        )}
                                </tbody>
                                :
                                <tfoot>
                                    <tr><td colSpan={4}>
                                    <div className="dierector-content mt-3 "
                                        style={{
                                            borderRight: "15px solid #003056",
                                            borderLeft: "15px solid #003056",
                                        }} >
                                        <p className="table-font-color m-0"> <b>Cash receipt records not found.</b> </p>
                                    </div>
                                    </td></tr>
                                </tfoot>
                            }
                        </table> */}
                    </div>
                </>
            }
            </div>
        </>
    )
};

export default Sales;