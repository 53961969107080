export const navLinks = [
  {
    name: "About",
    path: "/"
  },
  {
    name: "Blog",
    path: "/",
  },
  {
    name: "FAQs",
    path: "/",
  },
  {
    name: "Contact",
    path: "/",
  },
  //   {
  //     name: <Footer item="Contact Us" />,
  //     path: "#contact",
  //   },
];