import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { YAPILY_TERM_CON_LINK } from '../../config';

const YapilyDisclaimer = ({ isAuthScreen, onClickBack, onClickAuthButton}) => {
  const [bankName, setBankName] = useState('');
  const [disclaimer, setDisclaimer] = useState({ dataSharing: false, secureConn: false, fcaAuth: false, access: false });

  useEffect(() => {
    setBankName(localStorage.getItem('bank'));
  }, []);

  return (
    <>
      <Container className='' style={{ backgroundColor: '#FAFBFF', padding: '15px 30px' }}>
        {/* <Row style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              <Card style={{ width: '10rem', padding:10, margin:10 }}>
                <Card.Img variant="top" src={qr_code_url} width={250}/>
              </Card>
            </Row>
            <Row>
              <h4 className="text-muted m-0" style={{color:'#0d6efd !important', display: 'flex',alignItems: 'center',justifyContent: 'center'}}><a>Need Help?</a></h4>
            </Row>
            <Row style={{display: 'flex',alignItems: 'center',justifyContent: 'center', margin:10, padding:'15px 55px'}}>OR</Row> 
        */}
        <Row>
          <div style={{ margin: 10 }}>
            <img src="/img/yapily_connect.png" width="260" alt='yapily connect' style={{ width: '350px !important' }} />
          </div>
          <div>
            <div className='discHeader' onClick={() => setDisclaimer({ ...disclaimer, dataSharing: !disclaimer.dataSharing })}>
              <p>Data Sharing</p>
              <i className={disclaimer?.dataSharing ? "fa fa-minus-circle" : "fa fa-info-circle"} style={{ fontSize: 25 }} aria-hidden="true"></i>
            </div>
            {disclaimer?.dataSharing ?
              <div className='disclaimerYapily'>
                <p>Yapily Connect will retrieve your bank data based on your request and provide this information to <strong>Barlow Marshall</strong>.</p>
              </div> : ''}
          </div>
          <div>
            <div className='discHeader' onClick={() => setDisclaimer({ ...disclaimer, secureConn: !disclaimer.secureConn })}>
              <p>Secure Connection</p>
              <i className={disclaimer?.secureConn ? "fa fa-minus-circle" : "fa fa-info-circle"} style={{ fontSize: 25 }} aria-hidden="true"></i>
            </div>
            {disclaimer?.secureConn ?
              <div className='disclaimerYapily'>
                <p>Data is securely retrieved in read-only format and only for the duration agreed with you. You have the right to withdraw your consent at any time.</p>
              </div> : ''}
          </div>
          <div>
            <div className='discHeader' onClick={() => setDisclaimer({ ...disclaimer, fcaAuth: !disclaimer.fcaAuth })}>
              <p>FCA Authorisation</p>
              <i className={disclaimer?.fcaAuth ? "fa fa-minus-circle" : "fa fa-info-circle"} style={{ fontSize: 25 }} aria-hidden="true"></i>
            </div>
            {disclaimer?.fcaAuth ?
              <div className='disclaimerYapily'>
                <p><strong>Barlow Marshall</strong> is an agent of Yapily Connect Ltd.
                Yapily Connect Ltd is authorised and regulated by the Financial Conduct Authority under the Payment Service
                Regulations 2017 [827001] for the provision of Account Information and Payment Initiation services.</p>
              </div> : ''}
              <div>
                <p style={{ margin: 10 }}>In order to share your <strong>{bankName ? bankName : 'Bank'}</strong> data with <strong>Barlow Marshall</strong>,
                  you will now be securely redirected to your bank to confirm your consent for
                  Yapily Connect to read the following information:</p>
                <div className='disclaimerYapily'>
                  <ul>
                    <li className='mb-3'>Identification details</li>
                    <li className='mb-3'>Account(s) details</li>
                    <li className='mb-3'>Balances</li>
                    <li className='mb-3'>Interest rates</li>
                    <li className='mb-3'>Other transactional and account information</li>
                  </ul>
                </div>
              </div>
          </div>
          <div>
            <div className='discHeader' onClick={() => setDisclaimer({ ...disclaimer, access: !disclaimer.access })}>
              <p>About the access</p>
              <i className={disclaimer?.access ? "fa fa-minus-circle" : "fa fa-info-circle"} style={{ fontSize: 25 }} aria-hidden="true"></i>
            </div>
            {disclaimer?.access ?
              <div className='disclaimerYapily'>
                <p style={{ margin: '0px 5px 10px 10px' }}>
                  Yapily Connect will then use these details with <strong>Barlow Marshall</strong> solely for the purposes of <strong>informing any revenue based finance agreement between yourselves and Barlow Marshall</strong> that has already been entered, or will be entered.
                  This access is valid for <strong>90 days</strong>, you can cancel consent at any time via the <strong>Barlow Marshall/Open Banking/Dashboard Institutions</strong> or via your bank.
                  This request is not a one-off, you will continue to receive consent requests as older versions expire.
                </p>
                <p style={{ margin: '0px 5px 10px 10px' }}>Please read <a href={YAPILY_TERM_CON_LINK} target='_blank'>Yapily Connect AIS - Terms & Conditions</a></p>
              </div> : ''}
          </div>
          {isAuthScreen?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: 20 }}>
              <button className="btn btn-outline-primary w-40" onClick={onClickBack}>Cancel</button>
              <button type="button" className="btn btn-primary bg-primary w-40 Submit text-white text-decoration-none" onClick={onClickAuthButton}>
                Login to your bank
              </button>
            </div>
          :''}
        </Row>
      </Container>
    </>
  )
}

export default YapilyDisclaimer;
