import * as typeS from "../service/types";
import Swal from 'sweetalert2'
import { ListApplication } from "../service/ApiConfig";

export const listingApplicationRequest = () => {
    return {
      type: typeS.LIST_APPLICATION_REQUEST,
    };
  };
  
  export const listingApplicationSuccess = (list) => {
    return {
      type: typeS.LIST_APPLICATION_SUCCESS,
      payload: list,
    };
  };
  
  export const listingApplicationError = (error) => {
    return {
      type: typeS.LIST_APPLICATION_ERROR,
      payload: error,
    };
  };
  
  export const listApplicationData = (setLoading) => {
    return (dispatch) => {
      try {
        ListApplication().then((res) => {
          if (res&&res.status === 200) {
            dispatch(listingApplicationSuccess(res?.data?.data));
            // setLoading(false)
          } else {
            dispatch(listingApplicationError("err.message"));
            setLoading(false)
          }
        }).catch((err) => {
          dispatch(listingApplicationError(err.message));
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err,
          })
          setLoading(false)
        });
      } catch (err) {
        setLoading(false)
      }
    };
  }
  