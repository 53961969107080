import { Navigate } from "react-router-dom";
import { decryptString } from "../../services/CommonFunction";

export const Authenticate = ({ children, role }) => {
    const isToken = localStorage.getItem('token');
    const deRole = localStorage.getItem('role');
    const isRole = deRole ? decryptString(deRole) : null;

    // on unauthenticate route token is set -> go to dashboard
    if(isToken && isRole && role === 'UnAuthenticated') {
        if (isRole==='admin') {
            return <Navigate to="/admin-dashboard" />;
        } else if (isRole==='client') {
            return <Navigate to="/client-dashboard" />;
        } else if (isRole==='funded') {
            const userData = localStorage.getItem('userData');
            if(userData) {
                const user = userData ? JSON.parse(decryptString(userData)) : {}; // navigate('/getFunded', { state: { email: user.email } });
                localStorage.setItem('refresh','true');
                return <Navigate to="/getFunded" state={{ email: user.email }}/>;
            } else { return children; }
        } else { return children; }
    }
    else if (role !== 'UnAuthenticated' && (!isToken || role !== isRole)) {
        // user is not authenticated
        return <Navigate to="/login" />;
    } else {
        return children;
    }
};