import * as types from "../service/types";

const InitState = {
  loading: false,
  loginData: "",
  error: "",
};
const LogIn = (state = InitState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_SUCCESS:
      return {
        loading: false,
        loginData: action.payload,
        error: "",
      };
    case types.LOGIN_ERROR:
      return {
        loader: false,
        loginData: "",
        error: true,
      };
    default:
      return state;
  }
};

export default LogIn;