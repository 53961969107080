import React from "react";
import RejectIcon from "../../img/rejectedIcon.svg";
import acceptIcon from "../../img/acceptIcon.svg";
import PendingIcon from "../../img/Pending@2x.svg";
import queryIcon from "../../img/query.svg";
import { getDateDDMMYYYY } from "../../services/CommonFunction";
const ViewOffers = (props) => {

    return (
        <div
            className="modal fade"
            id="exampleModal3"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    {/* <div className="modal-header"></div> */}
                    <div className="modal-body">
                        <div className="modal-header">
                            <div  className="modal-title">
                            <h3 id="exampleModalLabel" className="m-0">
                                View offer
                            </h3>
                            </div>
                            <button
                                type="button"
                                className="btn-close ms-0"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="offer-container">
                 <div className={`offer-${props.offerData?.status}-icon  glyphicon glyphicon-remove-circle`}>
                 { props.offerData?.status===('withdraw_offer') && <img width={'40px'} src={RejectIcon} alt=""/>}
                 { props.offerData?.status===( 'rejected') && <img width={'40px'} src={RejectIcon} alt=""/>}
                 { props.offerData?.status===('accepted') && <img width={'40px'} src={acceptIcon} alt=""/>}
                 { props.offerData?.status===('pending') && <img width={'40px'} src={PendingIcon} alt=""/>}
                 { props.offerData?.status===('query') && <img width={'40px'} src={queryIcon} alt=""/>}
                 </div>
                 <div className={`offer-status offer-${props.offerData?.status}`}>Offer {props.offerData && props.offerData?.status==='withdraw_offer'?'Withdrawn!!':props.offerData?.status +'!!'}</div>
                 {/* <div className="offer-description">{props.offerData?.reason || ''}</div> */}
                 { props.offerData&&props.offerData.reason&&
                      <div className="dierector-content mt-3" style={{width:'100%'}}>
                        <p className="table-font-color m-0">
                          <b>Query : </b>{props.offerData.reason}
                        </p>
                      </div>
                    }
               </div>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button "
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        <b> PARTIES</b>
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                >
                                    <div className="accordion-body">
                                        <span className="d-flex">
                                            <p className="mx-3 w-40 my-0 table-font-color">
                                                <b>Advance Recipient</b>
                                            </p>
                                            <p className="w-60 my-0 table-font-color">{props.offerData?.company_name}</p>
                                        </span>
                                        <hr />
                                        {/* <span className="d-flex">
                                            <p className="mx-3 w-40 my-0 table-font-color">
                                                <b>Guarantor (parent company, if applicable)</b>
                                            </p>
                                            <p className="w-60 my-0 table-font-color">
                                                Guarantor name
                                            </p>
                                        </span>
                                        <hr /> */}
                                        <span className="d-flex">
                                            <p className="mx-3 w-40 my-0 table-font-color">
                                                <b>Advance Provider</b>
                                            </p>
                                            <p className="w-60 my-0 table-font-color">
                                                Accelerate Digital Limited (<b>“Barlow Marshall”</b>){" "}
                                            </p>
                                        </span>
                                        {/* <hr />
                                        <span className="d-flex">
                                            <p className="mx-3 w-40 my-0 table-font-color">
                                                <b>Other parties</b>
                                            </p>
                                            <p className="w-60 my-0 table-font-color">
                                                Other parties[Any additional parties] (together with
                                                Advance Recipient, the “<b>Business</b>”)
                                            </p>
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                            {
                                //   offerDetails ?
                                <div className="accordion-item mt-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            <b> PRINCIPAL TERMS</b>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                    >
                                        <div className="accordion-body">
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Effective From Date</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">{props.offerData&&props.offerData.effective_from && props.offerData.effective_from !== "None"?getDateDDMMYYYY(props.offerData.effective_from):''}</p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Advance Amount</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">{props.offerData?.advance_amount || 0}</p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Fixed Fee</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">{props.offerData?.fixed_fee || 0}</p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Full Repayment</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    {props.offerData?.full_repayment || 0} (“Repayment Amount”)
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Minimum Split (first 12 months)</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    {props.offerData?.minimum_split_first_12_months || 0}% of Net Receipts
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Minimum Split (from 12 months)</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    {props.offerData?.minimum_split_from_12_months || 0}% of Net Receipts until the Repayment Amount has
                                                    been received in full by us.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Maximum Split</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    {props.offerData?.maximum_split || 0}% of Net Receipts
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Minimum Performance</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    {props.offerData?.minimum_performance || 0} per month Based on information you have provided
                                                    about your past performance, we expect that your Net
                                                    Receipts will always exceed this Minimum Performance.
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                // : null
                            }
                            <div className="accordion-item mt-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="true"
                                        aria-controls="collapseThree"
                                    >
                                        <b> ADDITIONAL TERMS</b>
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                >
                                    <div className="accordion-body">
                                        <div className="">
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Facility Type</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Revenue sharing finance facility.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Purpose</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Working capital and growth finance.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Repayment</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Repayment will be made via a Split of Net Receipts
                                                    until Full Repayment has been made.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Gross Receipts</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Means all amounts (including any value added or
                                                    other tax) which are paid to the Business by any
                                                    customer for goods or services using any means of
                                                    payment.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Net Receipts</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Means, in relation to any period, the Business&apos;s
                                                    Gross Receipts during such period, after deducting
                                                    any Permitted Offsets referable to such period.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Permitted Offsets</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Means the proceeds of any loans from commercial
                                                    lenders permitted under this agreement, sales of
                                                    securities, insurance claims, litigation
                                                    settlements, customer refunds or reimbursements, R&D
                                                    Tax rebates, VAT rebates
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Drawdown</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    A single draw down of the Facility in the Advance
                                                    Amount shall be made following completion of
                                                    Conditions Precedent.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Split</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Means the percentage of Net Receipts the Business is
                                                    required to pay to Barlow Marshall in repayment of
                                                    the Repayment Amount
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Re-rate</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    means the determination of whether the Minimum
                                                    Split, Adjusted Split or Maximum Split shall be
                                                    payable from each Re-rate Date until the next
                                                    Re-rate Date, dependent on whether cumulative
                                                    repayments as at the Re-rate Date meet the Re-rate
                                                    Thresholds (as explained here [link to table]).
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Re-Rate Date</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    means the first anniversary of the date of this
                                                    agreement, and the dates falling at six-monthly
                                                    intervals thereafter. See more details on the
                                                    re-rate here [link to re-rate]
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Re-rate Threshold</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Cumulative repayment thresholds at 12, 18, 24, 30
                                                    and 36 months. See more details on the re-rate here
                                                    [link to re-rate]
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Adjusted Split</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Means, as at any Re-rate Date in respect of which
                                                    the Cumulative Repayments to such date are less than
                                                    the Re-rate Threshold as at such date, the
                                                    percentage which is the product of the following
                                                    calculation:
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Minimum Performance</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    If you anticipate that your Net Receipts will not be
                                                    at least equal to this Minimum Performance at any
                                                    time, you must tell us as soon as you become aware.
                                                    If, for 3 consecutive months, the monthly Net
                                                    Receipts does not exceed the Minimum Performance, or
                                                    the aggregate Net Receipts in any 6-month period
                                                    does not exceed the Minimum Performance multiplied
                                                    by 6, this shall be a Termination Event.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Repayment Method</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Repayment via use of dedicated settlement account
                                                    with automated split
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Information Requirements</b>
                                                </p>
                                                <div className="w-60 my-0 table-font-color">
                                                    <p className="w-60 my-0 table-font-color">
                                                        To include (without limitation):{" "}
                                                    </p>
                                                    <p className="w-60 my-0 table-font-color">
                                                        A) Online, read only, access to:
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            Open Banking, or visibility of all Business bank
                                                            accounts in such other manner that is acceptable
                                                            to Barlow Marshall.{" "}
                                                        </li>
                                                        <li>
                                                            Accounting management software for the Business.
                                                        </li>
                                                    </ul>
                                                    <p className="w-60 my-0 table-font-color">
                                                        {" "}
                                                        B) Within agreed timescales:
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            Management information and reports for the
                                                            Business.
                                                        </li>
                                                        <li>
                                                            Audited financial statements for the Business.
                                                        </li>
                                                    </ul>
                                                    <p className="w-60 my-0 table-font-color">
                                                        C) Books, records, documents and other written
                                                        evidence, consistent with its normal accounting
                                                        procedures and practices, sufficient to reasonably
                                                        and accurately reflect the performance of its
                                                        obligations and the determination of the Split
                                                        amount.
                                                    </p>
                                                </div>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Voluntary Prepayment</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Allowed, in whole or in part, with at least 5
                                                    business days written notice.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Mandatory Prepayment and Cancellation</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Standard for facilities of this nature and to
                                                    include (without limitation): Acceleration following
                                                    Termination Event; Change of control; sale of a
                                                    substantial part of the assets or business of the
                                                    group.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Termination Events</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Without limitation, Minimum Performance, failure to
                                                    pay, agreement breach, misrepresentation,
                                                    insolvency; administration; cross default;
                                                    dissolution or liquidation; disposal and suspension
                                                    or cessation of business; cessation of business,
                                                    loss of licence.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Information Requirements</b>
                                                </p>
                                                <div className="w-60 my-0 table-font-color">
                                                    <p className="w-60 my-0 table-font-color">
                                                        Standard for facilities of this nature and to
                                                        include typical business restrictions equivalent
                                                        to those commonly used in commercial lending
                                                        transactions. Subject to the side letter
                                                        agreement, the Business and the Guarantor will
                                                        require the prior written consent of Barlow
                                                        Marshall for any:
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            acquisitions of any company, business, shares,
                                                            securities or undertaking to incorporate or form
                                                            a company.
                                                        </li>
                                                        <li>
                                                            Disposals of any assets other than in the
                                                            ordinary course of business.
                                                        </li>
                                                        <li>
                                                            Borrowings, beyond arrangements disclosed and
                                                            approved by Barlow Marshall in advance of the
                                                            facility.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Conditions Precedent to Financing</b>
                                                </p>
                                                <div className="w-60 my-0 table-font-color">
                                                    <p className="w-60 my-0 table-font-color">
                                                        Standard for facilities of this nature and to
                                                        include (without limitation):
                                                    </p>
                                                    <ol>
                                                        <li>Execution of transaction documentation;</li>
                                                        <li>
                                                            Corporate authorisations and certificates;
                                                        </li>
                                                        <li>
                                                            Establishment of information access and
                                                            settlement account;
                                                        </li>
                                                        <li>
                                                            Completion of all required due diligence; and
                                                        </li>
                                                        <li>
                                                            KYC and other regulatory aspects as required by
                                                            Barlow Marshall.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Guarantee</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    From the parent company. Standard for arrangements
                                                    of this nature and to include typical commitments
                                                    and restrictions equivalent to those commonly used
                                                    for guarantors in commercial lending transactions.{" "}
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Taxes</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    All payments by the Advance Recipient shall be made
                                                    free and clear of all withholdings, taxes and
                                                    deductions.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Governing Law</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    The Laws of England.
                                                </p>
                                            </span>
                                            <hr />
                                            <span className="d-flex">
                                                <p className="mx-3 w-40 my-0 table-font-color">
                                                    <b>Confidentiality</b>
                                                </p>
                                                <p className="w-60 my-0 table-font-color">
                                                    Each Party acknowledges that the Offer is
                                                    confidential and shall not, and shall ensure that no
                                                    other member of their respective group of companies
                                                    shall, without the prior written consent of the
                                                    other party, disclose the Offer and any other
                                                    related documents or their contents to any other
                                                    person except as required by law or any applicable
                                                    governmental or other regulatory authority except to
                                                    such party’s solicitors and/or other professional
                                                    advisors.
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dierector-content mt-3">
                                <p className="table-font-color m-0">
                                    <b>
                                        &ldquo;THE TERMS SET OUT IN THIS OFFER ARE INDICATIVE ONLY AND
                                        ARE SUBJECT TO ENTRY INTO A FULL SERVICE AGREEMENT WITH
                                        BARLOW MARSHALL&rdquo;
                                    </b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewOffers;