import React, { useEffect, useState } from 'react'
// import NavBar from '../../components/NavBar'
import ClipLoader from "react-spinners/ClipLoader";
// import { MDBDataTableV5 } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { listTransactionData } from '../../redux/action/listTransaction';
import Select from 'react-select';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { customStylesSmallBox } from '../../utils/customSelect';
import { handleError, HttpCall } from '../../services/UseHttps';
import { modulrDashboardApi } from '../../config';
import { formatAmount, getDateDDMMYYYY, maskSecureString, maskSecureString2 } from '../../services/CommonFunction';
const ClientTransations = (props) => {
  const historyTransaction = props.transactionTabs;

  const dispatch = useDispatch();
  const select = useSelector(state => state && state.listTransaction && state.listTransaction.listTransactions);
  const applicationId = useSelector(state => state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj && state.profileInfo.profileData.application_obj.application_id);
  // const userId = useSelector(state => state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj && state.profileInfo.profileData.user_obj.user_id);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [selectedTransType, setSelectedTransType] = useState({ value: 'non-modulr', label: 'Non-Modulr Transactions' });
  const [selectedSource, setSelectedSource] = useState({ value: null, label: 'All Source' });
  const [selectedDays, setSelectedDays] = useState({ value: 30, label: 'Last 30 Days' });
  const [selectedStatus, setSelectedStatus] = useState({ value: null, label: 'All Transactions' });
  const [selectedAccount, setSelectedAccount] = useState({ value: null, label: 'All Accounts' });
  // const [showReason, setshowReason] = useState(false);
  const [accountNo, setAccountNo] = useState({});
  const [sortCode, setSortCode] = useState({});
  const [optionsAccount, setOptionsAccount] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [message, setMessage] = useState('Modulr transaction history records not found.');
  const optionsTransType = [
    { value: 'modulr', label: 'Modulr Transactions' },
    { value: 'non-modulr', label: 'Non-Modulr Transactions' }
  ];
  const optionsSource = [
    { value: null, label: 'All Source' },
    { value: 'excel-upload', label: 'Excel Upload' },
    { value: 'yapily', label: 'Yapily' }
  ];
  const optionsStatus = [
    { value: null, label: 'All Transactions' },
    { value: 'pending', label: 'Pending' },
    { value: 'settle_initiated', label: 'Settlement Initiated' },
    { value: 'settled', label: 'Settled' },
    { value: 'challenged', label: 'Challenged' },
    { value: 'challenge_accepted', label: 'Challenge Accepted' },
    { value: 'challenge_rejected', label: 'Challenge Rejected' },
  ];
  const optionsDay = [
    { value: 30, label: 'Last 30 Days' },
    { value: 90, label: 'Last Quarter' },
    { value: 365, label: 'Last Year' },
  ];
  const [datatable, setDatatable] = useState([]);
  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nPages, setNpages] = useState(0);

  useEffect(() => {
    if (applicationId && historyTransaction === 'Transactionhistory') {
      if(selectedTransType && selectedTransType.value === 'non-modulr') {
        dispatch(listTransactionData(setLoading, applicationId, selectedSource.value, selectedDays.value, selectedStatus.value, selectedAccount.value, currentPage, recordsPerPage));
      } else if(selectedTransType && selectedTransType.value === 'modulr') {
        fetchModulrData();
      }
    }
  }, [applicationId, historyTransaction, selectedSource, selectedDays, selectedStatus]);
  useEffect(() => {
    if (applicationId && historyTransaction === 'Transactionhistory') {
      if(selectedTransType && selectedTransType.value === 'non-modulr') {
        dispatch(listTransactionData(setLoading, applicationId, selectedSource.value, selectedDays.value, selectedStatus.value, selectedAccount.value, currentPage, recordsPerPage));
      } else if(selectedTransType && selectedTransType.value === 'modulr') {
        fetchModulrData();
      }
    }
  }, [selectedTransType, currentPage, recordsPerPage]);
  useEffect(() => {
    if (select && select.transactions && select.transactions.length > 0) {
      const totRecs = select.total_records?select.total_records:0;
      setTotalRecords(totRecs);
      setNpages(Math.ceil(totRecs / recordsPerPage));
      setDatatable(select&&select.transactions?select.transactions:[]);
    } else {
      setDatatable([]);
      setTotalRecords(0);
    }
  }, [select]);
  useEffect(() => {
    if (select && select.account_numbers) {
      if(select.account_numbers.length > 0) {
        const acountList = select.account_numbers.map((item) => { return { value: item, label: item } });
        const allAccounts = [{ value: null, label: 'All Accounts' }, ...acountList];
        setOptionsAccount(allAccounts);
      } else {
        setOptionsAccount([{ value: null, label: 'All Accounts' }]);
      }
    }
  }, [select]);

  const selectHandlerTransType = (e) => {
    setCurrentPage(0); setRecordsPerPage(10); setTotalRecords(0);  setNpages(0); setDatatable([]); setTransactions([]);
    setSelectedTransType({ label: e.label, value: e.value });
  }
  const selectHandlerAccount = (e) => {
    setSelectedAccount({ label: e.label, value: e.value });
    if (applicationId && historyTransaction === 'Transactionhistory') {
      if(selectedTransType && selectedTransType.value === 'non-modulr') {
        if(e.value !== null) { setCurrentPage(0); }
        dispatch(listTransactionData(setLoading, applicationId, selectedSource.value, selectedDays.value, selectedStatus.value, e.value, currentPage, recordsPerPage));
      } else if(selectedTransType && selectedTransType.value === 'modulr') {
        fetchModulrData();
      }
    }
    // const filterAccount = select && select.transactions && select.transactions.length > 0 && select.transactions.filter((el) => el.account_number === (e.value ? e.value : el.account_number));
    // setDatatable(filterAccount?filterAccount:[]);
  };
  const selectHandlerSource = (e) => {
    setSelectedSource({ label: e.label, value: e.value });
    if(e.value !== null) { setCurrentPage(0); }
  };
  const selectHandlerStatus = (e) => {
    // if (e.value === 'challenged' || e.value === 'challenge_accepted' || e.value === 'challenge_rejected') {
    //   setshowReason(true);
    // } else {
    //   setshowReason(false);
    // }
    setSelectedStatus({ label: e.label, value: e.value });
    if(e.value !== null) { setCurrentPage(0); }
  };
  const selectHandlerDays = (e) => {
    setSelectedDays({ label: e.label, value: e.value });
    setCurrentPage(0);
  };
  // fetch modulr dashboard data
  const fetchModulrData = () => {
      try {
          setLoading(true);
          HttpCall(`${modulrDashboardApi}/${applicationId}?page=${currentPage}&size=${recordsPerPage}&days=${selectedDays.value}`, "get")
            .then((res) => {
              if(res && res.status === 200 && res.data && res.data.status === true && res.data.data) {
                  setAccountNo(res.data.data.account_details&&res.data.data.account_details.account_number? {accNo: maskSecureString(res.data.data.account_details.account_number),anRaw:res.data.data.account_details.account_number}:{});
                  setSortCode(res.data.data.account_details&&res.data.data.account_details.sort_code? {sortCo:maskSecureString2(res.data.data.account_details.sort_code),scRaw:res.data.data.account_details.sort_code}:{});
                  setTransactions(res.data.data.transactions?res.data.data.transactions:[]);
                  const totRecs = res.data.data.total_records?res.data.data.total_records:0;
                  setTotalRecords(totRecs);
                  setNpages(Math.ceil(totRecs / recordsPerPage));
              }
              setLoading(false);
            }).catch((er) => {
                  setAccountNo({});
                  setSortCode({});
                  setTransactions([]);
                  setTotalRecords(0);
                  setLoading(false);
                  handleError(er, true);
            });
      } catch(error) {
          setAccountNo({});
          setSortCode({});
          setTransactions([]);
          setTotalRecords(0);
          setLoading(false);
          handleError(error)
      }
  };
  // Pagination functions
  // on records per page filter
  const selectRecordPerPage = (e) => {
      setRecordsPerPage(e.target.value);
  };
  // paginate button click
  const nextPage = () => {
      if(currentPage !== nPages) {
          setCurrentPage(currentPage + 1);
      }
  }
  const prevPage = () => {
      if(currentPage !== 0) {
          setCurrentPage(currentPage - 1)
      }
  }
  // Downalod Excel file
  const getDownloadRecords = async () => {
    try {
      if(totalRecords <= 0) {
        Swal.fire({icon: 'error',title: 'Error', text: 'Records not found to download report.'});
        return;
      }
      const modulrDownloadTran = [];
      setDownloading(true);
      HttpCall(`${modulrDashboardApi}/${applicationId}?page=0&size=${totalRecords}&days=${selectedDays.value}`, "get")
        .then(async (res) => {
          if(res && res.status === 200 && res.data && res.data.status === true && res.data.data) {
              const transactionsAll = res.data.data.transactions?res.data.data.transactions:[];
              transactionsAll.forEach((item) => {
                  const object = {'Date':getDateDDMMYYYY(item.transaction_date), 'Ref': item.reference,  'Account':accountNo.accNo, 'Sort Code':sortCode.sortCo, 'Description': item.description,
                        'Paid In': item.paid_in ? Number(parseFloat(item.paid_in).toFixed(2)) : item.paid_in,
                        'Paid Out': item.paid_out ? Number(parseFloat(item.paid_out).toFixed(2)) : item.paid_out,
                        'Balance': item.remaining_amount ? Number(parseFloat(item.remaining_amount).toFixed(2)) : item.remaining_amount
                  };
                  modulrDownloadTran.push(object);
              });
          }
          setDownloading(false);
          if(modulrDownloadTran && modulrDownloadTran.length > 0) {
              await downloadExcelReport(modulrDownloadTran);
          } else {
              Swal.fire({icon: 'error',title: 'Error', text: 'Records not found to download report.'});
          }
        }).catch((er) => { setDownloading(false); handleError(er, true); });
    } catch(error) { setDownloading(false); handleError(error); }
  }
  // download excel-sheet
  const downloadExcelReport = async (transactionData) => {
    try {
        var fileType = 'xlsx';
        var fileFormat = 'binary';
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(transactionData);
        XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet');
        const excelBuffer = XLSX.write(workbook, { bookType: fileType, type: 'array', cellStyles:true });
        const finalData = new Blob([excelBuffer], { type: fileFormat });
        FileSaver.saveAs(finalData, "modulr_transaction_history.xlsx");
    } catch(err) {
        // console.log('file donaload error : ', err);
    }
  }

  return (
    <>
      <div className="mb-4">
        <div className="card">
          <div className="card-body">
            <div className="col-12">
              <div className="tab-content" id="myTabContent">
                <div className="mb-4">
                  <div className="d-flex justify-content-between">
                    <div className="text-left">
                      <h4>Recent Transactions</h4>
                    </div>
                    <div className="mb-2 filter-transaction-container" style={{fontSize:'13px'}}>
                      <Select
                        className="react-select-container"
                        onChange={selectHandlerTransType}
                        value={selectedTransType}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => ""}
                        options={optionsTransType}
                        styles={customStylesSmallBox}
                        placeholder="Select Transaction Type"
                      />
                      { selectedTransType.value === 'non-modulr' &&
                        <Select
                          className="react-select-container"
                          onChange={selectHandlerSource}
                          value={selectedSource}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => ""}
                          options={optionsSource}
                          styles={customStylesSmallBox}
                          placeholder="Select Source"
                        />
                      }
                      {optionsAccount&&optionsAccount.length !== 0 && selectedTransType && selectedTransType.value === 'non-modulr' &&
                        <Select
                        className="react-select-container"
                        onChange={selectHandlerAccount}
                        value={selectedAccount}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => ""}
                        options={optionsAccount}
                        styles={customStylesSmallBox}
                        placeholder="Select Account"
                      />}
                      { selectedTransType.value === 'non-modulr' &&
                        <Select
                          className="react-select-container"
                          onChange={selectHandlerStatus}
                          value={selectedStatus}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => ""}
                          options={optionsStatus}
                          styles={customStylesSmallBox}
                          placeholder="Select Status"
                        />
                      }
                      <Select
                        className="react-select-container"
                        onChange={selectHandlerDays}
                        value={selectedDays}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => ""}
                        options={optionsDay}
                        styles={customStylesSmallBox}
                        placeholder="Select Days"
                      />
                    { selectedTransType.value === 'modulr' && <div>
                        <button onClick={getDownloadRecords} title="Download Transaction History" disabled={downloading}
                                className="btn btn-custom-no-white-shadow  btn-success" style={{marginTop:5,borderRadius:20}}>
                                <i className="fa fa-file-excel" aria-hidden="true" style={{marginRight:3}}></i>Download
                        </button> </div>
                      }
                    </div>
                  </div>
                  { loading ? <ClipLoader size={150}
                      css={{
                        display: "block",
                        margin: "0 auto",
                        borderColor: "green",
                      }} color="green" />
                    :
                    <> 
                      {selectedTransType && selectedTransType.value === 'non-modulr'?
                        <div className='table-responsive' style={{height:'70vh',marginBottom:'10px'}}>
                        <table id="tableData3" style={{fontSize:13}} className="table table-bordered table-striped" cellSpacing="0">
                          <thead>
                              <tr>
                                  <th>Status</th>
                                  <th>Source</th>
                                  <th>Sort Code</th>
                                  <th>Account Number</th>
                                  <th>Date</th>
                                  <th>Description</th>
                                  <th>Reference</th>
                                  {/* <th>Currency</th>
                                  <th>Currency Debit</th>
                                  <th>Currency to GBP Exchange</th> */}
                                  <th>GBP Debit (Paid In)</th>
                                  <th>GBP Credit (Paid Out)</th>
                                  <th>Split %</th>
                                  <th>Split Amount</th>
                                  <th>Client Split Amount</th>
                                  <th style={{minWidth:'175px'}}>Reason</th>
                              </tr>
                          </thead>
                          <tbody>
                              {datatable.map((tran,i) => 
                                <tr key={i}>
                                    <td>
                                      {
                                        tran.status === "settled" ?
                                            <>
                                              <div style={{ width: "100%", textAlign: "center" }}>
                                                <i
                                                  className="fa-solid fa-circle-check text-success cursor-pointer mx-1"
                                                  data-bs-placement="top"
                                                  title="Settled"
                                                ></i>
                                              </div>
                                            </>
                                        : tran.status === "settle_initiated" ?
                                            <>
                                              <div style={{ width: "100%", textAlign: "center" }}>
                                                <i
                                                  className="fa-solid fa-hourglass-start text-warning cursor-pointer mx-1"
                                                  data-bs-placement="top"
                                                  title="Settlement Initiated"
                                                ></i>
                                              </div>
                                            </>
                                        : tran.status === "challenged" ?
                                            <>
                                              <div style={{ width: "100%", textAlign: "center" }}>
                                                <i
                                                  className="fa-solid fa-clipboard-question text-danger cursor-pointer mx-1"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Challenged"
                                                ></i>
                                              </div>
                                            </>
                                        : tran.status === "challenge_rejected" ?
                                            <>
                                              <div style={{ width: "100%", textAlign: "center" }}>
                                                <i
                                                  className="fa-solid fa-circle-xmark text-danger mx-1 cursor-pointer"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Challenge Rejected"
                                                ></i>
                                              </div>
                                            </>
                                        : tran.status === "challenge_accepted" ?
                                            <>
                                              <div style={{ width: "100%", textAlign: "center" }}>
                                                <i
                                                  className="fa-solid fa-handshake text-success mx-1 cursor-pointer"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Challenge Accepted"
                                                ></i>
                                              </div>
                                            </>
                                        : tran.status === "pending" ?
                                            <>
                                              <div style={{ width: "100%", textAlign: "center" }}>
                                                <i
                                                  className="fa-solid fa-clock-rotate-left text-warning mx-1 cursor-pointer"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Pending"
                                                ></i>
                                              </div>
                                            </>
                                        : ''
                                      }
                                    </td>
                                    <td>{tran&&tran.source&&
                                            tran.source === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}>
                                              <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i>
                                            </div>
                                          : tran.source === "yapily"? <div style={{ width: "100%", textAlign: "center" }}>
                                              <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i>
                                            </div> :'-'}
                                    </td>
                                    <td>{tran&&tran.sort_code?tran.sort_code:''}</td>
                                    <td>{tran&&tran.account_number?tran.account_number:''}</td>
                                    <td>{tran&&tran.date?tran.date:''}</td>
                                    <td>{tran&&tran.description?tran.description:''}</td>
                                    <td>{tran&&tran.reference?tran.reference:''}</td>
                                    {/* <td>{tran&&tran.currency?(tran.currency).toUpperCase():''}</td>
                                    <td>{tran&&tran.currency_debit?`${(tran.currency).toUpperCase()=='USD'?'$':(tran.currency).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(tran.currency_debit):'0'}</td>
                                    <td>{tran&&tran.gbp_exchange_rate?tran.gbp_exchange_rate:'0'}</td> */}
                                    <td>{tran&&tran.debit_received?'£'+formatAmount(tran.debit_received):'0'}</td>
                                    <td>{tran&&tran.credit_spent?'£'+formatAmount(tran.credit_spent):'0'}</td>
                                    <td>{tran&&tran.split_percentage?tran.split_percentage:'0'}</td>
                                    <td>{tran&&tran.bm_split_amount?'£'+formatAmount(tran.bm_split_amount):'0'}</td>
                                    <td>{tran&&tran.client_split_amount?'£'+formatAmount(tran.client_split_amount):'0'}</td>
                                    <td>{tran&&tran.reason?tran.reason:''}</td>
                                </tr>
                              )}
                          </tbody>
                          {datatable && datatable.length <= 0 && 
                            <tfoot>
                                <tr><td colSpan={13}>
                                    <div className="dierector-content mt-3 "
                                        style={{
                                            borderRight: "15px solid #003056",
                                            borderLeft: "15px solid #003056",
                                        }} >
                                        <p className="table-font-color m-0"> <b>Non-modulr transaction history records not found.</b> </p>
                                    </div>
                                </td></tr>
                            </tfoot>
                          }
                        </table>
                        </div>
                      : selectedTransType && selectedTransType.value === 'modulr' ?
                      <table id="tableData3" className="table table-bordered table-striped" cellSpacing="0">
                          <thead>
                              <tr>
                                  <th>Status</th>
                                  <th>Date</th>
                                  <th>Ref</th>
                                  <th>Account</th>
                                  <th>Sort Code</th>
                                  <th>Description</th>
                                  <th>Paid In</th>
                                  <th>Paid Out</th>
                                  <th>Balance</th>
                              </tr>
                          </thead>
                          <tbody>
                              {transactions && transactions.length > 0 &&
                              transactions.map((tran,i) => 
                                <tr key={i}>
                                    <td>
                                        <div style={{ width: "100%", textAlign: "center" }}>
                                          <i className="fa-solid fa-circle-check text-success cursor-pointer mx-1"
                                            data-bs-placement="top" title="Settled" ></i>
                                        </div>
                                    </td>
                                    <td>{tran.transaction_date ? getDateDDMMYYYY(tran.transaction_date) : ''}</td>
                                    <td>{tran.reference?tran.reference:''}</td>
                                    <td>{accountNo&&accountNo.accNo?accountNo.accNo:''}</td>
                                    <td>{sortCode&&sortCode.sortCo?sortCode.sortCo:''}</td>
                                    <td>{tran.description ? tran.description:''}</td>
                                    <td>{tran.paid_in?'£'+formatAmount(tran.paid_in):'0'}</td>
                                    <td>{tran.paid_out?'£'+formatAmount(tran.paid_out):'0'}</td>
                                    <td>{tran.remaining_amount?'£'+formatAmount(tran.remaining_amount):'0'}</td>
                                </tr>
                              )}
                          </tbody>
                          {transactions && transactions.length <= 0 && 
                            <tfoot>
                                <tr><td colSpan={9}>
                                    <div className="dierector-content mt-3 "
                                        style={{
                                            borderRight: "15px solid #003056",
                                            borderLeft: "15px solid #003056",
                                        }} >
                                        <p className="table-font-color m-0"> <b>{message}</b> </p>
                                    </div>
                                </td></tr>
                            </tfoot>
                          }
                        </table> : ''
                    }     
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <div>
                              <label>Rows per page : </label>
                              <select placeholder="Select" value={recordsPerPage} onChange={selectRecordPerPage}>
                                  <option value={5}>5</option>
                                  <option value={10}>10</option>
                                  <option value={15}>15</option>
                                  <option value={20}>20</option>
                                  <option value={25}>25</option>
                              </select>
                          </div>
                              <ul className="pagination">
                                  <li className={currentPage !== 0 ? "page-item ":"page-item disabled"}>
                                      <a className="page-link" tabIndex="-1" onClick={prevPage}>Previous</a>
                                  </li>
                                  <li className={currentPage !== nPages && nPages > 1 && (currentPage+1 < nPages) ? "page-item ":"page-item disabled"}>
                                      <a className="page-link"  onClick={nextPage}>Next</a>
                                  </li>
                              </ul>
                      </div>
                      {selectedTransType && selectedTransType.value === 'modulr' && 
                        <div>
                            Electronic money account is provided by Modulr FS Limited, authorised and regulated by the Financial Conduct Authority for issuance of electronic money (FRN 900573)
                        </div>
                      }
                  </>
                      // <MDBDataTableV5
                      //   striped
                      //   bordered
                      //   hover
                      //   noRecordsFoundLabel="true"
                      //   data={datatable}
                      //   searching={false}
                      //   fullPagination
                      //   searchTop
                      //   searchBottom={false}
                      //   entriesOptions={[2, 5, 10, 20, 50, 100]}
                      //   paging={!(datatable&&datatable.rows&&datatable.rows.length <= "4")}
                      //   className={`${datatable&&datatable.rows&&datatable.rows.length <= "4"
                      //       ? "hide-pagination"
                      //       : ""
                      //     } ${showReason ? '' : 'hide-reason'}`}
                      // // pagingTop searchTop
                      // // theadColor="blue lighten-5" entries={100} pagesAmount={4} order={['uptime', 'asc']} className="mx-auto"
                      // />
                  }
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ClientTransations;