import ClipLoader from "react-spinners/ClipLoader";
const InfoBar = (props) => {
  return (
    <div className="py-3 my-4 bg-muted rounded-3">
      { props&&props.loadingData ? 
          <ClipLoader size={80} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
        :
          <ul className="text-center d-flex list-unstyled align-items-end m-auto">
            {props.data&&props.data.length > 0 && props.data.map((el, i) => (
              <li key={el.title} className={`${i < props.data.length - 1 && 'border-line'} client-overview`} role="presentation">
                <h4 className="text-muted m-0">{el.value}</h4>
                <p className="p-0">{el.title}</p>
              </li>
            ))}
          </ul>
      }
    </div>
  );
};

export default InfoBar;
