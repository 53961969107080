import * as types from "../service/types";

const InitState = {
  loading: false,
  truelayerData: "",
  error: "",
};
const Truelayer = (state = InitState, action) => {
  switch (action.type) {
    case types.TRUELAYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.TRUELAYER_SUCCESS:
      return {
        loading: false,
        truelayerData: action.payload,
        error: "",
      };
    case types.TRUELAYER_ERROR:
      return {
        loader: false,
        truelayerData: "",
        error: action.error,
      };
    default:
      return state;
  }
};

export default Truelayer;