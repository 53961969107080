import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { handleError, HttpCall } from "../../services/UseHttps";
import { listAdminOfferData } from "../../redux/action/offerList";
import { addBankAccountAPI } from "../../config";

const LinkNonModulrAccount = (props) => {
  const dispatch = useDispatch();
  const applicationId = props.offerData && props.offerData.application_id ? props.offerData.application_id:'';
  const companyName = props.offerData && props.offerData.company_name ? props.offerData.company_name : '';
  const bankAccount = props.offerData ? props.offerData : ''; // bank_account

  const [initialValues, setInitialValues] = useState({
    name: '',
    account_number: '',
    account_type: '',
    sort_code: '',
    iban: '',
    bic: '',
    application_id: applicationId,
  });
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const bankAccountTypes = ["SCAN", "IBAN", "DD", "INTL"];
  const numePattern = /^\d+$/;

  useEffect(() => {
    if(bankAccount) {
      setInitialValues({
        name: bankAccount.name ? bankAccount.name : '',
        account_number: bankAccount.account_number ? bankAccount.account_number : '',
        account_type: bankAccount.account_type ? bankAccount.account_type : '',
        sort_code: bankAccount.sort_code ? bankAccount.sort_code : '',
        iban: bankAccount.iban ? bankAccount.iban : '',
        bic: bankAccount.bic ? bankAccount.bic : '',
        application_id: applicationId,
      });
    }
  }, [bankAccount]);

  const handleLinkAccount = (values) => {
      try {
        setLoading(true);
        setErrorMsg('');
        const dataObj = {...values, application_id: applicationId };
        HttpCall(`${addBankAccountAPI}`, "post", dataObj).then((res) => {
            if(res && (res.status === 200 || res.status === 200) && res.data) {
              formik.resetForm();
              Swal.fire({ title: "Success", text: "Bank account linked successfully.", icon: "success"}); 
              dispatch(listAdminOfferData());
              props.setModalShow(false);
            } else {
              if(res && res.data && res.data.message) {
                setErrorMsg(res.data.message);
              } else {
                setErrorMsg('Fails to add bank account.');
              }
            }
            setLoading(false);
          }).catch((error) => {
            setLoading(false);
            formik.resetForm();
            handleError(error);
          });
      } catch (ex) {
        setLoading(false);
      }
    }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => { handleLinkAccount(values) },
    validationSchema: yup.object({
      name: yup.string().trim('Bank name cannot include leading and trailing spaces').strict(true).required('Bank name is required')
        .max(18, 'Bank name should be maximum 18 character'),
      account_number: yup.string().trim('Account number cannot include leading and trailing spaces').strict(true).required('Account number is required')
        .matches(numePattern, 'Account number is not valid, only numbers allowed.')
        .min(8, 'Account Number should be minimum 8 character')
        .max(8, 'Account Number should be maximum 8 character'),
      account_type: yup.string().trim().required('Account Type is required'),
      sort_code: yup.string().trim('Sort code cannot include leading and trailing spaces').strict(true).required('Sort code is required')
        .matches(numePattern, 'Sort code is not valid, only numbers allowed.')
        .min(6, "Minimum sort code should be 6 digits")
        .max(6, "Maximum sort code should be 6 digits"),
      iban: yup.string().trim('IBAN cannot include leading and trailing spaces').strict(true).required('IBAN is required')
        .min(15, 'IBAN should be minimum 15 character')
        .max(34, 'IBAN should be maximum 34 character'),
      bic: yup.string().trim('BIC cannot include leading and trailing spaces').strict(true).required('BIC is required')
        .min(8, 'BIC should be minimum 8 character')
        .max(11, 'BIC should be maximum 11 character'),
      application_id: yup.string()
    }),
});
 
  return (
    <Modal show={props.showModal} onHide={() => props.setModalShow(false)}
      size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton onHide={() => formik.resetForm()}>
        <Modal.Title id="contained-modal-title-vcenter">
          Link Client Settlement Account ({companyName})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card ">
          <div className="card-body">
            <form className="custom-form" onSubmit={formik.handleSubmit}>
              {loading ? 
                <ClipLoader size={150} css={{display: "block",margin: "0 auto",borderColor: "green",}} color="green" /> 
              :
              <>
                <div className="mb-3">
                  <input type="text" name="name" id="bankname" placeholder="Enter Bank Name"
                      value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}
                      className="form-control" aria-describedby="BankName" 
                  />
                  {formik.errors.name && formik.touched.name && (
                      <div className="text-danger">{formik.errors.name}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input type="text" name="account_number" id="accountNumber" placeholder="Enter Account Number"
                      value={formik.values.account_number} onChange={formik.handleChange} onBlur={formik.handleBlur}
                      className="form-control" aria-describedby="accountNumber" 
                  />
                  {formik.errors.account_number && formik.touched.account_number && (
                      <div className="text-danger">{formik.errors.account_number}</div>
                  )}
                </div>
                <div className="mb-3">
                  <select className="form-select" name="account_type" value={formik.values.account_type}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    aria-label="Default select example">
                    <option value='' disabled>Select Account Type</option>
                    {bankAccountTypes && bankAccountTypes.length > 0 &&
                        bankAccountTypes.map((acc, i) => <option key={i} value={acc}>{acc}</option>)
                    }
                </select>
                  {formik.errors.account_type && formik.touched.account_type && (
                      <div className="text-danger">{formik.errors.account_type}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input type="text" name="sort_code" id="sortCode" placeholder="Enter Sort Code"
                      value={formik.values.sort_code} onChange={formik.handleChange} onBlur={formik.handleBlur}
                      className="form-control" aria-describedby="sort Code" 
                  />
                  {formik.errors.sort_code && formik.touched.sort_code && (
                      <div className="text-danger">{formik.errors.sort_code}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input type="text" name="iban" id="iban" placeholder="Enter IBAN"
                      value={formik.values.iban} onChange={formik.handleChange} onBlur={formik.handleBlur}
                      className="form-control" aria-describedby="IBAN" 
                  />
                  {formik.errors.iban && formik.touched.iban && (
                      <div className="text-danger">{formik.errors.iban}</div>
                  )}
                </div>
                <div className="mb-3">
                  <input type="text" name="bic" id="bic" placeholder="Enter BIC"
                      value={formik.values.bic} onChange={formik.handleChange} onBlur={formik.handleBlur}
                      className="form-control" aria-describedby="BIC" 
                  />
                  {formik.errors.bic && formik.touched.bic && (
                      <div className="text-danger">{formik.errors.bic}</div>
                  )}
                </div>
                {errorMsg && <div className="alert alert-danger" role="alert">{errorMsg}</div>}
                <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow">
                  Link
                </button>
              </>
              }
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LinkNonModulrAccount;
