import HomePageBanner from "./Home-Page";
import FinanceFeature from "./Finance-Feature";
import FounderFriendly from "./Founder-Friendly";
import BarlowMarshall from "./Barlow-Marshall";
import FinanceBarlow from "./Finance-Barlow";
import B2bSaas from "./B2b-Saas";
import CaseStudy from "./Case-Study";
import FlexibleFinance from "./Flexible-Finance";
import MainHeader from "../components/Header";
import Footer from "../components/Footer";
import './HomePageResponsive.css'
import { useEffect } from "react";

const HomePageMain = (props) => {
  useEffect(() => {
    document.title = props.title;
   }, [])
  return (
    <div>
      <MainHeader />
      <HomePageBanner />
      <FinanceFeature />
      <FounderFriendly />
      <BarlowMarshall />
      <FinanceBarlow />
      <B2bSaas />
      <CaseStudy />
      <FlexibleFinance />
      <Footer />
    </div>
  );
};

export default HomePageMain;
