import { MDBDataTableV5 } from "mdbreact";
import { useState } from "react";

const CodatRecords = (props) => {

    // mdb-data-table
  const [mdbColHeaders, setMdbColHeaders] = useState([
    {
      label: "Invoice Number",
      field: "nominalCode",
      sort: "asc",
      width: 150,
    },
    {
      label: "Company Name",
      field: "name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency",
      field: "currency",
      sort: "asc",
      width: 150,
    },
    {
      label: "Total Tax amount",
      field: "currentBalance",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
  ]);
  const [datatable, setDatatable] = useState({
    columns: mdbColHeaders,
    rows: props.codatRecords ? props.codatRecords : [],
  });

    return (
        <div className="col-12">
            <MDBDataTableV5
                            striped
                            bordered
                            hover
                            data={datatable}
                            fullPagination
                            searchTop
                            searchBottom={false}
                            entriesOptions={[2, 5, 10, 20, 50, 100]}
                            paging={!(datatable.rows.length <= "4")}
                            className={`${
                                datatable.rows.length <= "4"
                                ? "hide-pagination"
                                : ""
                            }`}
                            />
        </div>
    );
}
export default CodatRecords;
