import * as types from "../service/types";
import Swal from 'sweetalert2'
import { ledgerApi, settledData } from "../service/ApiConfig";

export const ledger = (ledger) => {
    return {
        type: types.LEDGER_TRANSACTION,
        payload: ledger,
    };
};

export const ledgerPendingSuccess = (ledger) => {
    return {
        type: types.LEDGER_TRANSACTION_SUCCESS,
        payload: ledger,
    };
};

export const ledgerPendingError = (error) => {
    return {
        type: types.LEDGER_TRANSACTION_FAILED,
        payload: error,
    };
};
export const ledgerSettledSuccess = (ledger) => {
    return {
        type: types.LEDGER_TRANSACTION_SETTLED_SUCCESS,
        payload: ledger,
    };
};

export const ledgerSettledError = (error) => {
    return {
        type: types.LEDGER_TRANSACTION_SETTLED_FAILED,
        payload: error,
    };
};
export const ledgerChallengedSuccess = (ledger) => {
    return {
        type: types.LEDGER_TRANSACTION_CHELLENGED_SUCCESS,
        payload: ledger,
    };
};

export const ledgerChallengedError = (error) => {
    return {
        type: types.LEDGER_TRANSACTION_CHELLENGED_FAILED,
        payload: error,
    };
};

export const ledgerPendingData = (status,applicationId) => {
    return (dispatch) => {
        try {
            ledgerApi(status,applicationId).then((res) => {
                if (res&&res.data&&res.data.status === true) {
                    const ledgerRes = res;
                    dispatch(ledgerPendingSuccess(ledgerRes))
                } else {
                    dispatch(ledgerPendingError())
                }
            })
        } catch (err) {
            dispatch(ledgerPendingError(err.message))
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
            })
        }
    }
}

export const ledgerSettledData = (clientId,data) => {
    return (dispatch) => {
        try {
            ledgerApi(clientId,data).then((res) => {
                if (res) {
                    const ledgerSettled = res;
                    dispatch(ledgerSettledSuccess(ledgerSettled))
                } else {
                    dispatch(ledgerSettledError())
                }
            })
        } catch (err) {
            dispatch(ledgerSettledError(err.message))
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
            })
        }
    }
}

export const ledgerChallengedData = (data) => {
    return (dispatch) => {
        try {
            ledgerApi(data).then((res) => {
                if (res) {
                    const ledgerChallenged = res;
                    dispatch(ledgerChallengedSuccess(ledgerChallenged))
                } else {
                    dispatch(ledgerPendingError())
                }
            })
        } catch (err) {
            dispatch(ledgerChallengedError(err.message))
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
            })
        }
    }
}

export const settled = (settle) => {
    return {
        type: types.SETTLED_REQUEST,
        payload: settle,
    };
};

export const settledSuccess = (settle) => {
    return {
        type: types.SETTLED_REQUEST_SUCCESS,
        payload: settle,
    };
};

export const settledError = (error) => {
    return {
        type: types.SETTLED_REQUEST_FAILED,
        payload: error,
    };
};

export const settledApi = (data) => {
    return (dispatch) => {
        try {
            settledData(data).then((res) => {
                if (res&&res.data&&res.data.status === true) {
                    const settledResData = res.data.data;
                    dispatch(settledSuccess(settledResData))
                } else {
                    dispatch(settledError())
                }
            })
        } catch (err) {
            dispatch(settledError(err.message))
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
            })
        }
    }
}

// settle insitated get records
export const ledgerSettledInitSuccess = (ledger) => {
    return {
        type: types.SETTLED_INITIATED_REQUEST_SUCCESS,
        payload: ledger,
    };
};

export const ledgerSettledInitError = (error) => {
    return {
        type: types.SETTLED_INITIATED_REQUEST_FAILED,
        payload: error,
    };
};
export const ledgerSettledInitiateData = (status,applicationId) => {
    return (dispatch) => {
        try {
            ledgerApi(status,applicationId).then((res) => {
                if (res&&res.data&&res.data.status === true) {
                    const ledgerRes = res;
                    dispatch(ledgerSettledInitSuccess(ledgerRes))
                } else {
                    dispatch(ledgerSettledInitError())
                }
            })
        } catch (err) {
            dispatch(ledgerSettledInitError(err.message))
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err,
            })
        }
    }
}