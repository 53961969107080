import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import Swal from 'sweetalert2';
import { FILE_SIZE_LIMIT, TRANSACTION_UPLOAD_EXCEL_LINK } from "../../../config";
import { formatAmount } from "../../../services/CommonFunction";

const TransactionUpload = ({setRecordList, setSelectedFile}) => {
  const headerArray = [ "Sort Code*", "Account Number*", "Date*", "Description*", "Currency*", "Currency Debit*", "Currency to GBP Exchange*", "GBP Debit (Received)*",
    "Sort Code", "Account Number", "Date", "Description", "Currency", "Currency Debit","Currency to GBP Exchange", "GBP Debit (Received)"]; // optional: "Reference", "Currency Credit", "GBP Credit (Spent)" - list of headers
  const headerRequired = ["Sort Code", "Account Number", "Date", "Description", "Currency", "Currency Debit","Currency to GBP Exchange", "GBP Debit (Received)"]; // 8 - headers in excel-sheet
  const ALLOWED_FILE = ["application/vnd.ms-excel","application/msexcel", "application/xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
  const [errorMsg, setErrorMsg] = useState('');
  let fileData = [];

  const handleFiles = (e) => {
    setErrorMsg('');
    setRecordList([]);
    fileData = [];
    setSelectedFile(null);
    const file = e.target.files[0];
    const type = file.type.toLowerCase();
    if (!ALLOWED_FILE.includes(type)) { setErrorMsg(file.name + ' file type is not allowed.'); return; }
    if (file.size > FILE_SIZE_LIMIT) { setErrorMsg(file.name + ' file size is more than 1 MB.'); return; }

    let isValidFile = false;
    var filereader = new FileReader();
    filereader.readAsArrayBuffer(file);
    filereader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy;@'}); // { type: "array" }
        if(workbook.SheetNames && workbook.SheetNames.length > 0) {
          for(let i=0; i< workbook.SheetNames.length; i++) {
            const sheetName = workbook.SheetNames[i];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, {raw:false});
            const isValidHeader = await validateHeaders(json, sheetName);
            isValidFile = isValidHeader;
          }
          if(isValidFile) {
            setSelectedFile(file);
            setRecordList(fileData);
          }
        } else { setErrorMsg(file.name + ' file no sheets found.'); }
    };
    e.target.value=null;
  };

  const validateHeaders = async (data, sheetName) => {
    const headArr = [];
    if(data && data.length > 0) {
      await setJsonToData(data, sheetName);
      for (var key in data[0]) {
        if(key && headerArray.includes(key)) {
          key = key.replace(/\*$/,"");
          headArr.push(key);
        }
      }
      const missingCol = [];
      const dk = await headerRequired.map((h) => { if(!headArr.includes(h)) { missingCol.push(h); } });
      if(missingCol && missingCol.length > 0) {
        setErrorMsg(`Excel file template is not valid (Sheet : ${sheetName} - missing or empty columns '${missingCol.toString()}').`);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `Excel file template is not valid (Sheet : ${sheetName} - missing or empty columns '${missingCol.toString()}').`
        });
      }
      return headArr.length === headerRequired.length;
    } else {
      return false;
    }
  }
  const setJsonToData = async (data, sheetName) => {
    const recList = [];
    for(let i=0;i<data.length;i++) {
      const obj = {sort_code:'',account_no:'',date:'',description:'',ref_no:'',currency:'',currency_debit:'0.00',currency_credit:'0.00',currency_rate:'0.0',debit:'0.00',credit:'0.00'};
      if(data[i]) { // first column & other column
        if(data[i] && (data[i]['Sort Code'] || data[i]['Sort Code*'])) { obj['sort_code'] = data[i]['Sort Code'] || data[i]['Sort Code*']; }
        if(data[i] && (data[i]['Account Number'] || data[i]['Account Number*'])) { obj['account_no'] = data[i]['Account Number'] || data[i]['Account Number*']; }
        if(data[i] && (data[i]['Date'] || data[i]['Date*'])) { obj['date'] = data[i]['Date'] || data[i]['Date*']; }
        if(data[i] && (data[i]['Description'] || data[i]['Description*'])) { obj['description'] = data[i]['Description'] || data[i]['Description*']; }
        if(data[i] && (data[i]['Reference'] || data[i]['Reference*'])) { obj['ref_no'] = data[i]['Reference'] || data[i]['Reference*']; }
        if(data[i] && (data[i]['Currency'] || data[i]['Currency*'])) { obj['currency'] = (data[i]['Currency'] || data[i]['Currency*'] || '').toUpperCase(); }
        if(data[i] && (data[i]['Currency Debit'] || data[i]['Currency Debit*'])) {
          obj['currency_debit'] = ((data[i]['Currency'] || data[i]['Currency*'] || '').toUpperCase() == 'USD' ? '$': (data[i]['Currency'] || data[i]['Currency*'] || '').toUpperCase() == 'EUR'?'€':'£') +formatAmount(data[i]['Currency Debit'] || data[i]['Currency Debit*']); 
        }
        if(data[i] && (data[i]['Currency Credit'] || data[i]['Currency Credit*'])) {
          obj['currency_credit'] = ((data[i]['Currency'] || data[i]['Currency*'] || '').toUpperCase() == 'USD' ? '$': (data[i]['Currency'] || data[i]['Currency*'] || '').toUpperCase() == 'EUR'?'€':'£') +formatAmount(data[i]['Currency Credit'] || data[i]['Currency Credit*']);
        }
        if(data[i] && (data[i]['Currency to GBP Exchange'] || data[i]['Currency to GBP Exchange*'])) { obj['currency_rate'] = data[i]['Currency to GBP Exchange'] || data[i]['Currency to GBP Exchange*']; }
        if(data[i] && (data[i]['GBP Debit (Received)'] || data[i]['GBP Debit (Received)*'])) {
          obj['debit'] = '£' + formatAmount(data[i]['GBP Debit (Received)'] || data[i]['GBP Debit (Received)*']);
        }
        if(data[i] && (data[i]['GBP Credit (Spent)'] || data[i]['GBP Credit (Spent)*'])) {
          obj['credit'] = '£' + formatAmount(data[i]['GBP Credit (Spent)'] || data[i]['GBP Credit (Spent)*']);
        }
      }
      recList.push(obj);
    }
    fileData.push({sheetName, record: recList});
  }

  // Input File Reference & click event
  const hiddenFileInput = useRef(null);
  const handleBtnClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <div style={{margin: '20px 0'}}>
      <button type="button" className="btn btn-primary" onClick={handleBtnClick} style={{borderRadius: 4, opacity: 1, background: '#0071F7', marginRight: 10}}>
        Upload Transaction File
      </button>
      <a href={TRANSACTION_UPLOAD_EXCEL_LINK} download target="_blank" className="btn btn-default" style={{borderRadius: 4,border: '1px solid #0071F7', opacity: 1, background: '#ffffff'}}>
        Download Sample File
      </a>
      {/* <button type="button" className="btn btn-default" style={{borderRadius: 4,border: '1px solid #0071F7', opacity: 1, background: '#ffffff'}}>
        Download Sample File
      </button> */}
      <input
        type="file"
        className="input-field"
        accept=".csv, .xlsx, .xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleFiles}
        ref={hiddenFileInput}
        style={{display: 'none'}}
      />
      <div style={{color:'red', marginTop:10}}>{errorMsg}</div>
    </div>
  );
};

export default TransactionUpload;
