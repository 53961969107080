import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Swal from "sweetalert2";
import ClipLoader from "react-spinners/ClipLoader";
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { getYapilyInstitutionApi } from '../../config';
import { HttpCall } from '../../services/UseHttps';
import { ErrorHandler } from '../../components/Auth/errorHandler';
import SearchSelectYapily from './SearchSelectYapily';
import YapilyAuth from './YapilyAuth';
import { useSelector } from 'react-redux';
import YapilyConnect from './YapilyConnect';
import { useParams } from 'react-router-dom';
// import YapilyAccounts from './YapilyAccounts';
import YapilyDashboard from './YapilyDashboard';

const YapilyPage = (props) => {
    const userData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.user_obj);
    const profileData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);
    const yapilyStatus = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.yapily_obj);
    const urlParam = useParams();
    const [page, setPage] = useState(urlParam?.page);
    const [currState, setCurrState] = useState({yapilyBtn:false, institution:false, auth:false, consentData:{}, accounts: false, dashboard: false});
    const [loading, setLoading] = useState(true);
    const [bankList, setBankList] = useState([]);
    useEffect(() => {
      document.title = props.title;
      const instiId = localStorage.getItem('intId');
      const offerStatus = profileData && profileData?.offer && profileData?.offer?.status;
      if(page==='dashboard' && (yapilyStatus && yapilyStatus?.verification_status && yapilyStatus?.verification_status!==null) && offerStatus === 'accepted') {
        if((yapilyStatus.verification_status?.institution_count <= 0) && (userData?.parent===1) && (yapilyStatus?.yapily_integration_is_active)) {
          setCurrState({yapilyBtn: false, institution:true, auth:false, consentData:{}, accounts: false, dashboard: false});
        } else {
          setCurrState({yapilyBtn: false, institution:false, auth:false, consentData:{}, accounts: false, dashboard: true});
        }
      }
      /*else if(page==='accounts' && userData?.parent && offerStatus === 'accepted') {
        setCurrState({yapilyBtn: false, institution:false, auth:false, consentData:{}, accounts: true, dashboard: false});
      }*/
      else if(page==='auth' && instiId && userData?.parent && offerStatus === 'accepted') {
        setCurrState({yapilyBtn: false, institution:false, auth:true, consentData:{}, accounts: false, dashboard: false});
      }
      else if(yapilyStatus && yapilyStatus?.verification_status === null && offerStatus === 'accepted') {
        setCurrState({yapilyBtn: true, institution:false, auth:false, consentData:{}, accounts: false, dashboard: false});
      } else {
        if((userData?.parent) && (yapilyStatus?.yapily_integration_is_active)) {
          setCurrState({yapilyBtn: false, institution:true, auth:false, consentData:{}, accounts: false, dashboard: false});
        } else {
          setCurrState({yapilyBtn: false, institution:false, auth:false, consentData:{}, accounts: false, dashboard: true});
        }
      }
      setLoading(false);
    }, [yapilyStatus]);
    
    useEffect(() => {
      if(currState&&currState.institution) {
        getYapilyBanks();
      }
    }, [currState]);

    // get institutions with yapily
    const getYapilyBanks = () => {
      try {
          setLoading(true);
          HttpCall(`${getYapilyInstitutionApi}`, "GET").then((res) => {
              if(res && (res.status === 200 || res.status === 201) && res.data && res.data.status && res.data.data) {
                setBankList(res.data.data?.institutions?res.data.data?.institutions:[]);
              } else {
                Swal.fire({icon: 'error',title: 'Error', text: res.data&&res.data.error?res.data.error:'Fails to fetch yapily banks.'});
                setBankList([]);
              }
              setLoading(false);
          }).catch((error) => {
              setLoading(false);
              setBankList([]);
              ErrorHandler(error);
          });
      } catch (error) {
          Swal.fire({icon: 'error',title: 'Error',
                  text: error.response && error.response.data && error.response.data.error ?  error.response.data.error : 'Fails to create yapily user.'
              });
          setLoading(false);
      }
  }

    return (
      <>
        <NavBar />

        <div  className="bg-light" style={{minHeight: "75vh"}}>
            <div className="container">
                <div className="row py-3">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Tabs className="dashboard-tabs">
                            <TabList>
                                <Tab style={{ width: "100%" }}> Open Banking Integration </Tab>
                            </TabList>
                            <TabPanel>
                                <>
                                { loading ? 
                                    <ClipLoader size={120} css={{display: "block", margin: "0 auto", borderColor: "green"}} color="green" />
                                  : 
                                  currState.yapilyBtn ?
                                    <YapilyConnect email={userData?.email} isParent={userData?.parent} isYapActive={yapilyStatus?.yapily_integration_is_active} yapilyError={yapilyStatus?.yapily_integration_status_reason} setCurrState={setCurrState} setLoading={setLoading}/>
                                  : currState.institution ? 
                                    <SearchSelectYapily isParent={userData?.parent} bankList={bankList} isYapActive={yapilyStatus?.yapily_integration_is_active} setCurrState={setCurrState} userId={userData?.user_id} setLoading={setLoading}/>
                                  : currState.auth ? 
                                    <YapilyAuth currState={currState} setCurrState={setCurrState} setLoading={setLoading}/> 
                                  // : currState.accounts ? 
                                  //   <YapilyAccounts isYapActive={yapilyStatus?.yapily_integration_is_active} currState={currState} setCurrState={setCurrState} setLoading={setLoading}/>
                                  : currState.dashboard ? 
                                    <YapilyDashboard fromYapilyTab={true} isParent={userData?.parent} isYapActive={yapilyStatus?.yapily_integration_is_active} yapilyError={yapilyStatus?.yapily_integration_status_reason} setCurrState={setCurrState}/> 
                                  : ''
                                  }
                                </>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
      </>
    )
}

export default YapilyPage;