import { MDBDataTableV5 } from "mdbreact";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listChallengedTransactionData } from "../../redux/action/listChallengedTransaction";
import ClipLoader from "react-spinners/ClipLoader";
import { formatAmount } from "../../services/CommonFunction";
const OutStanding = (props) => {

  const historyTransaction = props.transactionTabs;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const applicationId = useSelector(state => state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj && state.profileInfo.profileData.application_obj.application_id);
  useEffect(() => {
    if (applicationId && historyTransaction === 'Outstanding') {
      dispatch(listChallengedTransactionData(setLoading, applicationId));
    }
  }, [applicationId, historyTransaction]);
  // useEffect(() => {
  //   if (historyTransaction === 'Outstanding') {
  //     dispatch(listChallengedTransactionData(setLoading, applicationId));
  //   }
  // }, [historyTransaction]);

  const optionsChallenge = [
    { value: null, label: 'Select the reason for challenge' },
    { value: 'intercompany', label: 'Intercompany Transfer' },
    { value: 'loanreceipts', label: 'Loan Receipts' },
    { value: 'securitysale', label: 'Security Sale' },
    { value: 'insurancereceipt', label: 'Insurance Receipt' },
    { value: 'refund', label: 'Refund' },
    { value: 'vatrefund', label: 'VAT Refund' },
    { value: 'rdtax', label: 'R&D Tax Refund' },
    { value: 'bankinterest', label: 'Bank Interest' },
    { value: 'others', label: 'Other' },
  ]

  const [mdbColHeaders, setMdbColHeaders] = useState([
    {
      label: "Source",
      field: "source",
      sort: "asc",
      width: 100,
    },
    {
      label: "Sort Code",
      field: "sort_code",
      sort: "asc",
      width: 150,
    },
    {
      label: "Account Number",
      field: "account_number",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 150,
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 150,
    },
    {
      label: "Reference",
      field: "reference",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency",
      field: "currency",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency Debit",
      field: "currency_debit",
      sort: "asc",
      width: 150,
    },
    // {
    //   label: "Currency Credit",
    //   field: "currency_credit",
    //   sort: "asc",
    //   width: 150,
    // },
    {
      label: "Currency to GBP Exchange",
      field: "gbp_exchange_rate",
      sort: "asc",
      width: 150,
    },
    {
      label: "GBP Debit (Received)",
      field: "debit_received",
      sort: "asc",
      width: 150,
    },
    {
      label: "Reason Type",
      field: "challenged_reason_type",
      sort: "asc",
      width: 100,
    },
    {
      label: "Reason",
      field: "challenged_reason",
      sort: "asc",
      width: 270,
    },
  ]);
  const [datatable, setDatatable] = useState({
    columns: mdbColHeaders,
    rows: []
  });
  const listOfChallange = useSelector((state) =>
    state && state.challengedTransaction && state.challengedTransaction.listChallengedTransactions
  );
  useEffect(() => {
    if (listOfChallange) {
      let tmpLists = [];
      for(let item of listOfChallange) {
        let challengeType = optionsChallenge.filter((oc) => {
          return oc.value === item.challenged_reason_type;
        });
        if(challengeType.length>0){
          item.challenged_reason_type = challengeType[0].label;
        }
        item.currency=item.currency?`${(item.currency).toUpperCase()}`:'';
        item.currency_debit=item.currency_debit?`${(item.currency).toUpperCase()=='USD'?'$':(item.currency).toUpperCase()=='EUR'?'€':'£'}`+formatAmount(item.currency_debit):'0';
        // item.currency_credit=item.currency_credit?`${item.currency=='USD'?'$':'£'}`+formatAmount(item.currency_credit):'';
        item.debit_received=item.debit_received?'£'+formatAmount(item.debit_received):'0';
        item.source = item['source'] === "excel-upload" ? <div style={{ width: "100%", textAlign: "center" }}> <i className="fas fa-file-excel" data-bs-placement="top" title="Excel Upload" style={{color:'green',fontSize:20}}></i> </div> :
                      item['source'] === "yapily" ? <div style={{ width: "100%", textAlign: "center" }}> <i className="fas fa-bank" data-bs-placement="top" title="Yapily" style={{color:'#1574b3',fontSize:20}}></i> </div> :'-'
        tmpLists.push(item);
      }
      setDatatable({ ...datatable, rows: tmpLists?tmpLists:[] });
    } else {
      if (listOfChallange === null) { setDatatable({ ...datatable, rows:[]}); }
    }
  }, [listOfChallange]);
  return (
    <>
      <div className="mb-4">
        <div className="card">
          <div className="card-body">
            <h5><b>Outstanding Challenges</b></h5>
            {datatable&&datatable.rows&&datatable.rows.length === 0 && (
              <div className="dierector-content mt-3 "
                style={{
                  borderRight: "15px solid #003056",
                  borderLeft: "15px solid #003056",
                }}
              >
                <p className="table-font-color m-0">
                  <b>{applicationId ? 'Outstanding challenges records not found.' : 'Please Select Client'}</b>
                </p>
              </div>
            )}
            {datatable&&datatable.rows&&datatable.rows.length > 0 &&
              (!loading ? (
                <MDBDataTableV5
                  striped bordered hover responsive
                  data={datatable} searchTop searchBottom={false} // searching={false}
                  fullPagination entriesOptions={[2, 5, 10, 20, 50, 100]}
                  paging={!(datatable.rows.length <= "4")}
                // className={`${
                //   datatable.rows.length <= "4" ? "hide-pagination" : ""
                // } ${showReason?'':'hide-reason'}`}
                />
              ) : (
                <>
                  <ClipLoader size={150} color="green"
                    css={{
                      display: "block", margin: "0 auto", borderColor: "green",
                    }}
                  />
                </>
              ))}
            {/* <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Invoice Number</th>
                                    <th>Account Number</th>
                                    <th>Supplier</th>
                                    <th>Description</th>
                                    <th>Credit</th>
                                    <th>Balance</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>12 May 2022</td>
                                    <td>234232</td>
                                    <td>5678923145</td>
                                    <td>Acme Co</td>
                                    <td></td>
                                    <td>£20,500.34</td>
                                    <td>£541,678.05</td>
                                    <td>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-3">Settle</button>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-2">Resolve</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11 May 2022</td>
                                    <td>234231</td>
                                    <td>5678923145</td>
                                    <td>Westfield</td>
                                    <td></td>
                                    <td>£20,500.34</td>
                                    <td>£541,678.05</td>
                                    <td>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-3">Settle</button>
                                        <button type="button"
                                            className="btn btn-custom btn-custom-no-white-shadow btn-custom-2">Resolve</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
          </div>

        </div>


      </div>
    </>
  )
};

export default OutStanding;