import React from "react";
import salesPayment from "../img/sales-repayment.svg";

const FlexibleFinance = () => {
  return (
    <>
      <section className="flexible-finance">
        <div className="container flexible-finance-container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="flexible-finance-left">
                <h1>Flexible repayments</h1>
                <h2>No fixed repayment date</h2>
                <div className="d-flex float-end flexible-image-div">
                  <div className="flexible-image-text">
                    <h1>Sales</h1>
                    <h2>Repayments</h2>
                  </div>
                  <div>
                    <img src={salesPayment} alt="SalePayment" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-self-center">
              <div className="flexible-finance-right">
                <h1>FLEXIBLE FINANCE</h1>
                <p>
                  Our revenue share model means you only repay us when you
                  generate sales. We’ll simply take a fixed percentage of your
                  daily sales until we have recouped the capital + flat fee. If
                  your revenues slow or stop, so do repayments.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FlexibleFinance;
