import { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormB from 'react-bootstrap/Form';
import * as Yup from "yup";
import { addSupportUserApi, supportUsersApi, supportUserStatusApi } from "../../config";
import { HttpCall } from "../../services/UseHttps";
import { ErrorHandler } from "../../components/Auth/errorHandler";
import { toTitleCase } from "../../services/CommonFunction";

const SupportUsers = ({user, appliData}) => {
    const [modalShow, setModalShow] = useState(false);
    const [modalStatusShow, setModalStatusShow] = useState(false);
    const [statusObj, setStatusObj] = useState({});
    const [loading, setLoading] = useState(false);
    // API : /support-user
    // "support_user_obj":[{"user_id":"","first_name":"","last_name":"","email":"","phone_number":"","status":0,"is_verified":0}]
    const mdbColHeaders = [
        {
          label: "First Name",
          field: "first_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Last Name",
          field: "last_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 150,
        },
        {
          label: "Mobile",
          field: "phone_number",
          sort: "asc",
          width: 150,
        },
        {
          label: "Status",
          field: "status_ui",
          sort: "asc",
          width: 150,
        },
        {
          label: "Verified",
          field: "verified_ui",
          sort: "asc",
          width: 150,
        },
        {
          label: "Actions",
          field: "action",
          sort: "asc",
          width: 150,
        }
    ];
    const [tableData, setTableData] = useState([]);
    const [userTableData, setuserTableData] = useState({columns: mdbColHeaders,rows: []});
    const [enabledUser, setEnabledUser] = useState(0);

    const userSchema = Yup.object().shape({
        fname: Yup.string().required('First name is required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for first name"),
        lname: Yup.string().required('Last name is required').matches(/^[aA-zZ\s]{1,50}$/, "Only alphabets are allowed with 50 letters for last name"),
        email: Yup.string().required('Email address is required').email('Email address is invalid')
    });

    useEffect(() => {
        getSupportUsers();
    },[])
    useEffect(() => {
        if(tableData && tableData.length > 0) {
            let cnt=0;
            const filtData = tableData.filter((el) => {
                let act = '';
                if (el.first_name) { el.first_name = toTitleCase(el.first_name||'') }
                if (el.last_name) { el.last_name = toTitleCase(el.last_name||'') }
                if (el.is_verified) { el.verified_ui = <span style={{color: 'green'}}> Yes </span>; } else { el.verified_ui = <span style={{color: 'red'}}> No </span>; }
                if (el.status) {
                    cnt++;
                    act = user&&user.parent?<div style={{ width: "100%", textAlign: "center" }}>
                    <FormB.Check type="switch" id="custom-switch" // label="On"
                        checked={true} onChange={(e) => openStatusModel('Disable',el) }/>
                    </div>:'';
                    el.status_ui = <span style={{color: 'green'}}> Enabled </span>;
                } 
                else {
                    act = user&&user.parent?<div style={{ width: "100%", textAlign: "center" }}>
                    <FormB.Check type="switch" id="custom-switch" // label="Off"
                        checked={false} onChange={(e) => openStatusModel('Enable',el) }/>
                    </div>:'';
                    el.status_ui = <span style={{color: 'red'}}> Disabled </span>;
                }
                el.action = act;
                return el;
            });
            setuserTableData({ ...userTableData, rows: filtData });
            setEnabledUser(cnt);
        } else {
            setuserTableData({ ...userTableData, rows:[]});
            setEnabledUser(0);
        }
    },[tableData]);
    useEffect(()=>{
        let cnt=0;
        if(tableData && tableData.length > 0) {
            tableData.filter((el) => {
                if (el.status) {
                    cnt++;
                }
            });
            setEnabledUser(cnt);
        }
    },[enabledUser]);

    // get support user list
    const getSupportUsers = () => {
        try {
            setLoading(true);
            HttpCall(`${supportUsersApi}`, "GET").then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data && res.data.data 
                    && res.data.data.support_user_obj && res.data.data.support_user_obj.length > 0) {
                    setTableData(res.data.data.support_user_obj);
                } else {
                    setTableData([]);
                }
                setLoading(false);
            }).catch((error) => {
                setTableData([]);
                setLoading(false);
                ErrorHandler(error);
            });
        } catch (ex) {
            setTableData([]);
            setLoading(false);
        }
    }

    const openModel = () => {
        setModalShow(true);
    }
    const closeModel = () => {
        setModalShow(false);
        setLoading(false);
    }
    const handleSubmit = (e) => {
        try {
            if(enabledUser < 5) {
                setLoading(true);
                const userObj = {"first_name": e.fname, "last_name": e.lname, "email": e.email };
                HttpCall(`${addSupportUserApi}`, "POST", userObj).then((res) => {
                    if(res && (res.status === 200 || res.status === 201) && res.data) {
                        Swal.fire({icon: 'success',title: 'Success', text: res.data&&res.data.message?res.data.message:'Support user added successfully.'});
                        closeModel();
                        getSupportUsers();
                    } else {
                        Swal.fire({icon: 'error',title: 'Error', text: res.data&&res.data.message?res.data.message:'Failed to add support user.'});
                    }
                    setLoading(false);
                }).catch((error) => {
                    setLoading(false);
                    ErrorHandler(error);
                    if(error && error.response&& error.response.status === 400 || error.response.status === 404) {
                        Swal.fire({icon: 'error',title: 'Error', text: error.response.data&&error.response.data.message?error.response.data.message:'Support user adding is failed.'});
                    }
                });
            } else {
                Swal.fire({icon: 'error',title: 'Error', text: `Maximum 5 support user can be Enables.`});
            }
        } catch (ex) {
            setLoading(false);
        }
    }

    // open-close Status model
    const openStatusModel = (title,usr) => {
        setStatusObj({action:title, name:usr.first_name, email: usr.email});
        setModalStatusShow(true);
    }
    const closeStatusModel = () => {
        setModalStatusShow(false);
        setStatusObj({});
        setLoading(false);
    }
    // support user's Enable/Disable status
    const handleStatusChanges = () => {
        try {
            const status = statusObj.action === 'Disable' ? 0 : 1;
            if(status && enabledUser >= 5) { Swal.fire({icon: 'error',title: 'Error', text: `Maximum 5 support user can be Enables.`}); return; }
            setLoading(true);
            HttpCall(`${supportUserStatusApi}`, "POST", {"email": statusObj.email, "is_active": status }).then((res) => {
                if(res && (res.status === 200 || res.status === 201) && res.data) {
                    Swal.fire({icon: 'success',title: 'Success', text: res.data&&res.data.message?res.data.message:`Support user ${statusObj.action} successfully.`});
                    closeStatusModel();
                    getSupportUsers();
                } else {
                    Swal.fire({icon: 'error',title: 'Error', text: `Failed to ${statusObj.action} support user.`});
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                ErrorHandler(error);
                if(error && error.response&& error.response.status === 400 || error.response.status === 404) {
                    Swal.fire({icon: 'error',title: 'Error', text: error.response.data&&error.response.data.message?error.response.data.message:'Support user status change failed.'});
                }
            });
        } catch (ex) {
            setLoading(false);
        }
    }
    
    return (
        <>
        {/* <div className="row"> */}
            <div className="col-xs-12 col-sm-12">
                <div className="card mt-4">
                    <div className="card-body">
                        <div className="row">
                            {user&&user.parent && appliData&&appliData.offer&&appliData.offer.status==="accepted"?<>
                                <div className="col-12">
                                    {enabledUser>=5 && <span className="text-danger"><small>*Maximum 5 support user can be Enables.</small></span> }
                                    <button className='btn btn-primary' style={{float:'right', backgroundColor:'#0d6efd', top:'-5px',position:'relative'}}
                                        onClick={openModel} title="Add User" disabled={enabledUser>=5}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                                <hr /></>
                                : ''
                            }
                            <div className="col-12">
                                <MDBDataTableV5
                                    striped bordered hover
                                    data={userTableData}  searchBottom={false} //searchTop
                                    fullPagination entriesOptions={[5, 10, 20, 50, 100]}
                                    paging={!(userTableData&&userTableData.rows&&userTableData.rows.length <= "4")}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* </div> */}

        {/* model for add User */}
        <Modal show={modalShow} onHide={closeModel} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton onHide={closeModel }>
            <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card ">
                <div className="card-body">
                    {/* <ClipLoader size={150} css={{display: "block", margin: "0 auto",borderColor: "green"}} color="green" /> */}
                    <Formik initialValues={{fname: "", lname: "",email: ""}} validationSchema={userSchema}
                        onSubmit={(values) => { handleSubmit(values) }} >
                        {({ touched, errors, values, handleChange}) =>
                            !loading ? (
                                <div>
                                    <Form className="custom-form">
                                        <div className="mb-3">
                                            <Field autoFocus={true} type="text" name="fname"
                                                value={values.fname} onChange={handleChange}
                                                placeholder="First Name" autoComplete="off" id="fname"
                                                aria-describedby="businessHelp" className={`mt-2 form-control
                                                ${touched.fname && errors.fname ? "is-invalid" : ""}`}
                                            />
                                            <ErrorMessage component="div" name="fname" className="invalid-feedback"/>
                                        </div>
                                        <div className="mb-3">
                                            <Field type="text" name="lname"
                                                value={values.lname} onChange={handleChange}
                                                placeholder="Last Name" autoComplete="off" id="lname"
                                                aria-describedby="businessHelp" className={`mt-2 form-control
                                                ${touched.lname && errors.lname ? "is-invalid" : ""}`}
                                            />
                                            <ErrorMessage component="div" name="lname" className="invalid-feedback"/>
                                        </div>
                                        <div className="mb-3">
                                            <Field type="email" name="email"
                                                value={values.email} onChange={handleChange}
                                                placeholder="Email Address" id="email" autoComplete="off"
                                                className={`mt-2 form-control
                                                ${touched.email && errors.email ? "is-invalid" : ""}`}
                                            />
                                            <ErrorMessage component="div" name="email" className="invalid-feedback"/>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary bg-primary w-100 Submit text-white text-decoration-none"
                                        >
                                            {loading ?
                                                <><ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" /></>
                                                : <>Save</>
                                            }

                                        </button>
                                    </Form>
                                </div>
                            ) : (
                                <>
                                    <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                </>
                            )
                        }
                    </Formik>
                </div>
            </div>
            </Modal.Body>
        </Modal>
        {/* model for status change of support user */}
        <Modal show={modalStatusShow} onHide={closeStatusModel} size="lg" centered
            aria-labelledby="contained-modal-title-vcenter">
            {statusObj.action === 'Enable' && enabledUser >= 5 && 
            <>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Limit exceeded to enable support user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-danger">Maximum 5 support user can be Enables.</div>
                </Modal.Body>
            </>
            }
            {statusObj.action && (enabledUser < 5 || statusObj.action === 'Disable') && 
            <>
                <Modal.Header closeButton onHide={closeStatusModel }>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {`Are you sure want to ${(statusObj.action).toLowerCase()} '${statusObj.name}' support user?`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div style={{textAlign:'center'}}>
                                    {loading ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                        : <>
                                            <button type="button" className="btn btn-primary bg-primary Submit text-white" onClick={handleStatusChanges}
                                            style={{marginRight:10}}>Yes</button>
                                            <button type="button" className="btn btn-danger" onClick={closeStatusModel}>No</button>
                                        </>
                                    }
                        </div>
                </Modal.Body>
            </>
            }
        </Modal>
        </>
    )
};

export default SupportUsers;