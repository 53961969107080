import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { MODULR_MONEY_SAFE_LINK } from "../config";
// import glosser from "../img/4691568_glassdoor_icon.png"

const Footer = () => {
  const [year, setYear] = useState('');
  useEffect(() => {
    const date = new Date();
    setYear(date.getFullYear());
  }, []);

  return (
    <>
      <footer>
        <div className="bottom-footer">
          <div className="container">
            <div style={{borderBottom:'1px solid black', textAlign:'center'}}>
              <p style={{fontSize: 10}}>BarMar Finance Limited is a distributor of Modulr FS Limited,
                a company registered in England and Wales with company number 09897919,
                which is authorised and regulated by the Financial Conduct Authority as
                an Electronic Money Institution (Firm Reference Number: 900573) for the issuance of electronic
                money and payment services. Your account and related payment services are provided by Modulr FS Limited.
                Whilst Electronic Money products are not covered by the Financial Services Compensation Scheme (FSCS) your
                funds will be held in one or more segregated accounts and the full value safeguarded in line with
                the Electronic Money Regulations 2011 - for more information please <a href={MODULR_MONEY_SAFE_LINK} target='_blank'>click here</a>
              </p>
            </div>
            <div className="d-flex" style={{justifyContent:'center',paddingTop:30, textAlign:'center', fontSize:13}}>
              <p className="mb-0">&copy; {year} Barlow Marshall Limited. All rights reserved.</p>
              <ul className="p-0 m-0 list-unstyled footer-social-media-icons">
                {/* <li>

                  <Link to="/">
                    <i aria-hidden className="fa-brands fa-twitter"></i>
                </Link>

                </li>
                <li>

                  <Link to="/">
                    <i aria-hidden className="fa-brands fa-linkedin-in"></i>
                </Link>

                </li>
                <li>

                  <Link to="/">
                    <i aria-hidden className="fa-brands fa-instagram"></i>
                </Link>

                </li>
                <li>

                  <Link to="/">
                    <img src={glosser} height={20} width={20} alt="glosser" />
                </Link>

                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
};

export default Footer;