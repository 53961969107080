import MainHeader from "../components/Header";
import Footer from "../components/Footer";
import './HomePageResponsive.css'
import { useEffect } from "react";

const TermsConditions = (props) => {
  useEffect(() => {
    document.title = props.title;
    window.scroll(0,0);
  }, []);
  return (
    <div>
      <MainHeader boxShadow={true}/>
      <section className="why-Barlow-Marshall" style={{ marginTop: 80, backgroundColor: '#fff', height:'100%',overflow:'auto' }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{marginTop:30}}>
              <h1 className="">Barlow Marshall Terms And Conditions</h1>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <article style={{ marginTop: 35 }}>
                <div style={{ textAlign: "center" }}>
                  <div style={{ fontSize: 20, fontWeight: 700 }}>INTRODUCED CLIENT SCHEDULE</div>
                  <div style={{ fontSize: 20, fontWeight: 700 }}>MODULR INTRODUCED CLIENT TERMS OF BUSINESS</div>
                </div>
                <div>
                  <div style={{ fontSize: 20, fontWeight: 700 }}>BACKGROUND</div>
                </div>
                <div className="entry-content">
                  <p>Modulr is a provider of Modulr Products (as described to you by Partner Platform), which includes the provision of an electronic money account for businesses and associated payment services. The electronic money account is provided by Modulr FS. These Introduced Client Terms of Business govern the Modulr Products that Modulr agrees to provide to the Introduced Client.</p>
                  <p>These Introduced Client Terms of Business (including all the attached Schedules), together with the Modulr Account Terms and Conditions set out the terms on which the Modulr Products are provided and constitute the Agreement between Modulr, Modulr FS and the Introduced Client.</p>
                  <div style={{ fontSize: 20, fontWeight: 700 }}>THE PARTIES AGREE AS FOLLOWS:</div>
                  <ol>
                    <li><strong>Interpretation</strong>
                      <ol>
                        <li>In these Introduced Client Terms of Business: (a) a reference to a clause is a reference to a clause in these Introduced Client Terms of Business; (b) headings are for reference only and shall not affect the interpretation of these Introduced Client Terms of Business; (c) the singular shall include the plural and vice versa; (d) a reference to a person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) and that person's personal representatives, successors and permitted assigns; (e) a reference to a party shall include its personal representatives, successors and permitted assigns; (f) reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted from time to time.</li>
                      </ol>
                    </li>
                    <li><strong>Modulr Products</strong>
                      <ol>
                        <li>Modulr will make available to the Introduced Client such products that are provided to the Partner Platform and, as described by Partner Platform in the application process.</li>
                        <li>The Introduced Client agrees to the Partner Platform providing all such information to Modulr who will check and verify the identity of the Introduced Client, its directors, beneficial owners and Authorised Users as required by law.</li>
                        <li>The Introduced Client acknowledges that a search of the electoral register may take place for anti-money laundering purposes on the individuals listed in clause 2.2 above.</li>
                        <li>The Introduced Client acknowledges that a “soft footprint” search may be placed on the electronic files of the individuals noted in clause 2.2 above by the Credit Reference Agencies and their personal details may be accessed by third parties for the specific purpose of anti-money laundering and countering the financing of terrorism (AML/CFT), identity verification and fraud prevention.</li>
                        <li>The Account and Cards (where applicable) are provided by Modulr FS to the Introduced Client in accordance with the Modulr Account Terms and Conditions. Modulr Products provided to the Introduced Client under this Agreement are for the sole use by the Introduced Client.</li>
                        <li>The Introduced Client can use the Account and Cards (where applicable) to make Transactions on the terms and conditions set out in the Modulr Account Terms and Conditions. A record of all Transactions relating to the Account can viewed on the Website or accessed via the Partner Platform (as applicable).</li>
                        <li>The Introduced Client shall promptly notify Customer Services as soon as it becomes aware login and security information enabling access to its Modulr Products have been lost, stolen or compromised.</li>
                        <li>From time to time Modulr may carry out additional checks on the Introduced Client, including the identity of its directors, beneficial owners and the nature of its business in accordance with its Due Diligence Procedure and as required by law.  Modulr may contact the Introduced Client or the Partner Platform (as applicable) for such purposes.  The Introduced Client agrees to provide such information as necessary.</li>
                        <li>The Introduced Client shall comply with all legislation and regulation as it applies to the Introduced Client. Any failure to comply with relevant legislation or regulation shall be considered a material breach of the Agreement and may result in Modulr discontinuing the provision of the Modulr Products as set out in clause 6.4.</li>
                        <li>The Introduced Client shall implement as appropriate Modulr's reasonable security recommendations it notifies to the Introduced Client from time to time.</li>
                      </ol>
                    </li>
                    <li><strong>Authorised Users</strong>
                      <ol>
                        <li>Access to the Modulr Products is restricted to individuals that have been designated by the Introduced Client as Authorised Users.</li>
                        <li>The Introduced Client must notify Modulr of all individuals it wishes to be an Authorised User.</li>
                        <li>Each Authorised User is permitted to access and use the Modulr Products in accordance with these Introduced Client Terms of Business.</li>
                        <li>The Introduced Client will be responsible for training its Authorised Users in the appropriate use of Modulr Products.</li>
                        <li>The Introduced Client shall ensure its Authorised Users
                          <ol>
                            <li>take all reasonable care to ensure Modulr Product access credentials, including login details to the Website, where applicable, are kept confidential to each Authorised User; and</li>
                            <li>do not share any information that would enable another party to access the Introduced Client's Account.</li>
                          </ol>
                        </li>
                        <li>The Introduced Client acknowledges and agrees that each Authorised User is authorised by the Introduced Client to act on its behalf. Modulr shall deem any instruction given by an Authorised User is an instruction given by the Introduced Client.</li>
                        <li>The Introduced Client will be responsible for timely notification to Modulr of any revocation of Authorised User access and will be liable for Transactions made, Fees incurred and use of Modulr Products by an Authorised User until Modulr has had one full Business Day to act on any received notice. This clause shall not apply to Introduced Clients accessing Modulr Products via the Partner Platform.</li>
                        <li>Where the Introduced Client accesses Modulr Products through a Partner Platform, such Partner Platform will be considered the Authorised User.  In this instance if additional Authorised Users are required they must be requested by the Partner Platform. The use of a Partner Platform to access the Modulr Products by the Introduced Client are set out in further detail below.</li>
                      </ol>
                    </li>
                    <li><strong>Accessing Modulr Products through a Partner Platform</strong>
                      <ol>
                        <li>In the event the Introduced Client utilizes a Partner Platform to access Modulr Products, the Introduced Client agrees and authorises the Partner Platform to instruct Modulr to access and use the Modulr Products on behalf of the Introduced Client, which shall include but not be limited to making Transactions, viewing and retrieving Transaction data, initiating refunds and closing the Account.</li>
                        <li>The Introduced Client acknowledges and agrees that Modulr shall have no liability whatsoever with respect to the performance, availability or quality of any Partner Platform.</li>
                        <li>The Introduced Client acknowledges and agrees to the following:
                          <ol>
                            <li>it must satisfy itself that its Platform Partner Agreement grants the Partner Platform all permission necessary to operate the Account on the Introduced Client's behalf;</li>
                            <li>the Platform Partner will be granted full access to operate the Introduced Client's Account as an Authorised User of the Introduced Client;</li>
                            <li>it is responsible for monitoring Partner Platform activities on its Account.  Any queries relating to such activities will be raised with the Partner Platform directly and settled between Partner Platform and the Introduced Client;</li>
                            <li>the Introduced Client has no recourse against Modulr for any act or omission of the Partner Platform with respect to its Account;</li>
                            <li>the Introduced Client understands it can only access its Account to make Transactions, review Transactions made or otherwise use Modulr Products through the service provided by the Partner Platform; and</li>
                            <li>it will only use the Account for the purpose set out in the Partner Platform Agreement.</li>
                          </ol>
                        </li>
                        <li>On receipt of notification by Modulr from the Partner Platform that it wishes to terminate this Agreement, this Agreement shall terminate.  Any funds in the Introduced Client's Account will be returned in accordance with the terms of the Modulr Account Terms and Conditions.</li>
                        <li>If the Introduced Client has any complaint or concern relating to the Account or other Modulr Products, such complaint or concern shall be raised directly to the Partner Platform, who shall deal with it in accordance with Modulr's Complaints Policy, a copy of which is available on request from the Partner Platform and on the Website.</li>
                      </ol>
                    </li>
                    <li><strong>Customer Services</strong>
                      <ol>
                        <li>The Introduced Client can contact Customer Services if it has any queries about the Modulr Products. Information may be requested from the Introduced Client, including but not limited to, its Authorised Users, Cardholders or Transaction information so that it can verify the identity of an Authorised User, the Cardholder and/or the Modulr Products provided to such Introduced Client.</li>
                        <li>Any information shared by the Introduced Client will be kept strictly confidential.  Where such information is provided in connection to a service provided by a third party, for example, the Account, then the Introduced Client's information will only be used in accordance with instructions of such third party and only for the purpose of providing Customer Services to the Introduced Client on behalf of such third party.</li>
                        <li>As part of Modulr's commitment to providing a quality customer service, its managers periodically monitor telephone communications between its employees and Introduced Clients to ensure that Modulr's high quality service standards are maintained. The Introduced Client .consents to such monitoring and recording of telephone communications and agrees to make its Authorised Users aware of such practice.   </li>
                      </ol>
                    </li>
                    <li><strong>Term and Termination </strong>
                      <ol>
                        <li>This Agreement shall commence on the date the Introduced Client receives confirmation from Modulr or the Partner Platform (where applicable) of its successful application for Modulr Products and shall continue until terminated by the Introduced Client, Partner Platform (if acting on behalf of the Introduced Client) or Modulr.</li>
                        <li>The Introduced Client or the Partner Platform (where applicable) may terminate this Agreement immediately by notifying Customer Services in writing by post or email.</li>
                        <li>Modulr may terminate this Agreement and close the Introduced Client's Account(s) by providing the Introduced Client with at least two months' notice.</li>
                        <li>Modulr may suspend or terminate this Agreement immediately if, for any reason, the Introduced Client (i) is unable to satisfy the Due Diligence Procedures, (ii) for breach of this Agreement, (iii) has provided false, incomplete or misleading information, (iv) has engaged in fraudulent, money laundering, terrorism financing or other illegal activity or we have reasonable suspicions in respect of same or (v) we are required to do so under any applicable law or regulation or at the direction of any regulatory, law enforcement or other competent authority.  Modulr shall notify you as soon as possible, unless prohibited by law, of such suspension or termination of the Agreement.</li>
                        <li>This Agreement will automatically terminate when all Accounts of the Introduced Client are closed (for any reason).</li>
                        <li>Modulr may terminate or suspend this Agreement in whole or in part immediately by giving written ‎notice to the Introduced Client if Modulr ceases to provide Cards pursuant to the provisions of Schedule 1.</li>
                        <li>On termination of this Agreement for any reason, any balance remaining in the Introduced Client's Account(s) shall be returned to the Introduced Client in accordance with the Modulr Account Terms and Conditions.  The Introduced Client shall pay immediately all outstanding Fees due (where applicable) under this Agreement and in the event of a negative balance in an Account, shall reimburse Modulr FS such amount equal to the negative balance.</li>
                      </ol>
                    </li>
                    <li><strong>Intellectual Property</strong>
                      <ol>
                        <li>The Introduced Client acknowledges all Intellectual Property Rights in the Modulr Products are owned by or provided under licence to Modulr.  Modulr grants the Introduced Client a non-exclusive, royalty-free licence for the duration of this Agreement to access and use the Modulr Products only for the purpose contemplated by this Agreement.</li>
                        <li>Nothing in this Agreement shall operate to create or transfer any Intellectual Property Right to the Introduced Client.</li>
                      </ol>
                    </li>
                    <li><strong>Force Majeure</strong>
                      <ol>
                        <li>Modulr and/or Modulr FS will not be liable for the non-performance or failure to provide any  part of the Modulr Products occurring as a result of any events that are beyond the reasonable control of Modulr, for example, but not limited to, fire, telecommunications or internet failure, utility failure, power failure, equipment failure, employment strife, riot, war, terrorist attack, non-performance of  third party suppliers, acts of God such as storm or lightening damage, or other causes over which Modulr and/or Modulr FS has no reasonable control.</li>
                      </ol>
                    </li>
                    <li><strong>Assignment Transfer and Subcontracting</strong>
                      <ol>
                        <li>The Modulr Products provided to the Introduced Client are personal to the Introduced Client.  The Introduced Client may not novate, assign or otherwise transfer this Agreement, any interest or right under this Agreement (in whole or in part) without the prior written consent of Modulr.</li>
                        <li>The Introduced Client agrees Modulr may, in its sole discretion, assign, or transfer some or all of its rights and obligations or delegate any duty of performance set out in the documents forming this Agreement. Modulr may subcontract any of its obligations under this Agreement.</li>
                        <li>In the event of any transfer of this Agreement by Modulr to another service provider; if the Introduced Client does not want to transfer to the new provider, the Introduced Client must notify Modulr of its objection in writing to Customer Services.  On receipt of such notification, Modulr it will terminate this Agreement.  Any balance remaining in the Introduced Client's Account(s) will be returned to the Introduced Client in accordance with the redemption procedure set out in the Modulr Account Terms and Conditions.</li>
                        <li>It is acknowledged by the Introduced Client that Modulr enters into this Agreement on its own behalf and as agent for and on behalf of Modulr FS with respect only to clauses 10 and 14 of these Introduced Client Terms of Business.</li>
                      </ol>
                    </li>
                    <li><strong>Liability</strong>
                      <ol>
                        <li>Nothing in this Agreement will operate to limit either party or its agent's liability with respect to fraud or for death or personal injury resulting from negligence, in either case whether committed by that party or its employees, agents or subcontractors.</li>
                        <li>Modulr and Modulr FS makes no warranty that access to and use of the Modulr Products will be uninterrupted or error free.</li>
                        <li>The Introduced Client acknowledges and agrees that Modulr and/or Modulr FS are not liable to the Introduced Client for any loss, liability or damages the Introduced Client suffers which result from, are related to, or in any way are connected with any fraud control, restriction measures or other measures implemented from time to time including as require, unless such loss, liability or damage is a direct result of Modulr and/or Modulr FS's fraud, gross negligence or willful misconduct in procuring the implementation of fraud control or purchase restriction measures that Modulr has expressly agreed in writing to procure for the Introduced Client.</li>
                        <li>Modulr and Modulr FS shall not be liable to the Introduced Client for any loss or damage the Introduced Client may suffer as a result of any act or omission of an Authorised User or Cardholder or an Authorised User's or Cardholder's use of or inability to use of the Modulr Products.</li>
                        <li>The Introduced Client agrees to indemnify Modulr and Modulr FS against any and all actions, claims, costs, damages, demands, expenses, liabilities, losses and proceedings Modulr and/or Modulr FS directly or indirectly incurs or which are brought against Modulr if the Introduced Client, or an Authorised User, or a Cardholder has acted fraudulently, been negligent or has misused a Modulr Product or any of the services provided under this Agreement.</li>
                        <li>Modulr and/or Modulr FS shall not be responsible in any way for any interest or claims of any third parties in respect of the Modulr Products, except as required by law or regulation.</li>
                      </ol>
                    </li>
                    <li><strong>Reports</strong>
                      <ol>
                        <li>Modulr may make available certain management or other reporting or business administration functionality via the Website.</li>
                        <li>Modulr may from time to time amend, modify, replace or withdraw in whole or in part such reporting it provides without further notice.</li>
                      </ol>
                    </li>
                    <li><strong>Data Privacy</strong>
                      <ol>
                        <li>Modulr will collect and retain personal information about the Introduced Client and each Authorised User and Cardholder to enable Modulr to deliver the Modulr Products, the services linked to it and deal with any enquiries that the Introduced Client may have about it. Modulr is the data controller of the personal information gathered by Modulr for such purpose. If Modulr uses a third party to provide a part of the Modulr Product then that provider will be the owner and controller of the personal information they require to collect in order to operate the relevant service. The use of personal information by third-party service providers will be set out in their service terms and conditions of use. Modulr will, at such third-party provider’s direction, process personal data on its behalf, for example, to enable Modulr to provide Customer Services to the Introduced Client.</li>
                        <li>Modulr processes personal information in accordance with relevant laws on the protection of personal data.</li>
                        <li>If Modulr transfers the Introduced Client's information to a third party in a country outside of the European Economic Area Modulr will ensure that the third party agrees to apply the same levels of protection that Modulr is legally obliged to have in place when Modulr processes personal data.</li>
                        <li>Further information about how Modulr uses personal information can be found in Modulr's Privacy Policy; please contact Customer Services for a copy of this.</li>
                      </ol>
                    </li>
                    <li><strong>Changes to the Agreement</strong>
                      <ol>
                        <li>Modulr may amend or modify this Agreement by giving no less than two (2) months' notice to the Introduced Client unless Modulr is required to make such a change sooner by law. All proposed changes will be posted on the Website and communicated to the Introduced Client by such other means that Modulr agreed with the Introduced Client, for example by email.  If the Introduced Client is accessing Modulr Products via a Partner Platform, all notifications will be communicated via such Partner Platform.</li>
                        <li>The Introduced Client has no obligation to accept such amendments proposed by Modulr.</li>
                        <li>The Introduced Client will be taken to have accepted any change to this Agreement that Modulr notifies to the Introduced Client unless the Introduced Client tells Modulr otherwise before the relevant change takes effect.  In such circumstance, Modulr will treat notice of objection by the Introduced Client as notification that the Introduced Client wishes to terminate this Agreement and the use of all Modulr Products immediately. All Accounts of the Introduced Client will be closed and any balance remaining in the Introduced Client's Account will be returned to the Introduced Client in accordance with the redemption procedure set out in the Modulr Account Terms and Conditions.   In this circumstance the Introduced Client will not be charged a fee for the Account closure and return of any balance.</li>
                      </ol>
                    </li>
                    <li><strong>General</strong>
                      <ol>
                        <li>In these Introduced Client Terms of Business, headings are for convenience only and shall not affect the interpretation of these Introduced Client Terms of Business.</li>
                        <li>Any delay or failure by Modulr to exercise any right or remedy under this Agreement shall not be interpreted as a waiver of that right or remedy or stop Modulr from exercising its rights at any subsequent time.</li>
                        <li>In the event that any part of this Agreement is held not to be enforceable, this shall not affect the remainder of the Agreement which shall remain in full force and effect.</li>
                        <li>The Introduced Client shall remain responsible for complying with this Agreement until its Account(s) are closed (for whatever reason) and all sums due under this Agreement have been paid in full.</li>
                        <li>This Agreement is written and available only in English and all correspondence with the Introduced Client shall be in English.</li>
                        <li>This Agreement is governed by the laws of England and the Introduced Client agrees to the non-exclusive jurisdiction of the English courts.</li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsConditions;
