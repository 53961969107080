import React, {useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import OutStanding from "./OutStanding";
import Overview from "./Overview";
// import Repayment from "./Repayment";
import Sales from "./Sales";
import Transactions from "./Transactions";
// import Select from 'react-select'
// import { customStyles } from "../../utils/customSelect";
import Footer from "../../components/Footer";
// import { useLocation } from "react-router-dom";
import { customStyles, customStyles2 } from "../../utils/customSelect";
import Select from "react-select";
import ViewTransations from "./ViewTransations";
import { useDispatch, useSelector } from "react-redux";
import { listClientData } from "../../redux/action/listClient";
const AdminLedgers = (props) => {
  const [allCustomerData, setAllcustomerData] = useState({});
  const [options, setOptions] = useState([]);
  const [customerData, setcustomerData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState();
  const [showTabs, setShowTabs] = useState('overview');
  const [isClientSelected, setisClientSelected] = React.useState(false);

  // const location = useLocation();
  const dispatch = useDispatch();
  const select = useSelector(
    (state) => state && state.listClient && state.listClient.listClientData
  );

  const selectHandler = (e) => {
    setSelectedOptions({ label: e.label, value: e.value });
    const userData = allCustomerData?.find((el) => el.application_id === e.value);
    setcustomerData(userData);
  };

  useEffect(() => {
dispatch(listClientData());
document.title = props.title;
  }, []);

  useEffect(() => {
    setAllcustomerData(select);
    const sortedComp = select && select.sort((a, b) => {
        let fa = a.company_name.toLowerCase(),
            fb = b.company_name.toLowerCase();
        if (fa < fb) { return -1; }
        if (fa > fb) { return 1; }
        return 0;
    });
    const optionsData = sortedComp && sortedComp.map((el) => {
        return { value: el.application_id, label: (el.company_name?el.company_name + "-" :'') + el.first_name + " " + el.last_name };
      });
    setOptions(optionsData);
  }, [select]);

 useEffect(() => {
   if (selectedOptions && selectedOptions?.value && selectedOptions?.value !=='') {
     
     setisClientSelected(true);
   }
 }, [selectedOptions?.value])
 

  return (
    <>
      <NavBar admin="true" />
      <section className={`sectionone ${isClientSelected ?'bg-light':''}`} style={{minHeight: `${isClientSelected ?'72vh':'56vh'}`}}>
      {isClientSelected ? (<>
        <div className="container ">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right d-flex justify-content-between mt-3">
              <div className="h3">
                {/* {(customerData?.first_name || "") + " " + (customerData?.last_name || "")} */}
              </div>
              <div className="mb-2">
                <Select
                  className="react-select-container"
                  onChange={selectHandler}
                  value={selectedOptions}
                  classNamePrefix="react-select"
                  noOptionsMessage={() => "No Client"}
                  options={options}
                  styles={customStyles}
                  placeholder="Select Client"
                  />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              {    // modulr_verification_status = None | UNVERIFIED | VERIFIED
                  customerData&&customerData.modulr_verification_status&&customerData.modulr_verification_status === 'VERIFIED' ? 
                      <div className="alert alert-success">
                          <strong>Verified!</strong> User's modulr account is verified.
                      </div>
                  :
                      <div className="alert alert-danger">
                          <strong>Unverified!</strong> User's modulr account is not verified.
                      </div>
              }
            </div>
          </div>
        </div>
      
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <ul className="nav nav-tabs flex-row" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link w-100 text-center ${showTabs==='overview'?'active':''}`}
                    onClick={()=>setShowTabs('overview')}
                    >
                    Overview
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link w-100 text-center ${showTabs==='sales'?'active':''}`}
                    onClick={()=>setShowTabs('sales')}
                  >
                    Sales & Repayments
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link w-100 text-center ${showTabs==='transactionHistory'?'active':''}`}
                    onClick={()=>setShowTabs('transactionHistory')}
                  >
                    Transaction History
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link w-100 text-center ${showTabs==='transactions'?'active':''}`}
                    onClick={()=>setShowTabs('transactions')}
                  >
                    Transactions
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link w-100 text-center ${showTabs==='outChallenge'?'active':''}`}
                    onClick={()=>setShowTabs('outChallenge')}
                  >
                    Outstanding Challenges
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                >
                { showTabs==='overview' && <Overview applicationId={selectedOptions?.value} />}
                { showTabs==='sales' && <Sales applicationId={selectedOptions?.value} />}
                { showTabs==='transactionHistory' && <ViewTransations applicationId={selectedOptions?.value} userId={customerData&&customerData.id?customerData.id:null} />}
                { showTabs==='transactions' && <Transactions applicationId={selectedOptions?.value} modulrRuleId={customerData&&customerData.modulr_rule_id?customerData.modulr_rule_id:null}/>}
                { showTabs==='outChallenge' && <OutStanding applicationId={selectedOptions?.value} />}
                </div>
           
              </div>
            </div>
          </div>
        </div>
        </>
         ) : (
          <div className="container ineligible-info flex-column">
            <h4>
              Please select Client
              </h4>
              <Select
                  className="react-select-container"
                  onChange={selectHandler}
                  value={selectedOptions}
                  classNamePrefix="react-select"
                  noOptionsMessage={() => "No Client"}
                  options={options}
                  styles={customStyles2}
                  placeholder="Please Select Client"
                  />
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default AdminLedgers;
