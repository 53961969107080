import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import { css } from "@emotion/react";
import logo from "../../img/logo-light.png";
import Loginpic from "../../img/img-19.png";
import { HttpCall } from "../../services/UseHttps";
import { forgetPasswordAPI, verifyFPOtpAPI } from "../../config";

 const ForgotPassword = (props) =>{
    useEffect(() => { document.title = props.title;
    }, []);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    // otp
    const [showOTP, setShowOTP] = useState(false);
    const [otpCount, setOtpCount] = useState(0);
    const [otpError, setOtpError] = useState('');
    const [loadingOtp, setLoadingOtp] = useState(false);
    const [otpSuccess, setOtpSuccess] = useState("");
    const [requestBody, setRequestBody] = useState({}); 
    const numePattern = /^\d+$/;

    const forgetSchema = Yup.object().shape({
        email: Yup.string().required('Email Address is required').email('Email Address is invalid')
    });
    const override = css`
        display: block;
        margin: 0 auto;
        border-color: green;
    `;
    const handleSubmit = (formValues, isEmail) => {
        const email = isEmail ? isEmail : formValues.email;
        try {
            setErrorMsg('');
            setLoading(true);
            setOtpSuccess('');
            setLoadingOtp(true);
            setRequestBody({"email": email});
            HttpCall(`${forgetPasswordAPI}?email=${email}`, "GET").then((res) => {
                if (res && res.status && (res.status === 200 || res.status === 201) && res.data && res.data.data) {
                    setOtpSuccess('Verification code has been sent to your registered mobile number');
                    setShowOTP(true); // show otp verify screen even if error.
                    setRequestBody({"email": email, "phone_number": res.data.data });
                } else {
                    setShowOTP(true); // show otp verify screen even if error.
                    setOtpSuccess(`Verification code has been sent to your registered mobile number linked to ${email} email address.`);
                }
                setLoading(false);
                setLoadingOtp(false);
            }).catch((error) => {
                setShowOTP(true); // show otp verify screen even if error.
                setOtpSuccess(`Verification code has been sent to your registered mobile number linked to "${email}" email address.`);
                setLoading(false);
                setLoadingOtp(false);
            });
        } catch(err) {
            setLoading(false);
            setLoadingOtp(false);
        }   
    }

    // otp
    const otpSchema = Yup.object().shape({
        otpFP: Yup.string().required('Verification code is required')
        .matches(numePattern, 'Verification code is allows only numbers.')
        .max(10, "Please enter valid verification code.")
    });
    const closeOtpModel = () => {
        setShowOTP(false);
        setLoading(false);
        setLoadingOtp(false);
        setOtpCount(0);
        setRequestBody({});
        setOtpError('');
        setOtpSuccess('');
    }
    const verifyOTPHandle = (values) => {
        try {
            if(values && values.otpFP && requestBody.email && requestBody.phone_number) {
                setOtpSuccess('');
                setLoadingOtp(true);
                let body = { "email": requestBody.email, phone_number: requestBody.phone_number, "otp": values.otpFP };
                HttpCall(`${verifyFPOtpAPI}`, "POST", body).then((res) => {
                    if (res && res.data && res.status && (res.status === 200 || res.status === 201)) {
                        if(res.data.token) {
                            localStorage.setItem('token', res.data.token);
                            setShowOTP(false);
                            Swal.fire({icon: 'success',title: 'Success', text: 'Verification code is verified successfully.'});
                            navigate('/reset-password');// go to reset password screen
                        } else {
                            setOtpError('Verification code does not match.');
                        }
                    } else {
                        setOtpError('Fail to verify code.');
                    }
                    setLoadingOtp(false);
                }).catch((error) => {
                    Swal.fire({icon: 'error',title: 'Error',
                        text: error.response && error.response.data && error.response.data.message ?  error.response.data.message : 'Fail to verify verification code.'
                    });
                    setLoadingOtp(false);
                });
            } else {
                Swal.fire({icon: 'error',title: 'Error', text: 'Entred verification code is not valid.'});
            }
        } catch(err) {
            setLoadingOtp(false);
        }
    }
    const reSendOtpHandle = () => {
        setOtpSuccess('');
        if(otpCount < 3) {
            if(requestBody && requestBody.email) {
                setOtpCount(otpCount+1);
                handleSubmit('', requestBody.email);
            } else {
                setOtpError('Email-id not found to send verification code.')
            }
        } else {
            setOtpError('You exceeded re-send verification code limit.');
        }
    }

    return (
        <>
            <section className="overflow-hidden div-height">
                <div className="row div-height">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="background h-100">
                            <div className="text-right px-4 pt-3 pb-5">
                                <Link to="/">
                                    <img src={logo} alt="logo" width="200" height="20" className="" />
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <img className="text-center" src={Loginpic} alt="welcome" height="350" width="400" />
                                    <h1 className="text-white mb-4">Barlow Marshall</h1>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center pb-4">
                                <div>
                                    <Link className="text-white PX-2" to={'/terms-conditions'}>Terms and Conditions</Link>
                                    <span className="text-white mx-2"> | </span>
                                    <Link className="text-white px-2" to={'/privacy-policy'}>Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 bg-light">
                        <div className="signup-box p-5 mt-2">
                            {!showOTP ? 
                                <>
                                    <h1 className="text-center mb-4"><b>Forgot Password</b></h1>
                                    {errorMsg && <div className="alert alert-danger" role="alert">{errorMsg}</div> }
                                    <Formik
                                        initialValues={{ email: "" }}
                                        validationSchema={forgetSchema}
                                        onSubmit={(values) => {
                                            handleSubmit(values, '')
                                        }}
                                    >
                                        {({ touched, errors}) =>
                                            !loading ? (
                                                <div>
                                                    <Form className="custom-form">
                                                        <div className="mb-3">
                                                            <Field type="email" name="email"
                                                                placeholder="Email Address" id="email"
                                                                autoComplete="off" className={`mt-2 form-control
                                                                ${touched.email && errors.email ? "is-invalid" : ""}`}
                                                            />
                                                            <ErrorMessage
                                                                component="div"
                                                                name="email"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <button type="submit" className="mb-4 btn btn-custom btn-custom-2 btn-custom-no-white-shadow">
                                                            {loading ? <><ClipLoader css={override} size={150} /></> : <>Submit</> }
                                                        </button>
                                                        <Link to="/login" className="text-decoration-none" style={{float:'right'}}>Back to Login</Link>
                                                        <br />
                                                    </Form>
                                                    <br />
                                                </div>
                                            ) : (
                                                <> <ClipLoader css={override} size={150} /> </>
                                            )
                                        }
                                    </Formik>
                                </>
                            : 
                                <>
                                    <h3 className="text-center mb-4"><b>Enter OTP to Reset Password</b></h3>
                                    <Formik initialValues={{ otpFP: "" }} validationSchema={otpSchema}
                                        onSubmit={(values) => { verifyOTPHandle(values) }}>
                                    {({ touched, errors, values, handleChange}) => 
                                        <Form className="custom-form">
                                            {loadingOtp ? <ClipLoader size={150} css={{ display: 'block', margin: '0 auto', borderColor: 'green' }} color="green" />
                                                :
                                                <>
                                                    { otpSuccess ? <div className='alert alert-success' style={{marginTop:15, marginBottom:'unset', fontSize:'small'}}> {otpSuccess} </div> : '' }
                                                    <div className="mb-3">
                                                        <Field
                                                            type="text" name="otpFP" value={values?.otpFP} onChange={handleChange}
                                                            id="otpFP" placeholder="Enter OTP" autoComplete="off"
                                                            aria-describedby="businessHelp" className={`mt-2 form-control
                                                            ${touched.otpFP && errors.otpFP ? "is-invalid" : ""}`}
                                                        />
                                                        <ErrorMessage component="div" name="otpFP" className="invalid-feedback"/>
                                                        { otpError ? <div className='alert alert-danger'> {otpError} </div> : '' }
                                                    </div>
                                                    <div className="mb-3">
                                                        <button type="button" className="btn btn-link" onClick={reSendOtpHandle}>Resend OTP</button>
                                                        <button type="button" className="btn btn-link" onClick={closeOtpModel} style={{float:'right'}}>Back</button>
                                                    </div>
                                                    <button type="submit" className=" btn btn-custom btn-custom-2 btn-custom-no-white-shadow">Verify</button>
                                                </>
                                            }
                                        </Form>
                                        }
                                    </Formik>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ForgotPassword;