import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { MDBDataTableV5 } from 'mdbreact';

const TransactionList = ({number, sheetName, transItem}) => {
  const [loading, setLoading] = useState(true);
  const mdbColHeaders = [
    {
      label: "Sort Code",
      field: "sort_code",
      sort: "asc",
      width: 150,
    },
    {
      label: "Account No",
      field: "account_no",
      sort: "asc",
      width: 150,
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      width: 150,
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 150,
    },
    {
      label: "Reference",
      field: "ref_no",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency",
      field: "currency",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency Debit",
      field: "currency_debit",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency Credit",
      field: "currency_credit",
      sort: "asc",
      width: 150,
    },
    {
      label: "Currency to GBP Exchange",
      field: "currency_rate",
      sort: "asc",
      width: 150,
    },
    {
      label: "GBP Debit (Received)",
      field: "debit",
      sort: "asc",
      width: 150,
    },
    {
      label: "GBP Credit (Spent)",
      field: "credit",
      sort: "asc",
      width: 150,
    }
  ];
  const [datatable, setDatatable] = useState({
    columns: mdbColHeaders,
    rows: transItem,
  });


  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
    <div className="">
    <div className="mb-4">
                    <div className="text-left"><h4>{number}. Preview Transactions Sheet : {sheetName} </h4></div>
                    {datatable.rows.length === 0 && (
                      <div
                        className="dierector-content mt-3 "
                        style={{
                          borderRight: "15px solid #003056",
                          borderLeft: "15px solid #003056",
                        }}
                      >
                        <p className="table-font-color m-0">
                          <b>No Data Found !!</b>
                        </p>
                      </div>
                    )}
                    {datatable.rows.length > 0 &&
                      (!loading ? (
                        <MDBDataTableV5 responsive
                          striped
                          bordered
                          hover
                          noRecordsFoundLabel="true"
                          data={datatable}
                          searching={false}
                          fullPagination
                          searchTop
                          searchBottom={false}
                          entriesOptions={[2, 5, 10, 20, 50, 100]}
                          paging={!(datatable.rows.length <= "4")}
                          className={`${
                            datatable.rows.length <= "4"
                            ? "hide-pagination"
                            : ""
                            }`}
                        // pagingTop searchTop
                        // theadColor="blue lighten-5" entries={100} pagesAmount={4} order={['uptime', 'asc']} className="mx-auto"
                        />
                      ) : (
                        <>
                          <ClipLoader
                            size={150}
                            css={{
                              display: "block",
                              margin: "0 auto",
                              borderColor: "green",
                            }}
                            color="green"
                          />
                        </>
                      ))}
                  </div>
                   
                    </div>
    </>
  );
};

export default TransactionList;
