import React, { useEffect, useRef } from "react";
// import SignatureCanvas from "react-signature-canvas";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import Swal from "sweetalert2";
import { handleError, HttpCall } from "../services/UseHttps";
import { useState } from "react";
import { useFormik } from "formik";
// import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profileInfoData, profileInfoSuccess } from "../redux/action/profileInfo";
import RejectIcon from "../../src/img/rejectedIcon.svg";
import acceptIcon from "../../src/img/acceptIcon.svg";
import PendingIcon from "../../src/img/Pending@2x.svg";
import queryIcon from "../../src/img/query.svg";
import { offerStatusAPI, viewOfferAPI } from "../config";
import AcceptOffer from "./AcceptOffer";
import ClipLoader from "react-spinners/ClipLoader";
import { formatAmount, getDateDDMMYYYY } from "../services/CommonFunction";

const ViewOffer = (props) => {
  useEffect(() => { document.title = props.title }, [])
  const profileData = useSelector(state=>state && state.profileInfo && state.profileInfo.profileData && state.profileInfo.profileData.application_obj);
  const dispatch = useDispatch();
  const [modalShowAccept, setModalShowAccept] = React.useState(false);
  const [isIneligible, setIsIneligible] = React.useState(false);
  const [offerDetails, setOfferDetails] = useState();
  const [status, setStatus] = useState(null);
  const [errorMessage, setErorrMessage] = useState('');
  const sigCanvas = useRef({});
  const [showAcceptOffer, setShowAcceptOffer] = useState(false);
  const clears = () => {
    if(Object.keys(sigCanvas.current || {}).length!==0) { 
      return sigCanvas?.current?.clear()
    }
  
  };
  // const save = (data) => {
  //   setTrimmedDataURL(
  //     sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
  //   );
  // };

  let viewOfferCount = 0;
  useEffect(() => {
    if (profileData  && profileData.application_id) { 
      if(viewOfferCount === 0) {
        viewOfferCount=1;
        HttpCall(`${viewOfferAPI}/${profileData.application_id}`, "get").then((res) => {
          if(res && res.data && res.data.data) {
            setOfferDetails(res.data.data);
          }
        }).catch((error) => {
          handleError(error);
        });
      }
    }
    setTimeout(()=>{ viewOfferCount = 0; },2000);

    // setApplicationId(profileInfo && profileInfo?.application_id)
    if (profileData && profileData.application_status==='ineligible') { 
      setIsIneligible(true);
      return;
    }
  }, [(profileData && profileData.application_id)]);

  const confirmQueryHandler =() => {
    Swal.fire({
      title: 'Send Query',
      input: 'text',
      inputPlaceholder: "Write your query here.....",
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Send',
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        if (inputValue === "") {
          Swal.showValidationMessage("Please enter your query ...") ;
              return false;
            }
      },
    }).then((res) => {
      if (res.isConfirmed) {
        setStatus("query");
        offerHandler({ reason: res?.value,status:'query' });
      }
    })
  }
  const confirmRejection = () => {
    Swal.fire({
      title: "Are you sure, You want to reject this offer?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I am sure!",
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          {
            title: "Please write rejection reason",
            input: "text",
            inputAttributes: {
              autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            showLoaderOnConfirm: true,
            onOpen: () => Swal.getConfirmButton().focus(),
            inputPlaceholder: "Rejection Reason",
            preConfirm: (inputValue) => {
              if (inputValue === "") {
                Swal.showValidationMessage("Please enter rejection reason") ;
                    return false;
                  }
                  return inputValue;
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((res) => {
            if (res && res.isConfirmed) {
              setStatus("rejected");
              offerHandler({ reason: res && res.value,status:'rejected' });
            return;
            }
            return;
          })
      }
    });
  };
  const dtatUrlBlob = function(dataURI, dataTYPE) {
    if(typeof dataURI !== 'string'){
      throw new Error('Invalid argument: dataURI must be a string');
  }
  dataURI = dataURI.split(',');
  var type = dataURI[0].split(':')[1].split(';')[0],
      byteString = atob(dataURI[1]),
      byteStringLength = byteString.length,
      arrayBuffer = new ArrayBuffer(byteStringLength),
      intArray = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteStringLength; i++) {
      intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([intArray], {
      type: type
  });
  }
  const offerHandler = (value) => {
    if ( status==='accepted' && sigCanvas.current.isEmpty()) {
      setErorrMessage('Please do your signature to accept offer...')
      return;
    }
    let data = new FormData();
    data.append("status", status || value?.status);
    data.append("reason", value?.reason);
    data.append("note", value?.reason);
    data.append(
      "file",
      status==='accepted'?dtatUrlBlob( sigCanvas?.current?.getTrimmedCanvas()?.toDataURL("image/png") ||''):''
    );
    HttpCall(`${offerStatusAPI}/${profileData.offer.id}`, "patch", data)
      .then((res) => {
        Swal.fire({
          title: "Done!",
          text: `${(status || value?.status)==='rejected'?'Offer Rejected':(status || value?.status)==='accepted'?'Offer Accepted':'Query Send'}`,
          icon: "success",
          // timer: 2000,
          // button: false,
        });
        formik.resetForm();
        dispatch(profileInfoSuccess(''));
        clears();
        setStatus(null);
        setModalShowAccept(false);
        dispatch(profileInfoData());
        setErorrMessage('');
        window.scrollTo(0, 0);
      }).catch((error) => {
        handleError(error);
      });
  };

  const initialValues = {
    status: "",
    reason: "",
    signature: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: offerHandler,
  });
  return (
    <>
      <NavBar />
      {!isIneligible && profileData && profileData.application_status!=="NEW"? (
        showAcceptOffer? <AcceptOffer setShowAcceptOffer={setShowAcceptOffer}/> :
        <div className="pb-5 bg-light" style={{minHeight: "75vh"}}>
          <div className="container">
            <div className="row py-3">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
               <div className="offer-container">
                 { offerDetails && offerDetails.status ? <>
                    <div className={`offer-${offerDetails?.status}-icon  glyphicon glyphicon-remove-circle`}>
                    { offerDetails?.status===('withdraw_offer' || 'rejected') && <img width={'40px'} src={RejectIcon} alt=""/>}
                    { offerDetails?.status===('accepted') && <img  width={'40px'} src={acceptIcon} alt=""/>}
                    { offerDetails?.status===('pending') && <img width={'40px'} src={PendingIcon} alt=""/>}
                    { offerDetails?.status===('query') && <img width={'40px'} src={queryIcon} alt=""/>}
                    </div>
                    <div className={`offer-status offer-${offerDetails?.status}`}>Offer {offerDetails && offerDetails?.status==='withdraw_offer'?'Withdrawn!!':offerDetails?.status +'!!'}</div>
                    {/* <div className="offer-description">{offerDetails?.reason || ''}</div> */}
                    { offerDetails&&offerDetails.reason&&
                      <div className="dierector-content mt-3" style={{width:'100%'}}>
                        <p className="table-font-color m-0">
                          <b>Query : </b>{offerDetails?.reason}
                        </p>
                      </div>
                    }
                  </>
                 :
                  <ClipLoader size={70} css={{ display: 'block', margin: '0 auto', borderColor: 'blue' }} color="blue" />
                 }
               </div>
                <div className="">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <b> PARTIES</b>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                      >
                        <div className="accordion-body">
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Advance Recipient</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                            {offerDetails?.company_name}
                            </p>
                          </span>
                          <hr />
                          {/* <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Guarantor (parent company, if applicable)</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              Guarantor name
                            </p>
                          </span>
                          <hr /> */}
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Advance Provider</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              Accelerate Digital Limited (
                              <b>“Barlow Marshall”</b>){" "}
                            </p>
                          </span>
                          {/* <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Other parties</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              Other parties[Any additional parties] (together
                              with Advance Recipient, the “<b>Business</b>”)
                            </p>
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <b> PRINCIPAL TERMS</b>
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                      >
                        <div className="accordion-body">
                          {/* <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Effective From Date</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails&&offerDetails.effective_from&&offerDetails.effective_from !== "None"?getDateDDMMYYYY(offerDetails.effective_from):''}
                            </p>
                          </span>
                          <hr /> */}
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Advance Amount</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails&&offerDetails.advance_amount?'£'+formatAmount(offerDetails.advance_amount):''}
                            </p>
                          </span>
                          <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Fixed Fee</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails&&offerDetails.fixed_fee?'£'+formatAmount(offerDetails.fixed_fee):''}
                            </p>
                          </span>
                          <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Full Repayment</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails&&offerDetails.full_repayment?'£'+formatAmount(offerDetails.full_repayment):''} (“Repayment
                              Amount”)
                            </p>
                          </span>
                          <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Minimum Split (first 12 months)</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails?.minimum_split_first_12_months}% of
                              Net Receipts
                            </p>
                          </span>
                          <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Minimum Split (from 12 months)</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails?.minimum_split_from_12_months}% of
                              Net Receipts until the Repayment Amount has been
                              received in full by us.
                            </p>
                          </span>
                          <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Maximum Split</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails?.maximum_split}% of Net Receipts
                            </p>
                          </span>
                          <hr />
                          <span className="d-flex">
                            <p className="mx-3 w-40 my-0 table-font-color">
                              <b>Minimum Performance</b>
                            </p>
                            <p className="w-60 my-0 table-font-color">
                              {offerDetails&&offerDetails.minimum_performance?'£'+formatAmount(offerDetails.minimum_performance):''} per month
                              Based on information you have provided about your
                              past performance, we expect that your Net Receipts
                              will always exceed this Minimum Performance.
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="true"
                          aria-controls="collapseThree"
                        >
                          <b> ADDITIONAL TERMS</b>
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                      >
                        <div className="accordion-body">
                          <div className="">
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Facility Type</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Revenue sharing finance facility.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Purpose</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Working capital and growth finance.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Repayment</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Repayment will be made via a Split of Net
                                Receipts until Full Repayment has been made.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Gross Receipts</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Means all amounts (including any value added or
                                other tax) which are paid to the Business by any
                                customer for goods or services using any means
                                of payment.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Net Receipts</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Means, in relation to any period, the
                                Business&apos;s Gross Receipts during such
                                period, after deducting any Permitted Offsets
                                referable to such period.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Permitted Offsets</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Means the proceeds of any loans from commercial
                                lenders permitted under this agreement, sales of
                                securities, insurance claims, litigation
                                settlements, customer refunds or reimbursements,
                                R&D Tax rebates, VAT rebates
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Drawdown</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                A single draw down of the Facility in the
                                Advance Amount shall be made following
                                completion of Conditions Precedent.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Split</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Means the percentage of Net Receipts the
                                Business is required to pay to Barlow Marshall
                                in repayment of the Repayment Amount
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Re-rate</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                means the determination of whether the Minimum
                                Split, Adjusted Split or Maximum Split shall be
                                payable from each Re-rate Date until the next
                                Re-rate Date, dependent on whether cumulative
                                repayments as at the Re-rate Date meet the
                                Re-rate Thresholds (as explained here [link to
                                table]).
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Re-Rate Date</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                means the first anniversary of the date of this
                                agreement, and the dates falling at six-monthly
                                intervals thereafter. See more details on the
                                re-rate here [link to re-rate]
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Re-rate Threshold</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Cumulative repayment thresholds at 12, 18, 24,
                                30 and 36 months. See more details on the
                                re-rate here [link to re-rate]
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Adjusted Split</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Means, as at any Re-rate Date in respect of
                                which the Cumulative Repayments to such date are
                                less than the Re-rate Threshold as at such date,
                                the percentage which is the product of the
                                following calculation:
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Minimum Performance</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                If you anticipate that your Net Receipts will
                                not be at least equal to this Minimum
                                Performance at any time, you must tell us as
                                soon as you become aware. If, for 3 consecutive
                                months, the monthly Net Receipts does not exceed
                                the Minimum Performance, or the aggregate Net
                                Receipts in any 6-month period does not exceed
                                the Minimum Performance multiplied by 6, this
                                shall be a Termination Event.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Repayment Method</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Repayment via use of dedicated settlement
                                account with automated split
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Information Requirements</b>
                              </p>
                              <div className="w-60 my-0 table-font-color">
                                <p className="w-60 my-0 table-font-color">
                                  To include (without limitation):{" "}
                                </p>
                                <p className="w-60 my-0 table-font-color">
                                  A) Online, read only, access to:
                                </p>
                                <ul>
                                  <li>
                                    Open Banking, or visibility of all Business
                                    bank accounts in such other manner that is
                                    acceptable to Barlow Marshall.{" "}
                                  </li>
                                  <li>
                                    Accounting management software for the
                                    Business.
                                  </li>
                                </ul>
                                <p className="w-60 my-0 table-font-color">
                                  {" "}
                                  B) Within agreed timescales:
                                </p>
                                <ul>
                                  <li>
                                    Management information and reports for the
                                    Business.
                                  </li>
                                  <li>
                                    Audited financial statements for the
                                    Business.
                                  </li>
                                </ul>
                                <p className="w-60 my-0 table-font-color">
                                  C) Books, records, documents and other written
                                  evidence, consistent with its normal
                                  accounting procedures and practices,
                                  sufficient to reasonably and accurately
                                  reflect the performance of its obligations and
                                  the determination of the Split amount.
                                </p>
                              </div>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Voluntary Prepayment</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Allowed, in whole or in part, with at least 5
                                business days written notice.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Mandatory Prepayment and Cancellation</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Standard for facilities of this nature and to
                                include (without limitation): Acceleration
                                following Termination Event; Change of control;
                                sale of a substantial part of the assets or
                                business of the group.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Termination Events</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Without limitation, Minimum Performance, failure
                                to pay, agreement breach, misrepresentation,
                                insolvency; administration; cross default;
                                dissolution or liquidation; disposal and
                                suspension or cessation of business; cessation
                                of business, loss of licence.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Information Requirements</b>
                              </p>
                              <div className="w-60 my-0 table-font-color">
                                <p className="w-60 my-0 table-font-color">
                                  Standard for facilities of this nature and to
                                  include typical business restrictions
                                  equivalent to those commonly used in
                                  commercial lending transactions. Subject to
                                  the side letter agreement, the Business and
                                  the Guarantor will require the prior written
                                  consent of Barlow Marshall for any:
                                </p>
                                <ul>
                                  <li>
                                    acquisitions of any company, business,
                                    shares, securities or undertaking to
                                    incorporate or form a company.
                                  </li>
                                  <li>
                                    Disposals of any assets other than in the
                                    ordinary course of business.
                                  </li>
                                  <li>
                                    Borrowings, beyond arrangements disclosed
                                    and approved by Barlow Marshall in advance
                                    of the facility.
                                  </li>
                                </ul>
                              </div>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Conditions Precedent to Financing</b>
                              </p>
                              <div className="w-60 my-0 table-font-color">
                                <p className="w-60 my-0 table-font-color">
                                  Standard for facilities of this nature and to
                                  include (without limitation):
                                </p>
                                <ol>
                                  <li>
                                    Execution of transaction documentation;
                                  </li>
                                  <li>
                                    Corporate authorisations and certificates;
                                  </li>
                                  <li>
                                    Establishment of information access and
                                    settlement account;
                                  </li>
                                  <li>
                                    Completion of all required due diligence;
                                    and
                                  </li>
                                  <li>
                                    KYC and other regulatory aspects as required
                                    by Barlow Marshall.
                                  </li>
                                </ol>
                              </div>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Guarantee</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                From the parent company. Standard for
                                arrangements of this nature and to include
                                typical commitments and restrictions equivalent
                                to those commonly used for guarantors in
                                commercial lending transactions.{" "}
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Taxes</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                All payments by the Advance Recipient shall be
                                made free and clear of all withholdings, taxes
                                and deductions.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Governing Law</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                The Laws of England.
                              </p>
                            </span>
                            <hr />
                            <span className="d-flex">
                              <p className="mx-3 w-40 my-0 table-font-color">
                                <b>Confidentiality</b>
                              </p>
                              <p className="w-60 my-0 table-font-color">
                                Each Party acknowledges that the Offer is
                                confidential and shall not, and shall ensure
                                that no other member of their respective group
                                of companies shall, without the prior written
                                consent of the other party, disclose the Offer
                                and any other related documents or their
                                contents to any other person except as required
                                by law or any applicable governmental or other
                                regulatory authority except to such party’s
                                solicitors and/or other professional advisors.
                              </p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 { profileData && profileData.offer && (profileData.offer.status !=='accepted' && profileData.offer.status !=='rejected' && profileData.offer.status !=='withdraw_offer' && profileData.offer.status !=='expired') && <div
                    className="row d-flex justify-content-md-between"
                    style={{ marginLeft: "70px" }}
                  >
                    <div className="col-md-4 mt-4 pb-4 ">
                      <button
                        type=" button"
                        onClick={() => {
                          setStatus("accepted");
                          setShowAcceptOffer(true);
                          window.scrollTo(0, 0);
                        }}
                        className="btn btn-custom btn-custom-3 w-75"
                        // data-bs-toggle="modal" data-bs-target="#exampleModal1"
                      >
                        Accept
                      </button>
                    </div>
                    <div className="col-md-4 mt-4 ">
                      <button
                        className="btn btn-custom btn-custom-4 w-75"
                        onClick={confirmRejection}
                        // data-bs-toggle="modal"
                        style={{ margin: "0 auto" }}
                      >
                        Reject
                      </button>
                    </div>
                    <div className="col-md-4 mt-4  ">
                      <button
                        type="button"
                        onClick={confirmQueryHandler}
                        className="btn btn-custom btn-custom-2 w-75"
                        // data-bs-toggle="modal"
                      >
                        Send Query
                      </button>
                    </div>
                  </div>}
                  <div className="dierector-content mt-3">
                    <p className="table-font-color m-0">
                      <b>
                        &quot;THE TERMS SET OUT IN THIS OFFER ARE INDICATIVE
                        ONLY AND ARE SUBJECT TO ENTRY INTO A FULL SERVICE
                        AGREEMENT WITH BARLOW MARSHALL&quot;
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
   
          {/* <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Re-rate Mechanism</h5>
                                <button type="button" onClick={()=>setStatus(null)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="card">
                                    <div className="card-body">
                                        <table className="table border">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Re-rate Date (“x” Months from the date of this agreement)</th>
                                                    <th scope="col">Re-rate Threshold</th>
                                                    <th scope="col">Minimum Split at such date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>12 Months</td>
                                                    <td>£xxx,xxx</td>
                                                    <td>xxx%</td>
                                                </tr>
                                                <tr>
                                                    <td>18 Months</td>
                                                    <td>£xxx,xxx</td>
                                                    <td>xxx%</td>
                                                </tr>
                                                <tr>
                                                    <td>24 Months</td>
                                                    <td>£xxx,xxx</td>
                                                    <td>xxx%</td>
                                                </tr>
                                                <tr>
                                                    <td>30 Months</td>
                                                    <td>£xxx,xxx</td>
                                                    <td>xxx%</td>
                                                </tr>
                                                <tr>
                                                    <td>36 Months</td>
                                                    <td>£xxx,xxx</td>
                                                    <td>xxx%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p>
                                            As per the definitions, the "Adjusted Split" as at any Re-rate Date shall be calculated
                                            as the Re-rate Threshold for such Re-rate Date, expressed as a percentage of the
                                            Cumulative Repayments as at such Re-rate Date, multiplied by the Minimum Split.
                                            The Adjusted Split cannot however result in a Split which is lower than the Minimum
                                            Split.
                                        </p>
                                        <p>
                                            By way of example, if the Re-rate Threshold at the 12 months Re-rate Date was £500,000,
                                            and the cumulative Split Amount that had been paid up to that date was £400,000, and
                                            Minimum Split is 7.5%, then the Adjusted Split would be 9.375% (being 125% multiplied by
                                            7.5%)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        </div>
      ) : (
        <div className="ineligible-info">
          <p>
            The offer is ineligible please get in touch with Barlow Marshall
            admin at{" "}
            <Link
              to={window.location.pathname}
              hash="/#"
              onClick={() =>
                (window.location = "mailto:admin@barlowmarshal.com")
              }
            >
              admin@barlowmarshal.com
            </Link>
          </p>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ViewOffer;