export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#2283fb',
    padding: 6,
    borderLeft: '3px solid #fff',
    borderRight: '3px solid #fff',
    fontSize:'14px',
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#2283fb",
    color: '#fff !important',
    minWidth: '150px',
    maxWidth: '300px',
    margin: '0 3px',
    padding: '5px 7px',
    border: '2px solid #fff',
    borderRadius: '25px',
    boxShadow: 'none',
    "&:hover": {
      // border: '2px solid #fff',
      // color:'#fff !important',
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#fff" : "#FFF"
    },
    "&:focusVisible": {
      // border: '2px solid #fff',
      boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%) !important',
    },
    // none of react-select's styles are passed to <Control />
    // width: innerWidth,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: "#fff" };
  },
  input: base => ({
    ...base,
    color: "#fff !important",
  }),
  container: base => ({
    ...base,
    color: "#fff",
  }),
  placeholder: base => ({
    ...base,
    color: "#fff",
  }),
  valueContainer: base => ({
    ...base,
    color: "#fff"
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content",
      minWidth: "100%"
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#fff",
    "&:hover": {

      color: '#fff !important',

    },

  }),
} 
export const customStyles2 = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#2283fb',
    padding: 6,
    borderLeft: '3px solid #fff',
    borderRight: '3px solid #fff',
    fontSize:'14px',
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#2283fb",
    color: '#fff !important',
    minWidth: '600px',
    maxWidth: '900px',
    margin: '0 3px',
    padding: '5px 7px',
    border: '2px solid #fff',
    borderRadius: '25px',
    boxShadow: 'none',
    "&:hover": {
      // border: '2px solid #fff',
      // color:'#fff !important',
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#fff" : "#FFF"
    },
    "&:focusVisible": {
      // border: '2px solid #fff',
      boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%) !important',
    },
    // none of react-select's styles are passed to <Control />
    // width: innerWidth,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: "#fff" };
  },
  input: base => ({
    ...base,
    color: "#fff !important",
  }),
  container: base => ({
    ...base,
    color: "#fff",
  }),
  placeholder: base => ({
    ...base,
    color: "#fff",
  }),
  valueContainer: base => ({
    ...base,
    color: "#fff"
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content",
      minWidth: "100%"
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#fff",
    "&:hover": {

      color: '#fff !important',

    },

  }),
} 
export const customStylesSmallBox = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#2283fb',
    padding: 2,
    borderLeft: '2px solid #fff',
    borderRight: '2px solid #fff',
    fontSize:'13px',
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#2283fb",
    color: '#fff !important',
    minWidth: '120px',
    maxWidth: '300px',
    margin: '0 2px',
    padding: '2px 2px',
    border: '2px solid #fff',
    borderRadius: '25px',
    boxShadow: 'none',
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#fff" : "#FFF"
    },
    "&:focusVisible": {
      boxShadow: '0 0 0 0.25rem rgb(13 110 253 / 25%) !important',
    },
    // none of react-select's styles are passed to <Control />
    // width: innerWidth,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition, color: "#fff" };
  },
  input: base => ({
    ...base,
    color: "#fff !important",
  }),
  container: base => ({
    ...base,
    color: "#fff",
  }),
  placeholder: base => ({
    ...base,
    color: "#fff",
  }),
  valueContainer: base => ({
    ...base,
    color: "#fff"
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none'
  }),
  menu: ({ width, ...css }) => ({
      ...css,
      width: "max-content",
      minWidth: "100%"
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#fff",
    "&:hover": {
      color: '#fff !important',
    },
  }),
}